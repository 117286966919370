// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uipps-report {
  display: flex;
  height: 100%;
}
.uipps-report .preloader,
.reports-item .preloader {
  position: fixed; /* Закрепление относительно окна */
  top: 0;
  left: 0;
  width: 100%; /* Прелоадер занимает всю ширину экрана */
  height: 100%; /* Прелоадер занимает всю высоту экрана */
  background-color: rgba(237, 240, 242, 0.5); /* Полупрозрачный фон */
  text-align: center;
  z-index: 9999; /* Поверх всего */
  margin: 0;
}

.uipps-report .preloader__wrap,
.reports-item .preloader__wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Центрирование содержимого */
}
`, "",{"version":3,"sources":["webpack://./src/styles/UippsReport.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;AACd;AACA;;EAEE,eAAe,EAAE,kCAAkC;EACnD,MAAM;EACN,OAAO;EACP,WAAW,EAAE,yCAAyC;EACtD,YAAY,EAAE,yCAAyC;EACvD,0CAA0C,EAAE,uBAAuB;EACnE,kBAAkB;EAClB,aAAa,EAAE,iBAAiB;EAChC,SAAS;AACX;;AAEA;;EAEE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC,EAAE,8BAA8B;AAClE","sourcesContent":[".uipps-report {\n  display: flex;\n  height: 100%;\n}\n.uipps-report .preloader,\n.reports-item .preloader {\n  position: fixed; /* Закрепление относительно окна */\n  top: 0;\n  left: 0;\n  width: 100%; /* Прелоадер занимает всю ширину экрана */\n  height: 100%; /* Прелоадер занимает всю высоту экрана */\n  background-color: rgba(237, 240, 242, 0.5); /* Полупрозрачный фон */\n  text-align: center;\n  z-index: 9999; /* Поверх всего */\n  margin: 0;\n}\n\n.uipps-report .preloader__wrap,\n.reports-item .preloader__wrap {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%); /* Центрирование содержимого */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
