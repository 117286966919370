/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import '../../styles/UippsMain.css';
import FilterForUips from '../Filter/FilterForUipps';
import TableForUips from '../Table/TableForUipps';
import MultiMarkerMap from '../Maps/MultiMarkerMap';
import HeaderForObject from '../CustomElements/HeaderForObject';

import { clearUippss, initUippsFromServer } from '../../redux/actions/uipps.action';
import { emitGetCoordsStart, emitGetCoordsStop, listenUippsCoords } from '../../redux/actions/uipps.action';
import translations from '../../content/PagesContent/DevicePages/devicePageTranslations.json';
import useTranslations from '../../customHook/useTranslations';
import useInfiniteScroll from '../../customHook/useInfiniteScroll';
import { useFilterControl } from '../../customHook/useFilterControl';

function UippsMain(props) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);

  const [isViewMap, setIsViewMap] = useState(false);
  const [isViewList, setIsViewList] = useState(true);
  // состояние для строки поиска
  const [filterInput, setFilterInput] = useState('');

  // Начальное состояние для стейта фильтрации передаем пропсами
  const initialFormData = {
    id: '',
    road_id: '',
    directorate_id: '',
    department_id: '',
    train_type_id: '',
    train_number_id: '',
    responsible: '',
    online: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData, '', setFilterInput);

  // хук для запроса данных с учетом параметров поиска фильтрации и сортировки, и контроля скрола
  const { bottomBoundaryRef, handleSearch } = useInfiniteScroll(
    formData, // Данные фильтров
    filterInput, // Строка поиска
    setFilterInput, // Функция для изменения строки поиска
    initUippsFromServer, // Функция для инициализации данных
    'uipps', // Redux-селектор
    resetFilters, // Сброс фильтров
    clearUippss, // Очистка стейта
    isViewMap // Флаг для остановки запросов
  );

  // слушаем событие получения координат
  useEffect(() => {
    dispatch(listenUippsCoords());
  }, [dispatch]);

  return (
    <div className="uipps">
      <FilterForUips
        setIsViewList={setIsViewList}
        setIsViewMap={setIsViewMap}
        isViewList={isViewList}
        isViewMap={isViewMap}
        initialFormData={initialFormData}
        formData={formData}
        setFormData={setFormData}
        showBtnReset={showBtnReset}
        resetFilters={resetFilters}
        t={t}
      />
      <div className="uipps_content">
        <HeaderForObject
          searchInput={filterInput}
          onFilterChange={(e) => {
            setFilterInput(e?.target?.value);
          }}
          onFilterSubmit={handleSearch}
        />
        {isViewList && <TableForUips formData={formData} bottomBoundaryRef={bottomBoundaryRef} t={t} />}
        {isViewMap && (
          <MultiMarkerMap
            emitStartFunction={emitGetCoordsStart}
            listenStopFunction={emitGetCoordsStop}
            formData={formData}
            filterInput={filterInput}
            setSearchInput={setFilterInput}
            t={t}
          />
        )}
      </div>
    </div>
  );
}

export default UippsMain;
