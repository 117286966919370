// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uipps {
  display: flex; 
  background-color: var(--sadr-background);
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.uipps_content{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: var(--sadr-font-size-20);
  margin-left: var(--sadr-font-size-12) ;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/styles/UippsMain.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wCAAwC;EACxC,WAAW;EACX,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,sCAAsC;EACtC,sCAAsC;EACtC,gBAAgB;AAClB","sourcesContent":[".uipps {\n  display: flex; \n  background-color: var(--sadr-background);\n  width: 100%;\n  height: 100vh;\n  overflow: hidden;\n}\n\n.uipps_content{\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  margin-right: var(--sadr-font-size-20);\n  margin-left: var(--sadr-font-size-12) ;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
