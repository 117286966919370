/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import { handleUniversalChange } from '../../utils/formUtils';
import Accordeon from '../CustomElements/Accordeon';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesUippsFromServer,
} from '../../redux/actions/options.action';
import { clearMapData } from '../../redux/actions/mapData.action';
import '../../styles/FilterForUips.css';

function FilterForUips({
  setIsViewList,
  isViewList,
  setIsViewMap,
  isViewMap,
  formData,
  setFormData,
  showBtnReset,
  resetFilters,
  t,
}) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate_id', 'department_id'],
    'road_id',
    initOptionsDirectoratesUippsFromServer,
    messageForSelectDirectorates
  );
  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department_id'],
    'directorate_id',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //цвет кнопок, события кнопок
  const [colorBtn, setColorBtn] = useState(false);
  const handleButtonClickList = () => {
    if (!isViewList) {
      setIsViewList((prev) => !prev);
      setIsViewMap((prev) => !prev);
      setColorBtn((prev) => !prev);
      resetFilters();
      dispatch(clearMapData());
    }
  };
  const handleButtonClickMap = () => {
    if (!isViewMap) {
      setIsViewMap((prev) => !prev);
      setIsViewList((prev) => !prev);
      setColorBtn((prev) => !prev);
      dispatch(clearMapData());
      resetFilters();
    }
  };

  return (
    <Accordeon header="КОНТРОЛЬ УИППС" hideAbove={1650} qParamName="uiips_acc">
      <div className="accordeon_buttons__list-map">
        <button
          type="button"
          className={`accordeon_button ${!colorBtn ? 'accordeon_button__active' : ''}`}
          onClick={handleButtonClickList}
        >
          Список
        </button>
        <button
          type="button"
          className={`accordeon_button ${colorBtn ? 'accordeon_button__active' : ''}`}
          onClick={handleButtonClickMap}
        >
          Карта
        </button>
      </div>
      <button
        type="button"
        className={`accordeon__reset-filters ${showBtnReset && 'accordeon__reset-filters_visible'}`}
        onClick={resetFilters}
      >
        <span className="accordeon__reset-filters_text">Сбросить все фильтры</span>
        <img className="accordeon__reset-filters_icon" src="/image/close.svg" alt=""></img>
      </button>

      <form className="accordeon__form" id="" method="POST" action="#" autoComplete="off">
        <div className="accordeon__select-container" id="">
          <Input
            placeholder="Введите ID УИППС"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            value={formData.id}
            name="id"
            label="ID УИППС"
            // type="number"
          />
          {isViewList && (
            <Selector
              options={optionsForSelect.status}
              onChange={(selectedOption) => {
                handleUniversalChange(formData, setFormData, null, 'online', selectedOption, 'select');
              }}
              placeholder={t?.placeholderSelectStatus || 'Выберите статус'}
              isMulti={false}
              label={t?.labelStatus || 'Статус'}
              value={
                formData.online ? optionsForSelect?.status?.find((option) => option.value === formData.online) : null
              }
              name="online"
            />
          )}
          <Selector
            options={optionsForSelect?.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'road_id', selectedOption, 'select');
            }}
            placeholder="Выберите дорогу"
            value={
              formData.road_id ? optionsForSelect?.roads?.find((option) => option.value === formData.road_id) : null
            }
            isMulti={false}
            name="road_id"
            label="Дорога"
          />
          <Selector
            options={optionsForSelect?.directoratesUipps}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorate_id', selectedOption, 'select');
            }}
            placeholder="Выберите дирекцию"
            value={
              formData?.directorate_id
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate_id)
                : null
            }
            name="directorate_id"
            isMulti={false}
            label="Дирекция"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect?.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'department_id', selectedOption, 'select');
            }}
            placeholder="Выберите № подразделения"
            value={
              formData?.department_id
                ? optionsForSelect?.departments?.find((option) => option.value === formData.department_id)
                : null
            }
            name="department_id"
            isMulti={false}
            label="Подразделение"
            isDisabled={departmentsControl?.isSelectorDisabled}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect?.trainTypes}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'train_type_id', selectedOption, 'select');
            }}
            placeholder="Выберите тип ПС"
            value={
              formData?.train_type_id
                ? optionsForSelect?.trainTypes.find((option) => option.value === formData.train_type_id)
                : null
            }
            name="train_type_id"
            isMulti={false}
            label="Тип подвижного состава"
          />
          <Input
            placeholder="Введите № локомотива"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 15)}
            value={formData?.train_number_id}
            name="train_number_id"
            label="Номер подвижного состава"
            // type="number"
          />
          <Input
            placeholder="Введите  № машиниста"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 15)}
            value={formData?.responsible}
            name="responsible"
            label="Табельный номер машиниста"
            // type="number"
          />
        </div>
      </form>
    </Accordeon>
  );
}

export default FilterForUips;
