import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import '../../styles/TableMap.css';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';

import { dateToString } from '../../utils/convertToDate';
import { useRole } from '../../customHook/useRole';

const pathUploads = process.env.REACT_APP_UPLOADS;

const TableMap = ({
  data = [],
  handleButtonClick,
  isBtn = true,
  setActiveButton,
  activeButton,
  archive = false,
  setSelectedStatus,
  setSelectedDateSort,
  selectedStatus,
  selectedDateSort,
  t,
}) => {
  //проверяем роли пользователя
  const isMaster = useRole('master');

  // Получаем опции
  const optionsForSelect = useSelector((state) => state.options);

  const btnName = useMemo(
    () => [
      t?.statusOperation || 'Эксплуатация',
      t?.statusStorage || 'Хранение',
      t?.statusRepair || 'Ремонт',
      t?.statusArchive || 'Архив',
    ],
    [t]
  );

  // записываем в стейт имя кнопки для отрисовки компонента
  const selectComponentButton = (buttonName) => {
    if (buttonName !== activeButton) {
      setActiveButton(buttonName);
    }
  };

  const statusDictionary = {
    Хранение: 'Skladištenje',
    'Изменение данных статуса Хранение': 'Ažuriranje skladištenja',
    'Выполнение ТО': 'Održavanje',
    Выдача: 'Izdavanje',
    'Изменение данных статуса Эксплуатация': 'Ažuriranje eksploatacije',
    Возврат: 'Povratak',
    'Направление в ремонт': 'Poslato na popravku',
    'Изменение данных статуса Ремонт': 'Ažuriranje popravke',
    'Возврат из ремонта': 'Vraćeno sa popravke',
    Списание: 'Otpisan',
  };

  const currentLanguage = useSelector((state) => state.language);

  const getTranslatedStatus = (status, language) => {
    if (language === 'RS') {
      return statusDictionary[status] || status; // Если язык сербский, возвращаем перевод
    }
    return status; // Если язык русский, возвращаем оригинальный статус
  };

  return (
    <div className="table-map-container">
      <div className="shadow"></div>
      {/* Блок с кнопками для выбора статусов */}
      <div className="table-map-button-container">
        {btnName?.map((buttonName) => {
          return (
            <CustomButton
              className={`device_button ${activeButton === buttonName ? 'activeBtn' : ''}`}
              key={buttonName}
              text={buttonName}
              colorText={activeButton === buttonName ? 'var(--sadr-success)' : 'var(--sadr-label-color)'}
              onClick={() => selectComponentButton(buttonName)}
            />
          );
        })}
      </div>
      {archive && (
        <div className="device-archive-status_select-container">
          <Selector
            column="left"
            options={optionsForSelect.optionsDateSort}
            onChange={(selectedOption) => setSelectedDateSort(selectedOption)}
            placeholder={t?.placeholderDateSortNew || 'Сначала новые'}
            width="var(--sadr-font-size-320) "
            height="var(--sadr-font-size-60)"
            className="device-archive__buttons_fit"
            value={selectedDateSort}
            isMulti={false}
            name="dateSort"
            isView
            defaultValue={selectedDateSort}
          />
          <Selector
            column="left"
            options={optionsForSelect.optionsActions}
            onChange={(selectedOption) => setSelectedStatus(selectedOption)}
            placeholder={t?.placeholderAllActions || 'Все действия'}
            width="var(--sadr-font-size-320) "
            height="var(--sadr-font-size-60)"
            className="device-archive__buttons_fit"
            value={selectedStatus}
            isMulti={false}
            name="typeStatus"
            isView
          />
        </div>
      )}
      {/* Если массив пустой выводим запись что данных нет */}
      {data?.length === 0 ? (
        <div className="table-map-no-data">{t?.noDataAvailable || 'Данные отсутствуют'}</div>
      ) : (
        data?.map((status, index) => {
          // Составление уникального ключа, объединяя несколько значений
          const uniqueKey = `${status?.id}_${status?.device_id}_${index}`;
          //определяем имя для кнопки чтобы передать в функцию для открытия модального окна по имени

          const btnName =
            status?.title === t?.statusStorage || status?.title === 'Хранение' ? 'edit' : status?.title || 'edit';
          //форматируем дату
          const formattedDate = dateToString(status?.date);
          //определяем состояние
          let conditionText;
          if (status?.broken === null) {
            conditionText = t?.deviceConditionUndefined || 'Не определено';
          } else {
            conditionText = status?.broken
              ? t?.deviceConditionBroken || 'Неисправно'
              : t?.deviceConditionWorking || 'Исправно';
          }

          return (
            // Таблица статусов
            <div key={uniqueKey} className="table-map-row">
              {/* Дата и кнопка */}
              <div className="date-button-container">
                <div className="date">
                  {getTranslatedStatus(status?.title, currentLanguage)} {formattedDate}
                </div>
                {isBtn && !isMaster && (
                  <CustomButtonEdit
                    onClick={(event) => {
                      handleButtonClick(btnName, status?.id);
                    }}
                  />
                )}
              </div>

              {/* Трехколоночная сетка */}
              <div className="grid-columns">
                {/* Колонка 1 */}
                {/* Хранение */}
                {(status?.title === 'Хранение' || status?.title === 'Изменение данных статуса Хранение') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.acceptanceCertificateTitle || 'Акт приема-передачи'}:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.storageLocationTitle || 'Место хранения'}:</div>
                      <div className="column-value">{status?.storage}</div>
                    </div>
                  </div>
                )}
                {/* ТО*/}
                {status?.title === 'Выполнение ТО' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.serviceDateTitle || 'Дата ТО'}:</div>
                      <div className="column-value">{dateToString(status?.service_date)}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.nextServiceDateTitle || 'Дата следующего ТО'}:</div>
                      <div className="column-value">{dateToString(status?.next_date)}</div>
                    </div>
                  </div>
                )}
                {/* Эксплуатация выдача */}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') && (
                  <div className="grid-column">
                    {!status.uipps && (
                      <div className="column-item">
                        <div className="column-title">{t?.senderFullNameTitle || 'ФИО выдавшего'}:</div>
                        <div className="column-value">{status?.sender}</div>
                      </div>
                    )}
                    <div className="column-item">
                      <div className="column-title">{t?.acceptanceCertificateTitle || 'Акт приема-передачи'}:</div>
                      <div className="column-value">
                        {' '}
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.employeeIdTitle || 'Таб. № принявшего'}:</div>
                      <div className="column-value">{status?.responsible_id}</div>
                    </div>
                  </div>
                )}
                {/* Эксплуатация возврат */}
                {status?.title === 'Возврат' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.receivedByTitle || 'ФИО принявшего'}:</div>
                      <div className="column-value">{status?.receiver}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.acceptanceCertificateTitle || 'Акт приема-передачи'}:</div>
                      <div className="column-value">
                        {' '}
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.storageLocationTitle || 'Место хранения'}:</div>
                      <div className="column-value">{status?.storage}</div>
                    </div>
                  </div>
                )}
                {/* Ремонт Направление в ремонт */}
                {(status?.title === 'Направление в ремонт' || status?.title === 'Изменение данных статуса Ремонт') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.acceptanceCertificateTitle || 'Акт приема-передачи'}:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.serviceCenterTitle || 'Сервисный центр'}:</div>
                      <div className="column-value">{status?.service_center}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.serviceCenterContactTitle || 'Контакты центра'}:</div>
                      <div className="column-value">{status?.service_center_contact}</div>
                    </div>
                    {/* попросили скрыть это поле */}
                    {/* <div className="column-item">
                      <div className="column-title">Дорога:</div>
                      <div className="column-value">{status?.road}</div>
                    </div> */}
                  </div>
                )}
                {/* Ремонт Возврат из ремонта */}
                {status?.title === 'Возврат из ремонта' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.acceptanceCertificateTitle || 'Акт приема-передачи'}:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.acceptance_cert
                          )}&fileName=${encodeURIComponent(status?.acceptance_cert_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.acceptance_cert_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.receivedByTitle || 'ФИО принявшего'}:</div>
                      <div className="column-value">{status?.receiver}</div>
                    </div>
                  </div>
                )}
                {/* Архив*/}
                {status?.title === 'Списание' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.writeOffDateTitle || 'Дата списания'}:</div>
                      <div className="column-value">{dateToString(status?.date)}</div>
                    </div>
                  </div>
                )}

                {/* Колонка 2 */}
                {/* Хранение */}
                {(status?.title === 'Хранение' || status?.title === 'Изменение данных статуса Хранение') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.receivedByTitle || 'ФИО принявшего'}:</div>
                      <div className="column-value">{status?.receiver}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.deviceConditionTitle || 'Состояние'}:</div>
                      <div className="column-value">{conditionText}</div>
                    </div>
                  </div>
                )}
                {/* ТО*/}
                {status?.title === 'Выполнение ТО' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.worksDoneTitle || 'Выполненные работы'}:</div>
                      <div className="column-value">{status?.works_done}</div>
                    </div>
                  </div>
                )}
                {/* Эксплуатация выдача */}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  !status.uipps && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">{t?.roadTitle || 'Дорога'}:</div>
                        <div className="column-value">{status?.road}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">{t?.directorateTitle || 'Дирекция'}:</div>
                        <div className="column-value">{status?.directorate}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">{t?.departmentTitle || 'Подразделение'}:</div>
                        <div className="column-value">{status?.department}</div>
                      </div>
                      {/* <div className="column-item">
                        <div className="column-title">Участок:</div>
                        <div className="column-value">{status?.section}</div>
                      </div> */}
                    </div>
                  )}
                {/* Эксплуатация выдача для уиппс*/}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  status.uipps && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">{t?.roadTitle || 'Дорога'}:</div>
                        <div className="column-value">{status?.road}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">{t?.directorateTitle || 'Дирекция'}:</div>
                        <div className="column-value">{status?.directorate}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">{t?.departmentTitle || 'Подразделение'}:</div>
                        <div className="column-value">{status?.department}</div>
                      </div>
                    </div>
                  )}
                {/* Эксплуатация возврат */}
                {status?.title === 'Возврат' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.deviceConditionTitle || 'Состояние'}:</div>
                      <div className="column-value">{conditionText}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.defectsReportTitle || 'Дефектная ведомость'}:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.attachment
                          )}&fileName=${encodeURIComponent(status?.attachment_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.attachment_name}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {/* Ремонт Направление в ремонт */}
                {(status?.title === 'Направление в ремонт' || status?.title === 'Изменение данных статуса Ремонт') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.senderResponsibleFullNameTitle || 'ФИО направившего'}:</div>
                      <div className="column-value">{status?.sender}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.defectsReportTitle || 'Дефектная ведомость'}:</div>
                      <div className="column-value">
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.defects_file
                          )}&fileName=${encodeURIComponent(status?.defects_file_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.defects_file_name}
                        </a>
                      </div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.deviceConditionTitle || 'Состояние'}:</div>
                      <div className="column-value">{conditionText}</div>
                    </div>
                  </div>
                )}
                {/* Ремонт Возврат из ремонта */}
                {status?.title === 'Возврат из ремонта' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.deviceConditionTitle || 'Состояние'}:</div>
                      <div className="column-value">{conditionText}</div>
                    </div>
                    <div className="column-item">
                      <div className="column-title">{t?.serviceReportTitle || 'Акт выполненных работ'}:</div>
                      <div className="column-value">
                        {' '}
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.attachment
                          )}&fileName=${encodeURIComponent(status?.attachment_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.attachment_name}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {/* Архив*/}
                {status?.title === 'Списание' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.writeOffActTitle || 'Акт списания'}:</div>
                      <div className="column-value">
                        {' '}
                        <a
                          className="link link-truncate"
                          href={`${pathUploads}?fileId=${encodeURIComponent(
                            status?.attachment
                          )}&fileName=${encodeURIComponent(status?.attachment_name)}`}
                          // target="_blank"
                          rel="noopener noreferrer"
                        >
                          {status?.attachment_name}
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {/* Колонка 3 */}
                {/* Хранение */}
                {(status?.title === 'Хранение' || status?.title === 'Изменение данных статуса Хранение') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.commentTitle || 'Комментарий'}:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
                {/* ТО*/}
                {status?.title === 'Выполнение ТО' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.commentTitle || 'Комментарий'}:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
                {/* Эксплуатация выдача */}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  !status.uipps && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">{t?.responsibleContactTitle || 'Контакты'}:</div>
                        <div className="column-value column-value-note">{status?.responsible_contact}</div>
                      </div>
                    </div>
                  )}

                {/* Эксплуатация выдача для уиппс станционное размещение */}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  status.uipps &&
                  status?.placement && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">{t?.placementTypeTitle || 'Тип ПС'}:</div>
                        <div className="column-value">{status?.train_type}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">{t?.placementNumberTitle || 'Номер ПС'}:</div>
                        <div className="column-value">{status?.train_number}</div>
                      </div>
                    </div>
                  )}
                {/* Эксплуатация выдача для уиппс не станционное размещение*/}
                {(status?.title === 'Выдача' || status?.title === 'Изменение данных статуса Эксплуатация') &&
                  status?.uipps &&
                  !status?.placement && (
                    <div className="grid-column">
                      <div className="column-item">
                        <div className="column-title">{t?.driverIdTitle || 'Табельный № машиниста'}:</div>
                        <div className="column-value column-value-note">{status?.driver_id}</div>
                      </div>
                      <div className="column-item">
                        <div className="column-title">{t?.driverContactTitle || 'Контакты машиниста'}:</div>
                        <div className="column-value column-value-note">{status?.responsible_contact}</div>
                      </div>
                    </div>
                  )}
                {/* Эксплуатация возврат */}
                {status?.title === 'Возврат' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.commentTitle || 'Комментарий'}:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
                {/* Ремонт Направление в ремонт */}
                {(status?.title === 'Направление в ремонт' || status?.title === 'Изменение данных статуса Ремонт') && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.commentTitle || 'Комментарий'}:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}

                {/* Ремонт Возврат из ремонта */}
                {status?.title === 'Возврат из ремонта' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.commentTitle || 'Комментарий'}:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
                {/* Архив*/}
                {status?.title === 'Списание' && (
                  <div className="grid-column">
                    <div className="column-item">
                      <div className="column-title">{t?.writeOffReasonTitle || 'Причина списания'}:</div>
                      <div className="column-value column-value-note">{status?.note}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default TableMap;
