import { addError } from "../redux/actions/error.action";

// Вспомогательная функция для чтения Blob как текста
const readBlobAsText = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result); // Когда чтение завершено, возвращаем результат
    reader.onerror = reject; // В случае ошибки, отклоняем промис
    reader.readAsText(blob); // Читаем Blob как текст
  });
};

// Функция для обработки ошибок, возникающих при запросе
export const handleRequestError = async (error, dispatch) => {
  let message = 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';

  if (error.response && error.response.data) {
    // Если ошибка имеет ответ и данные
    try {
      const errorMessage = await readBlobAsText(error.response.data); // Читаем данные ошибки как текст
      message = JSON.parse(errorMessage)?.error || message; // Парсим JSON и извлекаем сообщение об ошибке
    } catch (parseError) {
      console.log('Invalid JSON:', parseError); // Логируем ошибку при парсинге
    }
  }
  dispatch(addError({ error: message })); // Отправляем сообщение об ошибке через dispatch
  console.log(error); // Логируем исходную ошибку
};
