import { handleUniversalChange } from './formUtils';

/**
 * Function to create input props based on the provided parameters.
 *
 * @param {any} item - The item parameter - chosen parameter.
 * @param {number} index - The index parameter  - index of chosen parameter in array.
 * @param {any} inputData - The inputData  - array of input values.
 * @param {any} dataObject - The dataObject - json data.
 * @param {any} changingId - The changingId id of chosen parameter in editing mode.
 * @param {any} formData - The formData object with all input values.
 * @param {function} setFormData - The setFormData function to set input values.
 * @return {Object} The input props object containing key, column, defaultValue, placeholder, onChange, name, label, width, height, and value.
 */
export const createInputProps = (item, index, inputData, dataObject, changingId, formData, setFormData) => {
  //---- Set column position. If we have only one element to show, put column in center
  let column;
  if (dataObject.accessor.length <= 2) {
    column = 'center';
  } else {
    column = index % 2 === 0 ? 'right' : 'left';
  }

  //--- Check if validation is needed
  const validateSetting = dataObject.validate[index].needed ? dataObject.validate[index].validateSettings : null;
  // ---- Set default value if we are in editing mode or placeholder if in adding mode
  const defaultValue = changingId ? inputData[item] : '';
  const placeholder = changingId ? dataObject.header[index] : dataObject.header[index];
  // ---- according to json data, create labels with/without asterisk if field is required/not required

  let label = `${dataObject.header[index]} ${dataObject.required[index] ? '*' : ''}`;

  return {
    key: index,
    column,
    defaultValue,
    placeholder,
    onChange: (event) => {
      handleUniversalChange(formData, setFormData, event, null, null, null, null, null, null, validateSetting);
    },
    name: item,
    label,
    width: '100%',
    height: 'var(--sadr-font-size-60)',
    value: formData[item],
  };
};

/**
 * Generates props for a selector component.
 *
 * @param {any} item - The item parameter - chosen parameter.
 * @param {number} index - The index parameter  - index of chosen parameter in array.
 * @param {any} dataObject - The dataObject - json data.
 * @param {array} dependanceData - The array of dependent data to create dropdown list.
 * @param {any} formData - The formData object with all input values.
 * @param {function} setFormData - The setFormData function to set input values.
 * @return {Object} The input props object containing key, column, options, onChange, placeholder, label, width, height, isMulti, value, and name.
 */
export const createSelectorProps = (
  item,
  index,
  dataObject,
  dependanceData,
  formData,
  setFormData,
  optionsForSelectPositionId
) => {
  const column = index % 2 === 0 ? 'right' : 'left';
  let label = `${dataObject.header[index]} ${dataObject.required[index] ? '*' : ''}`;
  // ---- set parameter for dropdown selector
  const selectedField = dataObject.accessor[index];
  return {
    key: index,
    column,
    options: dependanceData,
    onChange: (selectedOption) => {
      handleUniversalChange(formData, setFormData, null, selectedField, selectedOption, 'select');
    },
    placeholder: dataObject.header[index],
    label,
    width: '100%',
    height: 'var(--sadr-font-size-60)',
    isMulti: false,
    value:
      item === 'positionId'
        ? formData?.positionId
          ? optionsForSelectPositionId?.find((option) => option.value === formData?.positionId)
          : null
        : dependanceData.find((option) => option.value === formData[selectedField]),
    name: item,
  };
};

/**
 * Sets the checkbox state in the form data object and updates the form data state.
 *
 * @param {boolean} selectState - The new state of the checkbox.
 * @param {object} formData - The form data object.
 * @param {function} setFormData - The function to update the form data state.
 * @param {string} name - The name of the checkbox in the form data object.
 */
const setCheckbox = (selectState, formData, setFormData, name) => {
  setFormData({
    ...formData,
    [name]: selectState,
  });
};
/**
 * Creates the props for a checkbox component based on the provided parameters.
 *
 * @param {any} item - The item parameter - chosen parameter.
 * @param {number} index - The index parameter  - index of chosen parameter in array.
 * @param {any} dataObject - The dataObject - json data.
 * @param {any} formData - The formData object with all input values.
 * @param {function} setFormData - The setFormData function to set input values.
 * @param {any} inputData - The inputData  - array of input values.
 * @return {Object} The checkbox props object containing key, column, height, width, label, onChange, and checked.
 */
export const createCheckboxProps = (item, index, inputData, dataObject, formData, setFormData) => {
  const column = index % 2 === 0 ? 'right' : 'left';
  const label = `${dataObject.header[index]} ${dataObject.required[index] ? '*' : ''}`;
  return {
    key: index,
    column,
    height: 'var(--sadr-font-size-60)',
    width: '100%',
    label,
    onChange: (selectState) => {
      setCheckbox(selectState, formData, setFormData, item);
    },
    checked: inputData[item],
  };
};

export const createMaskedInputProps = (input, index, formData, setFormData) => {
  const label = `${input.label} ${input.required ? '*' : ''}`;
  return {
    name: input.request,
    placeholder: input.placeholder,
    onChange: (event) => {
      handleUniversalChange(formData, setFormData, event);
    },
    label: label,
    mask: input.validate?.needed && input.validate?.mask ? input.validate.mask + '' : null,
    messageInfo: input.messageInfo,
    width: 'var(--sadr-font-size-320)',
    height: 'var(--sadr-font-size-60)',
    value: formData[input.request],
  };
};

export const createTimeSelectorProps = (item, index, dataObject, changingId, formData, setFormData) => {
  //---- Set column position. If we have only one element to show, put column in center
  let column;
  if (dataObject.accessor.length <= 2) {
    column = 'center';
  } else if (index === 5 || index === 6) {
    column = index === 6 ? 'right' : 'left';
  } else {
    column = index % 2 === 0 ? 'right' : 'left';
  }

  let placeholder = changingId ? dataObject.header[index] : dataObject.header[index];
  if (index === 5 || index === 6) {
    placeholder = 'Выберите время';
  }

  let label = `${dataObject.header[index]} ${dataObject.required[index] ? '*' : ''}`;

  return {
    selfAlign: index === 6 ? 'end' : '',
    rowspan: index === 6 ? 2 : '',
    key: index,
    column,
    onChange: (value) => {
      handleUniversalChange(formData, setFormData, null, item, { value: value }, 'select');
    },
    name: item,
    label,
    placeholder,
    value: formData[item],
    minuteStep: 10,
  };
};
