import { INIT_REPORTS, GET_REPORT } from '../type';

export function documentationReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_REPORTS: {
      return { ...state, reports: payload };
    }

    case GET_REPORT: {
      return { ...state, report: payload };
    }
    default:
      return state;
  }
}
