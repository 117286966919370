import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FormContainer from '../CustomElements/FormContainer';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import CustomButton from '../CustomElements/CustomButton';
import CustomContainer from '../CustomElements/CustomContainer';
import CustomTitle from '../CustomElements/CustomTitle';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

import { handleUniversalChange } from '../../utils/formUtils';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import useOptionsLoader from '../../customHook/useOptionsLoader';

import { newSemaphoreFromServer, editSemaphoreFromServer } from '../../redux/actions/infocards.action';
import { load, remove } from '../../redux/actions/loader.action';
import { initOptionsRailChainsFromServer, initOptionsSectionFromServer } from '../../redux/actions/options.action';
import useFormDataChanged from '../../customHook/useFormDataChanged';

function AddOrEditSemaphore({
  closeModal,
  road,
  station,
  isEdit,
  currentSemaphore,
  addSuccess,
  setAddSuccess,
  isAdmin,
}) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // Получаем опции
  const { optionsSemaphoreDirection, stations, railChainsPerStation } = useSelector((state) => state.options);

  // Закрывает модальное окно после успешного добавления нового устройства
  const handlerCloseModal = () => {
    setAddSuccess(false);
    closeModal();
  };

  // Состояние для формы. Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () => ({
      id: currentSemaphore?.semaphore_id || '',
      name: currentSemaphore?.name || '',
      direction: currentSemaphore?.direction || '',
      station_id: currentSemaphore?.station?.id || station,
      rail_id_before: currentSemaphore?.rail_chain_before?.id || '',
      rail_id_after: currentSemaphore?.rail_chain_after?.id || '',
      station_rail_chain_before: currentSemaphore?.rail_chain_before?.station || station || '',
      station_rail_chain_after: currentSemaphore?.rail_chain_after?.station || station || '',
    }),
    [currentSemaphore, station]
  );

  // функция для формирования пейлоада
  const transformFormData = (formData) => ({
    id: Number(formData.id),
    name: formData.name,
    direction: formData.direction,
    station_id: Number(formData.station_id),
    rail_id_before: formData.rail_id_before ? Number(formData.rail_id_before) : null,
    rail_id_after: formData.rail_id_after ? Number(formData.rail_id_after) : null,
  });
  //состояние для формы добавления нового светофора
  const [formData, setFormData] = useState(initialFormData);

  // состояние для необязательных полей
  const [optionalFields] = useState([
    'station_rail_chain_before',
    'rail_id_before',
    'station_rail_chain_after',
    'rail_id_after',
  ]);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, optionalFields);
  //проверяем были ли изменения в форме
  const isFormEdit = useFormDataChanged(initialFormData, formData, isEdit);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    // Переводим в состояние загрузки
    dispatch(load());
    if (!isEdit && isAdmin) {
      const payload = transformFormData(formData);
      // Отправляем данные на сервер через Redux
      dispatch(newSemaphoreFromServer(payload, formData?.station_id));
    }
    if (isEdit && isAdmin) {
      const payload = transformFormData(formData);
      delete payload.id;
      // Отправляем данные на сервер через Redux
      dispatch(editSemaphoreFromServer(payload, formData?.id, formData?.station_id));
    }
  };

  //хук для запроса опций  RC и контроля селекта
  const messageForSelectRC = 'Для выбора РЦ выберите станцию';
  const rcBeforeControl = useOptionsLoader(
    formData,
    setFormData,
    ['rail_id_before'],
    'station_rail_chain_before',
    initOptionsRailChainsFromServer,
    messageForSelectRC
  );

  const rcAfterControl = useOptionsLoader(
    formData,
    setFormData,
    ['rail_id_after'],
    'station_rail_chain_after',
    initOptionsRailChainsFromServer,
    messageForSelectRC
  );

  useEffect(() => {
    dispatch(initOptionsSectionFromServer(road));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Функция для получения опций для Selector
  const getSelectorOptions = (stationKey) => railChainsPerStation?.[formData?.[stationKey]] || [];

  // Функция для получения значения для Selector
  const getSelectorValue = (stationKey, chainKey) => {
    const options = getSelectorOptions(stationKey);
    return formData?.[chainKey] ? options?.find((option) => option?.value === formData?.[chainKey]) : '';
  };
  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, закрываем модальное окно
    if (!loader?.isLoading && loader?.isSuccess) {
      setAddSuccess(true);
    }
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  return (
    <CustomContainer>
      {/* <div className="add-device"> */}
      <CustomTitle titleText={isEdit ? 'РЕДАКТИРОВАНИЕ СВЕТОФОРА' : 'ДОБАВЛЕНИЕ НОВОГО СВЕТОФОРА'} />
      {addSuccess && (
        <ConfirmationPage
          confirmText={isEdit ? 'Изменения сохранены' : 'Новый светофор добавлен!'}
          onConfirm={handlerCloseModal}
          addNew={'К списку'}
        />
      )}
      {!addSuccess && (
        <FormContainer>
          <Input
            column="left"
            placeholder="Введите ID светофора"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="id"
            label="ID светофора *"
            width="100%"
            height="var(--sadr-font-size-60)"
            format="######"
            maxLength={9}
            isCoordinate={true}
            isAllowed={undefined}
            value={formData?.id}
            isDisabled={isEdit}
          />

          <Selector
            column="left"
            options={stations || []}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'station_rail_chain_before', selectedOption, 'select')
            }
            placeholder="Выберите станцию"
            label="Станция РЦ перед светофором"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="station_rail_chain_before"
            value={
              formData?.station_rail_chain_before
                ? stations?.find((option) => option?.value === formData?.station_rail_chain_before)
                : ''
            }
          />

          <Selector
            column="left"
            options={getSelectorOptions('station_rail_chain_before')}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'rail_id_before', selectedOption, 'select')
            }
            placeholder="Выберите РЦ"
            label="РЦ перед светофором"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="rail_id_before"
            isDisabled={rcBeforeControl?.isSelectorDisabled}
            message={rcBeforeControl?.message}
            customClick={rcBeforeControl?.onSelectorChange}
            value={getSelectorValue('station_rail_chain_before', 'rail_id_before')}
          />

          <Selector
            column="left"
            options={optionsSemaphoreDirection || []}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'direction', selectedOption, 'select')
            }
            placeholder="Выберите направление"
            label="Направление движения по светофору *"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="direction"
            value={
              formData?.direction
                ? optionsSemaphoreDirection?.find((option) => option.value === formData?.direction)
                : ''
            }
          />
          <Input
            column="right"
            placeholder="Введите название светофора"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="name"
            label="Название светофора *"
            width="100%"
            height="var(--sadr-font-size-60)"
            value={formData?.name}
          />

          <Selector
            column="right"
            options={stations || []}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'station_rail_chain_after', selectedOption, 'select')
            }
            placeholder="Выберите станцию"
            label="Станция РЦ за светофором"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="station_rail_chain_after"
            value={
              formData?.station_rail_chain_after
                ? stations?.find((option) => option?.value === formData?.station_rail_chain_after)
                : ''
            }
          />

          <Selector
            column="right"
            options={getSelectorOptions('station_rail_chain_after')}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'rail_id_after', selectedOption, 'select')
            }
            placeholder="Выберите РЦ"
            label="РЦ за светофором"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="rail_id_after"
            isDisabled={rcAfterControl?.isSelectorDisabled}
            message={rcAfterControl?.message}
            customClick={rcAfterControl?.onSelectorChange}
            value={getSelectorValue('station_rail_chain_after', 'rail_id_after')}
          />

          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            margin={'0 0 var(--sadr-font-size-20) 0'}
            text={isEdit ? 'Сохранить изменения' : 'Добавить светофор'}
            onClick={handlerSubmit}
            disabled={isButtonDisabled || !isFormEdit}
            isButton
          />
        </FormContainer>
      )}
    </CustomContainer>
  );
}

export default AddOrEditSemaphore;
