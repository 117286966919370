import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from './reducers/root.reducer';
// import { composeWithDevTools } from "@redux-devtools/extension";
import socket from '../config/socket';

const initialState = {
  language: 'RU',
  devices: [],
  uipps: [],
  files: [],
  kuobzes: [],
  sortData: {},
  loader: {},
  error: {},
  user: {},
  documentation: { reports: [], report: {} },
  brigadesState: {
    errors: {},
    brigades: [],
    brigadeComposition: [],
    brigadeDevice: [],
    brigadeAllSignals: [],
    brigadeSignals: {},
    brigadeInfo: {},
    mode: false,
    locationsWork: {},
    transfer: {},
    currentLocationsWork: {},
    resetMesh: {},
  },
  statuses: {
    storageStatuses: [],
    archiveStatuses: [],
    repairStatuses: [],
    usageStatuses: [],
  },
  infocards: {
    items: [],
    rail_chains: [],
    adjacency: [],
    semaphores: [],
  },
  mapData: {
    mapBorders: {},
    mapArray: [],
    ignoredIds: [],
    queryString: '',
  },
  adminChoiceData: {
    selectedName: '',
    selectedParam: '',
    data: {
      roads: [],
      directorates: [],
      departments: [],
      brigade_names: [],
      employees: [],
    },
  },
  archive: {
    archiveSession: [],
    archiveBrigadeSession: {},
    archiveFilterParams: {
      brigadeId: '',
      deviceId: '',
      roadId: '',
      stationId: '',
      start: '',
      finish: '',
    },
  },

  options: {
    status: [
      { value: 'on', label: 'В работе' },
      { value: 'off', label: 'Не в работе' },
      { value: 'all', label: 'Все' },
    ],
    сondition: [
      { value: false, label: 'Исправен' },
      { value: true, label: 'Неисправен' },
      { value: undefined, label: 'Не определено' },
    ],
    optionsActions: [
      { value: 'Все действия', label: 'Все действия' },
      { value: ['Выдача', 'Возврат'], label: 'Эксплуатация' },
      { value: 'Хранение', label: 'Хранение' },
      { value: ['Направление в ремонт', 'Возврат из ремонта'], label: 'Ремонт' },
      { value: 'Списание', label: 'Списание' },
      { value: 'Выполнение ТО', label: 'Выполнение ТО' },
      {
        value: [
          'Изменение данных статуса Ремонт',
          'Изменение данных статуса Эксплуатация',
          'Изменение данных статуса Хранение',
        ],
        label: 'Изменение данных статуса',
      },
    ],
    optionsDateSort: [
      { value: 'Сначала новые', label: 'Сначала новые' },
      { value: 'Сначала старые', label: 'Сначала старые' },
    ],
    optionsSemaphoreDirection: [
      { value: '0', label: 'Чётное' },
      { value: '1', label: 'Нечётное' },
    ],
  },
};

// Передаем сокет в thunk middleware как дополнительный аргумент
const middleware = [thunk.withExtraArgument({ socket })];

// Условно используем composeWithDevTools в режиме разработки
const composeEnhancers =
  process.env.REACT_APP_NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

// настройка стора без сокета
// export const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk)))
