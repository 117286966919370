import { useState, useRef, useEffect } from 'react';
import '../../styles/TimeSelector.css';

/**
 * Компонент TimeSelector для выбора времени в формате 'HH:mm'.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} [props.label] - Метка для инпута.
 * @param {string} [props.name] - Имя поля инпута.
 * @param {string} [props.value] - Выбранное время в формате 'HH:mm'.
 * @param {function} props.onChange - Функция обратного вызова для передачи нового значения 'HH:mm'.
 * @param {string} [props.placeholder='Выберите время'] - Текст-заполнитель для пустого инпута.
 * @param {boolean} [props.disabled=false] - Флаг отключения компонента.
 * @param {number} [props.hourStep=1] - Шаг для выбора часов (по умолчанию 1).
 * @param {number} [props.minuteStep=1] - Шаг для выбора минут (по умолчанию 1).
 * @returns {JSX.Element} Компонент TimeSelector.
 */
const TimeSelector = ({
  label,
  name,
  value,
  onChange,
  placeholder = 'Выберите время',
  disabled = false,
  hourStep = 1,
  minuteStep = 1,
}) => {
  const [isOpen, setIsOpen] = useState(false); // Состояние отображения выпадающего списка
  const [hours, setHours] = useState(null); // Текущее выбранное значение часов
  const [minutes, setMinutes] = useState(null); // Текущее выбранное значение минут
  const [isDropdownAbove, setIsDropdownAbove] = useState(false); // Флаг, указывающий, нужно ли отображать список над инпутом
  const [isHovered, setIsHovered] = useState(false); // Состояние для отображения подсказки удаления

  const dropdownRef = useRef(null); // Ссылка на выпадающий список
  const inputRef = useRef(null); // Ссылка на инпут
  const hoursRef = useRef(null); // Ссылка на список часов
  const minutesRef = useRef(null); // Ссылка на список минут

  /**
   * Функция для парсинга строки времени в формате 'HH:mm'.
   *
   * @param {string} timeStr - Строка времени.
   * @returns {Object} Объект с часами и минутами.
   */
  const parseTime = (timeStr) => {
    if (typeof timeStr !== 'string') return { hours: null, minutes: null };
    const [h, m] = timeStr.split(':').map(Number);
    return {
      hours: !isNaN(h) ? h : null,
      minutes: !isNaN(m) ? m : null,
    };
  };

  /**
   * Инициализирует локальные состояния `hours` и `minutes` на основе значения `value`.
   */
  useEffect(() => {
    const { hours: parsedHours, minutes: parsedMinutes } = parseTime(value);
    setHours(parsedHours);
    setMinutes(parsedMinutes);
  }, [value]);

  /**
   * Обновляет положение выпадающего списка (снизу или сверху) и автоматически прокручивает к выбранным значениям.
   */
  useEffect(() => {
    if (isOpen && dropdownRef.current && inputRef.current) {
      const dropdownRect = dropdownRef.current.getBoundingClientRect();
      const inputRect = inputRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Проверяем, помещается ли окно снизу, если нет - отображаем сверху
      const fitsBelow = dropdownRect.height + inputRect.bottom <= windowHeight;
      const fitsAbove = inputRect.top - dropdownRect.height > 0;

      if (fitsBelow) {
        setIsDropdownAbove(false);
      } else if (fitsAbove) {
        setIsDropdownAbove(true);
      } else {
        setIsDropdownAbove(false); // Если никуда не помещается, отображаем ниже по умолчанию
      }

      // Прокручиваем к выбранным значениям
      if (hoursRef.current && hours !== null) {
        const selectedHour = hoursRef.current.querySelector(`.hour-${hours}`);
        selectedHour?.scrollIntoView({ block: 'center' });
      }
      if (minutesRef.current && minutes !== null) {
        const selectedMinute = minutesRef.current.querySelector(`.minute-${minutes}`);
        selectedMinute?.scrollIntoView({ block: 'center' });
      }
    }
  }, [isOpen]);

  /**
   * Обрабатывает изменение часов.
   *
   * @param {number} newHours - Новое значение часов.
   */
  const handleHoursChange = (newHours) => {
    setHours(newHours);
    if (minutes !== null) {
      closeDropdown(newHours, minutes);
    }
  };

  /**
   * Обрабатывает изменение минут.
   *
   * @param {number} newMinutes - Новое значение минут.
   */
  const handleMinutesChange = (newMinutes) => {
    setMinutes(newMinutes);
    if (hours !== null) {
      closeDropdown(hours, newMinutes);
    }
  };

  /**
   * Закрывает выпадающий список и сохраняет выбранное время.
   *
   * @param {number} selectedHours - Выбранные часы.
   * @param {number} selectedMinutes - Выбранные минуты.
   */
  const closeDropdown = (selectedHours, selectedMinutes) => {
    const formattedTime = `${String(selectedHours).padStart(2, '0')}:${String(selectedMinutes).padStart(2, '0')}`;
    setIsOpen(false);
    onChange(formattedTime);
  };

  /**
   * Очищает текущее значение времени.
   */
  const clearTime = () => {
    setHours(null);
    setMinutes(null);
    onChange('');
    setIsHovered(false);
  };

  /**
   * Переключает отображение выпадающего списка.
   */
  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen((prev) => !prev);
    }
  };

  /**
   * Генерирует массив времени на основе шага и максимального значения.
   *
   * @param {number} step - Шаг.
   * @param {number} max - Максимальное значение.
   * @returns {number[]} Массив значений.
   */
  const generateTimeOptions = (step, max) => Array.from({ length: Math.ceil(max / step) }, (_, i) => i * step);

  const formattedTime =
    hours !== null && minutes !== null
      ? `${String(hours).padStart(2, '0')} : ${String(minutes).padStart(2, '0')}`
      : placeholder;
  const isPlaceholder = hours === null || minutes === null;
  return (
    <div className="time-picker-container">
      {label && (
        <div className="time-picker-label" htmlFor={name}>
          {label}
        </div>
      )}
      <div
        ref={inputRef}
        className={`time-picker-input ${disabled ? 'disabled' : ''} ${isPlaceholder ? 'placeholder' : ''}`}
        onClick={toggleDropdown}
      >
        {formattedTime}
        {hours !== null && minutes !== null && (
          <div
            className="time-clear-icon"
            onClick={clearTime}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg"
            >
              <path
                d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.03-2.651 3.03c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.15c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.03 2.651-3.03c0.469 0.469 1.228 0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.15 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                fill={isHovered ? '#D30630' : '#000'}
              ></path>
            </svg>
          </div>
        )}
      </div>
      {isOpen && (
        <div ref={dropdownRef} className={`time-picker-dropdown ${isDropdownAbove ? 'above' : 'below'}`}>
          <div className="time-picker-column">
            <div className="time-picker-header">Часы</div>
            <div className="time-picker-scroll" ref={hoursRef}>
              {generateTimeOptions(hourStep, 24).map((hour) => (
                <div
                  key={hour}
                  className={`time-picker-item hour-${hour} ${hour === hours ? 'selected' : ''}`}
                  onClick={() => handleHoursChange(hour)}
                >
                  {String(hour).padStart(2, '0')}
                </div>
              ))}
            </div>
          </div>
          <div className="time-picker-column">
            <div className="time-picker-header">Минуты</div>
            <div className="time-picker-scroll" ref={minutesRef}>
              {generateTimeOptions(minuteStep, 60).map((minute) => (
                <div
                  key={minute}
                  className={`time-picker-item minute-${minute} ${minute === minutes ? 'selected' : ''}`}
                  onClick={() => handleMinutesChange(minute)}
                >
                  {String(minute).padStart(2, '0')}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeSelector;
