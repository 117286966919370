import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/BrigadeSignals.css';
import Table from '../Table/Table';
import Selector from '../CustomElements/Selector';
import moment from 'moment';
import 'moment-timezone';
import useTableColumnSelect from '../../customHook/useTableColumnSelect';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { emitBrigadeAllSignals } from '../../redux/actions/brigades.action';
import { listenBrigadeAllSignalsInfo } from '../../redux/actions/brigades.action';
import { emitBrigadeAllSignalsListenStop } from '../../redux/actions/brigades.action';
import { useRole } from '../../customHook/useRole';

function BrigadeSignals({ currentBrigade, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Получение данных о бригадах из Redux store.
  const { brigadeAllSignals } = useSelector((state) => state.brigadesState);

  let isAdmin = useRole('admin');
  const isEngineerInfosignal = useRole('engineerInfosignal');
  const isCustomer = useRole('customer');

  // Колонки таблицы
  const columns = useMemo(
    () => [
      {
        Header: '№',
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: t?.signalTime,
        accessor: 'time',
        Cell: ({ value }) => {
          // Проверяем, является ли значение валидной датой и преобразуем её в Московское время
          const date = value !== undefined ? moment(value).tz('Europe/Moscow') : null;
          return date && date.isValid() ? date.format('DD.MM.YYYY HH:mm:ss') : '-';
        },
      },
      {
        Header: t?.signalIndex,
        accessor: 'index',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: t?.signalType,
        accessor: 'alertType',
        Cell: ({ value }) => {
          switch (value) {
            case 1:
              return 'OK';
            case 2:
              return t?.notification1;
            case 3:
              return t?.notification2;
            case 4:
              return t?.noConnection;
            case 5:
              return t?.alert;
            case 6:
              return t?.waiting;
            default:
              return '-';
          }
        },
      },
      {
        Header: t.direction,
        accessor: 'dir',
        Cell: ({ value }) => (value === 1 ? t?.oddDirection : value === 0 ? t?.evenDirection : '-'),
      },
      {
        Header: t?.uippsID,
        accessor: 'uippsId',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: t?.uippsSignalType,
        accessor: 'uippsAlertType',
        Cell: ({ value }) => {
          switch (Number(value)) {
            case 0:
              return 'ОК';
            case 1:
              return t?.alert;
            case 6:
              return t?.waiting;
            default:
              return '-';
          }
        },
      },
      {
        Header: t?.alertButtonPress,
        accessor: 'btn_alert',
        Cell: ({ value }) => (value === 1 ? t?.yes : value === 0 ? t?.no : '-'),
      },
      {
        Header: t?.clearButtonPress,
        accessor: 'btn_clear',
        Cell: ({ value }) => (value === 1 ? t?.yes : value === 0 ? t?.no : '-'),
      },
      ...(!isAdmin || !isCustomer || !isEngineerInfosignal
        ? []
        : [
            {
              Header: t?.route,
              accessor: 'routeRailName',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.occupiedRails,
              accessor: 'currentRailName',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.apkDistance,
              accessor: 'distance',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.sadrDistance,
              accessor: 'preciseDistance',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t.warningRails,
              accessor: 'warningRails',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.alertRails,
              accessor: 'alertRails',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.uippsSignalTime,
              accessor: 'uippsTime',
              Cell: ({ value }) => {
                // Проверяем, является ли значение валидной датой и преобразуем её в Московское время
                const date = value !== undefined ? moment(value).tz('Europe/Moscow') : null;
                return date && date.isValid() ? date.format('DD.MM.YYYY HH:mm:ss') : '-';
              },
            },
            {
              Header: t?.kuobzSignalTime,
              accessor: 'kuobzTime',
              Cell: ({ value }) => {
                // Проверяем, является ли значение валидной датой и преобразуем её в Московское время
                const date = value !== undefined ? moment(value).tz('Europe/Moscow') : null;
                return date && date.isValid() ? date.format('DD.MM.YYYY HH:mm:ss') : '-';
              },
            },
            {
              Header: t?.kuobzSignalType,
              accessor: 'kuobzAlertType',
              Cell: ({ value }) => {
                switch (value) {
                  case 1:
                    return 'ОК';
                  case 2:
                    return t?.notification1;
                  case 3:
                    return t?.notification2;
                  case 4:
                    return t?.noConnection;
                  case 5:
                    return t?.alert;
                  case 6:
                    return t?.waiting;
                  default:
                    return '-';
                }
              },
            },
            {
              Header: t?.kuobzCoordinates,
              accessor: 'kuobzCoords',
              Cell: ({ value }) => (value ? `${value.lat}, ${value.lon}` : '-'),
            },
            {
              Header: t?.iurCoordinates,
              accessor: 'iurCoords',
              Cell: ({ value }) => (value ? `${value.lat}, ${value.lon}` : '-'),
            },
            {
              Header: t?.uippsCoordinates,
              accessor: 'uippsCoords',
              Cell: ({ value }) => (value ? `${value.lat}, ${value.lon}` : '-'),
            },
            {
              Header: t?.kuobzGSM,
              accessor: 'kuobzGSM',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.kuobzGPS,
              accessor: 'kupbzGPS',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.iurGSM,
              accessor: 'iurGSM',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.iurGPS,
              accessor: 'iurGPS',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.uippsGSM,
              accessor: 'uippsGSM',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t.uippsGPS,
              accessor: 'uippsGPS',
              Cell: ({ value }) => defaultCellRender(value),
            },
          ]),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brigadeAllSignals]
  );

  // eslint-disable-next-line no-unused-vars
  const [visibleColumns, hiddenColumns, columnOptions, selectedColumns, handleColumnSelect] =
    useTableColumnSelect(columns);

  useEffect(() => {
    //отправляем событие чтобы получить сигналы
    dispatch(emitBrigadeAllSignals({ brigadeId: currentBrigade.id }));
    //слушаем событие 'get_all_signals_reply'
    dispatch(listenBrigadeAllSignalsInfo());
    // Функция очистки
    return () => {
      // отправляем событие чтобы сервер закончил слать инфо
      dispatch(emitBrigadeAllSignalsListenStop({ brigadeId: currentBrigade?.id }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {brigadeAllSignals && (
        <>
          <div className="brigade-signals_select-container">
            <Selector
              column="left"
              options={columnOptions}
              onChange={(selectedOption) => handleColumnSelect(selectedOption)}
              placeholder="Выберите поля таблицы"
              width="var(--sadr-font-size-350) "
              height="var(--sadr-font-size-60)"
              value={selectedColumns}
              isMulti={true}
              name="typeStatus"
              isView
              optionSquare
              portalHeightRem={31.25}
            />
          </div>

          <Table data={brigadeAllSignals} columns={visibleColumns} />
        </>
      )}
    </>
  );
}

export default BrigadeSignals;
