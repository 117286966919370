import { LOAD, SUCCESS, REMOVE } from '../type';

export function loaderReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case LOAD: {
      return { isLoading: true, isSuccess: false };
    }

    case SUCCESS: {
      return { isLoading: false, isSuccess: true };
    }

    case REMOVE: {
      return {};
    }

    default:
      return state;
  }
}
