/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/RailChains.css';
import Table from '../Table/Table';
import CustomButton from '../CustomElements/CustomButton';
import useUrlNavigate from '../../customHook/useUrlNavigate';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import CustomContainer from '../CustomElements/CustomContainer';
import AddRailChains from './AddRailChain';
import Modal from '../Modal/Modal';
import Input from '../CustomElements/Input';
import RailChainsTable from '../Table/RailChainsTable';
import Selector from '../CustomElements/Selector';
import CustomTitle from '../CustomElements/CustomTitle';
import Preloader from '../CustomElements/Preloader';
import ViewModifiedRailChains from './ViewModifiedRailChains';
import DisplayModifiedTrack from './DisplayModifiedTrack';

import { useRole } from '../../customHook/useRole';
import useVisibleSpans from '../../customHook/useVisibleSpans';
import useSelectableColumns from '../../customHook/useSelectableColumns';
import {
  getDataCheckResultFromServer,
  getXlsxFileFromServer,
  requestRailChains,
  updateRailChainsFromServer,
  getZipFileFromServer,
} from '../../redux/actions/infocards.action';
import { load, remove } from '../../redux/actions/loader.action';
import { renderCords, useInfocard, useIsEditing } from './Common';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { getYandexLinearMap, getYandexPointsLink } from '../../utils/mapUtils';
import { cloneArray } from '../../utils/arrUtils';
import { processTracks } from '../../utils/railChainsTableInputChangeHandlers';

/**
 * @typedef {Object} RCLocation
 * @property {number} lon - широта
 * @property {number} lat - долгота
 * @property {number} ord - Ордината
 */

/**
 * @typedef {Object} Coordinate
 * @property {RCLocation} end - Координаты конца рельсовой цепи
 * @property {[RCLocation, RCLocation, RCLocation, RCLocation]} controlled - Контролируемое ответствление. [А, Б, В, Г]
 * @property {[RCLocation, RCLocation, RCLocation]} uncontrolled - Неконтролируемое ответвление. [Н1, Н2, Н3]
 */

export default function RailChains() {
  const dispatch = useDispatch();
  // Получение сообщения об ошибке из глобального состояния
  const error = useSelector((state) => state.error);

  //текст для окна подтверждения если при запросе была ошибка текст меняем на другой
  const [confirmText, setConfirmText] = useState('Изменения РЦ завершены');

  //если при запросе на изменение ошибка меняем текст в окне
  useEffect(() => {
    if (error.error) {
      setConfirmText('Изменения РЦ не сохранены!');
    }
  }, [error]);

  // роли пользователя
  const isAdmin = useRole('admin');

  // Получаем данные о процессе
  let loader = useSelector((state) => state.loader);

  // отслеживаем наличие сообщений об ошибках на странице
  const cooordErrors = useVisibleSpans();

  // данные об измененных РЦ
  const modifiedRailChains = useRef({});

  // Состояния для отображения компонентов и модального окна
  const [isOpen, setIsOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  //состояние для модального окна подтверждения изменений
  const [confirmationOfChanges, setConfirmationOfChanges] = useState(false);

  // состояние для модалки с сообщением с успешном удалении
  const [successModal, setSuccessModal] = useState(false);

  //состояние для модалки подтверждения удаления
  const [confirmDelete, setConfirmDelete] = useState(false);

  // состояние для отображения кнопки показывающей изменение трека
  const [viewBtnModifiTrack, setViewBtnModifiTrack] = useState(true);

  // функция для проверки наличия поля трек и обновления состояния
  const handleCheckTrackField = (data) => {
    const trackExists = Object.values(data).some((section) => 'Трек' in section);
    setViewBtnModifiTrack(trackExists);
  };

  const [showDisplayModifiedTrack, setShowDisplayModifiedTrack] = useState(false);

  const { station, name, kit_id } = useInfocard();

  let navigate = useUrlNavigate();
  const { isEditing, setEditing } = useIsEditing();

  // получаем массив РЦ
  const source = useSelector((s) => s.infocards.rail_chains);

  // создаем копию массива РЦ для редактирования в таблице
  const [copySource, setCopySource] = useState(cloneArray(source));
  // Обновляем copySource при изменении source
  useEffect(() => {
    setCopySource(cloneArray(source));
  }, [source]);
  // Обновляем copySource при открытии таблицы редактирования
  useEffect(() => {
    setCopySource(cloneArray(source));
  }, [isEditing]);

  const [firstUpdateCopySource, setFirstUpdateCopySource] = useState(false);
  //записываем id измененого РЦ чтобы раздизейблить кнопку "сохранить"
  const isUpdate = (id) => {
    if (!firstUpdateCopySource) {
      setFirstUpdateCopySource(true);
      handleModify(id);
    }
  };

  // состояние для хранения id строк в которых ошибки в поле трек
  const [errorIds, setErrorIds] = useState([]);

  // состояние для хранения id строк в которых ошибки в поле трек
  const [errorCoordIds, setErrorCoordIds] = useState(new Set());

  // состояние для хранения id измененных строк
  const [modifiedIds, setModifiedIds] = useState(new Set());
  // Функция для добавления id row, если его еще нет в множестве
  const handleModify = (id) => {
    setModifiedIds((prevIds) => {
      const newIds = new Set(prevIds);
      newIds.add(id);
      return newIds;
    });
  };

  const [search, setSearch] = useState('');
  const searchInputRef = useRef(null);

  const handleDownloadXlsx = (event) => {
    event.preventDefault();
    dispatch(getXlsxFileFromServer(station, name));
  };

  const handleDownloadZip = (event) => {
    event.preventDefault();
    dispatch(getZipFileFromServer(station, kit_id));
  };

  const handleDataCheck = (event) => {
    event.preventDefault();
    dispatch(getDataCheckResultFromServer(station, name));
  };

  const onApply = () => {
    dispatch(load());
    setConfirmationOfChanges(false);
    // собираем в пейлоад только те объекты id которых помечены как измененные
    const payload = [...modifiedIds].reduce((acc, id) => {
      const obj = copySource.find((item) => item.id === id);
      if (obj) {
        // Убираем ненужные поля
        const {  road, road_name, station_name, department_name, ...cleanedObj } = obj;
        acc.push(cleanedObj);
      }
      return acc;
    }, []);

    const invalidIds = processTracks(payload);
    if (invalidIds) {
      setErrorIds(invalidIds);
      return;
    }
    dispatch(updateRailChainsFromServer(station, payload));
  };

  // отслеживаем выполнение запроса
  useEffect(() => {
    if (!loader?.isLoading && loader?.isSuccess && isEditing && !confirmDelete) {
      // очищаем стейт отслеживающий загрузку
      dispatch(remove());
      //очищаем стейт с id  измененных объектов
      setModifiedIds(new Set());
      setConfirmModal(true);
      setIsOpen(true);
      setFirstUpdateCopySource(false);
    }
  }, [loader?.isLoading, loader?.isSuccess]);

  // запрашиваю элементы от сервера
  useEffect(() => {
    if (Number.isFinite(+station)) {
      dispatch(requestRailChains(station));
    }
  }, [station]);

  // при перезагрузке сбрасываем массив id треков с ошибкой
  useEffect(() => {
    setErrorIds([]);
  }, []);

  const columns = useMemo(() => {
    return [
      {
        Header: 'ID РЦ',
        accessor: 'id',
        id: 'rail-chain-header',
        width: 'var(--sadr-font-size-150)',
        Cell: ({ cell: { value } }) => <span id="rail-chain-header">{defaultCellRender(value)}</span>,
      },
      {
        Header: 'Длина участка оповещения',
        columns: [
          {
            Header: 'ПЧ',
            accessor: 'alertLengths[0]',
            width: 'var(--sadr-font-size-100)',
            Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
          },
          {
            Header: 'ЭЧ',
            accessor: 'alertLengths[1]',
            width: 'var(--sadr-font-size-100)',
            Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
          },
        ],
      },
      {
        Header: 'Объект',
        accessor: 'object',
        width: 'var(--sadr-font-size-200)',
        Cell: ({ cell: { value } }) => <span style={{ whiteSpace: 'nowrap' }}>{defaultCellRender(value)}</span>,
      },
      {
        Header: 'Рельсовая цепь',
        accessor: 'rail_chain_name',
        width: 'var(--sadr-font-size-150)',
        Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
      },
      {
        Header: 'Ординаты концов РЦ',
        columns: [
          {
            Header: 'Питающий конец',
            accessor: 'coordinate.end.ord',
            width: 'var(--sadr-font-size-120)',
            Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
          },
          {
            Header: () => <div style={{}}>Контролируемое ответвление</div>,
            id: 'controlledBranch',
            columns: [
              {
                Header: 'А (релейный конец)',
                accessor: 'coordinate.controlled[0].ord',
                width: 'var(--sadr-font-size-120)',
                Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
              },
              {
                Header: 'Б',
                accessor: 'coordinate.controlled[1].ord',
                width: 'var(--sadr-font-size-120)',
                Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
              },
              {
                Header: 'В',
                accessor: 'coordinate.controlled[2].ord',
                width: 'var(--sadr-font-size-120)',
                Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
              },
              {
                Header: 'Г',
                accessor: 'coordinate.controlled[3].ord',
                width: 'var(--sadr-font-size-120)',
                Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
              },
            ],
          },
          {
            Header: () => <div style={{}}>Неконтролируемое ответвление</div>,
            id: 'uncontrolledBranch', // добавлено уникальное значение id
            columns: [
              {
                Header: 'H1',
                accessor: 'coordinate.uncontrolled[0].ord',
                width: 'var(--sadr-font-size-150)',
                minWidth: '200px',
                Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
              },
              {
                Header: 'H2',
                accessor: 'coordinate.uncontrolled[1].ord',
                width: 'var(--sadr-font-size-150)',
                minWidth: '200px',
                Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
              },
              {
                Header: 'H3',
                accessor: 'coordinate.uncontrolled[2].ord',
                width: 'var(--sadr-font-size-150)',
                minWidth: '200px',
                Cell: ({ cell: { value } }) => <span>{defaultCellRender(value)}</span>,
              },
            ],
          },
        ],
      },
      {
        Header: 'Координаты концов РЦ',
        columns: [
          {
            Header: 'Питающий конец',
            accessor: 'coordinate.end',
            width: 'var(--sadr-font-size-300)',
            Cell: renderCords(getYandexPointsLink),
          },
          {
            Header: 'Контролируемое ответвление',
            columns: [
              {
                Header: 'А (релейный конец)',
                accessor: 'coordinate.controlled[0]',
                width: 'var(--sadr-font-size-300)',
                Cell: renderCords(getYandexPointsLink),
              },
              {
                Header: 'Б',
                accessor: 'coordinate.controlled[1]',
                width: 'var(--sadr-font-size-300)',
                Cell: renderCords(getYandexPointsLink),
              },
              {
                Header: 'В',
                accessor: 'coordinate.controlled[2]',
                width: 'var(--sadr-font-size-300)',
                Cell: renderCords(getYandexPointsLink),
              },
              {
                Header: 'Г',
                accessor: 'coordinate.controlled[3]',
                width: 'var(--sadr-font-size-300)',
                Cell: renderCords(getYandexPointsLink),
              },
            ],
          },
          {
            Header: 'Неконтролируемое ответвление',
            columns: [
              {
                Header: 'H1',
                accessor: 'coordinate.uncontrolled[0]',
                width: 'var(--sadr-font-size-300)',
                Cell: renderCords(getYandexPointsLink),
              },
              {
                Header: 'H2',
                accessor: 'coordinate.uncontrolled[1]',
                width: 'var(--sadr-font-size-300)',
                Cell: renderCords(getYandexPointsLink),
              },
              {
                Header: 'H3',
                accessor: 'coordinate.uncontrolled[2]',
                width: 'var(--sadr-font-size-300)',
                Cell: renderCords(getYandexPointsLink),
              },
            ],
          },
        ],
      },
      {
        Header: 'Трек',
        accessor: 'track',
        width: 'var(--sadr-font-size-300)',
        Cell: renderCords(getYandexLinearMap) || '-',
      },
    ];
  }, [source]);

  const { selectOptions, filteredColumns, handleSelectChange, selectValue } = useSelectableColumns(columns);

  const go2root = useCallback(() => {
    navigate((url, root) => {
      url.pathname = root;
    });
    // Удаляем признак "занятости" иентерфейса
    dispatch(remove());
  }, [navigate, dispatch]);

  return (
    <div id="railchains-container" className="d-flex flex-column h-100 container-fluid overflow-hidden flex-nowrap">
      {!isEditing && (
        <div className="rail-chains-select-container">
          <Input
            id="input-rail-chains-select-container"
            name={'search-rc'}
            type="search"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            margin={0}
            defaultValue={search}
            placeholder="Поиск по ключевым словам..."
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            _ref={searchInputRef}
          />
          <Selector
            options={selectOptions}
            onChange={(selectedOption) => handleSelectChange(selectedOption)}
            placeholder="Показать/скрыть поля"
            filtersBlock={true}
            width="100%"
            height="var(--sadr-font-size-60)"
            value={selectValue}
            isMulti={true}
            name="railchains-columns-selector"
            isView
            optionSquare
          />
        </div>
      )}
      {!isEditing && (
        <Table
          data={source}
          columns={filteredColumns}
          className="flex-grow-1 rail-chains-table"
          searchInput={search}
          fixFirstColumn={true}
        />
      )}
      {isEditing && (
        <RailChainsTable
          station={station}
          handleModify={handleModify}
          source={source}
          setCopySource={setCopySource}
          copySource={copySource}
          errorIds={errorIds}
          setErrorIds={setErrorIds}
          errorCoordIds={errorCoordIds}
          setErrorCoordIds={setErrorCoordIds}
          isUpdate={isUpdate}
          successModal={successModal}
          setSuccessModal={setSuccessModal}
          confirmDelete={confirmDelete}
          setConfirmDelete={setConfirmDelete}
          modifiedRailChains={modifiedRailChains?.current}
        />
      )}

      <div className={`d-flex flex-row flex-nowrap ${isAdmin ? 'justify-content-evenly' : 'justify-content-center'}`}>
        {!isEditing && (
          <>
            {isAdmin && (
              <>
                <CustomButton
                  className="confirmation-btn"
                  text="+Добавить РЦ"
                  onClick={() =>
                    navigate((u, root) => {
                      u.pathname = root + '/create';
                    })
                  }
                  isButton
                />
                <CustomButton
                  className="confirmation-btn"
                  text="Редактировать"
                  onClick={() => setEditing(true)}
                  isButton
                  disabled={source?.length < 1}
                />
              </>
            )}
            <CustomButton className="confirmation-btn" text="Выгрузить" onClick={handleDownloadXlsx} isButton />
            <CustomButton className="confirmation-btn" text="Выгрузить zip" onClick={handleDownloadZip} isButton />
            <CustomButton className="confirmation-btn" text="Выполнить проверки" onClick={handleDataCheck} isButton />
          </>
        )}
        {isEditing && (
          <>
            <CustomButton
              className="confirmation-btn"
              text="Сохранить"
              onClick={() => {
                setIsOpen(true);
                setConfirmationOfChanges(true);
                setConfirmModal(true);
                handleCheckTrackField(modifiedRailChains?.current);
              }}
              disabled={modifiedIds.size === 0 || errorIds?.length > 0 || errorCoordIds.size > 0 || cooordErrors}
              isButton
            />
            <CustomButton
              className="confirmation-btn cancel"
              color="var(--sadr-background-secondary)"
              colorText="var(--sadr-border)"
              text="Отмена"
              onClick={() => {
                setIsOpen(false);
                setModifiedIds(new Set()); // Сбросить modifiedIds
                if (searchInputRef.current) {
                  setSearch((prev) => prev);
                }
                navigate((url) => {
                  url.searchParams.delete('editing'); // Удалить параметр 'editing' из URL
                  return url;
                });
              }}
              isButton
            />
          </>
        )}
      </div>
      {/* Модальное окно */}
      <Modal
        width={confirmationOfChanges ? '100%' : '50%'}
        height={confirmationOfChanges ? '' : '60%'}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {confirmModal && (
          <>
            {confirmationOfChanges && (
              <>
                <CustomContainer>
                  <CustomTitle titleText="Информация об измененных РЦ" />

                  <div className="container-rail-chains-modifi-table">
                    <ViewModifiedRailChains data={modifiedRailChains?.current} />
                    {viewBtnModifiTrack && (
                      <CustomButton
                        width="var(--sadr-font-size-280)"
                        className="confirmation-btn cancel"
                        color="var(--sadr-background-secondary)"
                        colorText="var(--sadr-border)"
                        margin="0 0 30px 0"
                        text={showDisplayModifiedTrack ? 'Скрыть изменения для трека' : 'Показать изменения для трека'}
                        onClick={() => {
                          setShowDisplayModifiedTrack((prev) => !prev);
                        }}
                      />
                    )}
                    {showDisplayModifiedTrack && <DisplayModifiedTrack data={modifiedRailChains?.current} />}
                  </div>

                  <div className="margin-top-auto">
                    <h5 className="confirmation-title rail-chais-title">Вы уверены, что хотите сохранить изменения?</h5>
                    <div className="confirmation-btn-container">
                      <CustomButton
                        width="var(--sadr-font-size-280)"
                        className="confirmation-btn confirm"
                        text="Подтвердить"
                        onClick={onApply}
                      />
                      <CustomButton
                        width="var(--sadr-font-size-280)"
                        className="confirmation-btn cancel"
                        color="var(--sadr-background-secondary)"
                        colorText="var(--sadr-border)"
                        text="Отмена"
                        onClick={() => {
                          setConfirmModal(false);
                          setIsOpen(false);
                          setShowDisplayModifiedTrack(false);
                        }}
                      />
                    </div>
                  </div>
                </CustomContainer>
              </>
            )}
            {loader?.isLoading && !confirmationOfChanges && (
              <>
                <div className="preloader-container">
                  <CustomTitle titleText="Идет процес сохранения, пожалуйста подождите!" />
                  <Preloader isLoading={loader?.isLoading} />
                </div>
              </>
            )}

            {!loader?.isLoading && !confirmationOfChanges && (
              <CustomContainer>
                <ConfirmationPage
                  onConfirm={() => {
                    setIsOpen(false);
                    setEditing(false);
                    setConfirmModal(false);
                    setConfirmText('Изменения РЦ завершены');
                    modifiedRailChains.current = {};
                  }}
                  confirmText={confirmText}
                  addNew="Закрыть"
                  btnWidth="var(--sadr-font-size-280)"
                />
              </CustomContainer>
            )}
          </>
        )}
      </Modal>
      <Routes>
        <Route
          path="/create/*"
          element={
            <Modal isOpen onClose={go2root} containerSelector="#railchains-container">
              <AddRailChains closeModal={go2root} />
            </Modal>
          }
        />
      </Routes>
    </div>
  );
}
