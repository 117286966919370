import { React, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/FilterForBrigades.css';
import Selector from '../CustomElements/Selector';
import Accordeon from '../CustomElements/Accordeon';
import Input from '../CustomElements/Input';
import DateRangePicker from '../CustomElements/DateRangePicker';

import { useFilterControl } from '../../customHook/useFilterControl';
import { useRole } from '../../customHook/useRole';
import useOptionsLoader from '../../customHook/useOptionsLoader';

import { handleUniversalChange } from '../../utils/formUtils';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsSectionFromServer,
} from '../../redux/actions/options.action';
import { clearBrigades, getDeviceById } from '../../redux/actions/brigades.action';
import { initBrigadesFromServer } from '../../redux/actions/brigades.action';
import { clearFilterConfig } from '../../redux/actions/filterConfig.action';

function FilterForBrigades({
  setIsViewList,
  isViewList,
  setIsViewMap,
  isViewMap,
  formData,
  setFormData,
  initialFormData,
  t,
}) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData);

  // стейт активности формы
  const formActive = useSelector((state) => state.filterState.filter);
  // блокируем дублирующиеся запросы
  const block = useRef(true);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = t?.messageForSelectDirectorates ||'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate_id', 'department_id', 'station_id'],
    'road_id',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = t?.messageForSelectDepartments ||'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department_id'],
    'directorate_id',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //хук для запроса опций для станций и контроля селекта
  const messageForSelectStation = t?.messageForSelectStation ||'Для выбора станции выберите дорогу';
  const stationControl = useOptionsLoader(
    formData,
    setFormData,
    ['station_id'],
    'road_id',
    initOptionsSectionFromServer,
    messageForSelectStation
  );

  //цвет кнопок, события кнопок
  const [colorBtn, setColorBtn] = useState(false);
  const handleButtonClickList = () => {
    if (!isViewList) {
      setIsViewList((prev) => !prev);
      setIsViewMap((prev) => !prev);
      setColorBtn((prev) => !prev);
      dispatch(clearFilterConfig());
    }
  };
  const handleButtonClickMap = () => {
    if (!isViewMap) {
      setIsViewMap((prev) => !prev);
      setIsViewList((prev) => !prev);
      setColorBtn((prev) => !prev);
      dispatch(clearFilterConfig());
    }
  };

  // Инициализации бригад с сервера.
  useEffect(() => {
    const isIdValid = formData?.id?.length === 6 || !formData?.id;
    // при сбросе поля обновляем стейт
    const isDeviceIdValid = formData?.deviceId?.length === 0;
    if (isIdValid && isDeviceIdValid) {
      if (formData.start) {
        formData.start = new Date(formData.start).getTime();
      }
      if (formData.finish) {
        formData.finish = new Date(formData.finish).getTime();
      }
      if (block.current) {
        block.current = false;
      } else if ((formData?.id?.length === 6 || !formData?.id) && formActive) {
        dispatch(clearBrigades());
        dispatch(clearFilterConfig());
        dispatch(initBrigadesFromServer(formData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData?.id,
    formData?.responsible,
    formData?.road_id,
    formData?.department_id,
    formData?.station_id,
    formData?.start,
    formData?.finish,
    formData?.deviceId,
    formData?.directorate_id,
    formActive,
  ]);

  // фильтрация по id
  useEffect(() => {
    if (formData?.deviceId?.length === 6) {
      dispatch(getDeviceById(formData?.deviceId));
      // resetFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.deviceId]);

  const isAdmin = useRole('admin');
  return (
    <Accordeon
      header={t?.accordionHeader}
      hideAbove={1200}
      qParamName={'brigades_acc'}
      formData={formData}
      resetFilters={resetFilters}
    >
      <div className="filter-for-brigades_container_btn">
        <button
          type="button"
          className={`filter-for-brigades_btn-list ${!colorBtn ? 'filter-for-brigades_btn-active' : ''}`}
          onClick={handleButtonClickList}
        >
          <span className="brigades-button-text">{t?.listButton}</span>
        </button>
        <button
          type="button"
          className={`filter-for-brigades_btn-map ${colorBtn ? 'filter-for-brigades_btn-active' : ''}`}
          onClick={handleButtonClickMap}
        >
          <span className="brigades-button-text">{t?.mapButton}</span>
        </button>
      </div>
      <button
        type="button"
        className={`filter-for-brigades_btn-reset-filters ${showBtnReset ? 'visible-btn-reset' : 'hidden-btn-reset'}`}
        onClick={resetFilters}
      >
        <span className="filter-for-brigades_btn-reset-filters_text">{t?.resetFiltersButton}</span>
        <img
          className="filter-for-brigades_btn-reset-filters_icon"
          src="/image/close.svg"
          alt={t?.closeButtonText}
        ></img>
      </button>
      <form className="filter-for-brigades_wrapper" id="" method="POST" action="#" autoComplete="off">
        <div className="filter-for-brigades_select" id="">
          <Input
            label={t?.idLabel}
            placeholder={t?.idPlaceholder}
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            value={formData?.id}
            name="id"
            // type="number"
          />
          <Input
            placeholder={t?.responsiblePlaceholder}
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData?.responsible}
            label={t?.responsibleLabel}
            name="responsible"
            // type="number"
          />
          <Selector
            options={optionsForSelect.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'road_id', selectedOption, 'select');
            }}
            placeholder={t?.roadPlaceholder}
            isMulti={false}
            label={t?.roadLabel}
            value={
              formData.road_id ? optionsForSelect?.roads?.find((option) => option.value === formData.road_id) : null
            }
            name="road_id"
          />
          <Selector
            options={optionsForSelect?.directorates}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorate_id', selectedOption, 'select');
            }}
            placeholder={t?.directoratePlaceholder}
            label={t?.directorateLabel}
            value={
              formData.directorate_id
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate_id)
                : null
            }
            isMulti={false}
            name="directorate_id"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'department_id', selectedOption, 'select');
            }}
            placeholder={t?.departmentPlaceholder}
            isMulti={false}
            label={t?.departmentLabel}
            value={
              formData.department_id
                ? optionsForSelect?.departments?.find((option) => option.value === formData.department_id)
                : null
            }
            name="department_id"
            isDisabled={departmentsControl?.isSelectorDisabled}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect?.stations}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'station_id', selectedOption, 'select');
            }}
            value={
              formData.station_id
                ? optionsForSelect.stations?.find((option) => option.value === formData.station_id)
                : null
            }
            isMulti={false}
            placeholder={t?.stationPlaceholder}
            name="station_id"
            label={t?.stationLabel}
            isDisabled={stationControl?.isSelectorDisabled}
            message={stationControl?.message}
            customClick={stationControl?.onSelectorChange}
          />
          {isAdmin && (
            <DateRangePicker
              label={t?.dateLabel}
              formData={formData}
              setFormData={setFormData}
              startDateField="start" // имя поля для даты начала, с дефолтным значением
              endDateField="finish" // имя поля для даты окончания, с дефолтным значением
              placeholder={t?.datePlaceholder}
              name="date_period"
            />
          )}
          <div className="divider" />
          <Input
            placeholder={t?.devicePlaceholder}
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            value={formData?.deviceId}
            label={t?.deviceLabel}
            name="deviceId"
            // type="number"
            maxLength={6}
          />
        </div>
      </form>
    </Accordeon>
  );
}

export default FilterForBrigades;
