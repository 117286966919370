/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { updateSortData } from '../redux/actions/sortData.action';
import { clearMapData, clearMapIgnores, initMapDataFromServer } from '../redux/actions/mapData.action';
import { getDeviceById } from '../redux/actions/brigades.action';

/**
 * Проверяет, есть ли непустые значения в объекте.
 *
 * @param {Object} data - Объект для проверки.
 * @returns {boolean} - Возвращает true, если есть хотя бы одно непустое значение.
 */
function hasNonEmptyValues(data) {
  return Object.entries(data).some(([key, value]) => {
    if (key === 'broken') {
      // Для поля broken: true, false и undefined считаются заполненными, '' - нет
      return value !== '';
    }
    // Для остальных полей стандартная проверка
    return value !== '' && value !== undefined && value !== null;
  });
}

/**
 * Создает строку запроса для карты на основе строки поиска и границ карты.
 *
 * @param {string} searchString - Строка поиска.
 * @param {Object} mapBorders - Границы карты с координатами _southWest и _northEast.
 * @returns {string} - Сформированная строка запроса.
 */
function createQueryStringForMap(searchString, mapBorders) {
  const params = new URLSearchParams();

  // Добавляем строку поиска
  if (searchString) {
    params.append('like', searchString);
  }

  // Добавляем границы карты
  if (mapBorders?._southWest && mapBorders?._northEast) {
    params.append('visibleMinLat', mapBorders._southWest.lat);
    params.append('visibleMaxLat', mapBorders._northEast.lat);
    params.append('visibleMinLon', mapBorders._southWest.lng);
    params.append('visibleMaxLon', mapBorders._northEast.lng);
  }

  return `?${params.toString()}`;
}

/**
 * Формирует строку запроса на основе входных данных.
 *
 * @param {Object} formData - Данные фильтров.
 * @param {string} searchString - Строка поиска.
 * @param {Object} sortData - Данные сортировки (содержат поля sort и direction).
 * @param {number} offset - Смещение для пагинации.
 * @param {number} limit - Лимит количества записей.
 * @returns {string} - Сформированная строка запроса.
 */
function createQueryString(formData, searchString, sortData, offset, limit) {
  const params = new URLSearchParams();
  // Добавляем сортировку
  if (sortData?.sort) params.append('sort', sortData.sort);
  if (sortData?.direction) params.append('direction', sortData.direction);

  // Добавляем строку поиска
  if (searchString) params.append('like', searchString);

  // Обрабатываем форм-данные
  Object.entries(formData).forEach(([key, value]) => {
    if (key === 'broken') {
      // Для поля broken добавляем значение, если оно false, undefined или true
      if (value !== '' && value !== undefined) {
        params.append(key, value);
      }
    } else if (value) {
      // Стандартная обработка для остальных полей
      if (key === 'receive_date') {
        params.append(key, moment.tz(value, 'Europe/Moscow').format('YYYY-MM-DD'));
      } else if (key === 'start' || key === 'finish') {
        params.append(key, new Date(value).getTime());
      } else {
        params.append(key, value);
      }
    }
  });

  // Добавляем лимит и офсет
  if (limit) params.append('limit', limit);
  params.append('offset', offset || 0); // Офсет по умолчанию 0

  return `?${params.toString()}`;
}

/**
 * Хук для реализации функциональности бесконечного скролла с поддержкой фильтров, сортировки и поиска.
 *
 * @param {Object} formData - Данные фильтров для формирования запроса.
 * @param {string} searchString - Текущая строка поиска.
 * @param {Function} setSearchString - Функция для изменения строки поиска.
 * @param {Function} initFunction - Функция для отправки запроса на сервер.
 * @param {Function|string} dataSelector - Redux-селектор для получения данных из состояния.
 * @param {Function} resetFilters - Функция для сброса фильтров.
 * @param {Function} clearState - Функция для очистки Redux-состояния перед запросом.
 *  @param {boolean} isMapActive - Флаг, указывающий, нужно ли останавливать запросы (например, при переходе на карту).
 * @param {number} [limit=50] - Лимит записей на страницу.
 * @returns {Object} - Объект с функцией handleSearch для обработки поиска и bottomBoundaryRef для бесконечного скролла.
 */
const useInfiniteScroll = (
  formData = '',
  searchString,
  setSearchString,
  initFunction,
  dataSelector,
  resetFilters,
  clearState,
  isMapActive,
  limit = 50
) => {
  const observer = useRef(null); // IntersectionObserver для отслеживания элемента
  const bottomBoundaryRef = useRef(null); // Реф элемента, который наблюдается
  const [offset, setOffset] = useState(0); // Текущее значение офсета
  const [noMoreData, setNoMoreData] = useState(false); // Флаг, что данных больше нет
  const [mode, setMode] = useState(null); // Режим
  const [isRequesting, setIsRequesting] = useState(false); // Флаг для управления запросами, чтобы запросы не повторялись

  const dispatch = useDispatch();

  // === Получение данных из Redux ===
  const sortData = useSelector((state) => state.sortData);
  const { mapBorders } = useSelector((state) => state.mapData);
  const brigadesData = useSelector((state) => state.brigadesState?.brigades);
  const data = useSelector((state) => state?.[dataSelector]);
  const customData = dataSelector === 'brigades' ? brigadesData : data;

  /**
   * Обработка поиска по кнопке.
   * Выполняет запрос с текущей строкой поиска и сбрасывает фильтры и сортировку.
   */
  const handleSearch = useCallback(() => {
    // выполняем только если есть строка поиска и мы на карте
    if (isMapActive) {
      // Очистка игнорируемых маркеров
      dispatch(clearMapIgnores());
      // Очистка маркеров и области видимости
      dispatch(clearMapData);
      // формирование нового запроса
      const queryString = createQueryStringForMap(searchString, mapBorders);
      dispatch(initMapDataFromServer(queryString));
      return;
    }
    // выполняем только если есть строка поиска и мы не на карте
    if (!isMapActive) {
      // Сброс фильтров, в этой же  функции происходит сброс сортировки
      if (hasNonEmptyValues(formData)) {
        resetFilters(false);
      }
      const queryString = createQueryString({}, searchString, sortData, 0, limit);
      // Очистка стейта для отображения в таблице
      dispatch(clearState());
      dispatch(initFunction(queryString)).then((result) => {
        if (!result || !result.data || result.data.length === 0) {
          // Остановка запросов, если данных больше нет
          setNoMoreData(true);
        }
      });
      setOffset(1); // Установка начального офсета
      setNoMoreData(false); // Сброс флага остановки запросов
    }
  }, [searchString, resetFilters, dispatch, clearState, initFunction, limit, mapBorders]);

  /**
   * Эффект: Переход в режим поиска.
   * Сбрасывает фильтры и сортировку при вводе строки поиска.
   */
  useEffect(() => {
    if (mode === 'search') return; // Прерываем выполнение если нужный режим
    if (searchString && mode !== 'search') {
      setMode('search'); // Устанавливаем режим "поиск"
      // если есть сортировка сбрасываем
      if (Object.keys(sortData).length > 0) {
        dispatch(updateSortData({}));
      }
      //сбрасываем офсет и запрет загрузки
      setOffset(0);
      setNoMoreData(false);
      // Сброс фильтров если они есть
      if (hasNonEmptyValues(formData)) {
        resetFilters(false);
      }
    }
  }, [searchString]);

  /**
   * Эффект: Переход в режим фильтров.
   * Сбрасывает строку поиска и сортировку при изменении фильтров.
   */
  useEffect(() => {
    if (mode === 'filter') return; // Прерываем выполнение если нужный режим
    if (hasNonEmptyValues(formData) && mode !== 'filter') {
      setMode('filter'); // Устанавливаем режим "фильтр"
      //если есть строка поиска сбрасываем
      if (searchString) {
        setSearchString('');
      }
      // если есть сортировка сбрасываем
      if (Object.keys(sortData).length > 0) {
        dispatch(updateSortData({}));
      }
      //сбрасываем офсет и запрет загрузки
      setOffset(0);
      setNoMoreData(false);
    }
  }, [formData]);

  /**
   * Эффект: Для первого запроса.
   */
  useEffect(() => {
    // Выполняем начальный запрос только один раз при монтировании компонента
    dispatch(clearState()); // Очищаем стейт
    const queryString = createQueryString({}, '', {}, 0, limit); // Пустой фильтр, сортировка и поиск
    dispatch(initFunction(queryString)).then((result) => {
      if (!result || !result.data || result.data.length === 0) {
        setNoMoreData(true); // Останавливаем запросы, если данных нет
      }
    });
    setNoMoreData(false);
    setOffset(1); // Устанавливаем начальный офсет
  }, []);

  /**
   * Эффект: Обработка изменения сортировки.
   * Выполняет запрос с учетом текущих фильтров или строки поиска.
   */
  useEffect(() => {
    if (isMapActive) return; // Прерываем выполнение, если активна карта
    if (sortData?.sort || sortData?.direction) {
      const queryString = createQueryString(formData, searchString, sortData, 0, limit);
      // Очистка стейта для отображения в таблице
      dispatch(clearState());
      dispatch(initFunction(queryString)).then((result) => {
        if (!result || !result.data || result.data.length === 0) {
          setNoMoreData(true);
        }
      });
      setOffset(1);
      setNoMoreData(false);
    }
  }, [sortData]);

  /**
   * Эффект: Инициализация данных при изменении фильтров.
   */
  useEffect(() => {
    if (isMapActive || isRequesting) return; // Прерываем выполнение, если активна карта
    // Проверка введён ли id длинной 6 или не введён
    const isIdValid = formData?.id?.length === 6 || !formData?.id;
    const isValidType = formData?.device_id?.length === 6 || !formData?.device_id;
    if (isIdValid && isValidType && mode === 'filter') {
      setIsRequesting(true);
      const queryString = createQueryString(formData, '', sortData, 0, limit);
      // Очистка стейта для отображения в таблице
      dispatch(clearState());

      if (formData?.device_id?.length === 6) {
        dispatch(getDeviceById(formData?.device_id));
        setIsRequesting(false);
        setOffset(0);
      } else {
        //отправка запроса с учетом параметров
        dispatch(initFunction(queryString)).then((result) => {
          if (!result || !result.data || result.data.length === 0) {
            setNoMoreData(true);
          }
        });
        setNoMoreData(false);
        setIsRequesting(false);
        setOffset(1);
      }
    }
  }, [formData, mode]);

  /**
   * Callback для IntersectionObserver.
   * Этот колбэк вызывается, когда отслеживаемый элемент пересекает границу видимости.
   * Отправляет запрос при достижении нижней границы страницы.
   */
  const observerCallback = useCallback(
    (entries) => {
      // Проверяем, что первый отслеживаемый элемент пересекает границу видимости
      if (entries[0].isIntersecting) {
        // Если больше данных нет, отключаем наблюдателя и прекращаем выполнение
        if (noMoreData) {
          if (observer.current) observer.current.disconnect(); // Отключаем наблюдателя, чтобы избежать лишних вызовов
          return; // Прекращаем дальнейшее выполнение, так как данных больше нет
        }
        // Генерируем строку запроса на основе текущих параметров фильтров, сортировки и пагинации
        const queryString = createQueryString(formData, searchString, sortData, offset, limit);
        // Отправляем запрос на сервер
        dispatch(initFunction(queryString)).then((result) => {
          // Если сервер вернул пустой результат или данные отсутствуют
          if (!result || !result.data || result.data.length === 0) {
            setNoMoreData(true); // Устанавливаем флаг, что данных больше нет
            if (observer.current) observer.current.disconnect(); // Отключаем наблюдателя, так как данные закончились
          } else {
            // Если данные есть, увеличиваем офсет для следующей загрузки
            setOffset((prevOffset) => prevOffset + 1);
            setNoMoreData(false);
          }
        });
      }
    },
    // Зависимости, чтобы обновлять колбэк при изменении параметров
    [dispatch, formData, searchString, sortData, offset, limit, initFunction, noMoreData]
  );

  /**
   * Настройка IntersectionObserver.
   * Эта функция задает новый `IntersectionObserver` и привязывает его к переданному DOM-узлу.
   * Если ранее существовал `IntersectionObserver`, он отключается.
   */
  const setObserver = useCallback(
    (node) => {
      // Если уже есть активный наблюдатель, отключаем его
      if (observer.current) observer.current.disconnect();

      // Создаем новый экземпляр `IntersectionObserver` с заданным колбэком
      observer.current = new IntersectionObserver(observerCallback, {
        root: document.querySelector('.table_container'), // Укажите ваш контейнер
        rootMargin: '1000px', // Запас в 200px для предварительной подгрузки данных
        threshold: 0, // Срабатывает, когда хотя бы 10% элемента видимы
      });

      // Если передан DOM-узел, наблюдаем за ним
      if (node) {
        observer.current.observe(node);
      }
    },
    [observerCallback] // Зависимость: обновляем функцию, если изменяется `observerCallback`
  );
  /**
   * Эффект: Настройка наблюдателя при изменении `bottomBoundaryRef` или `noMoreData`.
   * Пересоздает `IntersectionObserver` и привязывает его к элементу, если данные ещё есть.
   */
  useEffect(() => {
    // Проверяем, что реф связан с DOM-узлом и данные ещё доступны для загрузки
    if (bottomBoundaryRef.current && !noMoreData) {
      // Если есть существующий наблюдатель, отключаем его
      if (observer.current) observer.current.disconnect();

      // Создаем новый экземпляр `IntersectionObserver` с заданным колбэком
      observer.current = new IntersectionObserver(observerCallback, {
        root: document.querySelector('.table_container'), // Укажите ваш контейнер
        rootMargin: '1000px', // Запас в 200px для предварительной подгрузки данных
        threshold: 0, // Срабатывает, когда хотя бы 10% элемента видимы
      });

      // Привязываем наблюдателя к элементу, на который указывает `bottomBoundaryRef`
      observer.current.observe(bottomBoundaryRef.current);
    }
    return () => {
      if (observer.current) observer.current.disconnect();
    };
  }, [bottomBoundaryRef, noMoreData, observerCallback]); // Зависимости: обновляем эффект при изменении рефа, флага `noMoreData` или колбэка

  /**
   * Эффект: Установка наблюдателя при изменении данных.
   * Убедимся, что наблюдатель перенастраивается при изменении загруженных данных.
   */
  useEffect(() => {
    // Если `bottomBoundaryRef` связан с DOM-узлом, привязываем наблюдателя
    if (bottomBoundaryRef.current) {
      setObserver(bottomBoundaryRef.current); // Используем ранее определённую функцию для настройки наблюдателя
    }
  }, [customData, setObserver, bottomBoundaryRef]); // Зависимости: срабатывает при изменении данных, функции `setObserver` или рефа

  return { bottomBoundaryRef, handleSearch };
};

export default useInfiniteScroll;
