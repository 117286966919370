import { EDIT_LANGUAGE } from '../type';

export function languageReducer(state = '', action) {
  const { type, payload } = action;

  switch (type) {
    case EDIT_LANGUAGE: {
      return payload;
    }

    default:
      return state;
  }
}
