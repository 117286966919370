import { useState, useEffect, useMemo, React } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/DeviceRepair.css';
import FormContainer from '../CustomElements/FormContainer';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTextarea from '../CustomElements/CustomTextarea';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { convertToDate } from '../../utils/convertToDate';
import { transferDeviceForRepair } from '../../redux/actions/devices.action';
import { editRepairStatusFromServer } from '../../redux/actions/statuses.action';
import useFormDataChanged from '../../customHook/useFormDataChanged';
import { load } from '../../redux/actions/loader.action';
import PhoneInput from '../CustomElements/PhoneInput';
import useSuccessListener from '../../customHook/useSuccessListener';
import useFilesOptions from '../../customHook/useFilesOptions';
import FileSelector from '../CustomElements/FileSelector';

// компонент изменения статуса "Направление в ремонт" и для передачи устройства в ремонт(фигма 2.4.3)

function DeviceRepair({ currentDevice, closeModal, currentStatus, title, isEdit }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // Состояния для кнопки и уведомления об успешном добавлении
  const [addSuccess, setAddSuccess] = useState(false);

  const optionalFieldsObj = {
    broken: ['serviceCenterContact'],
    notBroken: ['serviceCenterContact', 'defectsFile', 'defectsFileName'],
  };

  const [optionalFields, setOptionalFields] = useState(optionalFieldsObj.notBroken);

  // отмена подтверждения
  const closeStatusEditModal = () => {
    setAddSuccess(false);
  };

  // Открываем  модальное для подтверждения изменений
  const handlerOpenModal = () => {
    setAddSuccess(true);
  };

  // Состояние для формы. Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () =>
      currentStatus
        ? {
          id: currentStatus.id,
          deviceId: currentDevice.id,
          date: convertToDate(currentStatus.date),
          acceptanceCert: currentStatus.acceptance_cert,
          acceptanceCertName: currentStatus?.acceptance_cert_name,
          serviceCenterId: currentStatus.service_center_id || '',
          serviceCenterContact: currentStatus.service_center_contact || '',
          senderId: currentStatus.sender_id || '',
          defectsFile: currentStatus.defects_file || '',
          defectsFileName: currentStatus?.defects_file_name || '',
          isBroken: currentStatus?.broken,
          note: currentStatus.note || '',
        }
        : {
          deviceId: currentDevice.id,
          date: '',
          acceptanceCert: '',
          serviceCenterId: '',
          serviceCenterContact: '',
          senderId: '',
          defectsFile: '',
          isBroken: '',
          note: '',
        },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );
  const [formData, setFormData] = useState(initialFormData);

  const acceptanceCertOptions = useFilesOptions(
    setFormData,
    'acceptanceCertName',
    'acceptanceCert'
  );


  const defectsFileOptions = useFilesOptions(
    setFormData,
    'defectsFileName',
    'defectsFile'
  );

  // Обновляем formData при изменении currentStatus
  useEffect(() => {
    setFormData(initialFormData);
    // Установить флаг открытия формы в true
  }, [initialFormData]);

  //проверяем были ли изменения в форме (true-да, true-нет)
  const isFormEdit = useFormDataChanged(initialFormData, formData, isEdit);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  useEffect(() => {
    if (formData?.isBroken) {
      setOptionalFields(optionalFieldsObj.broken);
    }
    if (!formData?.isBroken) {
      setOptionalFields(optionalFieldsObj.notBroken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.isBroken]);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    if (!isEdit) {
      const payload = createFormData(formData);
      //переводим в состояние загрузки
      dispatch(load());
      // Отправляем данные на сервер через Redux
      dispatch(transferDeviceForRepair(payload, optionsForSelect.deviceStatuses, currentDevice?.type_id));
    }
    if (isEdit) {
      handlerOpenModal();
    }
  };

  const handlerSubmitEdit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(editRepairStatusFromServer(payload, currentDevice.id, 'repair'));
  };

  //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal, closeStatusEditModal);

  return (
    <CustomContainer>
      <CustomTitle
        titleText={title || 'НАПРАВЛЕНИЕ В РЕМОНТ УСТРОЙСТВА'}
        deviceType={currentDevice.type}
        deviceId={currentDevice.id}
      />
      {addSuccess && (
        <ConfirmationPage
          confirmText="Вы уверены, что хотите изменить данные статуса?"
          confirmButtonText="Изменить"
          cancelButtonText="Отменить"
          onConfirm={handlerSubmitEdit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={closeStatusEditModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!addSuccess && (
        <FormContainer>
          <DateSelector
            column="left"
            width = "100%"
            height="var(--sadr-font-size-60)"
            label="Дата отправки в ремонт *"
            selectedDate={formData.date}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
            name="date"
            placeholder="Выберите дату"
            minDate={convertToDate(currentDevice?.last_action_date)}
            maxDate={new Date()}
          />
          <Selector
            column="left"
            options={optionsForSelect.serviceCenters}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'serviceCenterId', selectedOption, 'select')
            }
            placeholder="Выберите сервисный центр"
            label="Сервисный центр *"
            width = "100%"
            height="var(--sadr-font-size-60)"
            value={optionsForSelect.serviceCenters.find((option) => option.value === formData.serviceCenterId)}
            isMulti={false}
            name="serviceCenterId"
          />
          <Selector
            column="left"
            options={optionsForSelect.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'senderId', selectedOption, 'select')
            }
            placeholder="Выберите ФИО"
            label="ФИО направившего в ремонт *"
            width = "100%"
            height="var(--sadr-font-size-60)"
            value={optionsForSelect.employees.find((option) => option.value === formData.senderId)}
            isMulti={false}
            name="senderId"
          />
          <FileSelector
            formData={formData}
            setFormData={setFormData}
            attachmentOptions={acceptanceCertOptions}
            column='left'
            file={{
              field: 'acceptanceCert',
              name: 'acceptanceCertName',
              label: 'Акт приема-передачи *'
            }}
          />

          {/* Заглшка, чтобы сместить часть формы */}
          <div className='empty-placeholder' column="right"/>

          <PhoneInput
            column="right"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            label="Контактные данные сервисного центра"
            name="serviceCenterContact"
            value={formData.serviceCenterContact}
          />
          <Selector
            column="right"
            options={optionsForSelect.сondition.filter((item) => item.value !== undefined)}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'isBroken', selectedOption, 'select')
            }
            placeholder="Не определено"
            label="Состояние *"
            width = "100%"
            height="var(--sadr-font-size-60)"
            value={
              formData.isBroken === undefined
                ? optionsForSelect.сondition?.find((option) => option.label === 'Не определено')
                : formData.isBroken === ''
                  ? null
                  : optionsForSelect.сondition?.find((option) => option.value === formData.isBroken)
            }
            isMulti={false}
            name="isBroken"
          />

          <FileSelector
            formData={formData}
            setFormData={setFormData}
            attachmentOptions={defectsFileOptions}
            column='right'
            file={{
              field: 'defectsFile',
              name: 'defectsFileName',
              label: `Дефектная ведомость ${formData?.isBroken ? '*' : ''}`
            }}
          />

          <CustomTextarea
            // column="right"
            placeholder="Оставьте комментарий"
            type="textArea"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="note"
            label="Комментарий *"
            columnspan={2}
            // width = "100%"
            width="100%"
            height="100%"
            value={formData.note}
          />
          <CustomButton
            onClick={(event) => {
              handlerSubmit(event);
            }}
            width = "var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            color={isButtonDisabled ? '#ADADAD' : ''}
            text={isEdit ? 'Сохранить изменения' : 'Направить в ремонт'}
            disabled={isButtonDisabled || !isFormEdit}
            isButton
          />
        </FormContainer>
      )}
    </CustomContainer>
  );
}

export default DeviceRepair;
