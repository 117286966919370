import { ADD_ERROR, CLEAR_ERROR } from '../type';

export function errorReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_ERROR: {
      return payload;
    }

    case CLEAR_ERROR: {
      return { error: false };
    }

    default:
      return state;
  }
}
