import { useMemo } from 'react';
import { useAuthUser } from 'react-auth-kit';

/** @typedef {'admin' | 'user'} Role*/

/**
 * Проверяет есть ли выбранная роль для пользователя
 * @param roles {Role | Role[]}
 * @return {boolean}
 */
export function useRole(roles) {
  const user = useAuthUser()();
  return useMemo(() => {
    const _roles = Array.isArray(roles) ? roles : [roles];
    return _roles.every((x) => user?.role?.includes?.(x));
  }, [roles, user?.role]);
}
