import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/DeviceTransfer.css';
import CustomButton from '../CustomElements/CustomButton';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTextarea from '../CustomElements/CustomTextarea';
import CustomTitle from '../CustomElements/CustomTitle';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import FileSelector from '../CustomElements/FileSelector';

import { useButtonValidation } from '../../customHook/useButtonValidation';
import useFormDataChanged from '../../customHook/useFormDataChanged';

import { getCurrentStatusId } from '../../utils/getCurrentStatusId';
import { convertToDate } from '../../utils/convertToDate';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';

import { transferDeviceForStorage } from '../../redux/actions/devices.action';
import { load } from '../../redux/actions/loader.action';
import { editStorageStatusFromServer } from '../../redux/actions/statuses.action';
import useSuccessListener from '../../customHook/useSuccessListener';
import useFilesOptions from '../../customHook/useFilesOptions';

function DeviceTransfer({ currentDevice, closeModal, currentStatus, isEdit, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // Получение сообщения об ошибке из глобального состояния
  const error = useSelector((state) => state.error);

  const [addSuccess, setAddSuccess] = useState(false);

  // Состояние для формы. Мемоизируем начальные данные формы
  const initialFormData = useMemo(
    () =>
      currentStatus
        ? {
            id: currentStatus?.id,
            deviceId: currentDevice?.id,
            fromStatusId: getCurrentStatusId(currentDevice?.status, optionsForSelect?.deviceStatuses),
            date: convertToDate(currentStatus?.date),
            storageId: currentStatus?.storage_id || '',
            senderId: currentStatus.sender_id || '',
            isBroken: currentStatus?.broken,
            acceptanceCert: currentStatus?.acceptance_cert,
            acceptanceCertName: currentStatus?.acceptance_cert_name,
            receiverId: currentStatus?.receiver_id,
            note: currentStatus?.note || '',
          }
        : {
            deviceId: currentDevice.id,
            fromStatusId: getCurrentStatusId(currentDevice?.status, optionsForSelect.deviceStatuses),
            date: '',
            storageId: '',
            senderId: '',
            isBroken: undefined,
            acceptanceCert: '',
            receiverId: '',
            note: '',
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );

  const [formData, setFormData] = useState(initialFormData);

  const acceptanceCertOptions = useFilesOptions(setFormData, 'acceptanceCertName', 'acceptanceCert');

  // Обновляем formData при изменении currentStatus
  useEffect(() => {
    setFormData(initialFormData);
    // Установить флаг открытия формы в true
  }, [initialFormData]);

  //проверяем были ли изменения в форме (true-да, true-нет)
  const isFormEdit = useFormDataChanged(initialFormData, formData, isEdit);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, ['note']);

  // Открываем  модальное для подтверждения изменений
  const handlerOpenModal = (isEdit, payload) => {
    if (!isEdit) {
      dispatch(transferDeviceForStorage(payload, optionsForSelect.deviceStatuses, currentDevice?.type_id, 'storage'));
    }
    if (isEdit) {
      setAddSuccess(true);
    }
  };

  // отмена подтверждения
  const closeStatusEditModal = () => {
    setAddSuccess(false);
  };

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    // Отправляем данные на сервер через Redux
    const payload = createFormData(formData);
    handlerOpenModal(isEdit, payload);
  };

  const handlerSubmitEdit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(editStorageStatusFromServer(payload, formData.deviceId, 'storage'));
  };

  //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal);

  useEffect(() => {
    // Если нет ошибок, закрываем модальное окно
    if (!loader?.isLoading && loader?.isSuccess) {
      closeStatusEditModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess, error]);

  return (
    <CustomContainer>
      <CustomTitle
titleText={
  isEdit
    ? t?.deviceEditStatusTitle || 'ИЗМЕНЕНИЕ ДАННЫХ СТАТУСА'
    : t?.deviceTransferTitle || 'ПЕРЕМЕЩЕНИЕ НА ДРУГОЙ СКЛАД УСТРОЙСТВА'
}
        deviceType={currentDevice?.type}
        deviceId={currentDevice?.id}
      />
      {addSuccess && (
        <ConfirmationPage
          confirmText={t?.statusChangeConfirmationText || 'Вы уверены, что хотите изменить данные статуса?'}
          confirmButtonText={t?.confirmButton || 'Изменить'}
          cancelButtonText={t?.cancelButton || 'Отменить'}
          onConfirm={handlerSubmitEdit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={closeStatusEditModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!addSuccess && (
        <FormContainer>
          <DateSelector
            column="left"
            width="100%"
            height="var(--sadr-font-size-60)"
            label={t?.labelTransferDate || 'Дата передачи *'}
            selectedDate={formData?.date}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
            name="date"
            placeholder={t?.placeholderSelectDate || 'Выберите дату'}
            minDate={convertToDate(currentDevice?.last_action_date)}
            maxDate={new Date()}
          />
          <Selector
            column="left"
            options={optionsForSelect?.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'receiverId', selectedOption, 'select')
            }
            value={optionsForSelect?.employees?.find((option) => option.value === formData.receiverId)}
            label={t?.labelReceiverName || 'ФИО принявшего *'}
            placeholder={t?.placeholderReceiverName || 'Выберите ФИО принявшего'}
            name="receiverId"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
          />
          <FileSelector
            formData={formData}
            setFormData={setFormData}
            attachmentOptions={acceptanceCertOptions}
            rowspan={2}
            t={t}
            column={'left'}
            file={{
              field: 'acceptanceCert',
              name: 'acceptanceCertName',
              label: t?.labelAcceptanceCert || 'Акт приема-передачи *',
            }}
          />
          <Selector
            column="right"
            options={optionsForSelect?.storages}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'storageId', selectedOption, 'select')
            }
            placeholder={t?.placeholderStorageLocation || 'Выберите место хранения'}
            label={t?.labelNewStorageLocation || 'Новое место хранения *'}
            width="100%"
            height="var(--sadr-font-size-60)"
            value={optionsForSelect?.storages.find((option) => option?.value === formData?.storageId)}
            isMulti={false}
            name="storageId"
          />
          <Selector
            column="right"
            options={optionsForSelect.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'senderId', selectedOption, 'select')
            }
            value={optionsForSelect?.employees?.find((option) => option.value === formData.senderId)}
            label={t?.labelSenderName || 'ФИО передавшего *'}
            placeholder={t?.placeholderSenderName || 'Выберите ФИО передавшего'}
            name="senderId"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
          />
          <Selector
            column="right"
            options={optionsForSelect?.сondition?.filter((item) => item.value !== undefined)}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'isBroken', selectedOption, 'select')
            }
            value={optionsForSelect?.сondition?.find((option) => option.value === formData.isBroken)}
            label={t?.labelCondition || 'Состояние *'}
            placeholder={t?.placeholderCondition || 'Не определено'}
            name="isBroken"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
          />
          <CustomTextarea
            placeholder={t?.placeholderComment || 'Оставьте комментарий'}
            label={t?.labelComment?.replace(/\*$/, '').trim() || 'Комментарий'}
            type="textArea"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="note"
            width="100%"
            columnspan={2}
            // height="100%"
            value={formData.note}
          />

          <CustomButton
            onClick={(event) => {
              handlerSubmit(event);
            }}
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            color={isButtonDisabled ? '#ADADAD' : ''}
            text={
              isEdit ? t?.buttonSaveChanges || 'Сохранить изменения' : t?.buttonTransferDevice || 'Передать устройство'
            }
            disabled={isButtonDisabled || !isFormEdit}
            isButton
          />
        </FormContainer>
      )}
    </CustomContainer>
  );
}

export default DeviceTransfer;
