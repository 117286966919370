import { INIT_FILES, FILTER_FILES } from '../type';
import axiosInstance from '../../config/axios.config';
import { shouldFilter } from '../../utils/shouldFilter';
import { addError } from './error.action';
import { downloadFile } from '../../utils/downloadFile';

export const initFiles = (options) => ({
  type: INIT_FILES,
  payload: options,
});

export const filterFiles = (filter) => ({
  type: FILTER_FILES,
  payload: filter,
});

export const initFilesFromServer = (filterCriteria) => async (dispatch) => {
  try {
    const { data } = await axiosInstance('/api/files');
    const filterFlag = shouldFilter(filterCriteria);
    if (data) {
      dispatch(initFiles(data));
    }
    if (data && filterFlag) {
      dispatch(filterFiles(filterCriteria));
    }
  } catch (error) {
    console.log(error);
  }
};

// добавление нового file
export const newFileFromServer = (payload, name) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/api/files', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response.status === 200) {
      dispatch(addError({ error: `Файл ${name} успешно добавлен!` }));
      dispatch(initFilesFromServer());
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

export const removeFile = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.delete(`/api/files/${payload}`);
    if (response.status === 200) {
      dispatch(initFilesFromServer());
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

export const downloadFileFromServer = (payload) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(
      `${process.env.REACT_APP_UPLOADS}?fileId=${encodeURIComponent(payload?.fileId)}&fileName=${encodeURIComponent(
        payload?.fileName
      )}`,
      { responseType: 'blob' }
    );
    if (data) {
      const filename = payload?.fileName;
      downloadFile(data, filename);
    }
  } catch (error) {
    console.log(error);
  }
};
