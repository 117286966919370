// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-input {
  border: none;
  width: var(--sadr-font-size-420);
  width: 100%;
  height: var(--sadr-font-size-60);
  background-color: var(--sadr-background);
  border-color: var(--sadr-border-disabled);
  border-radius: var(--sadr-font-size-12)  !important;
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-20);
  color: #424242;
  outline: none;
  margin: 0 0 var(--sadr-font-size-20) 0;
  padding: var(--sadr-font-size-10) var(--sadr-font-size-10) var(--sadr-font-size-10) var(--sadr-font-size-20);
}


.phone-input:hover {
  border:  var(--sadr-font-size-1)  solid var(--sadr-success);
}

.phone-input::placeholder {
  color: var(--sadr-label-color);
  opacity: 1;
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 500;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-20);
  text-align: left;
}

.phone-input-label {
  all: unset;
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-20);
  color: var(--sadr-text-color);
  margin-bottom:  var(--sadr-font-size-5) ;
  margin-left: var(--sadr-font-size-6);
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/styles/PhoneInput.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gCAAgC;EAChC,WAAW;EACX,gCAAgC;EAChC,wCAAwC;EACxC,yCAAyC;EACzC,mDAAmD;EACnD,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,cAAc;EACd,aAAa;EACb,sCAAsC;EACtC,4GAA4G;AAC9G;;;AAGA;EACE,2DAA2D;AAC7D;;AAEA;EACE,8BAA8B;EAC9B,UAAU;EACV,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,6BAA6B;EAC7B,wCAAwC;EACxC,oCAAoC;EACpC,gBAAgB;AAClB","sourcesContent":[".phone-input {\n  border: none;\n  width: var(--sadr-font-size-420);\n  width: 100%;\n  height: var(--sadr-font-size-60);\n  background-color: var(--sadr-background);\n  border-color: var(--sadr-border-disabled);\n  border-radius: var(--sadr-font-size-12)  !important;\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 500;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-20);\n  color: #424242;\n  outline: none;\n  margin: 0 0 var(--sadr-font-size-20) 0;\n  padding: var(--sadr-font-size-10) var(--sadr-font-size-10) var(--sadr-font-size-10) var(--sadr-font-size-20);\n}\n\n\n.phone-input:hover {\n  border:  var(--sadr-font-size-1)  solid var(--sadr-success);\n}\n\n.phone-input::placeholder {\n  color: var(--sadr-label-color);\n  opacity: 1;\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 500;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-20);\n  text-align: left;\n}\n\n.phone-input-label {\n  all: unset;\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-20);\n  color: var(--sadr-text-color);\n  margin-bottom:  var(--sadr-font-size-5) ;\n  margin-left: var(--sadr-font-size-6);\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
