/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import CustomSwitch from '../CustomElements/CustomSwitch';
import { useSelector } from 'react-redux';
import axiosInstance from '../../config/axios.config';
import { useDispatch } from 'react-redux';
import { feature } from '../../redux/actions/user.action';
import '../../styles/Auth.css';
import { initOptionsMrmData } from '../../redux/actions/options.action';

function Auth() {
  const dispatch = useDispatch();
  const rolesOptions = useSelector((state) => state?.options?.roles);

  const { mrmIds } = useSelector((state) => state?.options);

  const [selectedAndroidId, setSelectedAndroidId] = useState(null);
  const [selectedRole, setSelectedRole] = useState(1);
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [file, setFile] = useState();
  const [email, setEmail] = useState();

  //состояния для свитчеров
  const [restrictDisabled, setRestrictDisabled] = useState(false);
  const [transferDisabled, setTransferDisabled] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isWorkWithoutKuobz, setIsWorkWithoutKuobz] = useState(false);
  const [isOrdinateInputEnabled, setIsOrdinateInputEnabled] = useState(false);

  useEffect(() => {
    dispatch(initOptionsMrmData())
  }, []);

  function handleSelectChange(e) {
    setSelectedAndroidId(e.target.value);
  }

  function handleSelectChangeRole(e) {
    setSelectedRole(e.target.value);
  }

  function handleChangeEmail(e) {
    setEmail(e.target.value);
  }

  async function handleCoordsHardcode() {
    if (!selectedAndroidId) {
      alert('Выберите устройство');
      return;
    }

    try {
      const payload = {
        androidId: selectedAndroidId,
        lat: lat,
        lon: lon
      };
      const { data } = await axiosInstance.post(`/api/administration/hardcode`, payload);
      if (data) {
        alert('Хардкод коондинат выполнен');
      }
    } catch (error) {
      console.log('Error occured diring coords hardcode', error);
    }
  }

  async function handleUpdate(whatToUpdate) {
    if (['devices', 'brigades', 'rails'].includes(whatToUpdate)) {
      try {
        const { data } = await axiosInstance.post(`/api/administration/update/${whatToUpdate}`);
        if (data) {
          alert(`Данные обновлены ${whatToUpdate}`);
        }
      } catch (error) {
        console.log('Error occured diring handleUpdate', error);
      }
    } else {
      console.log('Invalid param:', whatToUpdate)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Получение файла из input
    if (file) {
      setFile(file); // Обновление состояния
    }
  };

  const handleUpdateDirectory = async (action) => {
    if (!file) {
      alert('Пожалуйста, выберите файл перед отправкой.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    formData.append('action', action);
    try {
      const { data } = await axiosInstance.post(`/api/excel`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (data) {
        alert('файл успешно отправлен');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlerSubmit = (event) => {
    if (!selectedAndroidId) {
      alert('Выберите устройство');
      return;
    }
    event.preventDefault();
    dispatch(feature(selectedAndroidId));
  };

  const handleAuthorizeUipps = async () => {
    try {
      const { data } = await axiosInstance.post(`/api/devices/uipps/authorize`);
      alert(`УИППСы успешно авторизованы (${data?.amount} шт.)`);
    } catch (error) { }
  };

  const addNewUser = async () => {
    if (!email || !selectedRole) {
      alert('Пожалуйста, заполните данные перед отправкой.');
      return;
    }
    try {
      const payload = { email: email, roleId: selectedRole };
      const { data } = await axiosInstance.post(`/api/users`, payload);
      if (data) {
        setEmail('');
        alert(`Пользователь создан, пароль отправлен на адрес ${email}.`);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  // отслеживаем изменение свитчеров
  const fetchOrSendSwitchState = async (key = null, value = null) => {
    try {
      const endpoint = '/api/administration/buttons';
      if (key && value !== null) {
        await axiosInstance.post(endpoint, { [key]: value });
      } else {
        const { data } = await axiosInstance.post(endpoint);
        if (data) {
          setRestrictDisabled(data?.restrictDisabled);
          setTransferDisabled(data?.transferDisabled);
          setTimeout(() => {
            setIsInitialized(true);
          }, 0); // Добавляем задержку для установки isInitialized
        }
      }
    } catch (error) {
      console.log(`Error ${key ? `updating ${key} state` : 'fetching data'}:`, error);
    }
  };

  useEffect(() => {
    fetchOrSendSwitchState();
  }, []);

  useEffect(() => {
    if (isInitialized) {
      // Проверяем, было ли уже выполнено первоначальное получение данных
      fetchOrSendSwitchState('restrictDisabled', restrictDisabled);
    }
  }, [restrictDisabled]);

  useEffect(() => {
    if (isInitialized) {
      // Проверяем, было ли уже выполнено первоначальное получение данных
      fetchOrSendSwitchState('transferDisabled', transferDisabled);
    }
  }, [transferDisabled]);

  // получаем разрешена ли работа без куобз или меняем
  const sendStandaloneRequest = async (androidId, isStandalone = null, canEnterOrd = null) => {
    const payload = { androidId };
    if (isStandalone !== null) {
      payload.isStandalone = isStandalone;
    }
    if (canEnterOrd !== null) {
      payload.canEnterOrd = canEnterOrd;
    }

    try {
      const response = await axiosInstance.post('/api/administration/standalone', payload);
      if (response.status === 200) {
        setIsWorkWithoutKuobz(response?.data?.isStandalone);
        setIsOrdinateInputEnabled(response?.data?.canEnterOrd);
      }
    } catch (error) {
      console.error('Ошибка при отправке запроса:', error);
    }
  };

  // переключение свитчера Работа без КУОБЗ
  const handleSwitchToggle = () => {
    const newIsWorkWithoutKuobz = !isWorkWithoutKuobz;
    setIsWorkWithoutKuobz(newIsWorkWithoutKuobz);
    if (selectedAndroidId) {
      sendStandaloneRequest(selectedAndroidId, newIsWorkWithoutKuobz);
    }
  };

  // переключение свитчера Ввод ординаты
  const handleSwitchOrdToggle = () => {
    const newIsOrdinateInputEnabled = !isOrdinateInputEnabled;
    setIsOrdinateInputEnabled(newIsOrdinateInputEnabled);
    if (selectedAndroidId) {
      sendStandaloneRequest(selectedAndroidId, null, newIsOrdinateInputEnabled);
    }
  };

  // при выборе усройства проверяем разрешена ли работа без куобз
  useEffect(() => {
    if (selectedAndroidId) {
      sendStandaloneRequest(selectedAndroidId);
    }
  }, [selectedAndroidId]);

  return (
    <>
      <div className="p-3" style={{ textAlign: 'start', width: '40%' }}>
        <div className="p-2" style={{ width: '50vw' }}>
          <div className="iur-control-container">
            <div>
              <label style={{ padding: '0 0 5px' }}>Устройство</label>
              <select className="form-select iur-select" onChange={handleSelectChange}>
                <option disabled selected value="">
                  Выберите ИУР
                </option>
                {mrmIds?.map((option, index) => (
                  <option key={index} value={option.androidId}>
                    {option.value} ({option.androidId})
                  </option>
                ))}
              </select>
            </div>
            <CustomSwitch
              label="Работа без КУОБЗ"
              isActive={!isWorkWithoutKuobz}
              onToggle={() => handleSwitchToggle()}
              activeText="нет"
              inactiveText="да"
              disabled={!selectedAndroidId}
            />
            <CustomSwitch
              label="Ввод ординаты"
              isActive={!isOrdinateInputEnabled}
              onToggle={() => handleSwitchOrdToggle()}
              activeText="нет"
              inactiveText="да"
              disabled={!selectedAndroidId}
            />
          </div>

          <button className="btn btn-primary m-1" onClick={(event) => handlerSubmit(event)}>
            Запросить логи
          </button>
          <h3 className="pt-2">Хардкод координат для тестов</h3>
          <div>
            <label htmlFor="lat" style={{ padding: 0 }}>
              lat:
            </label>
            <input
              type="text"
              id="lat"
              name="lat"
              placeholder="latitude"
              onChange={(event) => setLat(event.target.value)}
            />
            <label htmlFor="lon" style={{ padding: 0 }}>
              lon:
            </label>
            <input
              className="mb-2"
              type="text"
              id="lon"
              name="lon"
              placeholder="longitude"
              onChange={(event) => setLon(event.target.value)}
            />
          </div>

          <button className="btn btn-primary m-1" onClick={handleCoordsHardcode}>
            Захардкодить
          </button>
        </div>
        <hr />
        <div className="p-2" style={{ width: '100vw' }}>
          <h3 className="p-2">Обновление данных из БД</h3>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'var(--sadr-font-size-20)' }}>
            <button className="btn btn-primary m-1" onClick={() => handleUpdate('devices')}>
              Обновить устройства
            </button>
            <button className="btn btn-primary m-1" onClick={() => handleUpdate('brigades')}>
              Обновить бригады
            </button>
            <button className="btn btn-primary m-1" onClick={() => handleUpdate('rails')}>
              Обновить РЦ
            </button>
            <button className="btn btn-primary m-1" onClick={handleAuthorizeUipps}>
              Авторизовать УИППС
            </button>
          </div>
        </div>
        <hr />
        <div class="p-2" style={{ width: '100vw' }}>
          <h3 className="">Добавить нового пользователя</h3>
          <div className="mt-2 w-10 col-md-3">
            <label style={{ padding: 0 }}>Адресс электронной почты</label>
            <input
              className="form-control mb-3"
              type="email"
              id="email"
              name="email"
              placeholder="Введите почту"
              onChange={handleChangeEmail}
              value={email}
            />
          </div>
          <div className="w-100">
            <label style={{ padding: 0 }}>Роль</label>
            <select className="form-select w-25 mb-4" onChange={handleSelectChangeRole}>
              <option disabled selected value={selectedRole}>
                Выберите роль
              </option>
              {rolesOptions?.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <button className="btn btn-primary col-md-2" onClick={() => addNewUser()}>
            Добавить
          </button>
        </div>
        <hr />
      </div>
      <div className="p-3" style={{ textAlign: 'start', width: '40%' }}>
        <div className="p-2" style={{ width: '100vw' }}>
          <h3 className="">Обновление справочников</h3>
          <div className="mt-3 w-10 col-md-3">
            <input
              className="form-control"
              type="file"
              id="file"
              name="file"
              placeholder="Выберите файл справочника"
              onChange={handleFileChange}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 'var(--sadr-font-size-20)' }}>
            <button className="btn btn-primary m-1 col-md-1" onClick={() => handleUpdateDirectory('update')}>
              Обновить
            </button>
            <button className="btn btn-primary m-1 col-md-1" onClick={() => handleUpdateDirectory('create')}>
              Добавить
            </button>
          </div>
        </div>
        <div className="p-2">
          <h3 className="">Деактивация кнопок</h3>
          <CustomSwitch
            label="Ограждение"
            isActive={!restrictDisabled}
            onToggle={() => setRestrictDisabled(!restrictDisabled)}
            activeText="выкл"
            inactiveText="вкл"
          />
          <CustomSwitch
            label="Перенос"
            isActive={!transferDisabled}
            onToggle={() => setTransferDisabled(!transferDisabled)}
            activeText="выкл"
            inactiveText="вкл"
          />
        </div>
      </div>
    </>
  );
}

export default Auth;
