// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contract-reports-modal-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.contract-reports-modal-success > *:first-child {
  margin-top: auto;
  margin-bottom: auto;
}

.contract-reports-modal-success > button {
  margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/ContractReport.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".contract-reports-modal-success {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  padding: 20px;\n  box-sizing: border-box;\n  position: relative;\n}\n\n.contract-reports-modal-success > *:first-child {\n  margin-top: auto;\n  margin-bottom: auto;\n}\n\n.contract-reports-modal-success > button {\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
