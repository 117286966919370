// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.documentation {
  display: flex; 
  background-color: var(--sadr-background);
  width: 100%;
}

.documentation_content{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  margin-right: var(--sadr-font-size-20);
  margin-left: var(--sadr-font-size-12) ;
  margin-bottom: var(--sadr-font-size-20);
}

`, "",{"version":3,"sources":["webpack://./src/styles/Documentation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wCAAwC;EACxC,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;EACX,sCAAsC;EACtC,sCAAsC;EACtC,uCAAuC;AACzC","sourcesContent":[".documentation {\n  display: flex; \n  background-color: var(--sadr-background);\n  width: 100%;\n}\n\n.documentation_content{\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  width: 100%;\n  margin-right: var(--sadr-font-size-20);\n  margin-left: var(--sadr-font-size-12) ;\n  margin-bottom: var(--sadr-font-size-20);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
