import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/BrigadeSignals.css';
import Table from '../Table/Table';
import Selector from '../CustomElements/Selector';
import moment from 'moment';
import 'moment-timezone';
import useTableColumnSelect from '../../customHook/useTableColumnSelect';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { emitBrigadeAllSignals } from '../../redux/actions/brigades.action';
import { listenBrigadeAllSignalsInfo } from '../../redux/actions/brigades.action';
import { emitBrigadeAllSignalsListenStop } from '../../redux/actions/brigades.action';
import { useRole } from '../../customHook/useRole';

function BrigadeSignals({ currentBrigade, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Получение данных о бригадах из Redux store.
  const { brigadeAllSignals } = useSelector((state) => state.brigadesState);

  const isAdmin = useRole('admin');
  const isEngineerInfosignal = useRole('engineerInfosignal');
  const isCustomer = useRole('customer');

  const columns = useMemo(
    () =>
      isAdmin || isCustomer || isEngineerInfosignal
        ? [
            {
              Header: '№',
              Cell: ({ row }) => <span>{row.index + 1}</span>,
            },
            {
              Header: t?.signalTime || 'Время',
              accessor: 'time',
              Cell: ({ value }) => {
                const date = value !== undefined ? moment(value).tz('Europe/Moscow') : null;
                return date && date.isValid() ? date.format('DD.MM.YYYY HH:mm:ss') : '-';
              },
            },
            {
              Header: t?.signalIndex || 'Номер сигнала',
              accessor: 'index',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.signalType || 'Тип сигнала',
              accessor: 'alertType',
              Cell: ({ value }) => {
                switch (value) {
                  case 0:
                    return 'Проследование РЦ';
                  case 1:
                    return 'ОК';
                  case 2:
                    return t?.notification1 || 'Оповещение 1';
                  case 3:
                    return t?.notification2 || 'Оповещение 2';
                  case 4:
                    return t?.noConnection || 'Потеря связи';
                  case 5:
                    return t?.alert || 'Тревога';
                  case 6:
                    return t?.waiting || 'Ожидание';
                  case 'start':
                    return t?.startWork || 'Начало работы';
                  case 'exitRails':
                    return t?.exitRails || 'Выход на путь';
                  case 'finish':
                    return t?.finishWork || 'Завершение работы';
                  default:
                    return '-';
                }
              },
            },
            {
              Header: t?.direction || 'Направление',
              accessor: 'dir',
              Cell: ({ value }) =>
                value === 1 ? t?.oddDirection || 'Нечетное' : value === 0 ? t?.evenDirection || 'Четное' : '-',
            },
            {
              Header: t?.apkDistance || 'Расстояние по АПК-ДК',
              accessor: 'distance',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.route || 'Контролируемая РЦ',
              accessor: 'routeRailName',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.occupiedRails || 'Занятые РЦ',
              accessor: 'currentRailName',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.warningRails || 'РЦ оповещения',
              accessor: 'warningRails',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.alertRails || 'РЦ тревоги',
              accessor: 'alertRails',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.alertButtonPress || 'Нажатие «Тревога»',
              accessor: 'btn_alert',
              Cell: ({ value }) => (value === 1 ? t?.yes || 'Да' : value === 0 ? t?.no || 'Нет' : '-'),
            },
            {
              Header: t?.clearButtonPress || 'Нажатие «Путь свободен»',
              accessor: 'btn_clear',
              Cell: ({ value }) => (value === 1 ? t?.yes || 'Да' : value === 0 ? t?.no || 'Нет' : '-'),
            },
            {
              Header: t?.uippsID || 'ID УИППС',
              accessor: 'uippsId',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.uippsSignalType || 'Сигнал на УИППС',
              accessor: 'uippsAlertType',
              Cell: ({ value }) => {
                switch (Number(value)) {
                  case 0:
                    return 'ОК';
                  case 1:
                    return t?.alert || 'Тревога';
                  default:
                    return '-';
                }
              },
            },
            {
              Header: t?.sadrDistance || 'Расстояние по координатам',
              accessor: 'preciseDistance',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.uippsGPS || 'GPS УИППС',
              accessor: 'uippsGPS',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.uippsGSM || 'GSM УИППС',
              accessor: 'uippsGSM',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.isRestrictOn || 'Ограждение',
              accessor: 'isRestrictOn',
              Cell: ({ value }) => (value === true ? t?.yes || 'Да' : value === false ? t?.no || 'Нет' : '-'),
            },
            {
              Header: t?.transferRails || 'Перенос (РЦ для переноса)',
              accessor: 'transferRails',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.iurAlertType || 'Сигнал на ИУР',
              accessor: 'iurAlertType',
              Cell: ({ value }) => {
                switch (Number(value)) {
                  case 1:
                    return 'ОК';
                  case 2:
                    return t?.notification1 || 'Оповещение 1';
                  case 3:
                    return t?.notification2 || 'Оповещение 2';
                  case 4:
                    return t?.noConnection || 'Потеря связи';
                  case 5:
                    return t?.alert || 'Тревога';
                  case 6:
                    return t?.waiting || 'Ожидание';
                  default:
                    return '-';
                }
              },
            },
            {
              Header: t?.iurConn || 'Связь с сервером (ИУР)',
              accessor: 'iurConn',
              Cell: ({ value }) => (value === true ? t?.yes || 'Да' : value === false ? t?.no || 'Нет' : '-'),
            },
            {
              Header: t?.iurGPS || 'GPS (ИУР)',
              accessor: 'iurGPS',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.iurGSM || 'GSM (ИУР)',
              accessor: 'iurGSM',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.inSafeZone || 'ИУ в БЗ',
              accessor: 'inSafeZone',
              Cell: ({ value }) => (Number(value) === 1 ? t?.yes || 'Да' : Number(value) === 0 ? t?.no || 'Нет' : '-'),
            },
            {
              Header: t?.safeZoneType || 'Форма БЗ',
              accessor: 'safeZoneType',
              Cell: ({ value }) =>
                Number(value) === 1 ? t?.polygon || 'Полигон' : Number(value) === 0 ? t?.circle || 'Круг' : '-',
            },
            {
              Header: t?.safeZoneWidth || 'Ширина БЗ',
              accessor: 'safeZoneWidth',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.mainKuobzId || 'ID ведущего КУОБЗ',
              accessor: 'mainKuobzId',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.kuobzSignalType || 'Сигнал на КУОБЗ',
              accessor: 'kuobzAlertType',
              Cell: ({ value }) => {
                switch (Number(value)) {
                  case 1:
                    return 'ОК';
                  case 2:
                    return t?.notification1 || 'Оповещение 1';
                  case 3:
                    return t?.notification2 || 'Оповещение 2';
                  case 4:
                    return t?.noConnection || 'Потеря связи';
                  case 5:
                    return t?.alert || 'Тревога';
                  case 6:
                    return t?.waiting || 'Ожидание';
                  default:
                    return '-';
                }
              },
            },
            {
              Header: t?.kuobzConn || 'Связь с сервером (КУОБЗ)',
              accessor: 'kuobzConn',
              Cell: ({ value }) => (value === true ? t?.yes || 'Да' : value === false ? t?.no || 'Нет' : '-'),
            },
            {
              Header: t?.kupbzGPS || 'GPS (КУОБЗ)',
              accessor: 'kupbzGPS',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.kuobzGSM || 'GSM (КУОБЗ)',
              accessor: 'kuobzGSM',
              Cell: ({ value }) => defaultCellRender(value),
            },
          ]
        : [
            {
              Header: '№',
              Cell: ({ row }) => <span>{row.index + 1}</span>,
            },
            {
              Header: t?.signalTime,
              accessor: 'time',
              Cell: ({ value }) => {
                // Проверяем, является ли значение валидной датой и преобразуем её в Московское время
                const date = value !== undefined ? moment(value).tz('Europe/Moscow') : null;
                return date && date.isValid() ? date.format('DD.MM.YYYY HH:mm:ss') : '-';
              },
            },
            {
              Header: t?.signalIndex,
              accessor: 'index',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.signalType,
              accessor: 'alertType',
              Cell: ({ value }) => {
                switch (value) {
                  case 1:
                    return 'OK';
                  case 2:
                    return t?.notification1;
                  case 3:
                    return t?.notification2;
                  case 4:
                    return t?.noConnection;
                  case 5:
                    return t?.alert;
                  case 6:
                    return t?.waiting;
                  default:
                    return '-';
                }
              },
            },
            {
              Header: t.direction,
              accessor: 'dir',
              Cell: ({ value }) => (value === 1 ? t?.oddDirection : value === 0 ? t?.evenDirection : '-'),
            },
            {
              Header: t?.uippsID,
              accessor: 'uippsId',
              Cell: ({ value }) => defaultCellRender(value),
            },
            {
              Header: t?.uippsSignalType,
              accessor: 'uippsAlertType',
              Cell: ({ value }) => {
                switch (Number(value)) {
                  case 0:
                    return 'ОК';
                  case 1:
                    return t?.alert;
                  case 6:
                    return t?.waiting;
                  default:
                    return '-';
                }
              },
            },
            {
              Header: t?.alertButtonPress,
              accessor: 'btn_alert',
              Cell: ({ value }) => (value === 1 ? t?.yes : value === 0 ? t?.no : '-'),
            },
            {
              Header: t?.clearButtonPress,
              accessor: 'btn_clear',
              Cell: ({ value }) => (value === 1 ? t?.yes : value === 0 ? t?.no : '-'),
            },
          ],
    [t, isAdmin, isCustomer, isEngineerInfosignal]
  );

  // eslint-disable-next-line no-unused-vars
  const [visibleColumns, hiddenColumns, columnOptions, selectedColumns, handleColumnSelect] =
    useTableColumnSelect(columns);

  useEffect(() => {
    //отправляем событие чтобы получить сигналы
    dispatch(emitBrigadeAllSignals({ brigadeId: currentBrigade.id }));
    //слушаем событие 'get_all_signals_reply'
    dispatch(listenBrigadeAllSignalsInfo());
    // Функция очистки
    return () => {
      // отправляем событие чтобы сервер закончил слать инфо
      dispatch(emitBrigadeAllSignalsListenStop({ brigadeId: currentBrigade?.id }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {brigadeAllSignals && (
        <>
          <div className="brigade-signals_select-container">
            <Selector
              column="left"
              options={columnOptions}
              onChange={(selectedOption) => handleColumnSelect(selectedOption)}
              placeholder={t?.tableFieldsPlaceholderSelect || 'Показать/скрыть поля'}
              filtersBlock={true}
              width="var(--sadr-font-size-350) "
              height="var(--sadr-font-size-60)"
              value={selectedColumns}
              isMulti={true}
              name="typeStatus"
              isView
              optionSquare
              portalHeightRem={31.25}
            />
          </div>

          <Table data={brigadeAllSignals} columns={visibleColumns} />
        </>
      )}
    </>
  );
}

export default BrigadeSignals;

// Колонки таблицы
// const columns = useMemo(
//   () => [
//     {
//       Header: '№',
//       Cell: ({ row }) => <span>{row.index + 1}</span>,
//     },
//     {
//       Header: t?.signalTime,
//       accessor: 'time',
//       Cell: ({ value }) => {
//         // Проверяем, является ли значение валидной датой и преобразуем её в Московское время
//         const date = value !== undefined ? moment(value).tz('Europe/Moscow') : null;
//         return date && date.isValid() ? date.format('DD.MM.YYYY HH:mm:ss') : '-';
//       },
//     },
//     {
//       Header: t?.signalIndex,
//       accessor: 'index',
//       Cell: ({ value }) => defaultCellRender(value),
//     },
//     {
//       Header: t?.signalType,
//       accessor: 'alertType',
//       Cell: ({ value }) => {
//         switch (value) {
//           case 1:
//             return 'OK';
//           case 2:
//             return t?.notification1;
//           case 3:
//             return t?.notification2;
//           case 4:
//             return t?.noConnection;
//           case 5:
//             return t?.alert;
//           case 6:
//             return t?.waiting;
//           default:
//             return '-';
//         }
//       },
//     },
//     {
//       Header: t.direction,
//       accessor: 'dir',
//       Cell: ({ value }) => (value === 1 ? t?.oddDirection : value === 0 ? t?.evenDirection : '-'),
//     },
//     {
//       Header: t?.uippsID,
//       accessor: 'uippsId',
//       Cell: ({ value }) => defaultCellRender(value),
//     },
//     {
//       Header: t?.uippsSignalType,
//       accessor: 'uippsAlertType',
//       Cell: ({ value }) => {
//         switch (Number(value)) {
//           case 0:
//             return 'ОК';
//           case 1:
//             return t?.alert;
//           case 6:
//             return t?.waiting;
//           default:
//             return '-';
//         }
//       },
//     },
//     {
//       Header: t?.alertButtonPress,
//       accessor: 'btn_alert',
//       Cell: ({ value }) => (value === 1 ? t?.yes : value === 0 ? t?.no : '-'),
//     },
//     {
//       Header: t?.clearButtonPress,
//       accessor: 'btn_clear',
//       Cell: ({ value }) => (value === 1 ? t?.yes : value === 0 ? t?.no : '-'),
//     },
//     ...(isAdmin || isCustomer || isEngineerInfosignal
//       ? [
//           {
//             Header: t?.route,
//             accessor: 'routeRailName',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t?.occupiedRails,
//             accessor: 'currentRailName',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t?.apkDistance,
//             accessor: 'distance',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t?.sadrDistance,
//             accessor: 'preciseDistance',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t.warningRails,
//             accessor: 'warningRails',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t?.alertRails,
//             accessor: 'alertRails',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t?.uippsSignalTime,
//             accessor: 'uippsTime',
//             Cell: ({ value }) => {
//               // Проверяем, является ли значение валидной датой и преобразуем её в Московское время
//               const date = value !== undefined ? moment(value).tz('Europe/Moscow') : null;
//               return date && date.isValid() ? date.format('DD.MM.YYYY HH:mm:ss') : '-';
//             },
//           },
//           {
//             Header: t?.kuobzSignalTime,
//             accessor: 'kuobzTime',
//             Cell: ({ value }) => {
//               // Проверяем, является ли значение валидной датой и преобразуем её в Московское время
//               const date = value !== undefined ? moment(value).tz('Europe/Moscow') : null;
//               return date && date.isValid() ? date.format('DD.MM.YYYY HH:mm:ss') : '-';
//             },
//           },
//           {
//             Header: t?.kuobzSignalType,
//             accessor: 'kuobzAlertType',
//             Cell: ({ value }) => {
//               switch (value) {
//                 case 1:
//                   return 'ОК';
//                 case 2:
//                   return t?.notification1;
//                 case 3:
//                   return t?.notification2;
//                 case 4:
//                   return t?.noConnection;
//                 case 5:
//                   return t?.alert;
//                 case 6:
//                   return t?.waiting;
//                 default:
//                   return '-';
//               }
//             },
//           },
//           {
//             Header: t?.kuobzCoordinates,
//             accessor: 'kuobzCoords',
//             Cell: ({ value }) => (value ? `${value.lat}, ${value.lon}` : '-'),
//           },
//           {
//             Header: t?.iurCoordinates,
//             accessor: 'iurCoords',
//             Cell: ({ value }) => (value ? `${value.lat}, ${value.lon}` : '-'),
//           },
//           {
//             Header: t?.uippsCoordinates,
//             accessor: 'uippsCoords',
//             Cell: ({ value }) => (value ? `${value.lat}, ${value.lon}` : '-'),
//           },
//           {
//             Header: t?.kuobzGSM,
//             accessor: 'kuobzGSM',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t?.kuobzGPS,
//             accessor: 'kupbzGPS',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t?.iurGSM,
//             accessor: 'iurGSM',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t?.iurGPS,
//             accessor: 'iurGPS',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t?.uippsGSM,
//             accessor: 'uippsGSM',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//           {
//             Header: t.uippsGPS,
//             accessor: 'uippsGPS',
//             Cell: ({ value }) => defaultCellRender(value),
//           },
//         ]
//       : []),
//   ],
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   [brigadeAllSignals]
// );
