import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/DeviceStorageStatus.css';
import Modal from '../Modal/Modal';
import EditOperationStatus from './EditStorageStatus';
import CustomButton from '../CustomElements/CustomButton';
import DeviceRepair from '../Devices/DeviceRepair';
import DeviceDeployment from '../Devices/DeviceDeployment';
import DeviceTransfer from '../Devices/DeviceTransfer';
import DeviceWriteOff from '../Devices/DeviceWriteOff';
import UippsDeployment from '../UIPPS/UippsDeployment';
import TableMap from '../Table/TableMap';

import useModalWithStatus from '../../customHook/useModalWithStatus';
import { initStatusesFromServer } from '../../redux/actions/statuses.action';
import useDeviceStatus from '../../customHook/useDeviceStatus';
import { useRole } from '../../customHook/useRole';

//компонент для отображения вкладки Хранение на станице устройства

function DeviceStorageStatus({ currentDevice, uippsType, setActiveButton, activeButton, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  //проверяем роли пользователя
  const isMaster = useRole('master');

  //получакм массив статаусов Хранение из стора
  const { storageStatuses } = useSelector((state) => state?.statuses);
  const [statusId, setStatusId] = useState('');

  //получаем статус текущего устройства
  const status = currentDevice?.status;

  // состояние для активации кнопки
  const [disabledBtn, setDisabledBtn] = useState(!['Хранение', t?.statusStorage || ''].includes(status));

  // хук для получения текущего статуса по id
  const currentStatus = useDeviceStatus(storageStatuses, statusId);

  // Обновляем состояние disabledBtn при изменении статуса
  useEffect(() => {
    setDisabledBtn(!['Хранение', t?.statusStorage || ''].includes(status));
  }, [status, t?.statusStorage]);

  // Хук для управления модальным окном статусов
  const { isOpen, activeModal, closeModal, handleButtonClick } = useModalWithStatus(
    storageStatuses,
    currentDevice,
    setStatusId
  );

  // компоненты для отображения
  const contentData = {
    [t?.statusTransferToAnotherStorage || 'Переместить на другой склад']: (
      <DeviceTransfer currentDevice={currentDevice} closeModal={closeModal} isEdit={false} t={t} />
    ),
    [t?.statusIssueOperation || 'Выдать в эксплуатацию']: uippsType ? (
      <UippsDeployment currentDevice={currentDevice} closeModal={closeModal} t={t} />
    ) : (
      <DeviceDeployment currentDevice={currentDevice} closeModal={closeModal} t={t} />
    ),
    [t?.statusSendForRepair || 'Направить в ремонт']: (
      <DeviceRepair currentDevice={currentDevice} closeModal={closeModal} t={t} />
    ),
    [t?.statusWriteOff || 'Списать']: (
      <DeviceWriteOff currentDevice={currentDevice} closeModal={closeModal} isOpen={isOpen} t={t} />
    ),
    edit:
      currentStatus?.from_status_id === 9 ? (
        <DeviceTransfer
          currentDevice={currentDevice}
          currentStatus={currentStatus}
          closeModal={closeModal}
          isEdit={true}
          t={t}
        />
      ) : (
        <EditOperationStatus
          currentStatus={currentStatus}
          currentDevice={currentDevice}
          closeModal={closeModal}
          t={t}
        />
      ),
  };
  // Инициализации статусов с сервера. принимает id устройства и тип статусов
  useEffect(() => {
    dispatch(initStatusesFromServer(currentDevice.id, 'storage'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Таблица со статусами */}
      {storageStatuses && (
        <TableMap
          data={storageStatuses}
          handleButtonClick={handleButtonClick}
          currentDevice={currentDevice}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          t={t}
        />
      )}

      {/* Блок с кнопками */}
      {!isMaster && (
        <div className="device-storage-status_button-action_container flex-wrap">
          {Object.keys(contentData)
            .slice(0, -1)
            .map((buttonName) => (
              <CustomButton
                className="device-storage-status_button-action"
                key={buttonName}
                text={buttonName}
                colorText="#FFFFF"
                onClick={() => handleButtonClick(buttonName)}
                disabled={
                  disabledBtn ||
                  ((buttonName === 'Выдать в эксплуатацию' || buttonName === t?.statusIssueOperation) &&
                    currentDevice?.broken)
                }
              />
            ))}
        </div>
      )}

      {/* Модальное окно */}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
          {contentData[activeModal]}
        </Modal>
      )}
    </>
  );
}

export default DeviceStorageStatus;
