import { useEffect, useState } from 'react';
import '../../styles/ServiceDevice.css';
import { useDispatch } from 'react-redux';
import CustomButton from '../CustomElements/CustomButton';
import { deviceServiceFromServer } from '../../redux/actions/devices.action';
import DateSelector from '../CustomElements/DateSelector';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import FormContainer from '../CustomElements/FormContainer';
import CustomTextarea from '../CustomElements/CustomTextarea';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import { convertToDate } from '../../utils/convertToDate';

function ServiceDevice({ closeModalDevice, currentDevice, typeUipps, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Состояния для уведомления об успешном добавлении
  const [serviceSuccess, setServiceSuccess] = useState(false);

  const [isFirstDatePickerBorderRed, setIsFirstDatePickerBorderRed] = useState(false);

  // Состояние для формы
  const [formData, setFormData] = useState({
    deviceId: currentDevice.id,
    date: '',
    nextDate: '',
    worksDone: '',
    note: '',
  });

  /** Функция для проверки валидности формы */
  const isButtonDisabled = useButtonValidation(formData, ['note']);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(deviceServiceFromServer(payload, formData, typeUipps));
    //Меняем флаг для отображения сообщения что устройство добавлено
    handlerCloseModal();
  };

  // Закрывает модальное окно после успешного добавления нового устройства
  const handlerCloseModal = () => {
    closeModalDevice();
    setServiceSuccess(false);
  };

  // прис бросе даты ТО сбрасываем дату следующего ТО
  useEffect(() => {
    setFormData((prev) => ({ ...prev, nextDate: '' }));
  }, [formData.date]);

  // Функция для обработки изменения в первом дата пикере
  const handleFirstDatePickerChange = (date) => {
    setFormData({ ...formData, date: date });
    setIsFirstDatePickerBorderRed(false); // Убираем красную рамку
  };

  // Функция для обработки клика по контейнеру второго дата пикера
  const handleSecondDatePickerFocus = () => {
    if (!formData.date) {
      setIsFirstDatePickerBorderRed(true); // Делаем рамку первого дата пикера красной
    }
  };

  return (
    <CustomContainer height="100%">
      <CustomTitle
        titleText={t?.serviceDeviceTitle || 'Выполнение ТО'}
        deviceType={currentDevice.type}
        deviceId={currentDevice.id}
      />
      {serviceSuccess && (
        <ConfirmationPage
          confirmText={t?.confirmServiceDeviceText || 'Вы уверены, что хотите сохранить изменения?'}
          confirmButtonText={t?.confirmButton || 'Изменить'}
          cancelButtonText={t?.cancelButton || 'Отменить'}
          onConfirm={handlerSubmit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={handlerCloseModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!serviceSuccess && (
        <>
          <FormContainer>
            <DateSelector
              column="left"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              label={t?.labelServiceDate || 'Дата ТО *'}
              selectedDate={formData.date}
              setSelectedDate={(date) => {
                handleUniversalChange(formData, setFormData, null, 'date', date, 'date');
                handleFirstDatePickerChange(date);
              }}
              name="date"
              minDate={convertToDate(currentDevice?.last_maintanance_date)}
              maxDate={new Date()}
              alert={isFirstDatePickerBorderRed}
            />
            <CustomTextarea
              column="left"
              type="textArea"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="worksDone"
              label={t?.labelWorksDone || 'Выполненные работы *'}
              placeholder={t?.placeholderWorksDone || 'Заполните описание выполненных работ'}
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-160)"
            />
            <DateSelector
              column="right"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
              label={t?.labelNextServiceDate || 'Дата следующего ТО *'}
              selectedDate={formData.nextDate}
              setSelectedDate={(date) => {
                handleUniversalChange(formData, setFormData, null, 'nextDate', date, 'date');
              }}
              name="nextDate"
              isDisabled={formData?.date ? false : true}
              minDate={formData.date}
              customClick={handleSecondDatePickerFocus}
            />
            <CustomTextarea
              column="right"
              type="textArea"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="note"
              label={t?.labelNotes || 'Примечания'}
              placeholder={t?.placeholderNotes || 'Оставьте примечания'}
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-160)"
            />
          </FormContainer>
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            color={isButtonDisabled ? '#ADADAD' : ''}
            text={t?.buttonSaveChanges || 'Сохранить изменения'}
            disabled={isButtonDisabled}
            onClick={() => setServiceSuccess(!serviceSuccess)}
            isButton
          />
        </>
      )}
    </CustomContainer>
  );
}

export default ServiceDevice;
