import Accordeon from '../CustomElements/Accordeon';
import { handleUniversalChange } from '../../utils/formUtils';
import Selector from '../CustomElements/Selector';
import { useFilterControl } from '../../customHook/useFilterControl';
import { useSelector } from 'react-redux';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsSectionFromServer,
} from '../../redux/actions/options.action';
import useOptionsLoader from '../../customHook/useOptionsLoader';

export default function FilterForInfocards({ formData, setFormData, initialFormData }) {
  const optionsForSelect = useSelector((s) => s.options);

  //опции для селекта версий
  const versionOptions = [
    { value: 'all', label: 'Все' },
    { value: 'diff', label: 'Различные' },
    { value: 'same', label: 'Совпадающие' }
  ];

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData);

  //хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate', 'department', 'station'],
    'road',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  //хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department'],
    'directorate',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  //хук для запроса опций для станций и контроля селекта
  const messageForSelectStation = 'Для выбора станции выберите дорогу';
  const stationControl = useOptionsLoader(
    formData,
    setFormData,
    ['station'],
    'road',
    initOptionsSectionFromServer,
    messageForSelectStation
  );

  return (
    <Accordeon
      header="ИНФОКАРТЫ"
      hideAbove={1650}
      qParamName="infocards_acc"
      formData={formData}
      resetFilters={resetFilters}
    >
      <button
        type="button"
        className={`accordeon__reset-filters ${showBtnReset && 'accordeon__reset-filters_visible'}`}
        onClick={() => {resetFilters(false)}}
      >
        <span className="accordeon__reset-filters_text">Сбросить все фильтры</span>
        <img className="accordeon__reset-filters_icon" src="/image/close.svg" alt=""></img>
      </button>

      <form className="accordeon__form" id="" method="POST" action="#" autoComplete="off">
        <div className="accordeon__select-container" id="">
          <Selector
            options={optionsForSelect.roads}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'road', selectedOption, 'select');
            }}
            placeholder="Выберите дорогу"
            isMulti={false}
            label="Дорога"
            value={formData.road ? optionsForSelect?.roads?.find((option) => option.value === formData.road) : null}
            name="road"
          />
          <Selector
            options={[]}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'region', selectedOption, 'select');
            }}
            placeholder="Выберите регион"
            label="Регион"
            value={
              formData.region
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.region)
                : null
            }
            isMulti={false}
            name="region"
          />
          <Selector
            options={optionsForSelect?.directorates}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'directorate', selectedOption, 'select');
            }}
            placeholder="Выберите дирекцию"
            label="Дирекция"
            value={
              formData.directorate
                ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate)
                : null
            }
            isMulti={false}
            name="directorate"
            isDisabled={directoratesControl?.isSelectorDisabled}
            message={directoratesControl?.message}
            customClick={directoratesControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect.departments}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'department', selectedOption, 'select');
            }}
            placeholder="Выберите № подразделения"
            isMulti={false}
            label="Подразделение"
            value={
              formData.department
                ? optionsForSelect?.departments?.find((option) => option.value === formData.department)
                : null
            }
            name="department"
            isDisabled={departmentsControl?.isSelectorDisabled}
            message={departmentsControl?.message}
            customClick={departmentsControl?.onSelectorChange}
          />
          <Selector
            options={optionsForSelect?.stations}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'station', selectedOption, 'select');
            }}
            value={
              formData.station ? optionsForSelect.stations?.find((option) => option.value === formData.station) : null
            }
            isMulti={false}
            placeholder="Выберите станцию/перегон"
            name="station"
            label="Станция/перегон"
            isDisabled={stationControl?.isSelectorDisabled}
            message={stationControl?.message}
            customClick={stationControl?.onSelectorChange}
          />

          <Selector
            options={versionOptions}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'version', selectedOption, 'select');
            }}
            value={formData.version ? versionOptions.find((option) => option.value === formData.version) : null}
            isMulti={false}
            name="version"
            label="Версия САДР/КИТ"
            // isClearable={false}
            placeholder="Выберите версию"
          />
        </div>
      </form>
    </Accordeon>
  );
}

