import { React, useState, useEffect, useMemo } from 'react';
import '../../styles/AddKuobz.css';
import CustomButton from '../CustomElements/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../CustomElements/Input';
import FormContainer from '../CustomElements/FormContainer';
import PhoneInput from '../CustomElements/PhoneInput';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { handleUniversalChange } from '../../utils/formUtils';
import { editKuobzFromServer } from '../../redux/actions/kuobz.action';
import { load, remove } from '../../redux/actions/loader.action';
import { addError } from '../../redux/actions//error.action';
import { checkValueLength } from '../../utils/checkValueLength';
import useFormDataChanged from '../../customHook/useFormDataChanged';

function EditKuobz({ closeModal, currentKuobz }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // состояние для отображения страницы подтверждения
  const [addSuccess, setAddSuccess] = useState(false);

  // состояние для необязательных полей
  const [optionalFields, setOptionalFields] = useState(['sim1', 'sim2', 'imei2', 'simNum1', 'simNum2']);

  // Состояние для формы
  const initialFormData = useMemo(
    () => ({
      id: currentKuobz?.id || '',
      sim1: currentKuobz?.sim1 || '',
      sim2: currentKuobz?.sim2 || '',
      imei1: currentKuobz?.imei1 || '',
      imei2: currentKuobz?.imei2 || '',
      simNum1: currentKuobz?.sim1num || '',
      simNum2: currentKuobz?.sim2num || '',
    }),
    [currentKuobz]
  );

  // Состояние для формы
  const [formData, setFormData] = useState(initialFormData);

  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  //проверяем были ли изменения в форме (true-да, true-нет)
  const isFormEdit = useFormDataChanged(initialFormData, formData, true);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    // Проверяем валидность imei1
    if (formData?.imei1 && !checkValueLength(formData?.imei1, 15)) {
      return dispatch(addError({ error: 'Указан невалидный imei1, значение должно содержать 15 символов' }));
    }
    // Проверяем валидность imei2
    if (formData?.imei2 && !checkValueLength(formData?.imei2, 15)) {
      return dispatch(addError({ error: 'Указан невалидный imei2, значение должно содержать 15 символов' }));
    }
    // Проверяем валидность simNum1
    if (formData?.simNum1 && (!checkValueLength(formData?.simNum1, 20) && !checkValueLength(formData?.simNum1, 18))) {
      return dispatch(addError({ error: 'Указан невалидный simNum1, значение должно содержать 18 или 20 символов' }));
    }
    // Проверяем валидность simNum2
    if (formData?.simNum2 && (!checkValueLength(formData?.simNum2, 20) && !checkValueLength(formData?.simNum2, 18))) {
      return dispatch(addError({ error: 'Указан невалидный simNum2, значение должно содержать 18 или 20 символов' }));
    }

    // Если все проверки пройдены успешно, переходим к отправке данных
    // Переводим в состояние загрузки
    dispatch(load());
    // Отправляем данные на сервер через Redux
    dispatch(editKuobzFromServer(formData, formData.id));
  };

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, закрываем модальное окно
    if (!loader?.isLoading && loader?.isSuccess) {
      setAddSuccess(true);
    }
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  // Закрывает модальное окно после успешного добавления нового устройства
  const handlerCloseModal = () => {
    closeModal();
    setAddSuccess(false);
  };

  useEffect(() => {
    if (formData.sim2) {
      setOptionalFields((prevState) => prevState.filter((item) => item !== 'imei2'));
    }
  }, [formData.sim2]);
  return (
    <div className="add-kuobz">
      <div className="add-kuobz_container-title">
        <h3 className="add-kuobz_title">{`ИЗМЕНЕНИЕ ДАННЫХ КУОБЗ № ${currentKuobz?.id}`}</h3>
      </div>
      {addSuccess && (
        <div className="add-kuobz_container-success">
          <h5 className="add-kuobz_title-success">Изменения сохранены!</h5>
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="К списку устройств"
            disabled={isButtonDisabled}
            onClick={handlerCloseModal}
          />
        </div>
      )}
      {!addSuccess && (
        <FormContainer>
          <Input
            column="left"
            placeholder="Введите IMEI 1"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 15)}
            name="imei1"
            label="IMEI 1 *"
            width = "100%"
            height="var(--sadr-font-size-60)"
            mask='999999999999999'
            value={formData.imei1}
          />
          <PhoneInput
            column="left"
            label="SIM1"
            name="sim1"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData.sim1}
          />

          <Input
            column="left"
            placeholder="Введите номер SIM 1"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="simNum1"
            label="Номер SIM 1"
            width = "100%"
            height="var(--sadr-font-size-60)"
            mask='99999999999999999999'
            value={formData.simNum1}
          />
          <Input
            column="right"
            placeholder="Введите IMEI 2"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="imei2"
            label={formData?.sim2 ? 'IMEI 2 *' : 'IMEI 2'}
            width = "100%"
            height="var(--sadr-font-size-60)"
            mask='999999999999999'
            value={formData.imei2}
          />
          <PhoneInput
            column="right"
            label="SIM2"
            name="sim2"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData.sim2}
          />

          <Input
            column="right"
            placeholder="Введите номер SIM 2"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="simNum2"
            label="Номер SIM 2"
            width = "100%"
            height="var(--sadr-font-size-60)"
            mask='99999999999999999999'
            value={formData.simNum2}
          />
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text="Сохранить изменения"
            onClick={(event) => {
              handlerSubmit(event);
            }}
            disabled={isButtonDisabled || !isFormEdit}
            isButton
          />
        </FormContainer>
      )}
    </div>
  );
}

export default EditKuobz;
