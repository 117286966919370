import React from 'react';
import '../../styles/DeviceHeader.css';
import { dateToString } from '../../utils/convertToDate';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import CustomButton from '../CustomElements/CustomButton';
import { formatValue } from '../../utils/formatValue';
import { useRole } from '../../customHook/useRole';

/**
 *
 * @param device - текущий девайс
 * @param onEdit {()->void} - Обработчик редактирования
 * @param onSubmit {()=>void} - Обработчик "Выполнить ТО"
 * @return {Element}
 * @constructor
 */
export function DeviceHeader({ device, onEdit, onService, t }) {
  const isMaster = useRole('master');
  const isDispatcher = useRole('dispatcher');
  return (
    <div className="main-device-container container-fluid row sadr-gap-40">
      {/* Колонка 1  устройство и статус */}
      <div className="d-flex flex-column col-auto">
        <div className="device-header">
          {device?.type} №{device?.id}
        </div>
        <div className="device-status-container d-flex flex-row justify-content-center align-items-center">
          <div className="circle"></div>
          <span className="device-status">{device?.status}</span>
        </div>
      </div>

      {/*/!* Колонка 2 с информацией о SIM-картах *!/*/}
      <div className="device-text d-flex flex-column col-auto">
        <span>
          <span className="device-text-title">{t?.deviceHeaderSim1 || 'SIM1'}: </span>
          <span className="number-cell">{formatValue(device?.sim1) ?? ''}</span>
        </span>
        <span>
          <span className="device-text-title">{t?.deviceHeaderSim2 || 'SIM2'}: </span>
          <span className="number-cell">{formatValue(device?.sim2) ?? ''}</span>
        </span>
        <span>
          <span className="device-text-title">{t?.deviceHeaderManufactureDate || 'Дата производства'}: </span>
          <span className="number-cell"> {dateToString(device?.manufacture_date)}</span>
        </span>
      </div>

      {/* Колонка 3 с датами ТО */}
      <div className="device-text d-flex flex-column col-auto">
        <span>
          <span className="device-text-title">{t?.deviceHeaderLastMaintenanceDate || 'Дата последнего ТО'}: </span>
          <span className="number-cell"> {dateToString(device?.last_maintanance_date)}</span>
        </span>
        <span>
          <span className="device-text-title">{t?.deviceHeaderNextMaintenanceDate || 'Дата следующего ТО'}: </span>
          <span className="number-cell"> {dateToString(device?.next_maintanance_date)}</span>
        </span>
        {device.status === (t?.statusArchived || 'Списан') && (
          <>
            <span>
              <span className="device-text-title">{t?.deviceHeaderWriteOffDate || 'Дата списания'}: </span>
              <span className="number-cell"> {dateToString(device?.write_off_date)}</span>
            </span>
            <span className="writeoff-text">
              <span className="device-text-title">{t?.deviceHeaderWriteOffReason || 'Причина списания'}: </span>
              <span className="number-cell">{device?.write_off_reason}</span>
            </span>
          </>
        )}
      </div>

      {/* Кнопки */}
      {!isMaster && <CustomButtonEdit className="device-edit-btn ms-auto mb-auto w-auto" onClick={onEdit} />}

      {!isMaster && device.status !== (t?.statusArchived || 'Списан') && (
        <CustomButton
          className="device-bottom-btn col-auto ms-auto mt-auto"
          text={t?.buttonServiceDevice || 'Выполнить ТО'}
          onClick={onService}
        />
      )}
    </div>
  );
}
