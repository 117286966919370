import { INIT_STATUSES, CLEAR_STAUSES } from '../type';
import { filterAndSortData } from '../../utils/filterAndSortData';

const initialState = {
  storageStatuses: [],
  archiveStatuses: [],
  repairStatuses: [],
  usageStatuses: [],
};

export function statusesReducer(state = {}, action) {
  const { type, payload, statusType, selectedStatus, selectedDateSort } = action;

  switch (type) {
    case INIT_STATUSES: {
      // Проверяем, есть ли параметры для фильтрации и сортировки
      let filteredPayload = payload;
      if (selectedStatus || selectedDateSort) {
        // Применяем фильтрацию и сортировку к payload
        filteredPayload = filterAndSortData(payload, selectedStatus, selectedDateSort);
      }
      // Возвращаем новое состояние с обновленными данными
      return { ...state, [`${statusType}Statuses`]: filteredPayload };
    }

    case CLEAR_STAUSES: {
      return initialState;
    }
    default:
      return state;
  }
}
