import { React, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../Table/Table';
import '../../styles/TableForDevices.css';
import AddDevice from '../Devices/AddDevice';
import Modal from '../Modal/Modal';
import ResetMesh from '../Brigades/ResetMesh';
import CustomButton from '../CustomElements/CustomButton';
import Device from '../Devices/Device';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import { emitResetMesh } from '../../redux/actions/brigades.action';
import { listenResetMesh } from '../../redux/actions/brigades.action';
import { useRole } from '../../customHook/useRole';

function TableForDevices({ formData, bottomBoundaryRef, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  const isDispatcher = useRole('dispatcher');
  const isMaster = useRole('master');
  // Получение данных устройств из Redux store
  const devices = useSelector((state) => state.devices);
  const [currentDevice, setCurrentDevice] = useState();

  // Состояния для отображения компонентов и модального окна
  const [isOpen, setIsOpen] = useState(false);
  const [newDeviceModal, setNewDeviceModal] = useState(false);
  const [openDeviceModal, setOpenDeviceModal] = useState(false);
  const [resetMeshModal, setResetMeshModal] = useState(false);

  // Открыть модалку для добавления нового устройства
  const addNewDevice = () => {
    setNewDeviceModal(true);
    setIsOpen(true);
  };

  // Открыть модалку для просмотра устройства
  const openDevice = (id) => {
    setCurrentDevice(devices.find((device) => device.id === id));
    setOpenDeviceModal(true);
    setIsOpen(true);
  };

  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
    setNewDeviceModal(false);
    setOpenDeviceModal(false);
    setResetMeshModal(false);
  };
  // открытие модалки для сброса mersh
  const handleButtonClick = (event, device) => {
    event.stopPropagation();
    setCurrentDevice(devices.find((elem) => elem.id === device.id));
    setResetMeshModal(true);
    setIsOpen(true);
  };

  // сброс mesh
  const handlerConfirmResetMesh = (id) => {
    const payload = { deviceId: id };
    dispatch(emitResetMesh(payload));
  };

  useEffect(() => {
    //слушаем событие 'reset_mesh_reply'
    dispatch(listenResetMesh());
  }, [dispatch]);

  // Колонки таблицы
  const columns = useMemo(
    () =>
      [
        {
          Header: t?.tableHeaderDeviceId,
          accessor: 'id',
          Cell: ({ cell: { value } }) => (
            <div className="table-for-cell-id">
              <span className="cell-id-icon"></span>
              <span className="cell-id-value">{value}</span>
            </div>
          ),
        },
        {
          Header: t?.tableHeaderType,
          accessor: 'type',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeaderStatus,
          accessor: 'status',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeaderCondition,
          accessor: 'broken',
          Cell: ({ value }) => {
            switch (value) {
              case true:
                return t?.tableHeaderConditionBroken;
              case false:
                return t?.tableHeaderConditionWorking;
              default:
                return t?.tableHeaderConditionUndefined;
            }
          },
        },
        {
          Header: t?.tableHeaderRoad,
          accessor: 'road',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeaderDirectorate,
          accessor: 'directorate',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeaderDepartment,
          accessor: 'department',
          Cell: ({ value }) => defaultCellRender(value),
        },
        // сейчас не требуеться, но пока оставил на всякий случай
        // {
        //   Header: 'Участок',
        //   accessor: 'section',
        //   Cell: ({ value }) => defaultCellRender(value),
        // },
        {
          Header: t?.tableHeaderResponsible,
          accessor: 'responsible',
          Cell: ({ value }) => defaultCellRender(value),
        },
        !(isDispatcher || isMaster) && {
          Header: t?.tableHeaderResetMesh || 'Сбросить Mesh',
          disableSorting: true, // Флаг для отключения сортировки
          Cell: ({ row }) => (
            <div className="">
              {row.original.type === t?.typeDeviceKUOBZ ? (
                <CustomButtonDelete
                  margin="0  var(--sadr-font-size-5) "
                  disabled={false}
                  onClick={(event) => {
                    handleButtonClick(event, row?.original);
                  }}
                />
              ) : (
                <span>-</span> // Отображать прочерк, если тип не "КУОБЗ"
              )}
            </div>
          ),
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [devices, t]
  );

  return (
    <div className="table-for-devices_or_uipps_container">
      {/* Компонент таблицы */}
      <Table
        data={devices}
        columns={columns}
        open={openDevice}
        showGlobalSearch={false}
        bottomBoundaryRef={bottomBoundaryRef}
        arrLength={devices.length}
        disableInternalSorting
      />

      <div className={`table-for-devices_btn-container ${isMaster ? 'padding-10' : ''}`}>
        {/* Кнопка для добавления нового устройства */}
        {!isMaster && <CustomButton text={t?.buttonAddNewDevice} onClick={addNewDevice} />}
      </div>

      {/* Модальное окно */}
      {/* передать что бы ширина модалки была как ширина таблицы containerSelector={'.table-for-devices_or_uipps_container'} */}
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
        {/* Компонент для добавления нового устройства */}
        {newDeviceModal && <AddDevice closeModal={closeModal} t={t} />}
        {/* Компонент для просмотра устройства */}
        {openDeviceModal && (
          <Device
            closeModal={closeModal}
            currentDevice={currentDevice}
            setCurrentDevice={setCurrentDevice}
            formData={formData}
            t={t}
          />
        )}
        {/* компонент для сброса mesh */}
        {resetMeshModal && (
          <ResetMesh
            closeModal={closeModal}
            confirmReset={false}
            handlerConfirm={handlerConfirmResetMesh}
            device={currentDevice}
            t={t}
          />
        )}
      </Modal>
    </div>
  );
}

export default TableForDevices;
