import QRCode from 'react-qr-code';
import React, { useState } from 'react';

function AndroidDev() {
  const [value] = useState('http://purgatory.ctrlplus.team:8081/apk_dev');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div className="m-5">DEV vesrion:</div>
      <QRCode title="SADR app" value={value} bgColor="var(--sadr-background-secondary)" fgColor="#000000" size={256} />
    </div>
  );
}

export default AndroidDev;
