import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import '../../styles/TablesForBrigades.css';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import Brigade from '../Brigades/Brigade';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { convertMillisecondsToDate } from '../../utils/convertToDate';
import { useRole } from '../../customHook/useRole';
import { filterStateWithLodash } from '../../utils/filterStateWithLodash';
import { initBrigadesFromServer } from '../../redux/actions/brigades.action';
import useInfiniteScroll from '../../customHook/useInfiniteScroll';

function TablesForBrigades({ formData, searchInput, t }) {
  // Получение данных о бригадах из Redux store
  const { brigades } = useSelector((state) => state.brigadesState);
  const [currentBrigade, setCurrentBrigade] = useState();

  // Состояния для отображения компонентa и модального окна
  const [isOpen, setIsOpen] = useState(false);
  const [openBrigadeModal, setOpenBrigadeModal] = useState(false);

  // Открыть модалку для просмотра бригады
  const openBrigade = (id) => {
    setCurrentBrigade(brigades.find((brigade) => brigade.id === id));
    setOpenBrigadeModal(true);
    setIsOpen(true);
  };

  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
    setOpenBrigadeModal(false);
  };

  // Фильтрация данных на основе роли пользователя
  const isAdmin = useRole('admin');
  const isCustomer = useRole('customer');
  const isMaster = useRole('master');
  const isDispatcher = useRole('dispatcher');
  const isEngineerInfosignal = useRole('engineerInfosignal');
  const filteredBrigades =
    isAdmin || isCustomer || isEngineerInfosignal || isMaster || isDispatcher
      ? brigades
      : filterStateWithLodash(brigades, { isWorking: true });

  const observer = useRef();
  const bottomBoundaryRef = useRef(null);
  // //Создаём бесконечный скролл с помощью кастгомного хука
  useInfiniteScroll(formData, filteredBrigades, observer, bottomBoundaryRef, initBrigadesFromServer);

  const columns = useMemo(
    () =>
      [
        {
          Header: t?.tableHeaderID,
          accessor: 'id',
          Cell: ({ cell: { value } }) => (
            <div className="table-for-brigades_cell_id number-cell">
              <img className="cell-id-img_for-table" src="/image/case.svg" alt="Иконка" /> {value}
            </div>
          ),
        },
        (isAdmin || isEngineerInfosignal || isMaster || isDispatcher) && {
          Header: t?.tableHeaderStart,
          accessor: 'start',
          Cell: ({ value }) => (
            <span className="text-nowrap date-cell">{defaultCellRender(convertMillisecondsToDate(value))}</span>
          ),
        },
        (isAdmin || isEngineerInfosignal || isMaster || isDispatcher) && {
          Header: t?.tableHeaderFinish,
          accessor: 'finish',
          Cell: ({ value }) => (
            <span className="text-nowrap date-cell">{defaultCellRender(convertMillisecondsToDate(value))}</span>
          ),
        },
        {
          Header: t?.tableHeaderStatus,
          accessor: 'isWorking',
          Cell: ({ cell: { value } }) => {
            if (typeof value === 'boolean') {
              return value ? (
                <div className="icon__item">
                  <img src="/image/status_brigade_work.svg" alt="ИУР включен, сессия не завершена" />
                  <span className="icon__text">{t?.statusWorking}</span>
                </div>
              ) : (
                <div className="icon__item">
                  <img src="/image/status_brigade_dont_work.svg" alt="ИУР выключен, сессия завершена" />
                  <span className="icon__text">{t?.statusNotWorking}</span>
                </div>
              );
            }

            switch (value) {
              case 0:
                return (
                  <div className="icon__item">
                    <img src="/image/status_brigade_work.svg" alt="ИУР включен, сессия не завершена" />
                    <span className="icon__text">{t?.statusWorking}</span>
                  </div>
                );
              case 1:
                return (
                  <div className="icon__item">
                    <img src="/image/status_brigade_dont_work.svg" alt="ИУР включен, сессия завершена" />
                    <span className="icon__text">{t?.statusNotWorking}</span>
                  </div>
                );
              case 2:
                return (
                  <div className="icon__item">
                    <img src="/image/status_brigade_orange.svg" alt="ИУР выключен, сессия не завершена" />
                    <span className="icon__text">{t?.statusOrange}</span>
                  </div>
                );
              case 3:
                return (
                  <div className="icon__item">
                    <img src="/image/status_brigade_blue.svg" alt="ИУР выключен, сессия завершена" />
                    <span className="icon__text">{t?.statusBlue}</span>
                  </div>
                );
              default:
                return '-';
            }
          },
        },
        {
          Header: t?.tableHeaderResponsible,
          accessor: 'responsible',
          Cell: ({ value }) => <span className="text-nowrap number-cell">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderRoad,
          accessor: 'road',
          Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderDirectorate,
          accessor: 'directorate',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeaderDepartment,
          accessor: 'department',
          Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderStation,
          accessor: 'station',
          Cell: ({ value }) => <span className="text-break">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderObject,
          accessor: 'obj',
          Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderRail,
          accessor: 'rail',
          Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredBrigades, t]
  );

  return (
    <div className="container-fluid table-for-brigades_container">
      {filteredBrigades && (
        <Table
          data={filteredBrigades ? filteredBrigades : []}
          columns={columns}
          open={openBrigade}
          showGlobalSearch={false}
          bottomBoundaryRef={bottomBoundaryRef}
          arrLength={filteredBrigades.length}
        />
      )}
      {/* Модальное окно */}
      {isOpen && (
        // containerSelector={'.brigades_content'}
        <Modal isOpen={isOpen} onClose={closeModal}>
          {/* Компонент для просмотра устройства */}
          {openBrigadeModal && (
            <Brigade
              closeModal={closeModal}
              currentBrigade={currentBrigade}
              setCurrentBrigade={setCurrentBrigade}
              formData={formData}
              t={t}
            />
          )}
        </Modal>
      )}
    </div>
  );
}

export default TablesForBrigades;
