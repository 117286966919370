import { combineReducers } from 'redux';
import { devicesReducer } from './devices.reducer';
import { brigadesReducer } from './brigades.reducer';
import { uippsReducer } from './uipps.reducer';
import { documentationReducer } from './documentation.reducer';
import { userReducer } from './user.reducer';
import { optionsReducer } from './options.reducer';
import { statusesReducer } from './statuses.reducer';
import { errorReducer } from './error.reducer';
import { loaderReducer } from './loader.reducer';
import { archiveReducer } from './archive.reducer';
import { kuobzReducer } from './kuobz.reducer';
import { filesReducer } from './files.reducer';
import { languageReducer } from './language.reducer';
import adminChoiceReducer from './adminChoice.reducer';
import infocardReducer from './infocard.reducer';
import { mapDataReducer } from './mapData.reducer';
import { sortDataReducer } from './sortData.reducer';

export const rootReducer = combineReducers({
  user: userReducer,
  brigadesState: brigadesReducer,
  devices: devicesReducer,
  uipps: uippsReducer,
  kuobzes: kuobzReducer,
  infocards: infocardReducer,
  archive: archiveReducer,
  documentation: documentationReducer,
  files: filesReducer,
  statuses: statusesReducer,
  mapData: mapDataReducer,
  options: optionsReducer,
  adminChoiceData: adminChoiceReducer,
  sortData: sortDataReducer,
  loader: loaderReducer,
  language: languageReducer,
  error: errorReducer,
});
