import {
  INIT_DEVICES,
  EDIT_DEVICE,
  SERVICE_DEVICE,
  EDIT_STATUS_HISTORY,
  ADD_DEVICES,
  EDIT_DEVICE_STATUS,
  CLEAR_DEVICES,
  UPDATE_SINGLE_DEVICE,
} from '../type';


export function devicesReducer(state = [], action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_DEVICES: {
      //Проверяем, есть ли в payload уже существующие id. Как показала практика, это возможно
      // Создаем Set с существующими id
      const existingIds = new Set(state.map((item) => item.id));

      // Фильтруем payload, оставляя только уникальные элементы
      const newPayload = payload.filter((item) => !existingIds.has(item.id));

      // Добавляем уникальные элементы к существующему состоянию
      return [...state, ...newPayload];
    }

    case ADD_DEVICES: {
      // Возвращаем новый массив с добавленным устройством
      return [payload, ...state];
    }

    case CLEAR_DEVICES: {
      return payload;
    }

    case EDIT_DEVICE: {
      const res = state?.map((device) => {
        if (device.id === Number(payload.id)) {
          return { ...device, ...payload };
        }
        return device;
      });
      return res;
    }

    case EDIT_DEVICE_STATUS: {
      const updatedDevices = state.map((device) => {
        if (device.id === Number(payload.deviceId)) {
          return { ...device, status: payload.status };
        }
        return device;
      });
      return updatedDevices;
    }

    case UPDATE_SINGLE_DEVICE: {
      const updatedState = state.map((device) => {
        if (device.id === Number(payload.id)) {
          return { ...device, ...payload };
        }
        return device;
      });
      return updatedState;
    }

    case EDIT_STATUS_HISTORY: {
      return state.map((device) => {
        if (device.id !== payload.deviceId) {
          return device;
        }

        return {
          ...device,
          statusHistory: device.statusHistory.map((status) => {
            if (status.id !== payload.id) {
              return status;
            }

            return {
              ...status,
              ...payload,
            };
          }),
        };
      });
    }

    case SERVICE_DEVICE: {
      const res = state.map((device) => {
        if (device.id === payload.deviceId) {
          // Обновляем объект device, добавляя или обновляя необходимые поля
          device.last_maintanance_date = payload.date;
          device.next_maintanance_date = payload.nextDate;
          return device;
        }
        return device;
      });
      return res;
    }

    default:
      return state;
  }
}
