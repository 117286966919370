import '../../styles/DoubleButton.css';
import CustomButton from '../CustomElements/CustomButton';

function DoubleButton({
  textBtnFirst,
  textBtnSec,
  label,
  onClikBtnFirst,
  onClickBtnSec,
  disabledBtnFirst,
  disabledBtnSec,
  svgPathBtnFirst,
  svgPathBtnSec,
  className,
  color,
  state,
}) {
  const handleBtnFirstClick = () => {
    // setColorBtn((prev) => !prev);
    onClikBtnFirst(`${label}-${textBtnFirst}`);
  };

  const handleBtnSecClick = () => {
    // setColorBtn((prev) => !prev);
    onClickBtnSec(`${label}-${textBtnSec}`);
  };

  const chosenColorFirst = color ? '#32a74e' : 'var(--sadr-background-secondary)';
  const chosenColorSec = !color ? '#32a74e' : 'var(--sadr-background-secondary)';

  return (
    <div className={`double-button-container ${className ? className : ''}`}>
      <label className="double-button-label">{label}</label>
      <div className="double-button">
        <CustomButton
          text={textBtnFirst}
          minHeight="var(--sadr-font-size-24)"
          colorText={color ? 'var(--sadr-background-secondary)' : 'var(--sadr-border)'}
          onClick={handleBtnFirstClick}
          className={`double-button__btn`}
          disabled={disabledBtnFirst}
          SvgIcon={svgPathBtnFirst}
          styleSvg={{ fill: color ? '#FEFEFF' : '#828883' }}
          color={
            label === 'Перенос' && state === 4
              ? '#F2DB89' // Условие для желтого цвета, если state равен 4
              : chosenColorFirst === '#32a74e' && disabledBtnFirst
              ? '#7B817D'
              : chosenColorFirst
          }
        />
        <CustomButton
          text={textBtnSec}
          minHeight="var(--sadr-font-size-24)"
          colorText={!color ? 'var(--sadr-background-secondary)' : 'var(--sadr-border)'}
          onClick={handleBtnSecClick}
          className={`double-button__btn`}
          disabled={disabledBtnSec}
          SvgIcon={svgPathBtnSec}
          styleSvg={{ fill: !color ? '#FEFEFF' : '#828883' }}
          color={chosenColorSec === '#32a74e' && disabledBtnSec ? '#7B817D' : chosenColorSec}
        />
      </div>
    </div>
  );
}

export default DoubleButton;
