import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/СhoosePlaceWork.css';
import CustomTitle from '../CustomElements/CustomTitle';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import CustomButton from '../CustomElements/CustomButton';
import CustomCheckbox from '../CustomElements/CustomCheckbox';
import WarnEditPlaceWork from './WarnEditPlaceWork';
import CustomContainer from '../CustomElements/CustomContainer';
// Действия Redux
import { emitLocationWork, emitEditPlaceWork, emitStartWork } from '../../redux/actions/brigades.action';
import { listenLocationWorkInfo } from '../../redux/actions/brigades.action';

function СhoosePlaceWork({ onClose, title, currentPW, setCurrenrPW, currentBrigade, confirmTextBtn, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  //данные из стейта cписок мест работы
  const { locationsWork } = useSelector((state) => state.brigadesState);

  // Состояние для отображения странмцы подтверждения.
  const [chooseSuccess, setСhooseSuccess] = useState(false);

  // Состояние для текущего выбранного места работы
  const [selectedCheckbox, setSelectedCheckbox] = useState();
  const [selectedNameCheckbox, setSelectedNameCheckbox] = useState();

  // Обработчик для подтверждения выбора места работы
  const handlerSubmit = () => {
    setСhooseSuccess(true);
  };

  // Обработчик начала работы
  const handlerSubmitWork = () => {
    if (confirmTextBtn === t?.startWorkButtonText) {
      //отправляем событие чтобы сервер начал слать инфо
      const payload = { brigadeId: currentBrigade.id, railId: selectedCheckbox };
      dispatch(emitStartWork(payload));
    }
    if (confirmTextBtn === t?.changeButtonText) {
      //отправляем событие об изменении места работы
      const payload = { brigadeId: currentBrigade.id, railId: selectedCheckbox };
      dispatch(emitEditPlaceWork(payload));
    }
    setСhooseSuccess(false);
    setSelectedCheckbox('');
    onClose();
  };

  // Обработчик отмены выбора места работы
  const handlerCancel = () => {
    setСhooseSuccess(false);
  };

  // Обработчик изменения состояния чекбокса
  const handleCheckboxChange = (id, name) => {
    setCurrenrPW('');
    setSelectedCheckbox(id);
    setSelectedNameCheckbox(name);
  };

  // Обработчик обновления списка мест работы
  const handlerRefresh = () => {
    dispatch(emitLocationWork({ brigadeId: currentBrigade.id }));
  };

  useEffect(() => {
    // обновлениe списка мест работы
    dispatch(emitLocationWork({ brigadeId: currentBrigade.id }));
    //слушаем событие 'location_choose_get_reply'
    dispatch(listenLocationWorkInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {locationsWork?.rails?.length === 0 && (
        <WarnEditPlaceWork handlerConfirm={handlerRefresh} brigadeId={currentBrigade.id} />
      )}

      {locationsWork?.rails?.length > 0 && (
        <div className="choose-place-work">
          <CustomTitle titleText={chooseSuccess ? t?.titleStartWork : title} />
          {chooseSuccess && (
            <CustomContainer>
              <ConfirmationPage
                confirmText={`${t?.startWorkConfirmation} ${selectedNameCheckbox}?`}
                confirmButtonText={t?.startWorkButtonText}
                cancelButtonText={t?.updateButtonText}
                onConfirm={handlerSubmitWork}
                onCancel={handlerCancel}
              />
            </CustomContainer>
          )}
          {!chooseSuccess && (
            <>
              <div className="choose-place-work-content">
                <div className="choose-place-work-container">
                  <span className="variable-text">{locationsWork?.loc_name}</span>
                  <span className="variable-text">({locationsWork?.ord_name})</span>
                </div>

                <span className="choose-place-work-text">{t?.choosePlaceWork}</span>

                {locationsWork?.rails?.map((location) => (
                  <CustomCheckbox
                    key={location?.rail_id}
                    label={location?.rail_name}
                    checked={currentPW ? currentPW === location?.rail_id : selectedCheckbox === location?.rail_id}
                    onChange={() => handleCheckboxChange(location?.rail_id, location?.rail_name)}
                    checkedIcon="image/radiobuttonSelected.svg"
                    uncheckedIcon="image/radiobutton.svg"
                    width="100%"
                    height="var(--sadr-font-size-32)"
                  />
                ))}
              </div>
              <div className="choose-place-work-container-page">
                <CustomButton
                  text={confirmTextBtn}
                  onClick={handlerSubmit}
                  margin="var(--sadr-font-size-10)"
                  disabled={!currentPW && !selectedCheckbox} // Кнопка неактивна, если место работы не выбрано
                />
                <CustomButton
                  text={t?.cancelButtonText}
                  onClick={handlerRefresh}
                  colorText="#1C221D"
                  color="var(--sadr-background-secondary)"
                  margin="var(--sadr-font-size-10)"
                  border
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default СhoosePlaceWork;
