//Тут идёт сборка и подстройка каждого элемента формы для грид таблицы
/* column - Определяется расположение колонки и в зависимости от этого подставляется класс,
    isButton - Если элемент является кнопкой, игнорим и идём дальше,
    columnspan - Если это крупный элемент, например коментарий (texterea), то задаём класс, соответствующий поглощению колонок, 
    rowspan - То же, что и columnspan, но для строк
    Так же ориентируемся на количество колонок "props.columns" и подменяем классы, если необходимо
  */
function FormItem({ children, column, isButton, columnspan, rowspan, columns, selfAlign }) {
  let columnClass = '';

  if (isButton || (!column && !columnspan && !rowspan)) {
    return null;
  }
  switch (column) {
    case 'left':
      columnClass = 'column-1';
      break;
    case 'center':
      if (columns === 1) {
        columnClass = 'column-1';
      } else {
        columnClass = 'column-2';
      }
      break;
    case 'right':
      if (columns === 3) {
        columnClass = 'column-3';
      } else {
        columnClass = 'column-2';
      }
      break;
    default:
      break;
  }
  if (columnspan) {
    columnClass += ` colspan-${columnspan}`;
  }
  if (rowspan) {
    columnClass += ` rowspan-${rowspan}`;
  }
  if(selfAlign === 'start') {
    columnClass += ` self-align-start`;
  }
  if(selfAlign === 'end') {
    columnClass += ` self-align-end`;
  }

  return <div className={columnClass}>{children}</div>;
}
export default FormItem;
