import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/Preloader.css';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import CustomButton from '../CustomElements/CustomButton';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import AddOrEditSemaphore from './AddOrEditSemaphore';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import CustomContainer from '../CustomElements/CustomContainer';

import { deleteSemaphoreFromServer } from '../../redux/actions/infocards.action';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { requestSemaphores } from '../../redux/actions/infocards.action';
import { useRole } from '../../customHook/useRole';
import { load, remove } from '../../redux/actions/loader.action';

export default function Semaphores({ station, road }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // роли пользователя
  const isAdmin = useRole('admin');
  // получаем все светофоры из стейта
  const semaphores = useSelector((s) => s.infocards.semaphores);
  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);
  // состояние для открытия модалки
  const [openModal, setOpenModal] = useState(false);
  // состояние для отображения страницы подтверждения
  const [addSuccess, setAddSuccess] = useState(false);
  // состояние для открытия модалки добавления или редактирования светофора
  const [addOrEditSemaphore, setAddOrEditSemaphore] = useState(false);
  // состояние для открытия модалки подтверждения удаления
  const [deleteSemaphore, setDeleteSemaphore] = useState(false);
  // состояние для текущего светофора
  const [currentSemaphore, setCurrentSemaphore] = useState();
  // состояние флага если мы в режиме редактирования
  const [isEdit, setIsEdit] = useState(false);

  // Закрыть модальное окно
  const closeModal = () => {
    setAddOrEditSemaphore(false);
    setDeleteSemaphore(false);
    setCurrentSemaphore();
    setAddSuccess(false);
    setOpenModal(false);
  };

  // удаление светофора
  const removeSemaphore = () => {
    if (isAdmin) {
      dispatch(load());
      dispatch(deleteSemaphoreFromServer(currentSemaphore?.semaphore_id, currentSemaphore?.station?.id));
    }
  };

  // запрашиваем все светофоры если есть id станции
  useEffect(() => {
    if (station) {
      dispatch(requestSemaphores(station));
    }
  }, [station, dispatch]);

  const formatRailChain = (value, originalStation) => {
    const stationMismatch = value?.station && value.station !== originalStation?.id;
    return stationMismatch ? `${value?.station_name}.${value?.name}` : value?.name;
  };

  // колонки для таблицы
  const columns = useMemo(
    () =>
      [
        {
          Header: 'ID светофора',
          accessor: 'semaphore_id',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: 'Светофор',
          accessor: 'name',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: 'Направление движения по светофору',
          accessor: 'direction',
          Cell: ({ cell: { value } }) => <span>{value === '0' ? 'четное' : value === '1' ? 'нечетное' : '-'}</span>,
        },
        {
          Header: 'РЦ перед светофором',
          accessor: 'rail_chain_before',
          Cell: ({ cell: { value }, row: { original } }) => defaultCellRender(formatRailChain(value, original.station)),
        },
        {
          Header: 'РЦ за светофором',
          accessor: 'rail_chain_after',
          Cell: ({ cell: { value }, row: { original } }) => defaultCellRender(formatRailChain(value, original.station)),
        },
        isAdmin && {
          Header: 'Действия',
          accessor: '.',
          Cell: ({ row: { original } }) => (
            <>
              <CustomButtonEdit
                onClick={() => {
                  setCurrentSemaphore(original);
                  setIsEdit(true);
                  setAddOrEditSemaphore(true);
                  setOpenModal(true);
                }}
              />
              <CustomButtonDelete
                onClick={() => {
                  setCurrentSemaphore(original);
                  setDeleteSemaphore(true);
                  setOpenModal(true);
                }}
              />
            </>
          ),
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [semaphores, isAdmin]
  );

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, закрываем модальное окно
    if (!loader?.isLoading && loader?.isSuccess && deleteSemaphore) {
      closeModal();
    }
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  return (
    <div className="d-flex flex-column h-100 container-fluid overflow-hidden flex-nowrap">
      <Table columns={columns} data={semaphores} hiddenDiv={true} />
      <div className="d-flex flex-row flex-nowrap justify-content-evenly">
        {isAdmin && (
          <CustomButton
            className="confirmation-btn"
            text="+ Добавить светофор"
            isButton
            onClick={() => {
              setIsEdit(false);
              setAddOrEditSemaphore(true);
              setOpenModal(true);
            }}
          />
        )}
      </div>
      {openModal && (
        <Modal
          isOpen={openModal}
          onClose={closeModal}
          width={deleteSemaphore || addSuccess ? '50%' : undefined}
          height={deleteSemaphore || addSuccess ? '60%' : undefined}
        >
          {addOrEditSemaphore && (
            <AddOrEditSemaphore
              closeModal={closeModal}
              station={station}
              road={road}
              isEdit={isEdit}
              currentSemaphore={currentSemaphore}
              addSuccess={addSuccess}
              setAddSuccess={setAddSuccess}
              isAdmin={isAdmin}
            />
          )}
          {deleteSemaphore && (
            <CustomContainer>
              <ConfirmationPage
                onConfirm={removeSemaphore}
                onCancel={closeModal}
                confirmButtonText={'Подтвердить'}
                cancelButtonText={'Отмена'}
                confirmText={`Вы уверены, что хотите удалить светофор ${currentSemaphore?.semaphore_id}?`}
                btnWidth="var(--sadr-font-size-280)"
              />
            </CustomContainer>
          )}
        </Modal>
      )}
    </div>
  );
}
