/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import CustomButton from '../CustomElements/CustomButton';
import { Svg_, Svg__, Svg___, _Svg, __Svg, ___Svg } from '../CustomElements/CustomIcon';
import '../../styles/DraggableDiv.css';

/**
 * Компонент DraggableDiv
 * Компонент, представляющий собой двигаемый по горизонтали див с возможностью изменения скорости и направления движения.
 *
 * @param {Object} props - Свойства компонента.
 * @param {string} props.start - Начальное время в формате ISO.
 * @param {string} props.end - Конечное время в формате ISO.
 * @param {Array<number>} props.timeSignalArray - Массив временных сигналов в миллисекундах.
 * @param {number} props.index - Текущий индекс сигнала.
 * @param {Function} props.setIndex - Функция для установки текущего индекса сигнала.
 * @returns {JSX.Element} Компонент DraggableDiv.
 */
function DraggableDiv({ start, end, timeSignalArray, index, setIndex, brigadeId }) {
  const [position, setPosition] = useState(0); // Позиция дива
  const [isMoving, setIsMoving] = useState(false); // Состояние движения
  const [speed, setSpeed] = useState(1); // Состояние скорости
  const [direction, setDirection] = useState(1); // Состояние направления
  const [containerWidth, setContainerWidth] = useState(0); // Ширина контейнера
  const [movingDivWidth, setMovingDivWidth] = useState(0); // Ширина двигаемого дива
  const isDragging = useRef(false); // Флаг перетаскивания
  const containerRef = useRef(null); // Ссылка на контейнер
  const movingDivRef = useRef(null); // Ссылка на двигаемый див
  const startPosition = useRef(0); // Начальная позиция перетаскивания

  const startTime = moment(start).valueOf(); // Начальное время в миллисекундах
  const endTime = moment(end).valueOf(); // Конечное время в миллисекундах

  /**
   * Вычисляет текущее время на основе позиции дива.
   * @param {number} position - Текущая позиция дива.
   * @returns {moment.Moment} Текущее время в Московской зоне.
   */
  const calculateTime = (position) => {
    if (!containerWidth || !movingDivWidth) return moment.invalid(); // Проверка, инициализированы ли размеры контейнера и дива
    const fraction = position / (containerWidth - movingDivWidth); // Доля пройденного пути
    const timeDuration = endTime - startTime; // Длительность интервала времени
    const currentTime = startTime + timeDuration * fraction; // Вычисление текущего времени
    return moment(currentTime).tz('Europe/Moscow'); // Возвращаем текущее время в Московской зоне
  };

  /**
   * Обновляет индекс сигнала на основе текущего времени.
   * @param {moment.Moment} currentTime - Текущее время.
   */
  // Обновление индекса сигнала на основе текущего времени
  const updateSignalIndex = (currentTime) => {
    for (let i = 0; i < timeSignalArray.length; i++) {
      // Проверяем, находится ли текущее время в пределах текущего сигнала
      if (
        currentTime.valueOf() >= timeSignalArray[i] && // Текущее время больше или равно текущему сигналу
        (i === timeSignalArray.length - 1 || currentTime.valueOf() < timeSignalArray[i + 1]) // Текущее время меньше следующего сигнала или это последний сигнал
      ) {
        setIndex(i); // Обновляем индекс сигнала
        break;
      }
    }
  };

  /**
   * Обработчик движения мыши.
   * @param {MouseEvent} e - Событие мыши.
   */
  const handleMouseMove = (e) => {
    if (isDragging.current) {
      // Проверка, перетаскивается ли див
      const dx = e.clientX - startPosition.current; // Вычисляем смещение мыши
      let newPosition = position + dx; // Новая позиция дива

      // Ограничиваем движение внутри контейнера
      if (newPosition < 0) {
        // Проверка, не выходит ли див за левую границу
        newPosition = 0; // Не позволяем двигаться левее контейнера
        setIndex(0); // Устанавливаем индекс на первый элемент массива
      } else if (newPosition > containerWidth - movingDivWidth) {
        // Проверка, не выходит ли див за правую границу
        newPosition = containerWidth - movingDivWidth; // Не позволяем двигаться правее контейнера
        setIndex(timeSignalArray.length - 1); // Устанавливаем индекс на последний элемент массива
      } else {
        // const currentTime = calculateTime(newPosition); // Вычисляем текущее время
        // updateSignalIndex(currentTime); // Обновляем индекс сигнала
      }

      setPosition(newPosition); // Обновляем позицию
      startPosition.current = e.clientX; // Обновляем начальную позицию
    }
  };

  // Обработчик отпускания мыши.
  const handleMouseUp = () => {
    isDragging.current = false; // Останавливаем перетаскивание при отпускании мыши
  };

  // Обновляет размеры контейнера и двигаемого дива.
  const updateDimensions = () => {
    if (containerRef.current && movingDivRef.current) {
      setContainerWidth(containerRef.current.offsetWidth); // Устанавливаем ширину контейнера
      setMovingDivWidth(movingDivRef.current.offsetWidth); // Устанавливаем ширину двигаемого дива
    }
  };

  // Добавляем обработчики событий мыши
  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [position, containerWidth, movingDivWidth]);

  // Обработчик для начала перетаскивания
  const handleMouseDown = (e) => {
    isDragging.current = true;
    startPosition.current = e.clientX; // Устанавливаем начальную позицию мыши
  };

  // Обработчик изменения размеров окна
  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  // Эффект для движения дива
  useEffect(() => {
    if (isMoving) {
      // Проверка, включено ли движение
      const interval = setInterval(() => {
        setPosition((prev) => {
          const newPosition = prev + speed * direction; // Новая позиция с учетом скорости и направления
          // Проверяем, достиг ли див границ контейнера
          if (newPosition >= containerWidth - movingDivWidth) {
            clearInterval(interval); // Останавливаем движение при достижении правой границы
            setIsMoving(false);
            setIndex(timeSignalArray.length - 1); // Устанавливаем индекс на последний элемент массива
            return containerWidth - movingDivWidth; // Устанавливаем позицию в конец контейнера
          } else if (newPosition <= 0) {
            clearInterval(interval); // Останавливаем движение при достижении левой границы
            setIsMoving(false);
            setIndex(0); // Устанавливаем индекс на первый элемент массива
            return 0; // Устанавливаем позицию в начало контейнера
          }

          // const currentTime = calculateTime(newPosition);
          // updateSignalIndex(currentTime);

          return newPosition;
        });
      }, 100);
      return () => clearInterval(interval); // Очищаем интервал при остановке движения
    }
  }, [isMoving, speed, direction, containerWidth, movingDivWidth]);

  // Эффект для обновления индекса сигнала при изменении позиции
  useEffect(() => {
    const currentTime = calculateTime(position); // Вычисляем текущее время
    if (position <= 0) {
      setIndex(0); // Устанавливаем индекс на первый элемент массива
    } else if (position >= containerWidth - movingDivWidth) {
      setIndex(timeSignalArray.length - 1); // Устанавливаем индекс на последний элемент массива
    } else {
      updateSignalIndex(currentTime); // Обновляем индекс сигнала
    }
  }, [position]);

  // Переключение состояния движения
  const toggleMovement = () => {
    if (position >= containerWidth - movingDivWidth || position <= 0) {
      // Проверка, достиг ли див границ контейнера
      setPosition(0); // Сбрасываем позицию при достижении границ
      setDirection(1); // Устанавливаем направление вперед
      setSpeed(1); // Устанавливаем скорость по умолчанию
      setIndex(0); // Устанавливаем индекс на первый элемент массива
    }
    setIsMoving((prev) => !prev); // Переключаем состояние движения
  };

  // Изменение скорости и направления движения
  const changeMovement = (newSpeed, newDirection) => {
    setSpeed(newSpeed); // Устанавливаем новую скорость
    setDirection(newDirection); // Устанавливаем новое направление
    setIsMoving(true); // Включаем движение
  };

  return (
    <div className="brigade-archive-card d-flex">
      <div className="brigade-archive-header-content">
        <div className="brigade-archive_card-title-container">
          <span className="brigade-archive_card-title">БРИГАДА № {brigadeId}</span>
        </div>
        <div>
          <div className="brigade-archive-btn-control-container">
            {/* Блок кнопок для управления движением назад */}
            <div className="d-flex">
              <CustomButton
                height={`var(--sadr-font-size-40)`}
                width={`var(--sadr-font-size-60)`}
                onClick={() => changeMovement(9, -1)} // Устанавливаем скорость и направление назад
                color={`var(--sadr-background-secondary)`}
                margin={`0 var(--sadr-font-size-5)`}
                SvgIcon={Svg___}
                className="btn-border btn-min-height"
              />
              <CustomButton
                height={`var(--sadr-font-size-40)`}
                width={`var(--sadr-font-size-60)`}
                onClick={() => changeMovement(6, -1)} // Устанавливаем скорость и направление назад
                color={`var(--sadr-background-secondary)`}
                border
                margin={`0 var(--sadr-font-size-5)`}
                SvgIcon={Svg__}
                className="btn-border btn-min-height"
              />
              <CustomButton
                height={`var(--sadr-font-size-40)`}
                width={`var(--sadr-font-size-60)`}
                onClick={() => changeMovement(3, -1)} // Устанавливаем скорость и направление назад
                color={`var(--sadr-background-secondary)`}
                border
                margin={`0 var(--sadr-font-size-5)`}
                SvgIcon={Svg_}
                className="btn-border btn-min-height"
              />
            </div>
            {/* Кнопка для запуска/остановки движения */}
            <CustomButton
              height={`var(--sadr-font-size-40)`}
              width={`var(--sadr-font-size-260)`}
              text={isMoving ? 'Пауза' : 'Запуск'} // Текст кнопки зависит от состояния движения
              onClick={toggleMovement} // Переключаем состояние движения
              margin={`0 var(--sadr-font-size-5)`}
              className="btn-min-height"
            />
            {/* Блок кнопок для управления движением вперед */}
            <div className="d-flex">
              <CustomButton
                height={`var(--sadr-font-size-40)`}
                width={`var(--sadr-font-size-60)`}
                onClick={() => changeMovement(3, 1)} // Устанавливаем скорость и направление вперед
                color={`var(--sadr-background-secondary)`}
                border
                margin={`0 var(--sadr-font-size-5)`}
                SvgIcon={_Svg}
                className="btn-border btn-min-height"
              />
              <CustomButton
                height={`var(--sadr-font-size-40)`}
                width={`var(--sadr-font-size-60)`}
                onClick={() => changeMovement(6, 1)} // Устанавливаем скорость и направление вперед
                color={`var(--sadr-background-secondary)`}
                border
                margin={`0 var(--sadr-font-size-5)`}
                SvgIcon={__Svg}
                className="btn-border btn-min-height"
              />
              <CustomButton
                height={`var(--sadr-font-size-40)`}
                width={`var(--sadr-font-size-60)`}
                onClick={() => changeMovement(9, 1)} // Устанавливаем скорость и направление вперед
                color={`var(--sadr-background-secondary)`}
                border
                margin={`0 var(--sadr-font-size-5)`}
                SvgIcon={___Svg}
                className="btn-border btn-min-height"
              />
            </div>
          </div>
        </div>
      </div>
      <div ref={containerRef} className="draggable-container">
        <span className="time-label start-time">{moment(start).tz('Europe/Moscow').format('YYYY-MM-DD HH:mm:ss')}</span>
        <div
          ref={movingDivRef}
          className="moving-div-time"
          onMouseDown={handleMouseDown}
          style={{
            left: `${position}px`,
          }}
        >
          <span style={{ pointerEvents: 'none' }}>
            {calculateTime(position).tz('Europe/Moscow').format('YYYY-MM-DD HH:mm:ss')}
          </span>
        </div>
        <span className="time-label end-time">{moment(end).tz('Europe/Moscow').format('YYYY-MM-DD HH:mm:ss')}</span>
      </div>
    </div>
  );
}

export default DraggableDiv;
