import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Функция для перевода статуса с русского на сербский и обратно, если объект t передан.
 * @param {string} status Статус, пришедший с сервера.
 * @param {Object} t Объект переводов, если доступен.
 * @returns {string} Переведенный статус или оригинальный статус, если t отсутствует.
 */
const translateStatus = (status, t) => {
  const statusTranslations = {
    Выдача: t?.statusIssueOperation || 'Выдача',
    Izdavanje: t?.statusIssueOperation || 'Izdavanje', // Сербский перевод
    Возврат: t?.statusReturnOperation || 'Возврат',
    Vraćanje: t?.statusReturnOperation || 'Vraćanje', // Сербский перевод
    'Принять на хранение': t?.statusReceiveForStorage || 'Принять на хранение',
    'Prihvat u skladište': t?.statusReceiveForStorage || 'Prihvat u skladište', // Сербский перевод
  };

  // Если статус есть в словаре на русском или сербском, возвращаем переведенное значение
  return t ? statusTranslations[status] || status : status;
};

/**
 * Хук для управления состоянием модального окна и текущим статусом устройства.
 * @param {Array} devicesStatuses Массив статусов устройства.
 * @param {Object} currentDevice Текущее устройство.
 * @param {function} setStatusId Функция для установки идентификатора статуса.
 * @param {Object} t Объект переводов, если доступен.
 * @returns {Object} Объект с состояниями и функциями для управления модальным окном.
 */
const useModalWithStatus = (devicesStatuses, currentDevice, setStatusId, t) => {
  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // Состояние, указывающее открыто ли модальное окно
  const [isOpen, setIsOpen] = useState(false);
  // Состояние для хранения текущего статуса устройства
  const [currentStatus, setCurrentStatus] = useState('');
  // Состояние для хранения текущего устройства
  const [device, setDevice] = useState('');
  // Состояние для управления активным модальным контентом
  const [activeModal, setActiveModal] = useState(null);

  const active = useRef(null);

  // Функция для закрытия модального окна и сброса состояний.
  const closeModal = () => {
    if (loader?.isLoading) return;
    setIsOpen(false);
    active.current = null;
  };

  useEffect(() => {
    setActiveModal(active.current);
  }, []);

  /**
   * Обработчик нажатия кнопки для открытия модального окна и установки текущего статуса.
   * @param {string} buttonName Название кнопки, соответствующее ключу в contentData.
   * @param {number|string} id Идентификатор статуса устройства.
   * @param {Object} device Текущее устройство.
   */
  const handleButtonClick = (buttonName, id, device) => {
    if (device) {
      setDevice(device);
    }
    if (id) {
      if (setStatusId) setStatusId(id);
      // Находим текущий статус устройства по ID
      const status = devicesStatuses?.find((item) => item.id === id);
      if (status) {
        // Добавляем тип устройства к статусу и устанавливаем в состояние
        status.typeDevice = currentDevice?.type;
        setCurrentStatus(status);
      }
    }

    // Переводим статус на нужный язык, если t передан, иначе оставляем оригинальный статус
    const translatedButtonName = translateStatus(buttonName, t);

    // Устанавливаем активное модальное окно и открываем его
    active.current = translatedButtonName;
    setActiveModal(translatedButtonName);
    setIsOpen(true);
  };

  // Возвращаем состояния и функции из хука
  return {
    isOpen,
    device,
    currentStatus,
    activeModal,
    closeModal,
    handleButtonClick,
  };
};

export default useModalWithStatus;
