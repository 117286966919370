/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Selector from '../CustomElements/Selector';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import CustomButton from '../CustomElements/CustomButton';
import Preloader from '../CustomElements/Preloader';

import { useLoader } from './Common';
import { initOptionsRailChainsFromServer } from '../../redux/actions/options.action';
import { saveAdjacencyFromServer } from '../../redux/actions/infocards.action';
import { load, remove } from '../../redux/actions/loader.action';
import '../../styles/AdjacencyEdit.css';

function AdjacencyEdit({ closeModal }) {
  const dispatch = useDispatch();

  //получаем id текущей смежности
  const { adjacency } = useParams();

  // хук для отслеживания процесса запросов
  const { isLoading, isSuccess } = useLoader();

  // получаем опции из стейта
  const selectOptions = useSelector((state) => state?.options);

  // получаем все смежности из стейта
  const adjacencys = useSelector((s) => s.infocards.adjacency);

  // Функция для поиска объекта по rail_chain
  const findObjectByRailChain = (railChain) => {
    return adjacencys?.find((item) => item.rail_chain === Number(railChain));
  };

  //стейт для текущей смежность
  const [currentAdjacency, setCurrentAdjacency] = useState();

  // заменяем нулы на объекты, для отображения в таблице
  const replaceNullsInAdjacency = (currentAdjacency) => {
    if (!currentAdjacency?.adjacency?.length) {
      return [{ station: currentAdjacency?.station, rail_chain: '' }];
    }
    return currentAdjacency.adjacency;
  };

  // состояние для  сета из rail_chain в adjacency
  const [excludedRailChains, setExcludedRailChains] = useState(new Set());

  //добавление смежности
  const handleAdd = () => {
    setCurrentAdjacency((prev) => ({
      ...prev,
      adjacency: [
        ...prev.adjacency,
        {
          rail_chain: '',
          name: '',
          station: prev?.station,
          station_name: prev?.station_name,
        },
      ],
    }));
  };

  // Удаление смежности по индексу
  const removeAdjacencyByIndex = (index) => {
    setCurrentAdjacency((prevState) => {
      const updatedAdjacency = [...prevState.adjacency]; // Создаем копию массива
      updatedAdjacency.splice(index, 1); // Удаляем элемент по индексу

      return {
        ...prevState,
        adjacency: updatedAdjacency,
      };
    });
  };

  // Проверяем, были ли изменения в смежностях
  const isAdjacencyChanged = useMemo(() => {
    const oldAdjacency = findObjectByRailChain(adjacency);
    // Сравниваем текущее значение с исходным
    return JSON.stringify(oldAdjacency?.adjacency) !== JSON.stringify(currentAdjacency?.adjacency);
  }, [currentAdjacency, adjacency]);

  // Проверяем, что все rail_chain в adjacency заполнены
  const areAllRailChainsFilled = useMemo(() => {
    return currentAdjacency?.adjacency?.every((adj) => Boolean(adj.rail_chain));
  }, [currentAdjacency]);

  // Если изменений нет, нет смежностей или не все rail_chain заполнены, блокируем кнопку сохранить
  const isDisabled = !currentAdjacency?.adjacency?.length || !isAdjacencyChanged || !areAllRailChainsFilled;

  // сохранение изменений для смежности
  const handleSave = () => {
    // Переводим в состояние загрузки
    dispatch(load());
    const payload = {
      rail_chain: currentAdjacency.rail_chain,
      adjacency: Array.from(excludedRailChains),
    };
    dispatch(saveAdjacencyFromServer(payload, currentAdjacency?.station));
  };

  // Функция для получения всех опций и отфильтрованных опций
  const getOptionsAndFilteredOptionsForRC = (selectOptions, originalStation, excludedRailChains) => {
    const options = selectOptions?.railChainsPerStation?.[originalStation] || [];
    const filteredOptionsForRC = options.filter((option) => !excludedRailChains.has(option.value));
    const extendedOptionsForRC = filteredOptionsForRC.map((rc) => ({
      value: rc?.value,
      label: `${rc.label} (${rc.value})`,
    }));

    return extendedOptionsForRC;
  };

  // Обработчик изменения рельсовой цепи
  const handleRailChainChange = (selectedOption, index, previousValue) => {
    const newRailChain = selectedOption?.value || '';
    const newName = selectedOption?.label || '';

    setExcludedRailChains((prev) => {
      const newSet = new Set(prev);
      if (previousValue) newSet.delete(previousValue); // Удаляем предыдущее значение
      if (newRailChain) newSet.add(newRailChain); // Добавляем новое значение
      return newSet;
    });

    setCurrentAdjacency((prev) => {
      const updatedAdjacency = [...prev.adjacency];
      updatedAdjacency[index] = {
        ...updatedAdjacency[index],
        rail_chain: newRailChain,
        name: newName,
      };
      return { ...prev, adjacency: updatedAdjacency };
    });
  };

  // Обработчик изменения станции
  const handleStationChange = (selectedOption, index) => {
    const newStation = selectedOption?.value || '';
    const newStationName = selectedOption?.label || '';

    setCurrentAdjacency((prev) => {
      const updatedAdjacency = [...prev.adjacency]; // Создаем копию массива
      updatedAdjacency[index] = {
        rail_chain: '',
        name: '',
        station: newStation,
        station_name: newStationName,
      };

      return { ...prev, adjacency: updatedAdjacency };
    });
  };

  //сохраняем в стейт текущую смежность
  useEffect(() => {
    const foundAdjacency = findObjectByRailChain(adjacency);
    const processedAdjacency = {
      ...foundAdjacency,
      adjacency: replaceNullsInAdjacency(foundAdjacency),
    };
    setCurrentAdjacency(processedAdjacency);
  }, [adjacencys, adjacency]);

  // сохраняем в стейт сет для контроля опций
  useEffect(() => {
    const trueValues = currentAdjacency?.adjacency?.map((adj) => adj.rail_chain).filter(Boolean) || [];
    setExcludedRailChains(new Set(trueValues));
  }, [currentAdjacency]);

  //запрашиваем опции рц для всех станций смежности
  useEffect(() => {
    if (currentAdjacency?.adjacency) {
      currentAdjacency.adjacency.forEach((adj) => {
        if (adj.station) {
          dispatch(initOptionsRailChainsFromServer(adj.station));
        }
      });
    }
  }, [currentAdjacency, dispatch]);

  useEffect(() => {
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
  }, [dispatch]);

  return (
    <div className="adjacency">
      <h3 className="modal-title">{`РЕДАКТИРОВАНИЕ СМЕЖНОСТИ ${currentAdjacency?.name}`}</h3>
      <h5 className="mb-3">
        {currentAdjacency?.road_name}, {currentAdjacency?.station_name}
      </h5>
      {isLoading && (
        <div className="place-in-middle">
          <Preloader isLoading />
        </div>
      )}
      {!isLoading && isSuccess && (
        <div className="place-in-middle">
          <h5 className="confirmation-title">Редактирование смежности завершено успешно</h5>
          <CustomButton
            className="confirmation-btn confirm"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            text={'К списку смежности'}
            onClick={closeModal}
          />
        </div>
      )}
      {!isLoading && !isSuccess && (
        <>
          <div className="form-container__forms-all three-column-auto">
            <h3 className="table_header">Станция</h3>
            <h3 className="table_header">Смежная РЦ</h3>
            <h3 className="table_header">Действия</h3>
            {currentAdjacency &&
              currentAdjacency?.adjacency?.map((adj, index) => (
                <React.Fragment key={index}>
                  <Selector
                    options={selectOptions?.stations}
                    onChange={(selectedOption) => handleStationChange(selectedOption, index)}
                    placeholder="Выберите станцию"
                    value={selectOptions?.stations?.find((option) => option.value === adj.station) || ''}
                    isMulti={false}
                    column="left"
                  />
                  <Selector
                    options={getOptionsAndFilteredOptionsForRC(selectOptions, adj.station, excludedRailChains)}
                    onChange={(selectedOption) => handleRailChainChange(selectedOption, index, adj.rail_chain)}
                    placeholder="Выберите рельсовую цепь"
                    value={
                      selectOptions?.railChainsPerStation?.[adj.station]?.find(
                        (option) => option.value === adj.rail_chain
                      ) || ''
                    }
                    isMulti={false}
                    column="center"
                  />
                  <div className="adjacency__del-button">
                    <CustomButtonDelete
                      column="right"
                      width="fit-content"
                      onClick={() => {
                        removeAdjacencyByIndex(index);
                      }}
                    />
                  </div>
                </React.Fragment>
              ))}
          </div>
          <div className="form-container_submit-button">
            <div className="adjacency-buttons">
              <CustomButton
                className="confirmation-btn align-self-center"
                text="+ Добавить"
                onClick={handleAdd}
                disabled={!areAllRailChainsFilled}
              />
              <CustomButton
                className="confirmation-btn confirm"
                text="Сохранить"
                onClick={handleSave}
                disabled={isDisabled}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AdjacencyEdit;
