import { useState, useEffect } from 'react';

/**
 * Хук для получения статуса устройства по его ID.
 *
 * @param {Array} storageStatuses Массив всех возможных статусов устройств.
 * @param {number|string} statusId Идентификатор статуса устройства.
 * @returns {Object|null} Текущий статус устройства или null, если статус не найден.
 */
const useDeviceStatus = (storageStatuses, statusId) => {
  const [currentStatus, setCurrentStatus] = useState(null);

  useEffect(() => {
    if (statusId) {
      const status = storageStatuses?.find((item) => {
        return item.id === statusId;
      });
      setCurrentStatus(status || {});
    } else {
      setCurrentStatus({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusId]);

  return currentStatus;
};

export default useDeviceStatus;
