/**
 * Рекурсивно обходит вложенный массив и возвращает элементы
 * @param arr {T[]}
 * @param child_fn {(T)=>T[] | undefined}
 * @return T[]
 */
export function select_recursive(arr, child_fn) {
  let result = [];
  arr?.forEach((item) => {
    result.push(item);
    result.push(...select_recursive(child_fn(item), child_fn));
  });
  return result;
}

/**
 * Создает глубокую копию массива с помощью метода structuredClone.
 * @param {Array} arr - Массив, который требуется клонировать.
 * @returns {Array} - Глубокая копия исходного массива.
 */
export const cloneArray = (arr) => {
  if (!Array.isArray(arr)) {
    console.log('не переданы параметры');
    return;
  }
  return arr.map((item) => structuredClone(item));
};
