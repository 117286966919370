import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import '../../styles/FilterForArchive.css';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import { handleUniversalChange } from '../../utils/formUtils';
import Checkbox from '../CustomElements/Checkbox';

function FilterForArchiveServer({ formData, setFormData, handleChangeCheckbox }) {
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  const { signals } = useSelector((state) => state?.archive?.archiveBrigadeSession);

  const [optionsTransferRails, setOptionsTransferRails] = useState([]);

  useEffect(() => {
    // Собираем все названия в один массив, разбивая строки на части
    const allNames = signals.reduce((acc, item) => {
      const names = item.transferRails ? item.transferRails.split(',').map((name) => name.trim()) : [];
      return [...acc, ...names];
    }, []);

    // Убираем повторения
    const uniqueNames = Array.from(new Set(allNames));

    // Преобразуем уникальные названия в массив опций для селекта
    const newOptions = uniqueNames.map((name) => ({
      value: name,
      label: name,
    }));

    setOptionsTransferRails(newOptions);
  }, [signals]);

  // опции для селектов
  const options = {
    dir: [
      { value: 0, label: 'четное' },
      { value: 1, label: 'нечетное' },
    ],
    inSafeZone: [
      { value: 0, label: 'Нет' },
      { value: 1, label: 'Да' },
    ],
    uippsAlertType: [
      { value: '0', label: 'ОК' },
      { value: '1', label: 'Тревога' },
    ],
    alertType: [
      { value: 1, label: 'ОК' },
      { value: 5, label: 'Тревога' },
      { value: 2, label: 'Оповещение 1' },
      { value: 3, label: 'Оповещение 2' },
      { value: 0, label: 'Проследование РЦ' },
    ],
  };

  return (
    <div className="filter-for-archive_select">
      <Input
        placeholder="Введите № сигнала"
        onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        value={formData?.index}
        name="index"
        label="Номер сигнала"
        // type="number"
      />
      <Input
        placeholder="Введите РЦ, куда едет ПС"
        onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        value={formData?.routeRailName}
        label="Контролируемая РЦ"
        name="routeRailName"
      />
      <Selector
        options={options?.alertType}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'alertType', selectedOption, 'select');
        }}
        placeholder="Выберите тип сигнала"
        value={
          formData?.alertType || formData?.alertType === 0
            ? options?.alertType?.find((option) => option.value === formData.alertType)
            : null
        }
        name="alertType"
        isMulti={false}
        label="Тип сигнала"
      />
      <Selector
        options={options?.dir}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'dir', selectedOption, 'select');
        }}
        placeholder="Выберите направление"
        value={
          formData.dir || formData?.dir === 0 ? options?.dir?.find((option) => option.value === formData.dir) : null
        }
        name="dir"
        isMulti={false}
        label="Направление"
      />
      <Input
        placeholder="Введите расстояние в метрах"
        onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        value={formData.distance}
        name="distance"
        label="Расстояние до ПС по АПК-ДК"
        // type="number"
      />
      <Input
        placeholder="Введите названия, длины"
        onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        value={formData.warningRails}
        name="warningRails"
        label="РЦ оповещения"
      />
      <Input
        placeholder="Введите названия, длины"
        onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        value={formData.alertRails}
        name="alertRails"
        label="РЦ тревоги"
      />
      <Input
        placeholder="Введите ID УИППС"
        onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
        value={formData.uippsId}
        name="uippsId"
        label="ID УИППС"
        // type="number"
      />
      <Selector
        options={options?.uippsAlertType}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'uippsAlertType', selectedOption, 'select');
        }}
        placeholder="Выберите сигнал"
        value={
          formData?.uippsAlertType
            ? options?.uippsAlertType.find((option) => option.value === formData?.uippsAlertType)
            : null
        }
        name="uippsAlertType"
        isMulti={false}
        label="Сигнал на УИППС"
      />
      <Input
        placeholder="Введите расстояние в метрах"
        onChange={(event) => handleUniversalChange(formData, setFormData, event)}
        value={formData.preciseDistance}
        name="preciseDistance"
        label="Расстояние по координатам"
        // type="number"
      />
      <Checkbox
        height={`var(--sadr-font-size-45)`}
        // width={`var(--sadr-font-size-320)`}
        width={'100%'}
        label="Кнопка «Тревога»"
        onChange={(newValue) => handleChangeCheckbox('btn_alert', newValue)}
        checked={formData?.btn_alert}
        margin={'0px 0px 10px 0px'}
      />
      <Checkbox
        height={`var(--sadr-font-size-45)`}
        // width={`var(--sadr-font-size-320)`}
        width={'100%'}
        label="Кнопка «Путь свободен»"
        onChange={(newValue) => handleChangeCheckbox('btn_clear', newValue)}
        checked={formData?.btn_clear}
        margin={'0px 0px 20px 0px'}
      />
      <Selector
        options={options?.inSafeZone}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'inSafeZone', selectedOption, 'select');
        }}
        placeholder="Выберите расположение ИУ"
        value={
          formData?.inSafeZone || formData?.inSafeZone === 0
            ? options?.inSafeZone?.find((option) => option.value === formData?.inSafeZone)
            : null
        }
        name="inSafeZone"
        isMulti={false}
        label="Контроль ИУ в безопасной зоне"
      />
      <Checkbox
        height={`var(--sadr-font-size-45)`}
        // width={`var(--sadr-font-size-320)`}
        width={'100%'}
        label="Кнопка «Ограждение»"
        onChange={(newValue) => handleChangeCheckbox('fencingBtnServer', newValue)}
        checked={formData?.fencingBtnServer}
        margin={'0px 0px 10px 0px'}
      />
      <Checkbox
        height={`var(--sadr-font-size-45)`}
        // width={`var(--sadr-font-size-320)`}
        label="РЦ для переноса"
        onChange={(newValue) => handleChangeCheckbox('transferRails', newValue)}
        checked={formData?.transferRails}
      />
      {/* <Selector
        options={optionsTransferRails}
        onChange={(selectedOption) => {
          handleUniversalChange(formData, setFormData, null, 'transferRails', selectedOption, 'select');
        }}
        placeholder="Выберите РЦ для переноса"
        value={optionsTransferRails?.find((option) => option.value === formData?.transferRails)}
        name="transferRails"
        isMulti={false}
        label="Перенос"
      /> */}
    </div>
  );
}

export default FilterForArchiveServer;
