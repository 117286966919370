import React from 'react';
import '../../styles/FormContainer.css';
import FormItem from './FormItem';

function FormContainer({ onSubmit, children, id, ...props }) {
  const childrenArray = React.Children.toArray(children);

  // Кнопка отправки формы
  const buttons = childrenArray.filter(
    (child) => child.props.isButton || child.type === 'submit' || child.props.type === 'submit'
  );

   return (
    <form id={id} className="form-container_form" onSubmit={onSubmit} {...props}>
      <div
        className={`form-container__forms-all ${
          props.columns === 3 ? 'three-column' : props.columns === 1 ? 'one-column' : ''
        }`}
      >
        {childrenArray.length &&
          childrenArray.map((child) => (
            //  Каждый элемент формы рендерится в грид таблицу
            //  Для правильного отображения каждый элемент формы проходить обработку через FormItem
            <FormItem
              key={child.key}
              column={child.props.column}
              columnspan={child.props.columnspan}
              rowspan={child.props.rowspan}
              isButton={child.props.isButton}
              columns={props.columns}
              selfAlign={child.props.selfAlign}
            >
              {child}
            </FormItem>
          ))}
      </div>

      {/* Рендер кнопки, если она была передана */}
      <div className="form-container_submit-button">{buttons}</div>
    </form>
  );
}

export default FormContainer;
