import { useMemo,  useState } from 'react';
import { useSelector } from 'react-redux';
import '../../styles/TablesForBrigades.css';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import Brigade from '../Brigades/Brigade';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { convertMillisecondsToDate } from '../../utils/convertToDate';
import { useRole } from '../../customHook/useRole';
import { filterStateWithLodash } from '../../utils/filterStateWithLodash';

function TablesForBrigades({ formData, bottomBoundaryRef, t }) {
  // Получение данных о бригадах из Redux store
  const { brigades } = useSelector((state) => state.brigadesState);
  const [currentBrigade, setCurrentBrigade] = useState();

  // Состояния для отображения компонентa и модального окна
  const [isOpen, setIsOpen] = useState(false);
  const [openBrigadeModal, setOpenBrigadeModal] = useState(false);

  // Открыть модалку для просмотра бригады
  const openBrigade = (id) => {
    setCurrentBrigade(brigades.find((brigade) => brigade.id === id));
    setOpenBrigadeModal(true);
    setIsOpen(true);
  };

  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
    setOpenBrigadeModal(false);
  };

  // Фильтрация данных на основе роли пользователя
  const isAdmin = useRole('admin');
  const isCustomer = useRole('customer');
  const isMaster = useRole('master');
  const isDispatcher = useRole('dispatcher');
  const isEngineerInfosignal = useRole('engineerInfosignal');
  const filteredBrigades =
    isAdmin || isCustomer || isEngineerInfosignal || isMaster || isDispatcher
      ? brigades
      : filterStateWithLodash(brigades, { isWorking: true });

  const columns = useMemo(
    () =>
      [
        {
          Header: t?.tableHeaderID,
          accessor: 'id',
          Cell: ({ cell: { value } }) => (
            <div className="table-for-cell-id">
              <span className="cell-id-icon"></span>
              <span className="cell-id-value">{value}</span>
            </div>
          ),
        },
        (isAdmin || isEngineerInfosignal || isMaster || isDispatcher) && {
          Header: t?.tableHeaderStart,
          accessor: 'start',
          Cell: ({ value }) => <span className="date-cell">{defaultCellRender(convertMillisecondsToDate(value))}</span>,
        },
        (isAdmin || isEngineerInfosignal || isMaster || isDispatcher) && {
          Header: t?.tableHeaderFinish,
          accessor: 'finish',
          Cell: ({ value }) => <span className="date-cell">{defaultCellRender(convertMillisecondsToDate(value))}</span>,
        },
        {
          Header: t?.tableHeaderStatus,
          accessor: 'isWorking',
          disableSorting: true, // Флаг для отключения сортировки
          tooltipText: t?.tooltipStatus || 'Сортировка по статусу не активна, Вы можете выполнить фильтрацию по этому полю.', // Текст для тултипа
          Cell: ({ cell: { value } }) => {
            if (typeof value === 'boolean') {
              return (
                <div className={`icon__item ${value ? 'status-working' : 'status-not-working'}`}>
                  <span className="icon__text">{value ? t?.statusWorking : t?.statusNotWorking}</span>
                </div>
              );
            }

            let statusClass = '';
            let statusText = '';

            switch (value) {
              case 0:
                statusClass = 'status-working';
                statusText = t?.statusWorking;
                break;
              case 1:
                statusClass = 'status-not-working';
                statusText = t?.statusNotWorking;
                break;
              case 2:
                statusClass = 'status-orange';
                statusText = t?.statusOrange;
                break;
              case 3:
                statusClass = 'status-blue';
                statusText = t?.statusBlue;
                break;
              default:
                return '-';
            }

            return (
              <div className={`icon__item ${statusClass}`}>
                <span className="icon__text">{statusText}</span>
              </div>
            );
          },
        },

        {
          Header: t?.tableHeaderResponsible,
          accessor: 'responsible',
          Cell: ({ value }) => <span className="text-nowrap number-cell">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderRoad,
          accessor: 'road',
          Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderDirectorate,
          accessor: 'directorate',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeaderDepartment,
          accessor: 'department',
          Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderStation,
          accessor: 'station',
          Cell: ({ value }) => <span className="text-break">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderObject,
          accessor: 'obj',
          Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderRail,
          accessor: 'rail',
          Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredBrigades, t]
  );

  return (
    <div className="table-for-brigades_container">
      {filteredBrigades && (
        <Table
          data={filteredBrigades ? filteredBrigades : []}
          columns={columns}
          open={openBrigade}
          showGlobalSearch={false}
          bottomBoundaryRef={bottomBoundaryRef}
          arrLength={filteredBrigades.length}
          disableInternalSorting
        />
      )}
      {/* Модальное окно */}
      {isOpen && (
        // containerSelector={'.brigades_content'}
        <Modal isOpen={isOpen} onClose={closeModal}>
          {/* Компонент для просмотра устройства */}
          {openBrigadeModal && (
            <Brigade
              closeModal={closeModal}
              currentBrigade={currentBrigade}
              setCurrentBrigade={setCurrentBrigade}
              formData={formData}
              t={t}
            />
          )}
        </Modal>
      )}
    </div>
  );
}

export default TablesForBrigades;
