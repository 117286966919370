import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/DeviceStorageFromOperation.css';
import CustomButton from '../CustomElements/CustomButton';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTextarea from '../CustomElements/CustomTextarea';
import CustomTitle from '../CustomElements/CustomTitle';
import FormContainer from '../CustomElements/FormContainer';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { convertToDate } from '../../utils/convertToDate';
import { getCurrentStatusId } from '../../utils/getCurrentStatusId';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import { transferDeviceForStorage } from '../../redux/actions/devices.action';
import useFormDataChanged from '../../customHook/useFormDataChanged';
import { editStorageStatusFromServer } from '../../redux/actions/statuses.action';
import { load } from '../../redux/actions/loader.action';
import { initStatusesFromServer } from '../../redux/actions/statuses.action';
import useSuccessListener from '../../customHook/useSuccessListener';
import FileSelector from '../CustomElements/FileSelector';
import useFilesOptions from '../../customHook/useFilesOptions';

// компонент изменения статуса эксплуатации возврат и  принятия на хранение

function DeviceStorageFromOperation({ currentDevice, closeModal, currentStatus, title, isEdit, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // Состояния для кнопки и уведомления об успешном добавлении
  const [addSuccess, setAddSuccess] = useState(false);

  // состояние  для необязательных полей
  const [optionalFields, setOptionalFields] = useState(['attachmentName', 'acceptanceCertName']);

  // отмена подтверждения
  const closeStatusEditModal = () => {
    setAddSuccess(false);
  };

  // Открываем  модальное для подтверждения изменений
  const handlerOpenModal = () => {
    setAddSuccess(true);
  };

  // Состояние для формы
  const initialFormData = useMemo(
    () =>
      currentStatus
        ? {
            id: currentStatus?.id,
            deviceId: currentStatus?.device_id,
            fromStatusId:
              currentStatus?.from_status_id ||
              getCurrentStatusId(currentDevice?.status, optionsForSelect.deviceStatuses),
            date: convertToDate(currentStatus?.date),
            receiverId: getCurrentStatusId(currentStatus?.receiver, optionsForSelect?.employees),
            isBroken: currentStatus?.broken,
            acceptanceCert: currentStatus?.acceptance_cert || '',
            acceptanceCertName: currentStatus?.acceptance_cert_name || '',
            storageId: currentStatus?.storage_id || '',
            attachment: currentStatus?.attachment || '',
            attachmentName: currentStatus?.attachment_name || '',
            note: currentStatus?.note || '',
          }
        : {
            deviceId: currentDevice.id,
            fromStatusId: getCurrentStatusId(currentDevice?.status, optionsForSelect.deviceStatuses),
            date: '',
            receiverId: '',
            isBroken: '',
            acceptanceCert: '',
            storageId: '',
            attachment: '',
            note: '',
          },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStatus]
  );
  const [formData, setFormData] = useState(initialFormData);

  const acceptanceCertOptions = useFilesOptions(setFormData, 'acceptanceCertName', 'acceptanceCert');
  const defectsFileOptions = useFilesOptions(setFormData, 'attachmentName', 'attachment');

  // Обновляем formData при изменении currentStatus
  useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  //проверяем были ли изменения в форме (true-да, true-нет)
  const isFormEdit = useFormDataChanged(initialFormData, formData, isEdit);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    if (!isEdit) {
      const payload = createFormData(formData);
      // Отправляем данные на сервер через Redux
      dispatch(transferDeviceForStorage(payload, optionsForSelect.deviceStatuses, currentDevice?.type_id, 'usage'));
    }
    if (isEdit) {
      handlerOpenModal();
    }
  };

  const handlerSubmitEdit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(editStorageStatusFromServer(payload, formData.deviceId, 'usage'));
  };

  // //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal, closeStatusEditModal);

  // приразмонтировании отправляем запрос чтобы получить обновленный стейт
  useEffect(() => {
    return () => {
      dispatch(initStatusesFromServer(currentDevice.id, 'usage'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // обновление массива с необязательными полями
  useEffect(() => {
    setOptionalFields((prevFields) => {
      const fieldsToManage = ['attachment'];
      if (!formData?.isBroken) {
        // Добавляем поля, избегая дубликатов
        return [...new Set([...prevFields, ...fieldsToManage])];
      }
      if (formData?.isBroken) {
        // Удаляем указанные поля, если состояние неисправен
        return prevFields.filter((field) => !fieldsToManage.includes(field));
      }
    });
  }, [formData.isBroken]);

  return (
    <CustomContainer>
      <CustomTitle
        titleText={title || t?.titleAcceptForStorage || 'ПРИНЯТИЕ НА ХРАНЕНИЕ УСТРОЙСТВА'}
        deviceType={currentDevice.type}
        deviceId={currentDevice.id}
      />
      {addSuccess && (
        <ConfirmationPage
          confirmText={t?.statusChangeConfirmationText || 'Вы уверены, что хотите изменить данные статуса?'}
          confirmButtonText={t?.confirmButton || 'Изменить'}
          cancelButtonText={t?.cancelButton || 'Отменить'}
          onConfirm={handlerSubmitEdit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={closeStatusEditModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!addSuccess && (
        <FormContainer>
          <DateSelector
            column="left"
            minDate={convertToDate(currentDevice?.last_action_date)}
            maxDate={new Date()}
            selectedDate={formData.date}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
            label={t?.labelReturnDate || 'Дата возврата *'}
            placeholder={t?.placeholderSelectDate || 'Выберите дату'}
            name="date"
            width="100%"
            height="var(--sadr-font-size-60)"
          />
          <Selector
            column="left"
            options={optionsForSelect.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'receiverId', selectedOption, 'select')
            }
            value={optionsForSelect.employees.find((option) => option.value === formData.receiverId) || ''}
            label={t?.labelReceiverName || 'ФИО принявшего *'}
            placeholder={t?.placeholderReceiverName || 'Выберите ФИО принявшего'}
            name="receiverId"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
          />

          <FileSelector
            attachmentOptions={acceptanceCertOptions}
            formData={formData}
            setFormData={setFormData}
            column={'left'}
            file={{
              field: 'acceptanceCert',
              name: 'acceptanceCertName',
              label: t?.labelAcceptanceCert || 'Акт приема-передачи *',
            }}
            rowspan={2}
            t={t}
          />

          <Selector
            column="right"
            options={optionsForSelect.storages}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'storageId', selectedOption, 'select')
            }
            value={optionsForSelect.storages.find((option) => option.value === formData.storageId) || ''}
            label={t?.labelStorageLocation || 'Место хранения *'}
            placeholder="Выберите место хранения"
            name="storageId"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
          />
          <Selector
            column="right"
            options={optionsForSelect.сondition.filter((item) => item.value !== undefined)}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'isBroken', selectedOption, 'select')
            }
            value={optionsForSelect.сondition.find((option) => option.value === formData.isBroken) || ''}
            label={t?.labelCondition || 'Состояние *'}
            placeholder={t?.placeholderCondition || 'Не определено'}
            name="isBroken"
            width="100%"
            height="var(--sadr-font-size-60)"
            isMulti={false}
          />

          <FileSelector
            attachmentOptions={defectsFileOptions}
            formData={formData}
            setFormData={setFormData}
            column={'right'}
            file={{
              field: 'attachment',
              name: 'attachmentName',
              label: `${t?.defectsReportTitle || 'Дефектная ведомость'}${formData?.isBroken ? ' *' : ''}`,
            }}
            rowspan={2}
            t={t}
          />

          <CustomTextarea
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData.note}
            label={t?.labelComment || 'Комментарий *'}
            placeholder={t?.placeholderComment || 'Оставьте комментарий'}
            name="note"
            type="textArea"
            width="100%"
            columnspan={2}
          />

          <CustomButton
            onClick={(event) => {
              handlerSubmit(event);
            }}
            text={isEdit ? t?.buttonSaveChanges || 'Сохранить изменения' : t?.buttonAcceptDevice || 'Принять устройство'}
            disabled={isButtonDisabled || !isFormEdit}
            isButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
          />
        </FormContainer>
      )}
    </CustomContainer>
  );
}

export default DeviceStorageFromOperation;
