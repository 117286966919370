/**
 * Определяет цвет фона на основе переданного статуса.
 *
 * @param {number} statusFlag - Целочисленный флаг статуса, который определяет цвет.
 * @returns {string} Возвращает строку с HEX-кодом цвета.
 *                   Зеленый для значения 0, желтый для 1, красный для 2, и зеленый по умолчанию для остальных значений.
 */
export function determineBackgroundColor(statusFlag) {
  switch (statusFlag) {
    case 0:
      return 'var(--sadr-success)'; // Цвет для первого значения зеленый
    case 1:
      return 'var(--sadr-warn)'; // Цвет для третьего значения желтый
    case 2:
      return 'var(--sadr-error)'; // Цвет для второго значения красный
    default:
      return 'var(--sadr-success)'; // Цвет по умолчанию
  }
}

/**
 * Рендерит ячейку таблицы с заданным значением.
 * Проверяет, является ли предоставленное значение валидным (не null, не undefined и не пустая строка).
 *
 * @param {*} value - Значение для рендеринга в ячейке таблицы, может быть любого типа.
 * @returns {string} Возвращает строковое представление валидного значения.
 *                   Если значение невалидно (null, undefined, пустая строка), возвращает дефис ('-').
 */
export function defaultCellRender(value) {
  return value !== null && value !== undefined && value !== '' ? value : '-';
}
