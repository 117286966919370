import { useState, useEffect } from 'react';

/**
 * Хук для управления фильтрами.
 *
 * @param {Object} initialFormData - Начальные данные формы для фильтрации.
 * @param {Object} formData - Текущие данные формы.
 * @param {Function} setFormData - Функция для обновления данных формы.
 * @param {Array} fieldsToIgnoreFalse - Массив полей, для которых `false` является валидным значением.
 * @returns {Object} - Объект с функцией сброса и флагом показа кнопки сброса.
 */
export function useFilterControl(initialFormData, formData, setFormData, fieldsToIgnoreFalse = []) {
  const [showBtnReset, setShowBtnReset] = useState(false);

  useEffect(() => {
    const isAnyFieldFilled = Object.entries(formData).some(([key, value]) => {
      // Проверяем, должны ли мы игнорировать `false` как пустое значение для текущего ключа
      if (fieldsToIgnoreFalse.includes(key)) {
        return value !== null && value !== ''; // для указанных полей `false` считается значением
      }
      return value !== null && value !== '' && value !== false; // для всех остальных полей `false` считается пустым
    });

    setShowBtnReset(isAnyFieldFilled);
  }, [formData, fieldsToIgnoreFalse]);

  // Сброс всех фильтров
  const resetFilters = () => {
    setFormData(initialFormData);
    setShowBtnReset(false);
  };

  return { resetFilters, showBtnReset };
}
