import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import CustomButton from '../CustomElements/CustomButton';
import DataModal from './DataModal';
import contentData from '../../content/PagesContent/adminDataPannel/admidDataPannelRu.json';
import { useRole } from '../../customHook/useRole';

function AddingDataBlock() {
  const isMaster = useRole('master');
  const [tableParametrs, setTableParametrs] = useState([]); //---- Creating of array of columns
  const [tableData, setTableData] = useState([]); //---- Creating of array of data for table
  const [modal, setModal] = useState(false); //---- State for modal window
  const [modalId, setModalId] = useState(); //---- Set id for editing parameter

  //---- Getting all data for administration page from Redux store
  const backendData = useSelector((state) => state.adminChoiceData);

  const openDataModal = (chosenId) => {
    setModal(true);
    setModalId(chosenId);
  };
  const closeDataModal = () => {
    setModal(false);
  };

  //---- Creating table with data to show for user
  useEffect(() => {
    if (backendData && backendData.data && backendData.selectedParam) {
      const selectedParam = backendData.selectedParam; //---- Selected parameter (pressed button)
      const selectedData = backendData.data[selectedParam]; //---- Data for table
      const headerData = contentData[selectedParam]; //---- Titles for table columns

      if (selectedData) {
        //---- Creating of array of columns
        const newTableParametrs = headerData.header
          .map((item, index) => {
            if (headerData.accessor[index] === 'device') {
              return {
                Header: item,
                accessor: headerData.accessor[index],
                Cell: ({ value }) => (value ? 'Да' : 'Нет'),
              };
            } else if (!headerData.accessor[index].endsWith('_id')) {
              return {
                Header: item,
                accessor: headerData.accessor[index],
                Cell: ({ value }) => defaultCellRender(value),
              };
            }
            return null;
          })
          .filter(Boolean);
        //---- Adding new column with "edit" buttons
        if (!isMaster) {
          newTableParametrs.push({
            Header: 'Изменить',
            Cell: ({
              row: {
                original: { id },
              },
            }) => (
              <div className="">
                <CustomButtonEdit
                  onClick={() => {
                    openDataModal(id);
                  }}
                />
              </div>
            ),
          });
        }

        //---- Creating of array weith data, excluding parameters with '_id'
        const newTableData = selectedData.map((item) => {
          const filteredItem = {};
          Object.entries(item).forEach(([key, value]) => {
            if (!key.endsWith('_id')) {
              filteredItem[key] = value;
            }
          });
          return filteredItem;
        });
        setTableParametrs(newTableParametrs);
        setTableData(newTableData);
      }
    }
  }, [backendData]);

  const columnEmployees = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Фамилия',
      accessor: 'surname',
      Cell: ({ value }) => defaultCellRender(value),
    },

    {
      Header: 'Имя',
      accessor: 'name',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Отчество',
      accessor: 'patronymic',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Контакты',
      accessor: 'contact',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Должность',
      accessor: 'position',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Полное название должности',
      accessor: 'position_full_name',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Дирекция',
      accessor: 'directorate_name',
      Cell: ({ value }) => defaultCellRender(value),
    },
    {
      Header: 'Подразделение',
      accessor: 'department_name',
      Cell: ({ value }) => defaultCellRender(value),
    },
    !isMaster && {
      Header: 'Изменить',
      Cell: ({
        row: {
          original: { id },
        },
      }) => (
        <div className="">
          <CustomButtonEdit
            onClick={() => {
              openDataModal(id);
            }}
          />
        </div>
      ),
    },
  ].filter(Boolean);

  return (
    <section className="adding-data-block">
      <div className="adding-data-block__table">
        {/* Draw table with data */}

        <Table
          className='adding-data-block__inner-table'
          data={tableData}
          columns={backendData.selectedParam === 'employees' ? columnEmployees : tableParametrs}
          // viewHeader={backendData.selectedName}
          showGlobalSearch={true}
          
        />

        {/* Button for adding new data (label from json, according to selected parameter) */}

        <div className={`adding-data-block__button ${isMaster ? 'padding-10' : ''}`}>
          {!isMaster && (
            <CustomButton
              text={`+ ${contentData.addLabels[backendData.selectedParam]}`}
              onClick={() => {
                openDataModal();
              }}
            />
          )}
        </div>

        {/* Modal window for editing/adding data */}
        {modal && (
          <Modal isOpen={modal} onClose={closeDataModal} width="70%" height="90%">
            <DataModal changingId={modalId} setModal={setModal} />
          </Modal>
        )}
      </div>
    </section>
  );
}
export default AddingDataBlock;
