import { LOGIN, LOGOUT } from '../type';
import axiosInstance from '../../config/axios.config';
import { initOptionsFromServer } from '../../redux/actions/options.action';

export const loginUser = (options) => ({
  type: LOGIN,
  payload: options,
});

export const login = (payload) => async (dispatch) => {
  let data;
  const headers = {
    'Skip-Interceptor': 'true', // Указываем, что интерсепторы должны пропустить этот запрос
  };
  try {
    data = (await axiosInstance.post('/signin', payload, { headers }))?.data;
  } catch (e) {
    console.log(e);
    dispatch(logoutUser({ error: e.message }));
  }

  const isUserOk = !!data?.roles?.length;
  const user = {
    user: isUserOk,
    role: data?.roles,
    error: !isUserOk,
    logout: !isUserOk,
    fullName: data?.name,
    position: data?.position,
    accessToken: data?.accessToken,
    refreshToken: data?.refreshToken,
  };

  localStorage.setItem('accessToken', user?.accessToken || '');
  localStorage.setItem('refreshToken', user?.refreshToken || '');
  localStorage.setItem('role', user?.role?.join(',') || '');

  dispatch(loginUser(user));

  if (user?.role?.[0] === 'debug') {
    return;
  }
  //полчаем опции для селектов
  dispatch(initOptionsFromServer());
};

export const logout = (options) => ({
  type: LOGOUT,
  payload: options,
});

export const logoutUser =
  (options) =>
  (dispatch, getState, { socket }) => {
    if (socket.connected) {
      socket?.disconnect();
    }
    dispatch(logout(options));
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('role');
    localStorage.removeItem('id');
  };

// слушаем событие, получаем обновленный AccessTocken
export const listenUpdateAccessTocken =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('update_access_tocken', (data) => {
      if (data) {
        // Перезапись старых токенов новыми
        localStorage.setItem('accessToken', data?.accessToken);
      }
      if (!data) {
      }
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('update_access_tocken');
    };
  };

// слушаем событие, получаем обновленный RefreshTocken
export const listenUpdateRefreshTocken =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('update_refresh_tocken', (data) => {
      if (data) {
        // Перезапись старых токенов новыми
        localStorage.setItem('refreshToken', data?.refreshToken);
      }
      if (!data) {
      }
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('update_refresh_tocken');
    };
  };

export const feature = (androidId) => async (dispatch) => {
  try {
    await axiosInstance(`/api/administration/log/${androidId}`);
  } catch (error) {
    console.log(error);
  }
};
