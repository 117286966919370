/* eslint-disable react-hooks/exhaustive-deps */
import '../styles/App.css';
import { useEffect } from 'react';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Map from './testComponents/Map';
import Devices from './Devices/Devices';
import Brigades from './Brigades/Brigades';
import Documentation from './Documentation/Documentation';
import Infocards from './Infocards/Infocards';
import UippsMain from './UIPPS/UippsMain';
import Archive from './Archive/Archive';
import Login from './Login/Login';
import Navbar from './CustomElements/Navbar';
import Kuobzes from './Kuobz/Kuobzes';
import Reports from './Documentation/Reports';
// import useBeforeUnload from '../customHook/useBeforeUnload';
import ErrorModal from './Modal/ErrorModal';
import { useAuthUser, useIsAuthenticated } from 'react-auth-kit';
import { loginUser } from '../redux/actions/user.action';
import { listenUpdateAccessTocken, listenUpdateRefreshTocken } from '../redux/actions/user.action';
import { initOptionsFromServer, updateOptionsLabels } from '../redux/actions/options.action';
import socket from '../config/socket';

// test components
import Android from './testComponents/Android';
import Auth from './testComponents/Auth';
import Feature from './testComponents/Feature';
// import Map from './testComponents/Map';
// import UippsApp from './testComponents/UippsApp';
// import Reports from './testComponents/Reports';
import MirShQR from './testComponents/MirShQR';
import AndroidDev from './testComponents/AndroidDev';
import ArchiveBrigade from './Archive/ArchiveBrigade';
import { useRole } from '../customHook/useRole';
import Files from './Files/Files';
import AdminDataPanel from './AdminDataPanel/AdminDataPanel';
import useClearStates from '../customHook/useClearStates';
import { listenSocketError } from '../redux/actions/error.action';

function App() {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const user = useSelector((state) => state.user);
  // хук запращивающий подтверждение перезагрузки страницы
  // useBeforeUnload();
  useEffect(() => {
    dispatch(updateOptionsLabels()); // Вызываем после монтирования компонента
  }, [dispatch]);
  const development = process.env.REACT_APP_NODE_ENV === 'development';

  const isAuthenticated = useIsAuthenticated();
  const authUser = useAuthUser(); // Получение роли текущего аутентифицированного пользователя.
  //проверяем роли пользователя
  const isAdmin = useRole('admin');
  const isUser = useRole('user');
  const isEngineer = useRole('engineer');
  const isReports = useRole('reports');
  const isCustomer = useRole('customer');
  const isDebug = useRole('debug');
  const isEngineerInfosignal = useRole('engineerInfosignal');
  const isMaster = useRole('master');
  const isDispatcher = useRole('dispatcher');

  //определение страницы, для скрытия навбара на странице логина
  const location = useLocation();
  // const isLoginPage = location.pathname === '/login'
  // временно пока есть тестовые компоненты
  const hideNavBar = [
    '/login',
    '/test',
    '/app/uipps',
    '/app/update',
    '/app/mirsh',
    '/app/dev',
    '/app',
    '/feature',
  ].includes(location.pathname);

  // очищает стейты при переходе между страницами
  useClearStates(location.pathname);

  useEffect(() => {
    if ((!user?.user && !isAuthenticated) || user?.logout) {
      signOut();
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // если есть данные о пользователе при перезагрузке страницы записываем их в стейт
  useEffect(() => {
    const user = authUser();
    if (user) {
      dispatch(loginUser(user));
    }
  }, []);

  // слушаем событие обновления токенов
  useEffect(() => {
    dispatch(listenUpdateAccessTocken());
    dispatch(listenUpdateRefreshTocken());
    dispatch(listenSocketError());
  }, []);

  useEffect(() => {
    const user = authUser();

    if (user && user.role[0] !== 'debug') {
      //полчаем опции для селектов
      dispatch(initOptionsFromServer());
    }
  }, []);

  //подключение сокета после перезагрузки страницы
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    // Проверяем наличие токенов и что сокет еще не подключен
    if (accessToken && refreshToken && !socket.connected) {
      socket.auth = { token: accessToken, refreshToken: refreshToken }; // Установка токена для сокета
      socket.connect(); // Подключаем сокет
    }
  }, []);

  return (
    <div className="App">
      {!hideNavBar && <Navbar />} {/* Не отображать Navbar на странице логина */}
      <Routes>
        {!isAuthenticated() && (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
        {(isAdmin || isUser || isCustomer || isEngineerInfosignal || isMaster || isDispatcher) && (
          <Route path="/brigades" element={<Brigades />} />
        )}
        {(isAdmin || isEngineerInfosignal || isDispatcher || isMaster) && (
          <>
            <Route path="/devices" element={<Devices />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/infocards/*" element={<Infocards />} />
            <Route path="/uipps" element={<UippsMain />} />
            <Route path="/archive" element={<Archive />} />
            <Route path="/files" element={<Files />} />
            <Route path="/administrate" element={<AdminDataPanel />} />
            <Route path="/archive/:id" element={<ArchiveBrigade />} />
            <Route path="*" element={<Navigate to="/devices" />} />
          </>
        )}
        {isEngineer && isAdmin && (
          <>
            <Route path="/development" element={<Auth />} />
            <Route exact path="/rails" element={<Map />} />
            <Route path="*" element={<Navigate to="/devices" />} />
          </>
        )}
        {isEngineer && (
          <>
            <Route path="/kuobz" element={<Kuobzes />} />
            <Route path="*" element={<Navigate to="/kuobz" />} />
          </>
        )}
        {isReports && (
          <>
            <Route path="/reports" element={<Reports />} />
            <Route path="*" element={<Navigate to="/reports" />} />
          </>
        )}
        {isCustomer && (
          <>
            <Route path="/reports" element={<Reports />} />
            <Route path="*" element={<Navigate to="/reports" />} />
          </>
        )}
        {isUser && <Route path="*" element={<Navigate to="/brigades" />} />}
        {/*Test components*/}
        {isDebug && <Route path="*" element={<Navigate to="/test" />} />}
        {development && (
          <>
            <Route exact path="/app/mirsh" element={<MirShQR />} />
            <Route path="*" element={<Navigate replace to="/devices" />} />
            <Route path="/app/dev" element={<AndroidDev />} />
            <Route path="/app" element={<Android />} />
            <Route path="/test" element={<Feature />} />
            {/* <Route exact path="/app/uipps" element={<UippsApp />} /> */}
            {/*  /!* тестовые, конец *!/*/}
          </>
        )}
      </Routes>
      <ErrorModal width="var(--sadr-font-size-600)" height="18.75rem" />
    </div>
  );
}

export default App;
