import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import DateSelector from '../CustomElements/DateSelector';
import Accordeon from '../CustomElements/Accordeon';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import { useFilterControl } from '../../customHook/useFilterControl';
import { handleUniversalChange, transformOptions } from '../../utils/formUtils';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsEmployeesFromServer,
} from '../../redux/actions/options.action';
import { clearDevices, initDevicesFromServer } from '../../redux/actions/devices.action';
import { clearFilterConfig } from '../../redux/actions/filterConfig.action';
import '../../styles/FilterForDevices.css';

function FilterForDevices({ formData, setFormData, initialFormData, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // Применяем функцию transformOptions к массиву optionsForSelect.employees
  const transformedOptions = transformOptions(optionsForSelect?.workers);

  // хук для сброса фильтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData);

  // стейт активности формы
  const formActive = useSelector((state) => state.filterState.filter);
  // блокируем дублирующиеся запросы
  const block = useRef(true);

  useEffect(() => {
    if (block.current) {
      block.current = false;
    } else if ((formData?.id?.length === 6 || !formData?.id) && formActive) {
      dispatch(clearDevices());
      dispatch(clearFilterConfig());
      dispatch(initDevicesFromServer(formData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, formActive]);

  // хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = t?.messageForSelectDirectorates || 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate_id', 'department_id', 'responsible'],
    'road_id',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  // хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = t?.messageForSelectDepartments || 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department_id', 'responsible'],
    'directorate_id',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  // хук для запроса опций для ответственных и контроля селекта
  const messageForSelectResponsible =
    t?.messageForSelectResponsible || 'Для выбора ответственного выберите подразделение';
  const responsibleControl = useOptionsLoader(
    formData,
    setFormData,
    ['responsible'],
    'department_id',
    initOptionsEmployeesFromServer,
    messageForSelectResponsible
  );

  return (
    <Accordeon
      header={t?.filterTitle || 'УЧЕТ УСТРОЙСТВ'}
      hideAbove={1200}
      qParamName={'device_acc'}
      formData={formData}
      resetFilters={resetFilters}
    >
      <button
        type="button"
        className={`filter-for-device_btn-reset-filters ${showBtnReset ? 'visible-btn-reset' : 'hidden-btn-reset'}`}
        onClick={resetFilters}
      >
        <span className="filter-for-device_btn-reset-filters_text">
          {t?.buttonResetFilters || 'Сбросить все фильтры'}
        </span>
        <img className="filter-for-device_btn-reset-filters_icon" src="/image/close.svg" alt="" />
      </button>

      <form className="filter-for-devices_form" id="" autoComplete="off">
        <Input
          label={t?.labelDeviceId || 'ID устройства'}
          placeholder={t?.placeholderDeviceId || 'Введите ID устройства'}
          onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
          value={formData.id}
          name="id"
        />
        <Selector
          // исключаем уиппс из опций
          options={optionsForSelect?.deviceTypes?.filter((item) => item?.value !== 4)}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'type_id', selectedOption, 'select');
          }}
          placeholder={t?.placeholderDeviceType || 'Выберите тип устройства'}
          label={t?.labelDeviceType || 'Тип устройства'}
          value={
            formData.type_id ? optionsForSelect.deviceTypes?.find((option) => option.value === formData.type_id) : null
          }
          name="type_id"
          isMulti={false}
        />
        <Selector
          label={t?.labelDeviceCondition || 'Состояние устройства'}
          options={optionsForSelect?.сondition}
          onChange={(selectedOption) =>
            handleUniversalChange(formData, setFormData, null, 'broken', selectedOption, 'select')
          }
          value={
            formData.broken === undefined
              ? optionsForSelect.сondition?.find((option) => option.label === 'Не определено')
              : formData.broken === ''
              ? null
              : optionsForSelect.сondition?.find((option) => option.value === formData.broken)
          }
          placeholder={t?.placeholderDeviceCondition || 'Выберите состояние'}
          isMulti={false}
          name="broken"
        />
        <Selector
          options={optionsForSelect.roads}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'road_id', selectedOption, 'select');
          }}
          placeholder={t?.placeholderRoad || 'Выберите дорогу'}
          isMulti={false}
          label={t?.labelRoad || 'Дорога'}
          value={formData.road_id ? optionsForSelect?.roads?.find((option) => option.value === formData.road_id) : null}
          name="road_id"
        />
        <Selector
          column="right"
          options={optionsForSelect?.directorates}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'directorate_id', selectedOption, 'select');
          }}
          placeholder={t?.placeholderDirectorate || 'Выберите дирекцию'}
          label={t?.labelDirectorate || 'Дирекция'}
          value={
            formData.directorate_id
              ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate_id)
              : null
          }
          isMulti={false}
          name="directorate_id"
          isDisabled={directoratesControl?.isSelectorDisabled}
          message={directoratesControl?.message}
          customClick={directoratesControl?.onSelectorChange}
        />
        <Selector
          options={optionsForSelect.departments}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'department_id', selectedOption, 'select');
          }}
          placeholder={t?.placeholderDepartment || 'Выберите № подразделения'}
          isMulti={false}
          label={t?.labelDepartment || 'Подразделение'}
          value={
            formData.department_id
              ? optionsForSelect?.departments?.find((option) => option.value === formData.department_id)
              : null
          }
          name="department_id"
          isDisabled={departmentsControl?.isSelectorDisabled}
          message={departmentsControl?.message}
          customClick={departmentsControl?.onSelectorChange}
        />
        <Selector
          options={transformedOptions}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'responsible', selectedOption, 'select');
          }}
          placeholder={t?.placeholderResponsible || 'Выберите № ответственного'}
          isMulti={false}
          label={t?.labelResponsible || 'Табельный номер ответственного'}
          value={
            formData?.responsible ? transformedOptions?.find((option) => option.value === formData.responsible) : null
          }
          name="responsible"
          isDisabled={responsibleControl?.isSelectorDisabled}
          message={responsibleControl?.message}
          customClick={responsibleControl?.onSelectorChange}
        />
        {/* сейчас не требуется, но пока оставил на всякий случай */}
        {/* <Selector
            options={optionsForSelect.sections}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'section_id', selectedOption, 'select');
            }}
            placeholder="Выберите участок"
            isMulti={false}
            label="Производственный участок"
            value={
              formData.section_id
                ? optionsForSelect?.sections?.find((option) => option.value === formData.section_id)
                : null
            }
            name="section_id"
          /> */}
        <Selector
          options={optionsForSelect.deviceStatuses}
          onChange={(selectedOption) =>
            handleUniversalChange(formData, setFormData, null, 'status_id', selectedOption, 'select')
          }
          placeholder={t?.placeholderDeviceStatus || 'Выберите статус'}
          isMulti={false}
          label={t?.labelDeviceStatus || 'Статус'}
          value={
            formData.status_id
              ? optionsForSelect?.deviceStatuses?.find((option) => option.value === formData.status_id)
              : null
          }
          name="status_id"
        />

        <DateSelector
          selectedDate={formData.receive_date}
          setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'receive_date', date, 'date')}
          label={t?.labelReceiveDate || 'Дата приемки'}
          name="receive_date"
          placeholder={t?.placeholderReceiveDate || 'Выберите дату приемки'}
        />
      </form>
    </Accordeon>
  );
}

export default FilterForDevices;
