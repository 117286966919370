import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/BrigadePlaceWork.css';
import Table from '../Table/Table';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import CustomButton from '../CustomElements/CustomButton';
import Modal from '../Modal/Modal';
import MyMap from '../Maps/MyMap';
import СhoosePlaceWork from './СhoosePlaceWork';
import BrigadeWayOut from './BrigadeWayOut';
import BrigadeTransferFencingAllowed from './BrigadeTransferFencingAllowed';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import {
  emitPlaceWorkListenStart,
  emitPlaceWorkListenStop,
  emitLocationWork,
  emitWayOut,
  emitTransferStart,
} from '../../redux/actions/brigades.action';
import { listenPlaceWorkInfo, listenCurrentLocationWorkInfo } from '../../redux/actions/brigades.action';
import { transformsUippsCoords } from '../../utils/transformsUippsCoords';
import { useRole } from '../../customHook/useRole';

function BrigadePlaceWork({ currentBrigade, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  //данные из стейта текущее место работы
  const { currentLocationsWork, brigadeSignals, brigadeInfo } = useSelector((state) => state.brigadesState);
  // Состояния для отображения компонентов и модального окна
  const [confirmTextBtn, setconfirmTextBtn] = useState('');

  //проверяем роли пользователя
  const isAdmin = useRole('admin');
  const isMaster = useRole('master');
  const isCustomer = useRole('customer');
  const isDispatcher = useRole('dispatcher');
  const isEngineerInfosignal = useRole('engineerInfosignal');

  const [isOpen, setIsOpen] = useState(false);
  const [choosePlaceWork, setChoosePlaceWork] = useState(false);
  const [brigadeWayOut, setBrigadeWayOut] = useState(false);
  const [openBrigadeTransferFencingAllowed, setOpenBrigadeTransferFencingAllowed] = useState(false);

  const [message, setMessage] = useState(
    brigadeSignals?.signals?.length > 0 ? t.wayOutForbiddenMessage : t.wayOutSafeMessage
  );

  useEffect(() => {
    setMessage(brigadeSignals?.signals?.length > 0 ? t.wayOutForbiddenMessage : t.wayOutSafeMessage);
  }, [brigadeSignals?.signals?.length, t.wayOutForbiddenMessage, t.wayOutSafeMessage]);

  const [currentPW, setCurrenrPW] = useState('');
  const [title, setTitle] = useState();

  const handleButtonClickAction = (place) => {
    // отправляем событие для получения мест работы
    dispatch(emitLocationWork({ brigadeId: currentBrigade?.id }));
    setTitle(t?.changePlaceWorkTitle);
    setconfirmTextBtn(t.changeButtonText);
    setCurrenrPW(place.object);
    setChoosePlaceWork(true);
    setIsOpen(true);
  };

  const handleButtonClickChoosePW = () => {
    // отправляем событие для получения мест работы
    dispatch(emitLocationWork({ brigadeId: currentBrigade?.id }));
    setTitle(t.assignPlaceWorkTitle);
    setconfirmTextBtn(t.startWorkButtonText);
    setChoosePlaceWork(true);
    setIsOpen(true);
  };

  //открытие бодалки для выхода на путь
  const wayOut = () => {
    setBrigadeWayOut(true);
    setIsOpen(true);
  };

  //открытие mодалки для начала переноса
  const openStartTransfer = () => {
    setOpenBrigadeTransferFencingAllowed(true);
    setIsOpen(true);
  };

  //старт переноса
  const startTransfer = () => {
    const payload = { brigadeId: currentBrigade?.id };
    dispatch(emitTransferStart(payload));
    closeModal();
  };

  const handlerConfirm = () => {
    // отправить сигнал 'exit_rails' payload - {brigadeId: number}
    dispatch(emitWayOut({ brigadeId: currentBrigade?.id }));
    closeModal();
  };
  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
    setChoosePlaceWork(false);
    setBrigadeWayOut(false);
    setOpenBrigadeTransferFencingAllowed(false);
  };
  // Колонки таблицы
  const formatKuobzAlert = (value) => {
    switch (value) {
      case 1:
        return 'ОК';
      case 2:
        return t?.notification1;
      case 3:
        return t?.notification2;
      case 4:
        return t?.alertNoConnection;
      case 5:
        return t?.alert;
      case 6:
        return t?.waiting;
      default:
        return '-';
    }
  };

  // Мемоизация списка маркеров
  const markers = useMemo(
    () => [
      // Добавляем специфический маркер для бригады в начало массива
      {
        position: [brigadeSignals?.iurLat, brigadeSignals?.iurLon],
        type: 'green',
        popupContent: `${t?.brigade} № ${currentBrigade?.id}`,
      },
      // Добавляем остальные маркеры, преобразованные из inputArray
      ...transformsUippsCoords(brigadeSignals?.uippsCoords),
      ...transformsUippsCoords(brigadeSignals?.kuobzCoords, true),
    ],
    [
      brigadeSignals?.iurLat,
      brigadeSignals?.iurLon,
      brigadeSignals?.uippsCoords,
      brigadeSignals?.kuobzCoords,
      currentBrigade?.id,
      t,
    ]
  );

  const columnsTableSignal = useMemo(
    () =>
      [
        {
          Header: '№',
          Cell: ({ row }) => {
            return <span>{row.index + 1}</span>;
          },
        },
        {
          Header: t?.uippsID,
          accessor: 'uippsId',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.direction,
          accessor: 'direction',
          Cell: ({ value }) => (value === 1 ? t?.oddDirection : t?.evenDirection),
        },
        {
          Header: t?.route,
          accessor: 'isAdjacentRail',
          Cell: ({ value }) => (value ? t?.pathAdjacent : t?.pathNotAdjacent),
        },
        (isAdmin || isEngineerInfosignal || isCustomer) && {
          Header: t?.columnSignalDistanceToPs,
          accessor: 'distance',
          Cell: ({ row }) => (
            <div>
              <div>{`${t?.apkDkDistance}${row?.original?.distanceApkDk}`}</div>
              <div>{`${t?.distanceSadr}${row?.original?.distanceSadr}`}</div>
            </div>
          ),
        },
        {
          Header: t?.railsHeader,
          accessor: 'rails',
          Cell: ({ row }) => (
            <div>
              <div>{`${t?.railsWarning}: ${row.original.railsWarning}`}</div>
              <div>{`${t?.alert}: ${row.original.railsAlert}`}</div>
            </div>
          ),
        },
        {
          Header: t?.columnSignalAlert,
          accessor: 'uippsAlert',
          Cell: ({ row }) => (
            <div>
              {row.original.uippsAlert !== undefined && (
                <div>
                  УИППС:{' '}
                  {(function (value) {
                    switch (Number(value)) {
                      case 0:
                        return 'ОК';
                      case 1:
                        return t?.alert;
                      case 6:
                        return t?.waiting;
                      default:
                        return '-';
                    }
                  })(row.original.uippsAlert)}
                </div>
              )}
              {row.original.kuobzAlert !== undefined && <div>КУОБЗ: {formatKuobzAlert(row.original.kuobzAlert)}</div>}
              <div>ИУР: {formatKuobzAlert(row.original.alertType)}</div>
            </div>
          ),
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brigadeSignals.signals, t, isAdmin, isEngineerInfosignal, isCustomer]
  );
  
  // Колонки таблицы
  const columns = useMemo(
    () =>
      [
        {
          Header: t?.tableHeaderRoad,
          accessor: 'road',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeaderStation,
          accessor: 'station',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeaderOrd,
          accessor: 'ord',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeaderObject,
          accessor: 'obj',
          Cell: ({ value }) => <span className="text-nowrap">{defaultCellRender(value)}</span>,
        },
        {
          Header: t?.tableHeaderRail,
          accessor: 'rail',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.tableHeadeAdjacentRails,
          accessor: 'adjacentRails',
          Cell: ({ value }) => defaultCellRender(value),
        },
        {
          Header: t?.iurCoordinates,
          accessor: 'iurCoordinates',
          Cell: () => (
            <div className="no-wrap">{`${defaultCellRender(brigadeSignals?.iurLat)} , ${defaultCellRender(
              brigadeSignals?.iurLon
            )}`}</div>
          ),
        },
        (isAdmin || isEngineerInfosignal) && {
          Header: t?.action,
          Cell: ({ row }) => (
            <div className="">
              <CustomButtonEdit
                margin="0  var(--sadr-font-size-5)"
                onClick={() => {
                  handleButtonClickAction(row?.original);
                }}
              />
            </div>
          ),
        },
      ]?.filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brigadeSignals?.iurLat, brigadeSignals?.iurLon, isAdmin, isEngineerInfosignal, t]
  );
  useEffect(() => {
    //отправляем событие чтобы сервер начал слать инфо
    dispatch(emitPlaceWorkListenStart({ brigadeId: currentBrigade.id }));
    //слушаем событие 'info'
    dispatch(listenPlaceWorkInfo());
    //слушаем событие 'work_place_info'
    dispatch(listenCurrentLocationWorkInfo());

    // Функция очистки
    return () => {
      // отправляем событие чтобы сервер закончил слать инфо
      dispatch(emitPlaceWorkListenStop({ brigadeId: currentBrigade.id }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="brigade-place-work-table-container">
        {(isAdmin || isEngineerInfosignal) && !brigadeInfo?.inWork && (
          <div className="brigade-place-work-btn-container">
            <CustomButton text={t?.assignWorkPlaceButton} onClick={handleButtonClickChoosePW} disabled={false} />
          </div>
        )}
        {/*если работы не начаты то скрыть всю информацию оставив только кнопку "Назначить место работ"  */}
        {brigadeInfo?.inWork && (
          <>
            {/* Таблица с данными о текущем месте работ */}
            <Table data={[currentLocationsWork || {}]} columns={columns} />
            {(isAdmin || isCustomer || isEngineerInfosignal || isMaster || isDispatcher) &&
              !brigadeSignals?.isExitRails && (
                <>
                  {/* Блок с предупреждающим сообщением, если есть сигналы выход запрещен, красный цвет */}
                  {/* Если сигналов нет выход разрешен, зеленым цветом */}
                  <div
                    className="brigade-place-work-info"
                    style={{ color: brigadeSignals?.signals?.length > 0 ? 'var(--sadr-error)' : 'var(--sadr-success)' }}
                  >
                    {message}
                  </div>
                  {/* Кнопка выхода на путь должна отображаться только когда нет сигналов и бригада не вышла на путь и пользователь в роли admin*/}
                  {
                    // Проверяем, если пользователь является админом и бригада не вышла на путь,
                    // или если не является админом, сигналы отсутствуют и бригада не вышла на путь
                    (((isAdmin || isEngineerInfosignal) && !brigadeSignals?.isExitRails) ||
                      ((isAdmin || isEngineerInfosignal) &&
                        brigadeSignals?.signals?.length === 0 &&
                        !brigadeSignals?.isExitRails)) && (
                      <div className="brigade-place-work-btn-container">
                        <CustomButton text={t?.exitWayButton} onClick={wayOut} disabled={false} />
                      </div>
                    )
                  }
                </>
              )}
            {(isAdmin || isCustomer || isEngineerInfosignal) &&
              brigadeInfo?.state === 4 &&
              brigadeSignals?.signals?.length === 0 && (
                <>
                  {/* Блок с текстом и кнопкой перенос */}
                  <div
                    className="brigade-place-work-info"
                    style={{ color: brigadeSignals?.signals?.length > 0 ? 'var(--sadr-error)' : 'var(--sadr-success)' }}
                  >
                    {t?.transferAllowedMessage}
                  </div>

                  {/* Кнопка начать перенос */}
                  {brigadeInfo?.state === 4 &&
                    brigadeSignals?.signals?.length === 0 &&
                    (isAdmin || isEngineerInfosignal) && (
                      <div className="brigade-place-work-btn-container">
                        <CustomButton text={t?.transferStartButton} onClick={openStartTransfer} disabled={false} />
                      </div>
                    )}
                </>
              )}

            {/*Таблица сигналов */}
            {brigadeSignals?.signals && (
              <Table
                className="customBorderRadius"
                data={brigadeSignals?.signals}
                columns={columnsTableSignal}
                colorTR={true}
              />
            )}

            {/* Карта, требуеться передавать координаты */}
            <div className="brigade-place-work-map-container">
              <MyMap markers={markers} />
            </div>
          </>
        )}
        {/* [brigadeSignals?.iurLat, brigadeSignals?.iurLon] */}
      </div>
      {/* Модальное окно */}
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.brigades_content'}>
        {choosePlaceWork && (
          <СhoosePlaceWork
            onClose={closeModal}
            title={title}
            currentPW={currentPW}
            setCurrenrPW={setCurrenrPW}
            currentBrigade={currentBrigade}
            confirmTextBtn={confirmTextBtn}
            t={t}
          />
        )}
        {brigadeWayOut && <BrigadeWayOut closeModal={closeModal} handlerConfirm={handlerConfirm} t={t} />}
        {openBrigadeTransferFencingAllowed && (
          <BrigadeTransferFencingAllowed closeModal={closeModal} handlerConfirm={startTransfer} t={t} />
        )}
      </Modal>
    </>
  );
}

export default BrigadePlaceWork;
