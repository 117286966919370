import { useState, useEffect, useCallback } from 'react';
import Modal from '../Modal/Modal';
import '../../styles/RailChainsTable.css';
import Input from '../CustomElements/Input';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import CustomContainer from '../CustomElements/CustomContainer';
import { useDispatch, useSelector } from 'react-redux';
import { load, remove } from '../../redux/actions/loader.action';
import { deleteRailChainsFromServer } from '../../redux/actions/infocards.action';
import {
  handleFieldChange,
  handleCoordinateChange,
  handleAlertLengthsChange,
  parseCoordinatesString,
  validateCoordinate,
} from '../../utils/railChainsTableInputChangeHandlers';
import { coordinateFormat, isCoordinateAllowed } from '../../utils/coordinateFormat';
import { trackChange } from '../../utils/trackChange';

function RailChainsTable({
  source,
  copySource,
  setCopySource,
  handleModify,
  errorIds,
  station,
  setErrorIds,
  setErrorCoordIds,
  errorCoordIds,
  isUpdate,
  successModal,
  setSuccessModal,
  confirmDelete,
  setConfirmDelete,
  modifiedRailChains,
}) {
  const dispatch = useDispatch();

  // Получаем данные о процессе
  let loader = useSelector((state) => state.loader);

  // Состояния для отображения компонентов и модального окна
  const [isOpen, setIsOpen] = useState(false);

  // состояние для записи id рц который планируют удалить
  const [idRcForDelete, setIdRcForDelete] = useState();

  // Открыть модальное окно
  const openModal = () => {
    setIsOpen(true);
  };

  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
  };

  // функция для удаления РЦ
  const deleteRailChains = () => {
    dispatch(load());
    dispatch(deleteRailChainsFromServer(station, idRcForDelete));
  };

  // отслеживаем выполнение запроса
  useEffect(() => {
    if (!loader?.isLoading && loader?.isSuccess && confirmDelete) {
      setConfirmDelete(false);
      setSuccessModal(true);
      // очищаем стейт отслеживающий загрузку
      dispatch(remove());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  const updateErrorCoordIds = useCallback(
    (event, row, index, cellName, coord) => {
      // Проверяем валидность координаты
      const isValid = validateCoordinate(event?.target?.value, cellName.includes('lat'));

      setErrorCoordIds((prevErrorCoordIds) => {
        const updatedErrorCoordIds = new Set(prevErrorCoordIds);

        // Формируем ключ для ошибки
        const errorKey = `${row?.id}-${cellName}-${index}`;

        if (isValid) {
          // Удаляем из Set ошибку, если она была
          updatedErrorCoordIds.delete(errorKey);
        } else {
          // Добавляем в Set ошибку, если валидация не прошла
          updatedErrorCoordIds.add(errorKey);
        }

        return updatedErrorCoordIds;
      });
    },
    [setErrorCoordIds]
  );

  // Обертка useCallback для обновления ошибок и изменения поля
  const updateErrorIdsAndHandleFieldChange = useCallback(
    (event, row, index) => {
      // Парсинг строки координат и получение невалидных id и массива координат
      const { invalidCoordinatesIds, coordinatesArray } = parseCoordinatesString(event?.target?.value, row?.id);

      if (invalidCoordinatesIds?.length > 0) {
        // Если есть невалидные id, добавляем их в список ошибок
        setErrorIds((prevErrorIds) => {
          const uniqueErrorIds = new Set([...prevErrorIds, ...invalidCoordinatesIds]);
          return Array.from(uniqueErrorIds);
        });
      } else {
        // Если проверка прошла успешно, удаляем id из списка ошибок
        setErrorIds((prevErrorIds) => {
          const index = prevErrorIds.indexOf(row?.id);
          if (index !== -1) {
            const updatedErrorIds = [...prevErrorIds];
            updatedErrorIds.splice(index, 1);
            return updatedErrorIds;
          }
          return prevErrorIds;
        });
        // Обработка изменения поля после успешной валидации
        handleFieldChange(event, index, 'track', setCopySource, false, coordinatesArray);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setErrorIds]
  );

  // Функция для удаления id из списка ошибок
  const removeErrorId = useCallback((id) => {
    setErrorIds((prevErrorIds) => {
      // Находим индекс id в текущем массиве ошибок
      const index = prevErrorIds.indexOf(id);
      if (index !== -1) {
        // Если id найден в массиве, создаем его копию
        const updatedErrorIds = [...prevErrorIds];
        // Удаляем id из копии массива
        updatedErrorIds.splice(index, 1);
        // Возвращаем обновленный массив без удаленного id
        return updatedErrorIds;
      }
      // Возвращаем текущий массив, если id не найден
      return prevErrorIds;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //пропсы для инпутов
  const inputProps = {
    disableHover: true,
    textAlign: 'center',
    background: 'white',
    margin: '0px',
    width: '100%',
    height: '100%',
    mask: '9999',
  };

  const inputCoordProps = {
    isCoordinate: true, // Устанавливаем, что это координатный ввод
    format: coordinateFormat, // Передаем кастомный формат
    isAllowed: isCoordinateAllowed, // Передаем кастомную валидацию
    width: '100%',
    height: '100%',
    disableHover: true,
    textAlign: 'center',
    background: 'white',
    margin: '0px',
    isNotMarginMessage: true,
  };

  const inputOrdinatProps = {
    disableHover: true,
    textAlign: 'center',
    background: 'white',
    margin: '0px',
    width: '100%',
    height: '100%',
    format: '#######',
    maxLength: 8,
    isCoordinate: true,
    isAllowed: undefined,
  };

  const inputTrakProps = {
    restrictionRegex: /^[0-9.,;\s-]*$/,
    name: 'track',
    width: '100%',
    height: '100%',
    disableHover: true,
    textAlign: 'center',
    background: 'white',
    margin: '0px',
    isNotMarginMessage: true,
    internalProcessing: true,
  };

  const coordInfoFormat = `Введите координаты в формате десятичных градусов (DD):
    Широта:
        Пример: 45.1234567 (север), -34.5678901 (юг)
    Долгота:
        Пример: 19.5678901 (восток), -58.1234567 (запад)
    Замечания:
        Используйте точку для десятичных долей.
        Пример: Широта 40.7127767, Долгота -74.0059741

Точность 7 знаков после запятой

Точность до 7 знаков после запятой позволяет определить положение с точностью до приблизительно 1.1 см.`;

  return (
    <div className="table-container">
      {/* хедер таблицы на гридах */}
      <div className="table-header">
        <div className="sub-header header-center sticky-col top-border left-border" row-span={4}>
          ID РЦ
        </div>

        <div className="sub-header top-border header-center" col-span={2} row-span={2}>
          Длина участка оповещения{' '}
        </div>
        <div className="sub-header header-center top-border" row-span={4}>
          Объект
        </div>
        <div className="sub-header header-center top-border" row-span={4}>
          РЦ
        </div>
        <div className="sub-header header-center top-border header-center" col-span={8}>
          Ординаты концов РЦ
        </div>
        <div className="sub-header header-center sub-header-tooltip top-border coord" col-span={16}>
          Кординаты концов РЦ
          <div className="info-icon">
            <div className="tooltip">
              <span>{coordInfoFormat}</span>
            </div>
          </div>
        </div>
        <div className="sub-header header-center sub-header-tooltip top-border" row-span={4}>
          Трек
          <div className="info-icon-track">
            <div className="tooltip">Формат: широта, долгота; широта, долгота; . . . ;</div>
          </div>
        </div>
        <div className="sub-header header-center top-border cell-delete" row-span={4}>
          Удаление РЦ
        </div>

        <div className="sub-header header-center" row-span={3}>
          Питающий конец
        </div>
        <div className="sub-header header-center" col-span={4}>
          Контролируемое ответвление
        </div>
        <div className="sub-header header-center" col-span={3}>
          Неконтролируемое ответвление
        </div>
        <div className="sub-header header-center" col-span={2} row-span={2}>
          Питающий конец
        </div>
        <div className="sub-header header-center" col-span={8}>
          Контролируемое ответвление
        </div>
        <div className="sub-header header-center" col-span={6}>
          Неконтролируемое ответвление
        </div>
        <div className="sub-header header-center" row-span={2}>
          ПЧ
        </div>
        <div className="sub-header header-center" row-span={2}>
          ЭЧ
        </div>
        {/* ординаты */}
        <div className="sub-header header-center" row-span={2}>
          A
        </div>
        <div className="sub-header header-center" row-span={2}>
          Б
        </div>
        <div className="sub-header header-center" row-span={2}>
          В
        </div>
        <div className="sub-header header-center" row-span={2}>
          Г
        </div>
        <div className="sub-header header-center" row-span={2}>
          Н1
        </div>
        <div className="sub-header header-center" row-span={2}>
          Н2
        </div>
        <div className="sub-header header-center" row-span={2}>
          Н3
        </div>
        {/* координаты */}
        <div className="sub-header header-center" col-span={2}>
          A
        </div>
        <div className="sub-header header-center" col-span={2}>
          Б
        </div>
        <div className="sub-header header-center" col-span={2}>
          В
        </div>
        <div className="sub-header header-center" col-span={2}>
          Г
        </div>
        <div className="sub-header header-center" col-span={2}>
          Н1
        </div>
        <div className="sub-header header-center" col-span={2}>
          Н2
        </div>
        <div className="sub-header header-center" col-span={2}>
          Н3
        </div>

        <div className="sub-header header-center">Широта</div>
        <div className="sub-header header-center">Долгота</div>
        <div className="sub-header header-center">Широта</div>
        <div className="sub-header header-center">Долгота</div>
        <div className="sub-header header-center">Широта</div>
        <div className="sub-header header-center">Долгота</div>
        <div className="sub-header header-center">Широта</div>
        <div className="sub-header header-center">Долгота</div>
        <div className="sub-header header-center">Широта</div>
        <div className="sub-header header-center">Долгота</div>
        <div className="sub-header header-center">Широта</div>
        <div className="sub-header header-center">Долгота</div>
        <div className="sub-header header-center">Широта</div>
        <div className="sub-header header-center">Долгота</div>
        <div className="sub-header header-center">Широта</div>
        <div className="sub-header header-center">Долгота</div>
      </div>

      {/* данные таблица */}
      <div className="table-body">
        {copySource.map((row, index) => {
          //создаем переменные
          const oldRailCain = source.find((railCain) => railCain?.id === row.id); // объект рц до изменений
          const id = row?.id; // id рц
          const nameRC = oldRailCain?.rail_chain_name;
          const oldTrack = oldRailCain?.track?.map(({ lat, lon }) => lat + ',' + lon)?.join(';');
          const curentTrack = row?.track?.map(({ lat, lon }) => lat + ',' + lon)?.join(';') || '';
          return (
            <div key={index} className="table-row">
              {/* id */}
              <div className="cell sticky-col left-border">{id}</div>
              {/* Длина участка оповещения ПЧ */}
              <div className="cell">
                <Input
                  id={`${id}-pch-${index}`}
                  value={row?.alertLengths[0] || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleAlertLengthsChange(event, index, true, setCopySource);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Длина участка оповещения ПЧ',
                      oldRailCain?.alertLengths[0],
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                  format="####"
                  maxLength={4}
                />
              </div>
              {/* Длина участка оповещения ЕЧ */}
              <div className="cell">
                <Input
                  id={`${id}-ech-${index}`}
                  value={row?.alertLengths[1] || ''}
                  onChange={(event) => isUpdate()}
                  onBlur={(event) => {
                    handleAlertLengthsChange(event, index, false, setCopySource);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Длина участка оповещения ЕЧ',
                      oldRailCain?.alertLengths[1],
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                  format="####"
                  maxLength={4}
                />
              </div>
              {/* Объект */}
              <div className="cell">
                <Input
                  id={`${id}-object-${index}`}
                  value={row?.object || ''}
                  onChange={(event) => isUpdate(id, event)}
                  onBlur={(event) => {
                    handleFieldChange(event, index, 'object', setCopySource, false);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Объект',
                      oldRailCain?.object,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputProps}
                  mask={undefined}
                  internalProcessing={true}
                />
              </div>
              {/* РЦ */}
              <div className="cell">
                <Input
                  id={`${id}-rc-${index}`}
                  value={row?.rail_chain_name || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleFieldChange(event, index, 'rail_chain_name', setCopySource, false);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'РЦ',
                      oldRailCain?.rail_chain_name,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputProps}
                  mask={undefined}
                  internalProcessing={true}
                />
              </div>
              {/* Ординаты концов РЦ Контролируемое ответвление питающий конец*/}
              <div className="cell">
                <Input
                  id={`${id}-end-ord-${index}`}
                  value={row?.coordinate?.end?.ord || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'coordinate', 'ord', index, setCopySource);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Ординаты концов РЦ Контролируемое ответвление питающий конец',
                      oldRailCain?.coordinate?.end?.ord,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                />
              </div>
              {/* Ординаты концов РЦ Контролируемое ответвление А*/}
              <div className="cell">
                <Input
                  id={`${id}-coord-contr-ord-a-${index}`}
                  value={row?.coordinate?.controlled?.[0]?.ord || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'ord', index, setCopySource, 0);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Ординаты концов РЦ Контролируемое ответвление А',
                      oldRailCain?.coordinate?.controlled?.[0]?.ord,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                />
              </div>
              {/* Ординаты концов РЦ Контролируемое ответвление Б*/}
              <div className="cell">
                <Input
                  id={`${id}-cooord-control-ord-b-${index}`}
                  value={row?.coordinate?.controlled?.[1]?.ord || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'ord', index, setCopySource, 1);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Ординаты концов РЦ Контролируемое ответвление Б',
                      oldRailCain?.coordinate?.controlled?.[1]?.ord,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                />
              </div>
              {/* Ординаты концов РЦ Контролируемое ответвление В*/}
              <div className="cell">
                <Input
                  id={`${id}-cooord-control-ord-v-${index}`}
                  value={row?.coordinate?.controlled?.[2]?.ord || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'ord', index, setCopySource, 2);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Ординаты концов РЦ Контролируемое ответвление В',
                      oldRailCain?.coordinate?.controlled?.[2]?.ord,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                />
              </div>
              {/* Ординаты концов РЦ Контролируемое ответвление Г*/}
              <div className="cell">
                <Input
                  id={`${id}-cooord-control-ord-g-${index}`}
                  value={row?.coordinate?.controlled?.[3]?.ord || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'ord', index, setCopySource, 3);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Ординаты концов РЦ Контролируемое ответвление Г',
                      oldRailCain?.coordinate?.controlled?.[3]?.ord,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                />
              </div>
              {/* Ординаты концов РЦ Неконтролируемое ответвление Н1*/}
              <div className="cell">
                <Input
                  id={`${id}-cooord-uncontrolled-ord-n1-${index}`}
                  value={row?.coordinate?.uncontrolled?.[0]?.ord || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'uncontrolled', 'ord', index, setCopySource, 0);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Ординаты концов РЦ Неконтролируемое ответвление Н1',
                      oldRailCain?.rcoordinate?.uncontrolled?.[0]?.ord,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                />
              </div>
              {/* Ординаты концов РЦ Неконтролируемое ответвление Н2*/}
              <div className="cell">
                <Input
                  id={`${id}-cooord-uncontrolled-ord-n2-${index}`}
                  value={row?.coordinate?.uncontrolled?.[1]?.ord || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'uncontrolled', 'ord', index, setCopySource, 1);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Ординаты концов РЦ Неконтролируемое ответвление Н2',
                      oldRailCain?.rcoordinate?.uncontrolled?.[1]?.ord,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                />
              </div>
              {/* Ординаты концов РЦ Неконтролируемое ответвление Н3*/}
              <div className="cell">
                <Input
                  id={`${id}-cooord-uncontrolled-ord-n3-${index}`}
                  value={row?.coordinate?.uncontrolled?.[2]?.ord || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'uncontrolled', 'ord', index, setCopySource, 2);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Ординаты концов РЦ Неконтролируемое ответвление Н3',
                      oldRailCain?.rcoordinate?.uncontrolled?.[2]?.ord,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputOrdinatProps}
                />
              </div>
              {/* Координаты концов РЦ Контролируемое ответвление питающий конец*/}
              <div className="cell">
                <Input
                  id={`${id}-coord-end-lat-${index}`}
                  value={row?.coordinate?.end?.lat || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'coordinate', 'lat', index, setCopySource);
                    updateErrorCoordIds(event, row, index, 'coord-end-lat');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление питающий конец(широта)',
                      oldRailCain?.coordinate?.end?.lat,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-end-lat-${index}`) && row?.coordinate?.end?.lat
                      ? 'неверный формат'
                      : !row?.coordinate?.end?.lat && row?.coordinate?.end?.lon
                      ? 'введите широту'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              <div className="cell">
                <Input
                  id={`${id}-coord-end-lon-${index}`}
                  value={row?.coordinate?.end?.lon || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'coordinate', 'lon', index, setCopySource);
                    updateErrorCoordIds(event, row, index, 'coord-end-lon');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление питающий конец(долгота)',
                      oldRailCain?.coordinate?.end?.lon,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-end-lon-${index}`) && row?.coordinate?.end?.lon
                      ? 'неверный формат'
                      : !row?.coordinate?.end?.lon && row?.coordinate?.end?.lat
                      ? 'введите долготу'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              {/* Координаты концов РЦ Контролируемое ответвление А*/}
              <div className="cell">
                <Input
                  id={`${id}-coord-contr-a-lat-${index}`}
                  value={row?.coordinate?.controlled?.[0]?.lat || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'lat', index, setCopySource, 0);
                    updateErrorCoordIds(event, row, index, 'coord-contr-a-lat');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление А(широта)',
                      oldRailCain?.coordinate?.controlled?.[0]?.lat,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-contr-a-lat-${index}`) && row?.coordinate?.controlled?.[0]?.lat
                      ? 'неверный формат'
                      : !row?.coordinate?.controlled?.[0]?.lat && row?.coordinate?.controlled?.[0]?.lon
                      ? 'введите широту'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              <div className="cell">
                <Input
                  id={`${id}-coord-contr-a-lon-${index}`}
                  value={row?.coordinate?.controlled?.[0]?.lon || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'lon', index, setCopySource, 0);
                    updateErrorCoordIds(event, row, index, 'coord-contr-a-lon');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление А(долгота)',
                      oldRailCain?.coordinate?.controlled?.[0]?.lon,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-contr-a-lon-${index}`) && row?.coordinate?.controlled?.[0]?.lon
                      ? 'неверный формат'
                      : !row?.coordinate?.controlled?.[0]?.lon && row?.coordinate?.controlled?.[0]?.lat
                      ? 'введите долготу'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              {/* Координаты концов РЦ Контролируемое ответвление Б*/}
              <div className="cell">
                <Input
                  id={`${id}-coord-contr-b-lat-${index}`}
                  value={row?.coordinate?.controlled?.[1]?.lat || ''}
                  {...inputCoordProps}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'lat', index, setCopySource, 1);
                    updateErrorCoordIds(event, row, index, 'coord-contr-b-lat', row?.coordinate?.controlled?.[1]?.lon);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление Б (широта)',
                      oldRailCain?.coordinate?.controlled?.[1]?.lat,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-contr-b-lat-${index}`) && row?.coordinate?.controlled?.[1]?.lat
                      ? 'неверный формат'
                      : !row?.coordinate?.controlled?.[1]?.lat && row?.coordinate?.controlled?.[1]?.lon
                      ? 'введите широту'
                      : ''
                  }
                />
              </div>

              <div className="cell">
                <Input
                  id={`${id}-coord-contr-b-lon-${index}`}
                  value={row?.coordinate?.controlled?.[1]?.lon || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'lon', index, setCopySource, 1);
                    updateErrorCoordIds(event, row, index, 'coord-contr-b-lon', row?.coordinate?.controlled?.[1]?.lat);
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление Б(долгота)',
                      oldRailCain?.coordinate?.controlled?.[1]?.lon,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  {...inputCoordProps}
                  messageError={
                    errorCoordIds.has(`${id}-coord-contr-b-lon-${index}`) && row?.coordinate?.controlled?.[1]?.lon
                      ? 'неверный формат'
                      : !row?.coordinate?.controlled?.[1]?.lon && row?.coordinate?.controlled?.[1]?.lat
                      ? 'введите долготу'
                      : ''
                  }
                />
              </div>

              {/* Координаты концов РЦ Контролируемое ответвление В*/}
              <div className="cell">
                <Input
                  id={`${id}-coord-contr-v-lat-${index}`}
                  value={row?.coordinate?.controlled?.[2]?.lat || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'lat', index, setCopySource, 2);
                    updateErrorCoordIds(event, row, index, 'coord-contr-v-lat');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление В (широта)',
                      oldRailCain?.coordinate?.controlled?.[2]?.lat,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-contr-v-lat-${index}`) && row?.coordinate?.controlled?.[2]?.lat
                      ? 'неверный формат'
                      : !row?.coordinate?.controlled?.[2]?.lat && row?.coordinate?.controlled?.[2]?.lon
                      ? 'введите широту'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              <div className="cell">
                <Input
                  id={`${id}-coord-contr-v-lon-${index}`}
                  value={row?.coordinate?.controlled?.[2]?.lon || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'lon', index, setCopySource, 2);
                    updateErrorCoordIds(event, row, index, 'coord-contr-v-lon');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление В (долгота)',
                      oldRailCain?.coordinate?.controlled?.[2]?.lon,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-contr-v-lon-${index}`) && row?.coordinate?.controlled?.[2]?.lon
                      ? 'неверный формат'
                      : !row?.coordinate?.controlled?.[2]?.lon && row?.coordinate?.controlled?.[2]?.lat
                      ? 'введите долготу'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              {/* Координаты концов РЦ Контролируемое ответвление Г*/}
              <div className="cell">
                <Input
                  id={`${id}-coord-contr-g-lat-${index}`}
                  value={row?.coordinate?.controlled?.[3]?.lat || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'lat', index, setCopySource, 3);
                    updateErrorCoordIds(event, row, index, 'coord-contr-g-lat');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление Г (широта)',
                      oldRailCain?.coordinate?.controlled?.[3]?.lat,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-contr-g-lat-${index}`) && row?.coordinate?.controlled?.[3]?.lat
                      ? 'неверный формат'
                      : !row?.coordinate?.controlled?.[3]?.lat && row?.coordinate?.controlled?.[3]?.lon
                      ? 'введите широту'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              <div className="cell">
                <Input
                  id={`${id}-coord-contr-g-lon-${index}`}
                  value={row?.coordinate?.controlled?.[3]?.lon || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'controlled', 'lon', index, setCopySource, 3);
                    updateErrorCoordIds(event, row, index, 'coord-contr-g-lon');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Контролируемое ответвление Г (долгота)',
                      oldRailCain?.coordinate?.controlled?.[3]?.lon,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-contr-g-lon-${index}`) && row?.coordinate?.controlled?.[3]?.lon
                      ? 'неверный формат'
                      : !row?.coordinate?.controlled?.[3]?.lon && row?.coordinate?.controlled?.[3]?.lat
                      ? 'введите долготу'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              {/* Координаты концов РЦ Неконтролируемое ответвление Н1*/}
              <div className="cell">
                <Input
                  id={`${id}-coord-uncontrolled-n1-lat-${index}`}
                  value={row?.coordinate?.uncontrolled?.[0]?.lat || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'uncontrolled', 'lat', index, setCopySource, 0);
                    updateErrorCoordIds(event, row, index, 'coord-uncontrolled-n1-lat');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Неконтролируемое ответвление Н1 (широта)',
                      oldRailCain?.coordinate?.uncontrolled?.[0]?.lat,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-uncontrolled-n1-lat-${index}`) &&
                    row?.coordinate?.uncontrolled?.[0]?.lat
                      ? 'неверный формат'
                      : !row?.coordinate?.uncontrolled?.[0]?.lat && row?.coordinate?.uncontrolled?.[0]?.lon
                      ? 'введите широту'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              <div className="cell">
                <Input
                  id={`${id}-coord-uncontrolled-n1-lon-${index}`}
                  value={row?.coordinate?.uncontrolled?.[0]?.lon || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'uncontrolled', 'lon', index, setCopySource, 0);
                    updateErrorCoordIds(event, row, index, 'coord-uncontrolled-n1-lon');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Неконтролируемое ответвление Н1 (долгота)',
                      oldRailCain?.coordinate?.uncontrolled?.[0]?.lon,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-uncontrolled-n1-lon-${index}`) &&
                    row?.coordinate?.uncontrolled?.[0]?.lon
                      ? 'неверный формат'
                      : !row?.coordinate?.uncontrolled?.[0]?.lon && row?.coordinate?.uncontrolled?.[0]?.lat
                      ? 'введите долготу'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              {/* Координаты концов РЦ Неконтролируемое ответвление Н2*/}
              <div className="cell">
                <Input
                  id={`${id}-coord-uncontrolled-n2-lat-${index}`}
                  value={row?.coordinate?.uncontrolled?.[1]?.lat || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'uncontrolled', 'lat', index, setCopySource, 1);
                    updateErrorCoordIds(event, row, index, 'coord-uncontrolled-n2-lat');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Неконтролируемое ответвление Н2 (широта)',
                      oldRailCain?.coordinate?.uncontrolled?.[1]?.lat,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-uncontrolled-n2-lat-${index}`) &&
                    row?.coordinate?.uncontrolled?.[1]?.lat
                      ? 'неверный формат'
                      : !row?.coordinate?.uncontrolled?.[1]?.lat && row?.coordinate?.uncontrolled?.[1]?.lon
                      ? 'введите широту'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              <div className="cell">
                <Input
                  id={`${id}-coord-uncontrolled-n2-lon-${index}`}
                  value={row?.coordinate?.uncontrolled?.[1]?.lon || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'uncontrolled', 'lon', index, setCopySource, 1);
                    updateErrorCoordIds(event, row, index, 'coord-uncontrolled-n2-lon');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Неконтролируемое ответвление Н2 (долгота)',
                      oldRailCain?.coordinate?.uncontrolled?.[1]?.lon,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-uncontrolled-n2-lon-${index}`) &&
                    row?.coordinate?.uncontrolled?.[1]?.lon
                      ? 'неверный формат'
                      : !row?.coordinate?.uncontrolled?.[1]?.lon && row?.coordinate?.uncontrolled?.[1]?.lat
                      ? 'введите долготу'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              {/* Координаты концов РЦ Неконтролируемое ответвление Н3*/}
              <div className="cell">
                <Input
                  id={`${id}-coord-uncontrolled-n3-lat-${index}`}
                  value={row?.coordinate?.uncontrolled?.[2]?.lat || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'uncontrolled', 'lat', index, setCopySource, 2);
                    updateErrorCoordIds(event, row, index, 'coord-uncontrolled-n3-lat');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Неконтролируемое ответвление Н3 (широта)',
                      oldRailCain?.coordinate?.uncontrolled?.[2]?.lat,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-uncontrolled-n3-lat-${index}`) &&
                    row?.coordinate?.uncontrolled?.[2]?.lat
                      ? 'неверный формат'
                      : !row?.coordinate?.uncontrolled?.[2]?.lat && row?.coordinate?.uncontrolled?.[2]?.lon
                      ? 'введите широту'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              <div className="cell">
                <Input
                  id={`${id}-coord-uncontrolled-n3-lon-${index}`}
                  value={row?.coordinate?.uncontrolled?.[2]?.lon || ''}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    handleCoordinateChange(event, 'uncontrolled', 'lon', index, setCopySource, 2);
                    updateErrorCoordIds(event, row, index, 'coord-uncontrolled-n3-lon');
                    const changes = trackChange(
                      modifiedRailChains,
                      id,
                      'Координаты концов РЦ Неконтролируемое ответвление Н3 (долгота)',
                      oldRailCain?.coordinate?.uncontrolled?.[2]?.lon,
                      event?.target?.value,
                      nameRC
                    );
                    if (changes) handleModify(id);
                  }}
                  messageError={
                    errorCoordIds.has(`${id}-coord-uncontrolled-n3-lon-${index}`) &&
                    row?.coordinate?.uncontrolled?.[2]?.lon
                      ? 'неверный формат'
                      : !row?.coordinate?.uncontrolled?.[2]?.lon && row?.coordinate?.uncontrolled?.[2]?.lat
                      ? 'введите долготу'
                      : ''
                  }
                  {...inputCoordProps}
                />
              </div>
              {/* Трек*/}
              <div className="cell">
                <Input
                  id={`${id}-track-${index}`}
                  value={curentTrack}
                  onChange={(event) => isUpdate(id)}
                  onBlur={(event) => {
                    updateErrorIdsAndHandleFieldChange(event, row, index, setCopySource);
                    const changes = trackChange(modifiedRailChains, id, 'Трек', oldTrack, event?.target?.value, nameRC);
                    if (changes) handleModify(id);
                  }}
                  {...inputTrakProps}
                  onFocus={() => {
                    removeErrorId(id);
                  }}
                  messageError={errorIds.includes(id) ? 'Координаты в поле трек введены некорректно' : ''}
                />
              </div>
              <div className="cell cell-delete">
                <CustomButtonDelete
                  onClick={() => {
                    setConfirmDelete(true);
                    openModal();
                    setIdRcForDelete(id);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
      {/* Модальное окно */}
      <Modal width="50%" height="60%" isOpen={isOpen} onClose={closeModal}>
        {confirmDelete && (
          <CustomContainer>
            <ConfirmationPage
              onConfirm={deleteRailChains}
              onCancel={closeModal}
              confirmButtonText={'Подтвердить'}
              cancelButtonText={'Отмена'}
              confirmText={`Вы уверены, что хотите удалить РЦ ${idRcForDelete}?`}
              btnWidth="var(--sadr-font-size-280)"
            />
          </CustomContainer>
        )}
        {successModal && (
          <CustomContainer>
            <ConfirmationPage
              onConfirm={() => {
                setSuccessModal(false);
                closeModal();
              }}
              confirmText={` РЦ id ${idRcForDelete} успешно удалено!`}
              addNew="Закрыть"
              btnWidth="var(--sadr-font-size-280)"
            />
          </CustomContainer>
        )}
      </Modal>
    </div>
  );
}

export default RailChainsTable;
