import { React, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import '../../styles/TableForUipps.css';
import Table from './Table';
import Modal from '../Modal/Modal';
import CustomButton from '../CustomElements/CustomButton';
import AddUipps from '../UIPPS/AddUipps';
import Uipps from '../UIPPS/Uipps';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { initUippsFromServer } from '../../redux/actions/uipps.action';
import useInfiniteScroll from '../../customHook/useInfiniteScroll';
import { useRole } from '../../customHook/useRole';

function TableForUips({ searchInput, formData }) {
  // Получение данных устройств из Redux store
  const uipps = useSelector((state) => state.uipps);
  const [uippsId, setUippsId] = useState();

  const isMaster = useRole('master');

  // Состояния для отображения компонентов и модального окна
  const [isOpen, setIsOpen] = useState(false);
  const [newUippsModal, setNewUippsModal] = useState(false);
  const [openUippsModal, setOpenUippsModal] = useState(false);

  const observer = useRef();
  const bottomBoundaryRef = useRef(null);

  // //Создаём бесконечный скролл с помощью кастгомного хука
  useInfiniteScroll(formData, uipps, observer, bottomBoundaryRef, initUippsFromServer);

  // Открыть модалку для добавления нового устройства
  const addNewUipps = () => {
    setNewUippsModal(true);
    setIsOpen(true);
  };

  // Открыть модалку для просмотра устройства
  const openUipps = (id) => {
    setUippsId(id);
    setOpenUippsModal(true);
    setIsOpen(true);
  };

  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
    setNewUippsModal(false);
    setOpenUippsModal(false);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID УИППС',
        accessor: 'id',
        Cell: ({ cell: { value } }) => (
          <div className="table-for-uipps_cell_id number-cell">
            <img className="cell-id-img_for-table" src="/image/case.svg" alt="Иконка" />{' '}
            <span style={{ verticalAlign: 'middle' }}>{value}</span>
          </div>
        ),
      },

      {
        Header: 'Дорога',
        accessor: 'road',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Дирекция',
        accessor: 'directorate',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Подразделение',
        accessor: 'department',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Тип ПС',
        accessor: 'train_type',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: '№ ПС',
        accessor: 'train_number',
        Cell: ({ value }) => <div className="number-cell">{defaultCellRender(value)}</div>,
      },
      {
        Header: 'Ответственный',
        accessor: 'responsible_id',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'В работе',
        accessor: 'online', // Используем 'online' в качестве ключа доступа
        Cell: ({ cell: { value } }) =>
          value ? (
            <img src="/image/status_uips_work.svg" alt="Да" />
          ) : (
            <img src="/image/status_uips_dont_work.svg" alt="Нет" />
          ),
        sortType: (rowA, rowB) => {
          // Значения уже являются булевыми, поэтому мы можем сравнивать их напрямую
          const statusA = rowA.original.online;
          const statusB = rowB.original.online;

          // Сравниваем булевы значения
          if (statusA === statusB) {
            return 0; // Статусы равны, порядок не меняем
          }
          return statusA ? -1 : 1; // Работающая бригада (true) идет первой, не работающая (false) - после
        },
      },
      {
        Header: 'Станция/Перегон',
        accessor: 'station',
        Cell: ({ value }) => defaultCellRender(value),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uipps]
  );

  return (
    <div className="table-for-devices_or_uipps_container">
      <Table
        data={uipps}
        columns={columns}
        searchInput={searchInput}
        open={openUipps}
        showGlobalSearch={false}
        bottomBoundaryRef={bottomBoundaryRef}
        arrLength={uipps.length}
      />

      <div className={`table-for-uipps_btn-container ${isMaster ? 'padding-10' : ''}`}>
        {!isMaster && <CustomButton text="+ Добавить новый УИППС" onClick={addNewUipps} />}
      </div>
      {/* containerSelector={'.table-for-devices_or_uipps_container'} */}
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
        {/* Компонент для добавления нового устройства */}
        {newUippsModal && <AddUipps closeModal={closeModal} />}
        {/* Компонент для просмотра устройства */}
        {openUippsModal && (
          <Uipps closeModal={closeModal} uippsId={uippsId} setCurrentUipps={setUippsId} formData={formData} />
        )}
      </Modal>
    </div>
  );
}

export default TableForUips;
