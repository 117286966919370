
import { useSelector } from 'react-redux';
import Selector from '../CustomElements/Selector';
import Input from '../CustomElements/Input';
import DateSelector from '../CustomElements/DateSelector';
import Accordeon from '../CustomElements/Accordeon';
import useOptionsLoader from '../../customHook/useOptionsLoader';
import { handleUniversalChange, transformOptions } from '../../utils/formUtils';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesFromServer,
  initOptionsEmployeesFromServer,
} from '../../redux/actions/options.action';
import '../../styles/FilterForDevices.css';

function FilterForDevices({ formData, setFormData, showBtnReset, resetFilters, t }) {
  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);

  // Применяем функцию transformOptions к массиву optionsForSelect.employees
  const transformedOptions = transformOptions(optionsForSelect?.workers);

  // хук для запроса опций для дирекций и контроля селекта
  const messageForSelectDirectorates = t?.messageForSelectDirectorates || 'Для выбора дирекции выберите дорогу';
  const directoratesControl = useOptionsLoader(
    formData,
    setFormData,
    ['directorate_id', 'department_id', 'responsible'],
    'road_id',
    initOptionsDirectoratesFromServer,
    messageForSelectDirectorates
  );

  // хук для запроса опций для подразделений и контроля селекта
  const messageForSelectDepartments = t?.messageForSelectDepartments || 'Для выбора подразделения выберите дирекцию';
  const departmentsControl = useOptionsLoader(
    formData,
    setFormData,
    ['department_id', 'responsible'],
    'directorate_id',
    initDepartmenOptionsFromServer,
    messageForSelectDepartments
  );

  // хук для запроса опций для ответственных и контроля селекта
  const messageForSelectResponsible =
    t?.messageForSelectResponsible || 'Для выбора ответственного выберите подразделение';
  const responsibleControl = useOptionsLoader(
    formData,
    setFormData,
    ['responsible'],
    'department_id',
    initOptionsEmployeesFromServer,
    messageForSelectResponsible
  );

  return (
    <Accordeon
      header={t?.filterTitle || 'УЧЕТ УСТРОЙСТВ'}
      hideAbove={1650}
      qParamName={'device_acc'}
    >
      <button
        type="button"
        className={`accordeon__reset-filters ${showBtnReset && 'accordeon__reset-filters_visible'}`}
        onClick={resetFilters}
      >
        <span className="accordeon__reset-filters_text">{t?.buttonResetFilters || 'Сбросить все фильтры'}</span>
        <img className="accordeon__reset-filters_icon" src="/image/close.svg" alt="" />
      </button>

      <form className="accordeon__form" id="" autoComplete="off">
        <Input
          label={t?.labelDeviceId || 'ID устройства'}
          placeholder={t?.placeholderDeviceId || 'Введите ID устройства'}
          onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
          value={formData.id}
          name="id"
        />
        <Selector
          // исключаем уиппс из опций
          options={optionsForSelect?.deviceTypes?.filter((item) => item?.value !== 4)}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'type_id', selectedOption, 'select');
          }}
          placeholder={t?.placeholderDeviceType || 'Выберите тип устройства'}
          label={t?.labelDeviceType || 'Тип устройства'}
          value={
            formData.type_id ? optionsForSelect.deviceTypes?.find((option) => option.value === formData.type_id) : null
          }
          name="type_id"
          isMulti={false}
        />
        <Selector
          label={t?.labelDeviceCondition || 'Состояние устройства'}
          options={optionsForSelect?.сondition.filter((option) => option.value !== undefined)}
          onChange={(selectedOption) =>
            handleUniversalChange(formData, setFormData, null, 'broken', selectedOption, 'select')
          }
          value={
            formData.broken !== null && formData.broken !== undefined && formData.broken !== ''
              ? optionsForSelect?.сondition?.find((option) => option.value === formData.broken)
              : null // Если значение не задано, возвращаем null
          }
          placeholder={t?.placeholderDeviceCondition || 'Выберите состояние'}
          isMulti={false}
          name="broken"
        />
        <Selector
          options={optionsForSelect.roads}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'road_id', selectedOption, 'select');
          }}
          placeholder={t?.placeholderRoad || 'Выберите дорогу'}
          isMulti={false}
          label={t?.labelRoad || 'Дорога'}
          value={formData.road_id ? optionsForSelect?.roads?.find((option) => option.value === formData.road_id) : null}
          name="road_id"
        />
        <Selector
          options={optionsForSelect?.directorates}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'directorate_id', selectedOption, 'select');
          }}
          placeholder={t?.placeholderDirectorate || 'Выберите дирекцию'}
          label={t?.labelDirectorate || 'Дирекция'}
          value={
            formData.directorate_id
              ? optionsForSelect?.directorates?.find((option) => option.value === formData.directorate_id)
              : null
          }
          isMulti={false}
          name="directorate_id"
          isDisabled={directoratesControl?.isSelectorDisabled}
          message={directoratesControl?.message}
          customClick={directoratesControl?.onSelectorChange}
        />
        <Selector
          options={optionsForSelect.departments}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'department_id', selectedOption, 'select');
          }}
          placeholder={t?.placeholderDepartment || 'Выберите № подразделения'}
          isMulti={false}
          label={t?.labelDepartment || 'Подразделение'}
          value={
            formData.department_id
              ? optionsForSelect?.departments?.find((option) => option.value === formData.department_id)
              : null
          }
          name="department_id"
          isDisabled={departmentsControl?.isSelectorDisabled}
          message={departmentsControl?.message}
          customClick={departmentsControl?.onSelectorChange}
        />
        <Selector
          options={transformedOptions}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, 'responsible', selectedOption, 'select');
          }}
          placeholder={t?.placeholderResponsible || 'Выберите № ответственного'}
          isMulti={false}
          label={t?.labelResponsible || 'Табельный номер ответственного'}
          value={
            formData?.responsible ? transformedOptions?.find((option) => option.value === formData.responsible) : null
          }
          name="responsible"
          isDisabled={responsibleControl?.isSelectorDisabled}
          message={responsibleControl?.message}
          customClick={responsibleControl?.onSelectorChange}
        />
        {/* сейчас не требуется, но пока оставил на всякий случай */}
        {/* <Selector
            options={optionsForSelect.sections}
            onChange={(selectedOption) => {
              handleUniversalChange(formData, setFormData, null, 'section_id', selectedOption, 'select');
            }}
            placeholder="Выберите участок"
            isMulti={false}
            label="Производственный участок"
            value={
              formData.section_id
                ? optionsForSelect?.sections?.find((option) => option.value === formData.section_id)
                : null
            }
            name="section_id"
          /> */}
        <Selector
          options={optionsForSelect.deviceStatuses}
          onChange={(selectedOption) =>
            handleUniversalChange(formData, setFormData, null, 'status_id', selectedOption, 'select')
          }
          placeholder={t?.placeholderDeviceStatus || 'Выберите статус'}
          isMulti={false}
          label={t?.labelDeviceStatus || 'Статус'}
          value={
            formData.status_id
              ? optionsForSelect?.deviceStatuses?.find((option) => option.value === formData.status_id)
              : null
          }
          name="status_id"
        />

        <DateSelector
          selectedDate={formData.receive_date}
          setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'receive_date', date, 'date')}
          label={t?.labelReceiveDate || 'Дата приемки'}
          name="receive_date"
          placeholder={t?.placeholderReceiveDate || 'Выберите дату приемки'}
        />
      </form>
    </Accordeon>
  );
}

export default FilterForDevices;
