import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/DeviceArchiveStatus.css';
import TableMap from '../Table/TableMap';
import CustomTitle from '../CustomElements/CustomTitle';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import CustomButton from '../CustomElements/CustomButton';
import Modal from '../Modal/Modal';

import { initStatusesFromServer } from '../../redux/actions/statuses.action';
import { useRole } from '../../customHook/useRole';

//компонент для отображения вкладки Архив на станице устройства

function DeviceArchiveStatus({ currentDevice, setActiveButton, activeButton, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  //проверяем роли пользователя
  const isMaster = useRole('master');

  //получакм массив статаусов Хранение из стора
  const { archiveStatuses } = useSelector((state) => state?.statuses);

  //поолучаем статус текущего устройства
  const status = currentDevice?.status;
  const [disabledBtn] = useState(!(status === 'Списан'));

  // Состояние, указывающее открыто ли модальное окно
  const [isOpen, setIsOpen] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState({
    value: 'Все действия',
    label: 'Все действия',
  });
  const [selectedDateSort, setSelectedDateSort] = useState({
    value: 'Сначала новые',
    label: 'Сначала новые',
  });

  const handleButtonClick = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);
  const handlerSubmit = () => {
    closeModal();
  };

  // Инициализации статусов с сервера. принимает id устройства и тип статусов
  useEffect(() => {
    dispatch(initStatusesFromServer(currentDevice.id, 'archive', selectedStatus, selectedDateSort));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus, selectedDateSort]);

  return (
    <>
      {/* Таблица со статусами */}
      {archiveStatuses && (
        <TableMap
          isBtn={false}
          data={archiveStatuses}
          handleButtonClick={handleButtonClick}
          currentDevice={currentDevice}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          archive={true}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          selectedDateSort={selectedDateSort}
          setSelectedDateSort={setSelectedDateSort}
          t={t}
        />
      )}
      {/* Блок с кнопками */}
      {!(isMaster) && (
        <div className="device-repair-status_button-container">
          <CustomButton
            className="device-repair-status_button"
            text="Вернуть на хранение"
            colorText="#FFFFF"
            onClick={handleButtonClick}
            color={disabledBtn ? '#ADADAD' : ''}
            // disabled={disabledBtn}
            disabled={true}
          />
        </div>
      )}
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
        <CustomTitle titleText="ИЗМЕНЕНИЕ ДАННЫХ" deviceType={currentDevice.type} deviceId={currentDevice.id} />
        <ConfirmationPage
          confirmText={'Вы уверены, что хотите вернуть УИППС на хранение?'}
          confirmButtonText="Вернуть на хранение"
          cancelButtonText="Отменить"
          onConfirm={handlerSubmit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={closeModal} // Функция для закрытия модального окна или отмены действия
        />
      </Modal>
    </>
  );
}

export default DeviceArchiveStatus;
