import { React, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/TableForArchive.css';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import Table from '../Table/Table';
import { convertMillisecondsToDate } from '../../utils/convertToDate';
import { useSelector } from 'react-redux';

function TableForArchive() {
  let navigate = useNavigate();

  // Получение данных о бригадах из Redux store
  const { archiveSession } = useSelector((state) => state.archive);

  const handleRowClick = (value, row) => {
    navigate(`/archive/${row._id}`);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'ID ИУР',
        accessor: 'brigadeId',
        Cell: ({ cell: { value } }) => (
          <div className="table-for-cell-id">
            <span className="cell-id-icon"></span>
            <span className="cell-id-value">{value}</span>
          </div>
        ),
      },
      {
        Header: 'Начало',
        accessor: 'start',
        Cell: ({ value }) => defaultCellRender(convertMillisecondsToDate(value)),
      },
      {
        Header: 'Конец',
        accessor: 'finish',
        Cell: ({ value }) => defaultCellRender(convertMillisecondsToDate(value)),
      },
      {
        Header: 'Дорога',
        accessor: 'road',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Подразделение',
        accessor: 'department',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Станция/перегон',
        accessor: 'station',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Объект',
        accessor: 'obj',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'РЦ',
        accessor: 'railName',
        Cell: ({ value }) => defaultCellRender(value),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [archiveSession]
  );

  return (
    <div className="table-for-archive_container">
      {/* Компонент таблицы */}
      <Table
        data={archiveSession}
        columns={columns}
        showGlobalSearch={true}
        open={handleRowClick}
        text="Введите параметры фильтра"
      />
    </div>
  );
}

export default TableForArchive;
