import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import '../../styles/Navbar.css';
import { useAuthUser } from 'react-auth-kit';
import { editLanguage } from '../../redux/actions/language.actions';
import _ from 'lodash';
import useTranslations from '../../customHook/useTranslations';
import translations from '../../content/Navbar/Navbar.json';

function toArr(arr) {
  return Array.isArray(arr) ? arr : [arr];
}

function Navbar(props) {
  const currentLanguage = useSelector((state) => state.language);
  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);
  const menu = [
    {
      link: '/devices',
      role: ['admin', 'engineerInfosignal', 'master', 'dispatcher'],
      src: '/image/menu_1.svg',
      title: t?.deviceManagement || 'Учет устройств',
      key: 23232,
    },
    {
      link: '/brigades',
      role: ['user', 'admin', 'customer', 'engineerInfosignal', 'master', 'dispatcher'],
      src: '/image/menu_2.svg',
      title: t?.brigadeManagement || 'Управление бригадами',
      key: 23234,
    },
    {
      link: '/uipps',
      role: ['admin', 'engineerInfosignal', 'master', 'dispatcher'],
      src: '/image/menu_3.svg',
      title: t?.uippsControl || 'Контроль УИППС',
      key: 23236,
    },
    {
      link: '/documentation',
      role: ['admin', 'customer', 'engineerInfosignal', 'master', 'dispatcher'],
      src: '/image/menu_4.svg',
      title: t?.reportingDocumentation || 'Отчетная документация',
      key: 23238,
    },
    {
      link: '/archive',
      role: ['admin', 'engineerInfosignal', 'master', 'dispatcher'],
      src: '/image/menu_5.svg',
      title: t?.archive || 'Архив',
      key: 23230,
    },

    {
      link: '/infocards',
      role: ['admin', 'engineerInfosignal', 'master', 'dispatcher'],
      src: '/image/menu_6.svg',
      title: t?.infoCards || 'Информационные карты',
      key: 23240,
    },
    {
      link: '/files',
      role: ['admin', 'engineerInfosignal', 'master', 'dispatcher'],
      src: '/image/file.svg',
      title: t?.files || 'Файлы',
      key: 23241,
    },

    {
      link: '/reports',
      role: ['reports'],
      src: '/image/menu_4.svg',
      title: t?.reports || 'Отчеты',
      key: 81,
    },
    {
      link: '/kuobz',
      role: ['engineer'],
      src: '/image/kuobz.svg',
      title: t?.kuobzEditing || 'Редактирование КУОБЗ',
      key: 6,
    },
    {
      link: '/administrate',
      role: ['admin', 'engineerInfosignal', 'master', 'dispatcher'],
      src: '/image/menu_7.svg',
      title: t?.dataAdministration || 'Администрирование данных',
      key: 191,
    },
    {
      link: '/development',
      exactRole: ['engineer', 'admin'],
      src: '/image/seting.svg',
      title: t?.testData || 'Тестовые данные',
      key: 7,
    },
    {
      link: '/rails',
      exactRole: ['engineer', 'admin'],
      src: '/image/map.svg',
      title: t?.map || 'Карта',
      key: 8,
    },
  ];

  const dispatch = useDispatch();
  const user = useAuthUser()();
  const loc = useLocation();
  const [currentLang, setCurrentLang] = useState(currentLanguage);

  const menuItems = useMemo(() => {
    const currentRoles = toArr(user?.role);
    return menu
      .filter((x) => {
        if (x.role) {
          const roles = toArr(x.role);
          // Если среди привилегий пользователя нет ни одной требуемой
          if (!_.intersection(roles, currentRoles).length) return false;
        }
        if (x.exactRole) {
          const roles = toArr(x.exactRole);
          // Если у пользователя нет хотя бы одной из требуемых ролей
          if (roles.some((x) => !currentRoles.includes(x))) return false;
        }
        return true;
      })
      .map((x) => ({
        src: x.src,
        title: x.title,
        key: x.key,
        to: {
          pathname: x.link,
          search: loc.search,
          hash: loc.hash,
        },
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc.search, loc.hash, ...toArr(user?.role), t]);

  // Нет смысла показывать только одну кнопку навигации
  if (menuItems.length < 2) return <></>;

  return (
    <nav className="nav-container">
      <ul className="navbar__list">
        {menuItems.map((li) => (
          <NavLink to={li.to} key={li.key}>
            <li
              className={`navbar__item ${
                loc.pathname === li.to.pathname || (li.to.pathname === '/documentation' && loc.pathname === '/reports')
                  ? 'active'
                  : ''
              }`}
            >
              <img className="navbar__icon" src={li.src} alt="" />
              <span className="navbar__text">{li.title}</span>
            </li>
          </NavLink>
        ))}
      </ul>
      {process.env.REACT_APP_LANG && (
        <div
          onClick={() => {
            setCurrentLang((prevLang) => {
              const newLanguage = prevLang === 'RU' ? 'RS' : 'RU';
              dispatch(editLanguage(newLanguage));
              return newLanguage;
            });
          }}
          className="switch-lang"
          style={{ marginTop: 'auto' }}
        >
          <div className="switch-circle-lang">{currentLang}</div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
