/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, useRowState } from 'react-table';
import '../../styles/Table.css';
import HeaderForObject from '../CustomElements/HeaderForObject';
import useTableEdit from './useTableEdit';
import { useDispatch, useSelector } from 'react-redux';
import { loadRequest } from '../../redux/actions/requestLoader.action';
import translations from '../../content/Error/error.json';
import useTranslations from '../../customHook/useTranslations';

function Table({
  data,
  columns,
  searchInput,
  open,
  hideHeader,
  showGlobalSearch,
  viewHeader,
  getRowProps,
  radius,
  colorTR,
  text,
  isEditing,
  changes,
  setChanges,
  hiddenDiv = false,
  highlightRowIndex,
  getRowId,
  className,
  fixFirstColumn,
  bottomBoundaryRef,
  arrLength,
}) {
  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);
  const dispatch = useDispatch();
  const [rowsLength, setRowsLength] = useState(0);
  const [filterInput, setFilterInput] = useState(''); // Состояние для хранения значения глобального фильтра
  const [selectedRow, setSelectedRow] = useState(null); // Состояние для индекса выбранной строки
  const [localLoading, setLocalLoading] = useState(true);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state } = useTable(
    {
      getRowId,
      columns,
      data: data ? data : [],
      setChanges,
      // Не сбрасываем состояние при изменении источника данных
      autoResetRowState: false,
      autoResetSortBy: false,
      initialCellStateAccessor: (cell) => ({
        value: cell?.column?.accessor?.(cell?.row?.original),
      }),
      useControlledState: (s) => ({
        ...s,
        isEditing,
        changes,
      }),
    },
    useFilters, // Используем хук для фильтрации
    useGlobalFilter, // Хук для глобальной фильтрации
    useSortBy, // Используем хук для сортировки
    useRowState, // состояния строк (нужно для редактирования)
    useTableEdit // для редактирования
  );
  const requestLoader = useSelector((state) => state.requestLoader);
  const canSendRequest = useSelector((state) => state.filterConfig.canSendRequest);

  // Функция для обработки клика по ячейке
  const handleCellClick = (index, value, row) => {
    if (open) {
      setSelectedRow(index); // Устанавливаем выбранную строку
      open(value, row); // Вызываем функцию open с значением ячейки
    }
  };

  // Эффект для применения глобального фильтра
  const { globalFilter } = state;
  useEffect(() => {
    const actualFilterValue = showGlobalSearch ? filterInput : searchInput;
    // Применяем сохраненное значение фильтра
    setGlobalFilter(actualFilterValue);
  }, [filterInput, data, setGlobalFilter, searchInput, showGlobalSearch]);

  useEffect(() => {
    const actualFilterValue = showGlobalSearch ? filterInput : searchInput;
    if (actualFilterValue && !globalFilter) {
      setGlobalFilter(actualFilterValue);
    }
  });

  useEffect(() => {
    if (rowsLength !== rows?.length) {
      setLocalLoading(false); // Установили локальное состояние загрузки в 'false', если данные существуют
      dispatch(loadRequest(false)); // Обновили глобальное состояние загрузки
      setRowsLength(rows?.length);
    }
  }, [dispatch, data, rows]);

  // Ref для хранения ссылок на все рендеренные строки
  const rowRefs = useRef([]);

  useEffect(() => {
    // Находим индекс подсвеченной строки
    const highlightedRowIndex = rows.findIndex((row) => Number(row.id) === highlightRowIndex);
    // Если строка найдена и ref существует, прокручиваем к ней
    if (highlightedRowIndex >= 0 && rowRefs.current[highlightedRowIndex]) {
      rowRefs.current[highlightedRowIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    if (requestLoader && canSendRequest) {
      setLocalLoading(true);
    }
  }, [highlightRowIndex, rows, requestLoader, canSendRequest]);
  return (
    <>
      {showGlobalSearch && (
        <HeaderForObject
          searchInput={filterInput}
          onFilterChange={(e) => {
            setFilterInput(e?.target?.value);
          }}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      {!hiddenDiv && (
        <div
          id="table-fixer"
          style={{
            minHeight: 'var(--sadr-font-size-20)',
            backgroundColor: 'var(--sadr-background-secondary)',
            borderTopLeftRadius: 'var(--sadr-font-size-12)',
            borderTopRightRadius: 'var(--sadr-font-size-12)',
          }}
        ></div>
      )}
      {viewHeader && <h3 className="table_title">{viewHeader}</h3>}
      <div className="table_container">
        <table
          {...getTableProps()}
          className={`custom-table ${className ? className : ''} ${
            bottomBoundaryRef && arrLength > 20 ? 'table__hiden-boundary' : ''
          }`}
        >
          {/* <table {...getTableProps()} className={`custom-table ${className ? className : ''}`}> */}
          {!hideHeader && ( // Условие для отображения шапки
            <thead>
              {headerGroups.map((headerGroup) => {
                return (
                  <tr {...headerGroup.getHeaderGroupProps()} className="table_header">
                    {headerGroup.headers.map((column, columnIndex) => {
                      const thProps = {
                        ...column.getHeaderProps(column.getSortByToggleProps()),
                        className: '', // ваш класс, если он нужен
                      };

                      return (
                        <th {...thProps} className={fixFirstColumn && columnIndex === 0 ? 'sticky-first-header' : ''}>
                          {column?.render('Header')}
                          <span>{column?.isSorted ? (column?.isSortedDesc ? ' ↓' : ' ↑') : ''}</span>
                        </th>
                      );
                    })}
                  </tr>
                );
              })}
            </thead>
          )}
          <tbody {...getTableBodyProps()}>
            {rows?.length > 0 ? (
              rows?.map((row, rowIndex) => {
                prepareRow(row);
                // Первичная проверка на подсветку строки по индексу
                const isHighlightRow = rowIndex === highlightRowIndex;
                // Условие стиля на основе данных с учетом isHighlightRow
                const rowStyle = isHighlightRow
                  ? { backgroundColor: '#c1ffcf' } // Цвет подсветки выбранной строки
                  : colorTR
                  ? row.original.alertType === 5 || row.original.alertType === 2
                    ? { backgroundColor: '#EE9D9D' } // Красный фон для alertType 5 или 2
                    : { backgroundColor: '#F2DB89' } // Желтый фон для других значений alertType
                  : {}; // Без стиля, если colorTR ложно
                // Проверяем, выбрана ли данная строка
                const isRowSelected = rowIndex === selectedRow; // Проверяем, выбрана ли данная строка
                return (
                  <tr
                    {...row.getRowProps()}
                    key={row.id}
                    ref={(el) => (rowRefs.current[rowIndex] = el)}
                    style={rowStyle} // Применение стиля к строке
                    className={`table_row ${isRowSelected ? 'selected-tr' : ''}`}
                    onClick={() => handleCellClick(rowIndex, row.cells[0].value, row?.original)} // Добавляем обработчик клика
                  >
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${className ? className : ''} ${
                            fixFirstColumn && cellIndex === 0 ? 'sticky-first-column' : ''
                          }`}
                        >
                          {/* {cellValue !== null && cellValue !== undefined && cellValue !== '' ? cell.render('Cell') : '-'} */}
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              }) // Рендер строки с сообщением об отсутствии данных
            ) : (
              <tr>
                <td colSpan={columns.length} className="table-no-data">
                  {text ? text : t?.noData}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* Если есть возможность подгрузить данные (bottomBoundaryRef) то рисуется невидимый элемент, который попав в область видимости вызывает подгрузку новых данных */}
        {/* При подгрузке рисуется лоадер на время подгрузки данных. Если нет возможности подгрузить данные (bottomBoundaryRef) то никаких действий */}
        {bottomBoundaryRef && arrLength > 40 ? (
          <span ref={bottomBoundaryRef} />
        ) : (
          localLoading && requestLoader && <p>Loading...</p>
        )}
        <div className="table-gradient"></div>
      </div>
    </>
  );
}

export default Table;
