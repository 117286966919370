/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from 'react';
import '../../styles/Accordeon.css';
import useWindowWidth from '../../customHook/useWindowWidth';
import useSearchParam from '../../customHook/useSearchParam';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterState } from '../../redux/actions/filterState.action';

const closed = 'closed';

/**
 * Аккордеон - панель с 2 фазами: сжатая (виден только заголовок), расширенная (виден весь контент)
 * @param header - Заголовок панели
 * @param children - контент аккордеона
 * @param hideAbove - кол-во пикселей экрана, после которого кнопка скрывания панели исчезает.
 * @param qParamName - Опциональный параметр для сохранения статуса окна
 * @param hdrClass - Опциональный CSS класс для заголовка
 */
export default function Accordeon({ header, children, hideAbove, qParamName, hdrClass, formData, resetFilters }) {
  const dispatch = useDispatch();
  const filtersState = useSelector((state) => state.filterState);

  if (!qParamName) console.error('You should pass a query param name for proper work');
  const [openState, setOpenState] = useSearchParam(qParamName, '');
  const { width } = useWindowWidth();
  const onClick = useCallback(
    (close) => {
      setOpenState(close ? closed : '');
    },
    [setOpenState]
  );
  const collapsed = useMemo(() => openState === closed, [openState]);
  const canShow = useMemo(() => {
    // Не задали значений, чтобы кнопка пряталась, показываем всегда
    if (!Number.isFinite(hideAbove) || hideAbove < 0) return true;
    return width < hideAbove;
  }, [hideAbove, width]);

  const filterFlag = formData && !filtersState?.filter && Object.values(formData).some((value) => value);
  useEffect(() => {
    if (filterFlag) resetFilters();
  }, [filtersState]);

  useEffect(() => {
    if (filterFlag && !filtersState?.blockFlag) {
      dispatch(
        setFilterState({
          search: false,
          filter: true,
          blockFlag: false,
        })
      );
    }
  }, [formData]);

  return (
    <div className={`accorderon-container ${collapsed ? 'collapsed' : ''} d-flex flex-column`}>
      <div className={`accordeon-hdr d-flex ${collapsed ? 'flex-column' : 'flex-row flex-nowrap'}`}>
        {canShow && (
          <button
            className={`accordeon-expand ${collapsed ? 'open' : ''}`}
            onClick={() => onClick(collapsed ? false : true)}
          >
            <img src="/image/angle-left.svg" alt="Иконка" style={{ opacity: 1 }} />
          </button>
        )}
        <div className={`${collapsed ? 'accorderon-title-collapsed' : 'accorderon-title ' + hdrClass} `}>{header}</div>
      </div>
      {!collapsed && children}
    </div>
  );
}
