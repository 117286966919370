/* eslint-disable react-hooks/exhaustive-deps */
import { React, useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/Kuobzes.css';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import EditKuobz from './EditKuobz';
import AddKuobz from './AddKuobz';
import CustomButton from '../CustomElements/CustomButton';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import { clearKuobzes, initKuobzesFromServer } from '../../redux/actions/kuobz.action';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { useRole } from '../../customHook/useRole';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import { emitResetMesh } from '../../redux/actions/brigades.action';
import { listenResetMesh } from '../../redux/actions/brigades.action';
import ResetMesh from '../Brigades/ResetMesh';
import translations from '../../content/PagesContent/DevicePages/devicePageTranslations.json';
import useTranslations from '../../customHook/useTranslations';
import HeaderForObject from '../CustomElements/HeaderForObject';
import { clearFilterConfig } from '../../redux/actions/filterConfig.action';
import useInfiniteScroll from '../../customHook/useInfiniteScroll';

function renderNumber(value) {
  return <div className="number-cell">{defaultCellRender(value)}</div>;
}

function Kuobzes(props) {
  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // Получение данных устройств из Redux store
  const kuobzes = useSelector((state) => state.kuobzes);
  const canEdit = useRole('edit');

  // Состояния для отображения компонентов и модального окна
  const [isOpen, setIsOpen] = useState(false);
  const [newKuobzModal, setNewKuobzModal] = useState(false);
  const [editKuobzModal, setEditKuobzModal] = useState(false);
  const [currentKuobz, setCurrentKuobz] = useState();
  const [resetMeshModal, setResetMeshModal] = useState(false);

  // открытие модалки для сброса mersh
  const handleButtonClick = (event, device) => {
    event.stopPropagation();
    setCurrentKuobz(device);
    setResetMeshModal(true);
    setIsOpen(true);
  };

  //Создаём бесконечный скролл с помощью кастгомного хука
  const observer = useRef();
  const bottomBoundaryRef = useRef(null);
  useInfiniteScroll(null, kuobzes, observer, bottomBoundaryRef, initKuobzesFromServer);

  const [searchInput, setSearchInput] = useState('');
  const runDispathes = () => {
    dispatch(clearFilterConfig());
    dispatch(clearKuobzes());
    dispatch(initKuobzesFromServer(searchInput));
  };
  const onFilterSubmit = (event) => {
    event.preventDefault();
    runDispathes();
  };
  // сброс mesh
  const handlerConfirmResetMesh = (id) => {
    const payload = { deviceId: id };
    dispatch(emitResetMesh(payload));
  };
  useEffect(() => {
    runDispathes();
  }, []);

  useEffect(() => {
    //слушаем событие 'reset_mesh_reply'
    dispatch(listenResetMesh());
  }, [dispatch]);

  // Открыть модалку для изменения устройства
  const openKuobz = (event, item) => {
    event.stopPropagation();
    setCurrentKuobz(item);
    setEditKuobzModal(true);
    setIsOpen(true);
  };

  // Открыть модалку для добавления нового устройства
  const addNewDevice = () => {
    setNewKuobzModal(true);
    setIsOpen(true);
  };

  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
    setNewKuobzModal(false);
    setEditKuobzModal(false);
    setResetMeshModal(false);
  };

  // Колонки таблицы
  const columns = useMemo(
    () => [
      {
        Header: 'ID устройства',
        accessor: 'id',
        Cell: ({ cell: { value } }) => (
          <div className="table-for-devices_cell_id number-cell">
            <img className="cell-id-img_for-table" src="/image/case.svg" alt="Иконка" style={{ opacity: 1 }} />{' '}
            {value}
          </div>
        ),
      },
      {
        Header: 'IMEI1',
        accessor: 'imei1',
        Cell: ({ value }) => renderNumber(value),
      },
      {
        Header: 'SIM1',
        accessor: 'sim1',
        Cell: ({ value }) => renderNumber(value),
      },
      {
        Header: 'Номер SIM 1',
        accessor: 'sim1num',
        Cell: ({ value }) => renderNumber(value),
      },
      {
        Header: 'IMEI2',
        accessor: 'imei2',
        Cell: ({ value }) => renderNumber(value),
      },
      {
        Header: 'SIM2',
        accessor: 'sim2',
        Cell: ({ value }) => renderNumber(value),
      },

      {
        Header: 'Номер SIM 2',
        accessor: 'sim2num',
        Cell: ({ value }) => renderNumber(value),
      },
      ...(canEdit
        ? [
            {
              Header: 'Изменить',
              Cell: ({ row }) => (
                <div className="">
                  <CustomButtonEdit
                    onClick={(event) => {
                      openKuobz(event, row?.original);
                    }}
                  />
                </div>
              ),
            },
            {
              Header: 'Сбросить Mesh',
              Cell: ({ row }) => (
                <div className="">
                  <CustomButtonDelete
                    margin="0  var(--sadr-font-size-5) "
                    disabled={false}
                    onClick={(event) => {
                      handleButtonClick(event, row?.original);
                    }}
                  />
                </div>
              ),
            },
          ]
        : []),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kuobzes]
  );

  return (
    <div className="kuobzes">
      {/* Компонент таблицы */}
      <HeaderForObject
        searchInput={searchInput}
        onFilterChange={(e) => {
          setSearchInput(e?.target?.value);
        }}
        onFilterSubmit={onFilterSubmit}
      />
      <Table
        data={kuobzes}
        columns={columns}
        showGlobalSearch={false}
        bottomBoundaryRef={bottomBoundaryRef}
        arrLength={kuobzes.length}
      />

      <div className="kuobze_btn-container">
        {/* Кнопка для добавления нового устройства */}
        <CustomButton text="+ Добавить новый КУОБЗ" onClick={addNewDevice} />
      </div>
      {/* Модальное окно */}
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
        {/* Компонент для добавления нового устройства */}
        {newKuobzModal && <AddKuobz closeModal={closeModal} />}
        {/* Компонент для просмотра устройства */}
        {editKuobzModal && (
          <EditKuobz closeModal={closeModal} currentKuobz={currentKuobz} setCurrentKuobz={setCurrentKuobz} />
        )}
        {resetMeshModal && (
          <ResetMesh
            closeModal={closeModal}
            confirmReset={false}
            handlerConfirm={handlerConfirmResetMesh}
            device={currentKuobz}
            t={t}
          />
        )}
      </Modal>
    </div>
  );
}

export default Kuobzes;
