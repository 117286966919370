import { combineReducers } from 'redux';
import { devicesReducer } from './devices.reducer';
import { brigadesReducer } from './brigades.reducer';
import { uippsReducer } from './uipps.reducer';
import { documentationReducer } from './documentation.reducer';
import { userReducer } from './user.reducer';
import { optionsReducer } from './options.reducer';
import { statusesReducer } from './statuses.reducer';
import { errorReducer } from './error.reducer';
import { loaderReducer } from './loader.reducer';
import { archiveReducer } from './archive.reducer';
import { kuobzReducer } from './kuobz.reducer';
import { filesReducer } from './files.reducer';
import { languageReducer } from './language.reducer';
import adminChoiceReducer from './adminChoice.reducer';
import infocardReducer from './infocard.reducer';
import { filterConfigReducer } from './filterConfig.reducer';
import { requestLoaderReducer } from './requestLoader.reducer';
import { mapDataReducer } from './mapData.reducer';
import { filterStateReducer } from './filterState.reducer';

export const rootReducer = combineReducers({
  archive: archiveReducer,
  kuobzes: kuobzReducer,
  error: errorReducer,
  user: userReducer,
  mapData: mapDataReducer,
  devices: devicesReducer,
  brigadesState: brigadesReducer,
  infocards: infocardReducer,
  uipps: uippsReducer,
  filterConfig: filterConfigReducer,
  documentation: documentationReducer,
  options: optionsReducer,
  statuses: statusesReducer,
  loader: loaderReducer,
  requestLoader: requestLoaderReducer,
  files: filesReducer,
  adminChoiceData: adminChoiceReducer,
  language: languageReducer,
  filterState: filterStateReducer,
});
