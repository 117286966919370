import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../../styles/Brigades.css';

import FilterForBrigades from '../Filter/FilterForBrigades';
import TableForBrigades from '../Table/TablesForBrigades';
import MultiMarkerMap from '../Maps/MultiMarkerMap';
import HeaderForObject from '../CustomElements/HeaderForObject';

import useTranslations from '../../customHook/useTranslations';
import {
  clearBrigades,
  emitGetCoordsStart,
  emitGetCoordsStop,
  listenBrigadesCoords,
} from '../../redux/actions/brigades.action';
import { initBrigadesFromServer, listenUpdateBrigade } from '../../redux/actions/brigades.action';
import translations from '../../content/PagesContent/BrigadePages/brigadePageTranslations.json';
import { useFilterControl } from '../../customHook/useFilterControl';
import useInfiniteScroll from '../../customHook/useInfiniteScroll';

function Brigades(props) {
  const dispatch = useDispatch();

  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);
  const [isViewMap, setIsViewMap] = useState(false);
  const [isViewList, setIsViewList] = useState(true);

  // состояние для строки поиска
  const [filterInput, setFilterInput] = useState('');

  // Начальное состояние для стейта фильтрации передаем пропсами
  const initialFormData = {
    id: '',
    responsible: '',
    road_id: '',
    department_id: '',
    station_id: '',
    start: '',
    finish: '',
    device_id: '',
    directorate_id: '',
    isWorking: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData, '', setFilterInput);

  // хук для запроса данных с учетом параметров поиска фильтрации и сортировки, и контроля скрола
  const { bottomBoundaryRef, handleSearch } = useInfiniteScroll(
    formData, // Данные фильтров
    filterInput, // Строка поиска
    setFilterInput, // Функция для изменения строки поиска
    initBrigadesFromServer, // Функция для инициализации данных
    'brigades', // Redux-селектор
    resetFilters, // Сброс фильтров
    clearBrigades, // Очистка стейта
    isViewMap // Флаг для остановки запросов
  );

  useEffect(() => {
    // слушаем событие получения координат
    dispatch(listenBrigadesCoords());
    // слушаем событие получения обновленной инфы для бригад
    dispatch(listenUpdateBrigade());
  }, [dispatch]);

  return (
    <div className="brigades">
      <FilterForBrigades
        setIsViewList={setIsViewList}
        setIsViewMap={setIsViewMap}
        isViewList={isViewList}
        isViewMap={isViewMap}
        initialFormData={initialFormData}
        formData={formData}
        setFormData={setFormData}
        showBtnReset={showBtnReset}
        resetFilters={resetFilters}
        t={t}
      />
      <div className="brigades_content">
        <HeaderForObject
          searchInput={filterInput}
          onFilterChange={(e) => {
            setFilterInput(e?.target?.value);
          }}
          onFilterSubmit={handleSearch}
        />

        {isViewList && <TableForBrigades formData={formData} t={t} bottomBoundaryRef={bottomBoundaryRef} />}
        {isViewMap && (
          <MultiMarkerMap
            emitStartFunction={emitGetCoordsStart}
            listenStopFunction={emitGetCoordsStop}
            formData={formData}
            filterInput={filterInput}
            setSearchInput={setFilterInput}
            t={t}
            type='brigades'
          />
        )}
      </div>
    </div>
  );
}

export default Brigades;
