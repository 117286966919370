import { UPDATE_SORT_DATA } from '../type';

export function sortDataReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SORT_DATA: {
      return payload
    }
    default:
      return state;
  }
}
