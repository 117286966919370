import  { useState, forwardRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment-timezone';
import ru from 'date-fns/locale/ru';
import sr from 'date-fns/locale/sr'; // Импортируем сербскую локализацию
import '../../styles/DateRangePicker.css';
import '../../styles/Select.css';

const DateRangePicker = ({
  startDateField,
  endDateField,
  formData,
  setFormData,
  disabled,
  label,
  placeholder,
  width,
  height,
  name,
  topPosition,
  minDate,
  maxDate,
  message,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const customStyles = {
    width: width,
    height: height,
  };

  // Получаем текущий язык из состояния Redux
  const currentLanguage = useSelector((state) => state.language);

  // Состояние для хранения локали
  const [locale, setLocale] = useState(currentLanguage === 'RS' ? sr : ru);

  // useEffect для обновления локали при смене языка
  useEffect(() => {
    setLocale(currentLanguage === 'RS' ? sr : ru);
  }, [currentLanguage]);
  // Обработчик изменения диапазона дат
  const handleChange = (dates) => {
    const [start, end] = dates;
    setFormData({
      ...formData,
      [startDateField]: start ? start : '',
      [endDateField]: end ? end : '',
    });
  };

  // Функция для сброса выбранных дат
  const clearDates = (event) => {
    event.stopPropagation(); // Предотвращаем всплытие события
    setFormData({
      ...formData,
      [startDateField]: '', // Сброс даты начала
      [endDateField]: '', // Сброс даты окончания
    });
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="date-range-container" onClick={onClick} ref={ref}>
      {label && (
        <div className="date-selector-label" htmlFor={name}>
          {label}
        </div>
      )}
      <div style={{ position: 'relative', ...customStyles }}>
        <input
          style={customStyles}
          className="custom-input"
          value={value}
          readOnly
          placeholder={placeholder}
          disabled={disabled}
          name={name}
        />
        {!formData?.[startDateField] || !formData?.[endDateField] ? (
          <img
            src="/image/calendar.svg"
            alt="Calendar Icon"
            className="calendar-icon"
            style={{
              position: 'absolute',
              right: 'var(--sadr-font-size-10)',
              top: topPosition ? `${topPosition}` : '50%',
              transform: 'translateY(-50%)',
            }}
          />
        ) : (
          <div
            onClick={(event) => clearDates(event)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
              position: 'absolute',
              top: topPosition ? `${topPosition}` : '50%',
              right: 'var(--sadr-font-size-10)',
              transform: 'translateY(-50%)',
              padding: 'var(--sadr-font-size-8)',
            }}
          >
            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
              <path
                d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                fill={isHovered ? '#D30630' : '#000'}
              ></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  ));

  return (
    <div className={'container-select visible-select'}>
      <DatePicker
        selectsRange={true}
        startDate={formData?.[startDateField]}
        endDate={formData?.[endDateField]}
        onChange={handleChange}
        isClearable={true}
        customInput={<CustomInput />}
        disabled={disabled}
        locale={locale}
        dateFormat="dd/MM/yyyy"
        minDate={minDate} // Устанавливаем минимальную дату
        maxDate={maxDate} // Устанавливаем максимальную дату
      />
      <span className={`message-text-select ${message ? 'visible' : ''}`}>{message}</span>
    </div>
  );
};

export default DateRangePicker;
