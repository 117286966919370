import {
  ADD_IGNORE_ID,
  ADD_MAP_COORDS,
  CLEAR_MAP_DATA,
  CLEAR_MAP_IGNORES,
  EDIT_MAP_ARRAY,
  INIT_MAP_ARRAY,
  UPDATE_MAP_ARRAY,
} from '../type';
import axiosInstance from '../../config/axios.config';
import { checkIfItemInFilter, checkVisibleCoords } from '../../utils/mapUtils';

export const initMapData = (mapArray, queryString) => ({
  type: INIT_MAP_ARRAY,
  payload: {mapArray, queryString},
});

export const addBorderCoords = (coords) => ({
  type: ADD_MAP_COORDS,
  payload: coords,
});

export const editMapData = (mapData) => ({
  type: EDIT_MAP_ARRAY,
  payload: mapData,
});

export const updateMapArray = (newMapArray) => ({
  type: UPDATE_MAP_ARRAY,
  payload: newMapArray,
});

export const addNewIgnoredId = (newId) => ({
  type: ADD_IGNORE_ID,
  payload: newId,
});

export const clearMapData = () => ({
  type: CLEAR_MAP_DATA,
  payload: {
    mapBorders: {},
    mapArray: [],
    queryString: '',
  },
});

export const clearMapIgnores = () => ({
  type: CLEAR_MAP_IGNORES,
  payload: [],
});


// получение всех устройств
export const initMapDataFromServer = (queryString) => async (dispatch, getState) => {
  const uippses = getState().uipps;
  try {
    const requestTo = uippses.length > 0 ? 'devices/uipps' : 'brigades';
    const result = await axiosInstance(`/api/${requestTo}${queryString}`);
    if (result.data && result.status === 200) {
      dispatch(initMapData(result.data, queryString ));
    }
  } catch (error) {
    console.log(error);
  }
};

// Проверка координат устройства и добавление новых, если появилось новое устройство
export const lookAfterMapArray = (data, type) => async (dispatch, getState) => {
  const { ignoredIds, mapBorders, mapArray, queryString } = getState().mapData;

  if (!data || !Object.keys(data).length || !type) return;

  const dataIds = new Set(Object.keys(data).map((id) => Number(id))); // IDs из `data`

  // Создаем новый `mapArray`, оставляя только видимые элементы, которые присутствуют в `data`
  let updatedMapArray = mapArray
    .filter(
      (item) => dataIds.has(Number(item.id)) && checkVisibleCoords(mapBorders, data[item.id].lat, data[item.id].lon)
    )
    .map((item) => {
      const { lat, lon, isWorking } = data[item.id];
      return {
        ...item,
        lat,
        lon,
        type,
        isWorking,
      };
    });

  // Добавляем новые видимые элементы, которых нет в `mapArray`
  for (const [socketId, { lat, lon, isWorking }] of Object.entries(data)) {
    if (updatedMapArray.find((item) => Number(item.id) === Number(socketId))) continue;

    const visible = checkVisibleCoords(mapBorders, lat, lon);
    if (!visible) continue;

    const inFilter = await checkIfItemInFilter(socketId, type, queryString, ignoredIds);
    if (inFilter) {
      updatedMapArray.push({
        id: socketId,
        lat,
        lon,
        type,
        isWorking,
      });
    } else {
      dispatch(addNewIgnoredId(socketId));
    }
  }

  dispatch(updateMapArray(updatedMapArray));
};
