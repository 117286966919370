import { React, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import '../../styles/TableForFiles.css';
import Table from '../Table/Table';
import CustomButtonUpload from '../CustomElements/CustomButtonUpload';

import { useRole } from '../../customHook/useRole';
import { downloadFileFromServer } from '../../redux/actions/files.action';
import { newFileFromServer } from '../../redux/actions/files.action';
import { defaultCellRender } from '../../utils/formatValueForHTML';
import { dateToString } from '../../utils/convertToDate';

function TableForFiles(props) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  const isMaster = useRole('master');

  // получаем все отчеты из стора
  const files = useSelector((state) => state.files);

  const handleButtonClickAction = (file) => {
    const payload = { fileId: file?.id, fileName: file?.name };
    dispatch(downloadFileFromServer(payload));
  };

  const columns = useMemo(
    () => [
      {
        Header: '№',
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: 'Название файла',
        accessor: 'name',
        Cell: ({ value }) => <div style={{ textAlign: 'left', paddingLeft: '10px' }}>{defaultCellRender(value)}</div>,
      },
      {
        Header: 'Дата загрузки',
        accessor: 'date',
        Cell: ({ value }) => defaultCellRender(dateToString(value)),
      },
      {
        Header: 'Загрузка',
        accessor: 'link',
        disableSorting: true, // Флаг для отключения сортировки
        Cell: ({ row }) => (
          <button
            className="custom-button-edit"
            onClick={() => {
              handleButtonClickAction(row.original);
            }}
          >
            <img src="/image/download.svg" alt="Скачать" />
          </button>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files]
  );

  return (
    <div className="table-for-files-container">
      <Table data={files || []} columns={columns} showGlobalSearch={true} />
      <div className={`table-for-documentation_btn-container margin-none ${isMaster ? 'padding-10' : ''}`}>
        {!isMaster && (
          <CustomButtonUpload
            className="table-for-files-status_button-action"
            text="Добавить новый файл"
            colorText="#FFFFF"
            fileName="file"
            uploadFileFunc={newFileFromServer}
            name="name"
          />
        )}
      </div>
    </div>
  );
}

export default TableForFiles;
