import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import '../../styles/DeviceOperationStatus.css';
import CustomButton from '../CustomElements/CustomButton';
import DeviceStorageFromOperation from '../Devices/DeviceStorageFromOperation';
import EditOperationSendStatus from '../Statuses/EditOperationSendStatus';
import useModalWithStatus from '../../customHook/useModalWithStatus';
import { initStatusesFromServer } from '../../redux/actions/statuses.action';
import useDeviceStatus from '../../customHook/useDeviceStatus';
import TableMap from '../Table/TableMap';
import EditOperationSendUippsStatus from '../UIPPS/EditOperationSendUippsStatus';
import { useRole } from '../../customHook/useRole';

function DeviceOperationStatus({ currentDevice, setActiveButton, activeButton, uippsType, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  //проверяем роли пользователя
  const isMaster = useRole('master');

  //получакм массив статаусов Хранение из стора
  const { usageStatuses } = useSelector((state) => state?.statuses);
  //получаем статус текущего устройства
  const status = currentDevice?.status;
  // состояние для активации кнопки
  const [disabledBtn, setDisabledBtn] = useState(!(status === (t?.statusOperation || 'Эксплуатация')));

  const [statusId, setStatusId] = useState('');

  // хук для получения текущего статуса по id
  const currentStatus = useDeviceStatus(usageStatuses, statusId);

  // Обновляем состояние disabledBtn при изменении статуса
  useEffect(() => {
    setDisabledBtn(!(status === t?.statusOperation || 'Эксплуатация'));
  }, [status, t?.statusOperation]);

  const title = t?.deviceOperationStatusTitle || 'ИЗМЕНЕНИЕ ДАННЫХ СТАТУСА';

  // Хук для управления модальным окном статусов
  const { isOpen, activeModal, closeModal, handleButtonClick } = useModalWithStatus(
    usageStatuses,
    currentDevice,
    setStatusId,
    t
  );
  const contentData = {
    [t?.statusIssueOperation || 'Выдача']: uippsType ? (
      <EditOperationSendUippsStatus
        key={currentStatus?.id || 'new'}
        currentDevice={currentDevice}
        currentStatus={currentStatus}
        closeModal={closeModal}
        title={title}
        isEdit={true}
        t={t}
      />
    ) : (
      <EditOperationSendStatus
        currentDevice={currentDevice}
        currentStatus={currentStatus}
        closeModal={closeModal}
        title={title}
        isEdit={true}
        t={t}
      />
    ),
    [t?.statusReturnOperation || 'Возврат']: (
      <DeviceStorageFromOperation
        currentDevice={currentDevice}
        currentStatus={currentStatus}
        closeModal={closeModal}
        title={title}
        isEdit={true}
        t={t}
      />
    ),
    [t?.statusReceiveForStorage || 'Принять на хранение']: (
      <DeviceStorageFromOperation currentDevice={currentDevice} closeModal={closeModal} t={t} />
    ),
  };
  // Инициализации статусов с сервера. принимает id устройства и тип статусов
  useEffect(() => {
    dispatch(initStatusesFromServer(currentDevice.id, 'usage'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Таблица со статусами */}
      {usageStatuses && (
        <TableMap
          data={usageStatuses}
          handleButtonClick={handleButtonClick}
          currentDevice={currentDevice}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          t={t}
        />
      )}
      {/* Блок с кнопками */}
      {!isMaster && (
        <div className="device-operation-status_button-container">
          <CustomButton
            className="device-operation-status_button"
            text={t?.buttonStorage || 'Принять на хранение'}
            colorText="#FFFFF"
            onClick={() => handleButtonClick(t?.buttonStorage || 'Принять на хранение')}
            color={disabledBtn ? '#ADADAD' : ''}
            disabled={disabledBtn}
          />
        </div>
      )}
      {/* Модальное окно */}
      <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
        {contentData[activeModal]}
      </Modal>
    </>
  );
}

export default DeviceOperationStatus;
