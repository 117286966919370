/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import Markers from './Markers';
import Modal from '../Modal/Modal';
import Brigade from '../Brigades/Brigade';
import { clearBrigades, initBrigadesFromServer } from '../../redux/actions/brigades.action';

const redIcon = L.icon({
  iconUrl: '/image/redIcon.svg',
  iconSize: [24, 24],
});

const greenIcon = L.icon({
  iconUrl: '/image/greenIcon.svg',
  iconSize: [15, 15],
});

const blueIcon = L.icon({
  iconUrl: '/image/blueIcon.svg',
  iconSize: [15, 15],
});

const yellowIcon = L.icon({
  iconUrl: '/image/yellowIcon.svg',
  iconSize: [15, 15],
});

function MultiMarkerMap({ emitStartFunction, listenStopFunction, filterInput, formData, type, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // Получение данных о бригадах из Redux store
  const { brigades } = useSelector((state) => state.brigadesState);

  // Состояния для отображения компонентa и модального окна
  const [isOpen, setIsOpen] = useState(false);
  const [openBrigadeModal, setOpenBrigadeModal] = useState(false);

  // Открыть модалку для просмотра бригады
  const openBrigade = async (id) => {
    dispatch(clearBrigades());
    //получаем бригаду с сервера по id
    await dispatch(initBrigadesFromServer(`?id=${id}`));
    setOpenBrigadeModal(true);
    setIsOpen(true);
  };

  // Закрыть модальное окно
  const closeModal = () => {
    setIsOpen(false);
    setOpenBrigadeModal(false);
  };

  // Вычисляем позицию на основе первого элемента markers
  const position = useMemo(() => {
    const startPosition = [53.18442135, 50.12827981];
    return startPosition;
  }, []);

  // Функция для получения иконки
  const getIcon = (type) => {
    switch (type) {
      case 'green':
        return greenIcon;
      case 'red':
        return redIcon;
      case 'blue':
        return blueIcon;
      case 'yellow':
        return yellowIcon;
      default:
        return redIcon;
    }
  };

  //старт и прекращение прослушивания координат
  useEffect(() => {
    // Проверяем, передана ли функция emitFunctionStart
    if (emitStartFunction) {
      // Отправляем событие чтобы сервер начал слать координаты
      dispatch(emitStartFunction());
    }

    // Функция очистки
    return () => {
      // Проверяем, передана ли функция emitFunctionStop
      if (listenStopFunction) {
        // Отправляем событие чтобы сервер прекратил слать координаты
        dispatch(listenStopFunction());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MapContainer
        center={position}
        zoom={13}
        scrollWheelZoom={true}
        style={{ height: '100%', width: '100%', borderRadius: 'var(--sadr-font-size-10)' }}
        attributionControl={false}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Markers getIcon={getIcon} filterInput={filterInput} formData={formData} openBrigade={openBrigade} type={type}/>
      </MapContainer>
      {/* Модальное окно */}
      {isOpen && (
        // containerSelector={'.brigades_content'}
        <Modal isOpen={isOpen} onClose={closeModal}>
          {/* Компонент для просмотра устройства */}
          {openBrigadeModal && <Brigade closeModal={closeModal} currentBrigade={brigades?.[0]} t={t} />}
        </Modal>
      )}
    </>
  );
}

export default MultiMarkerMap;

