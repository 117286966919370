/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from 'react';
import FilterForDevices from '../Filter/FilterForDevices';
import TableForDevices from '../Table/TableForDevices';
import HeaderForObject from '../CustomElements/HeaderForObject';
import '../../styles/Devices.css';
import translations from '../../content/PagesContent/DevicePages/devicePageTranslations.json';
import useTranslations from '../../customHook/useTranslations';
import { useFilterControl } from '../../customHook/useFilterControl';
import useInfiniteScroll from '../../customHook/useInfiniteScroll';
import { clearDevices, initDevicesFromServer } from '../../redux/actions/devices.action';

function Devices(props) {
  const [type, setType] = useState();

  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);

  const [searchInput, setSearchInput] = useState('');

  // Начальное состояние для стейта фильтрации передаем пропсами
  const initialFormData = {
    id: '',
    type_id: '',
    road_id: '',
    section_id: '',
    status_id: '',
    department_id: '',
    receive_date: '',
    responsible: '',
    broken: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData, '', setSearchInput);

  // хук для запроса данных с учетом параметров поиска фильтрации и сортировки, и контроля скрола
  const { bottomBoundaryRef, handleSearch } = useInfiniteScroll(
    formData, // Данные фильтров
    searchInput, // Строка поиска
    setSearchInput, // Функция для изменения строки поиска
    initDevicesFromServer, // Функция для инициализации данных
    'devices', // Redux-селектор
    resetFilters, // Сброс фильтров
    clearDevices, // Очистка стейта
    false // Флаг для остановки запросов
  );

  return (
    <div className="devices">
      <FilterForDevices
        type={type}
        setType={setType}
        initialFormData={initialFormData}
        formData={formData}
        setFormData={setFormData}
        showBtnReset={showBtnReset}
        resetFilters={resetFilters}
        t={t}
      />
      <div className="devices_content container-fluid">
        <HeaderForObject
          searchInput={searchInput}
          onFilterChange={(e) => {
            setSearchInput(e?.target?.value);
          }}
          onFilterSubmit={handleSearch}
        />
        <TableForDevices bottomBoundaryRef={bottomBoundaryRef} searchInput={searchInput} formData={formData} t={t} />
      </div>
    </div>
  );
}

export default Devices;
