/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Marker, Tooltip, useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBorderCoords,
  clearMapData,
  clearMapIgnores,
  initMapDataFromServer,
} from '../../redux/actions/mapData.action';
import 'leaflet/dist/leaflet.css';

/**
 * Создает строку запроса на основе данных формы и границ карты.
 *
 * @param {Object} formData - Объект с данными формы.
 * @param {Object} mapBounds - Объект с координатами границ карты (_southWest и _northEast).
 * @returns {string} - Сформированная строка запроса.
 */
function createQueryString(formData, mapBounds) {
  const params = new URLSearchParams();

  // Добавляем параметры из formData
  Object.entries(formData).forEach(([key, value]) => {
    if (value) {
      // Преобразуем поля start и finish в timestamp
      if (key === 'start' || key === 'finish') {
        params.append(key, new Date(value).getTime());
      } else {
        params.append(key, value);
      }
    }
  });

  // Добавляем параметры границ карты
  if (mapBounds?._southWest && mapBounds?._northEast) {
    params.append('visibleMinLat', mapBounds._southWest.lat);
    params.append('visibleMaxLat', mapBounds._northEast.lat);
    params.append('visibleMinLon', mapBounds._southWest.lng);
    params.append('visibleMaxLon', mapBounds._northEast.lng);
  }

  return `?${params.toString()}`;
}

// Компонент для рендеринга маркеров
const Markers = ({ getIcon, filterInput, formData, openBrigade, type }) => {
  const map = useMap();
  const dispatch = useDispatch();
  // Состояние для хранения видимых маркеров
  const { mapArray } = useSelector((state) => state.mapData);
  // Локальное состояние для хранения видимых маркеров
  const [visibleMarkers, setVisibleMarkers] = useState([]);

  useEffect(() => {
    const markers = mapArray
      ?.filter((item) => item != null) // Убираем null или undefined
      ?.reduce((acc, { id, lat, lon, type, isWorking }) => {
        // Проверяем, что объект online и имеет действительные координаты
        if (lat != null && lon != null && lat !== 'nan' && lon !== 'nan' && !isNaN(lat) && !isNaN(lon)) {
          // Добавляем новый маркер в аккумулятор
          acc.push({
            id: id,
            position: [lat, lon],
            type: isWorking === 2 ? 'yellow' : 'green',
            popupContent: `${type || 'Бригада'} № ${id || 5}`,
          });
        }
        return acc;
      }, []); // Инициализируем аккумулятор пустым массивом
    setVisibleMarkers(markers);
  }, [mapArray]);

  // Колбек-функция для обновления видимых маркеров
  const updateVisibleMarkers = useCallback(() => {
    const bounds = map.getBounds();
    // Проверка введён ли id длинной 6 или не введён
    const isIdValid = formData?.id?.length === 6 || !formData?.id;
    const isValidType = formData?.device_id?.length === 6 || !formData?.device_id;

    if (isIdValid && isValidType) {
      // Очистка игнорируемых маркеров
      dispatch(clearMapIgnores());
      // Очистка маркеров и области видимости и строки запроса
      dispatch(clearMapData);
      // Добавление в стейт координат области видимости
      dispatch(addBorderCoords(bounds));
      const queryString = createQueryString(formData, bounds);
      dispatch(initMapDataFromServer(queryString));
    }
  }, [map, formData]); // Зависимости функции

  // Обновление видимых маркеров
  useEffect(() => {
    updateVisibleMarkers();
    map.on('moveend', updateVisibleMarkers);
    return () => {
      map.off('moveend', updateVisibleMarkers);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateVisibleMarkers]);

  // Рендеринг видимых маркеров
  return (
    <>
      {visibleMarkers.map((marker, index) => (
        <Marker
          key={index}
          position={marker.position}
          icon={getIcon(marker.type)}
          eventHandlers={{
            click: () => {
              if (type === 'brigades') openBrigade(Number(marker?.id));
            },
          }}
        >
          <Tooltip>{marker.popupContent}</Tooltip>
        </Marker>
      ))}
    </>
  );
};

export default Markers;
