import { EDIT_LANGUAGE } from '../type';
import { updateOptionsLabels } from '../actions/options.action';

// export const editLanguage = (payload) => ({

//   type: EDIT_LANGUAGE ,
//   payload: payload
// })

export const editLanguage = (payload) => {
  return (dispatch) => {
    // Сначала диспатчим изменение языка
    dispatch({
      type: EDIT_LANGUAGE,
      payload: payload,
    });

    // Затем обновляем лейблы в зависимости от нового языка
    dispatch(updateOptionsLabels()); // Передаем язык для обновления лейблов
  };
};
