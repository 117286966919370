import { FETCH_DATA_REQUEST, SELECT_PARAMETER } from '../type';

const adminChoiceReducer = (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case SELECT_PARAMETER:
      return {
        ...state,
        selectedName: payload.label,
        selectedParam: payload.param,
      };
    case FETCH_DATA_REQUEST:
      state.data[payload.parameter] = [];
      return {
        ...state,
        data: {
          ...state.data,
          [payload.parameter]: payload.data,
        },
      };
    default:
      return state;
  }
};

export default adminChoiceReducer;
