import { Route, Routes } from 'react-router-dom';
import { React, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Preloader from '../CustomElements/Preloader';
import useUrlNavigate from '../../customHook/useUrlNavigate';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import CustomButtonEdit from '../CustomElements/CustomButtonEdit';
import { useInfocard, useLoader } from './Common';
import ModalDelete from './ModalDelete';

import { useRole } from '../../customHook/useRole';
import { remove } from '../../redux/actions/loader.action';
import { initOptionsSectionFromServer } from '../../redux/actions/options.action';
import { initAdjacencyFromServer } from '../../redux/actions/infocards.action';
import { deleteAdjacencyFromServer } from '../../redux/actions/infocards.action';
import AdjacencyEbit from './AdjacencyEdit';

function Adjacency() {
  const dispatch = useDispatch();

  // роли пользователя
  const isAdmin = useRole('admin');
  let navigate = useUrlNavigate();

  const { station, road } = useInfocard();

  const source = useSelector((s) => s.infocards.adjacency);

  const { isLoading } = useLoader();

  useEffect(() => {
    if (Number.isFinite(station)) {
      // Запрашиваю элементы для таблицы
      dispatch(initAdjacencyFromServer(station));
      // Запрашиваю данные для селектов
      dispatch(initOptionsSectionFromServer(road));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'РЦ',
        accessor: 'name',
        Cell: ({ cell: { value } }) => {
          return <span>{value}</span>;
        },
      },
      {
        Header: 'Смежные РЦ',
        accessor: 'adjacency',
        Cell: ({ cell: { value } }) => {
          let text = [];
          if (value?.length) {
            for (let adjacency of value) {
              if (station !== adjacency?.station) {
                text.push(`${adjacency?.station_name}.${adjacency?.name}`);
              } else {
                text.push(adjacency?.name);
              }
            }
            return <span>{text.join(', ')}</span>;
          } else {
            return 'no';
          }
        },
      },
      isAdmin && {
        Header: 'Действия',
        Cell: ({ row: { original } }) => {
          return (
            <div className="d-flex flex-row flex-nowrap gap-1 justify-content-center">
              {isLoading && <Preloader isLoading />}
              {!isLoading && (
                <>
                  <CustomButtonEdit
                    onClick={() => {
                      navigate((url, root) => {
                        url.pathname = root + `/edit/${original.rail_chain}`;
                      });
                    }}
                  />
                  <CustomButtonDelete
                    onClick={() => {
                      navigate((url, root) => {
                        url.pathname = root + `/delete/${original.rail_chain}`;
                      });
                    }}
                  />
                </>
              )}
            </div>
          );
        },
      },
    ].filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, navigate, isAdmin]);

  const go2root = useCallback(() => {
    navigate((url, root) => {
      url.pathname = root;
    });
    dispatch(remove());
  }, [navigate, dispatch]);

  const [childParams, setChildParams] = useState({});

  const deletingAdjacency = useMemo(() => {
    const { adjacency } = childParams || {};
    if (!adjacency) {
      return {};
    }

    let adjObj = source.find((x) => x.rail_chain == adjacency);
    if (!adjObj) {
      return {};
    }
    return adjObj?.name;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childParams]);

  return (
    <div
      className="d-flex flex-column h-100 container-fluid overflow-hidden flex-nowrap"
      style={{ paddingLeft: 'var(--sadr-font-size-12)' }}
    >
      <Table data={source} columns={columns} hiddenDiv={true} />
      <Routes>
        <Route
          path="/edit/:adjacency/*"
          element={
            <Modal isOpen onClose={go2root}>
              <AdjacencyEbit closeModal={go2root} />

            </Modal>
          }
        />
        <Route
          path="/delete/:adjacency/*"
          element={
            <Modal isOpen onClose={go2root}>
              <ModalDelete
                setParams={setChildParams}
                confirmText={`Вы уверены, что хотите удалить смежность для ${deletingAdjacency}?`}
                removalText={`Удаление ${deletingAdjacency.prettyName}`}
                successText={`Смежность была удалена`}
                goBackText="К списку смежностей"
                canDelete={Object.keys(deletingAdjacency).length}
                closeModal={go2root}
                onDelete={() => dispatch(deleteAdjacencyFromServer(station, childParams?.adjacency))}
              />
            </Modal>
          }
        />
      </Routes>
    </div>
  );
}
export default Adjacency;
