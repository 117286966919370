/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { clearBrigades } from '../redux/actions/brigades.action';
import { clearDevices } from '../redux/actions/devices.action';
import { clearUippss } from '../redux/actions/uipps.action';
import { useEffect } from 'react';
import { updateSortData } from '../redux/actions/sortData.action';

const useClearStates = (location) => {
  const dispatch = useDispatch();
  // Creation of states to clear with clear actions
  useEffect(() => {
    dispatch(updateSortData({}));
    const statesToClear = {
      devices: clearDevices,
      brigades: clearBrigades,
      uipps: clearUippss,
      // documentation:
      // archive:
      // infocards:
      // files:
      // kuobz:
    };
    Object.keys(statesToClear).forEach((state) => {
      if (state !== location.slice(1)) {
        dispatch(statesToClear[state]());
      }
    });
  }, [location]);
};
export default useClearStates;
