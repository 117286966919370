/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createMaskedInputProps } from '../../utils/createInputProps';
import CustomButton from '../CustomElements/CustomButton';
import FormContainer from '../CustomElements/FormContainer';
import Input from '../CustomElements/Input';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { emitLocationWork } from '../../redux/actions/brigades.action';
import { handleUniversalChange } from '../../utils/formUtils';
import CustomTitle from '../CustomElements/CustomTitle';

function BrigadeAddWorkPlace({ brigadeId, setModal, inputsData }) {
  const [formData, setFormData] = useState({}); // data for form

  const dispatch = useDispatch();
  const isButtonDisabled = useButtonValidation(formData, []);
  //---- Function to send form data to server
  const handleSubmit = async (event) => {
    event.preventDefault();
    //---- if all data is valid
    //---- sending data to server using api without redux
    //---- update list of work places
    dispatch(emitLocationWork(formData));
    setModal(false);
  };
  //---- create formData with brigade id
  useEffect(() => {
    if (inputsData && brigadeId) {
      setFormData({ brigadeId: brigadeId });
      inputsData.inputs.forEach((item) => {
        const keyValue = item.request;
        setFormData((prev) => ({ ...prev, [keyValue]: '' }));
      });
    }
  }, []);
  return (
    <FormContainer columns = {1}>
      <CustomTitle titleText={inputsData.popupTitle} columnspan = {1}/>
      {/* draw form according to the mode and checked parameter. */}
      {inputsData.inputs.map((input, index) => {
        if (input.validate?.validateSettings) {
          return (
            // creating input with validation
            <Input
              name={input.request}
              placeholder={input.placeholder}
              width={'100%'} 
              onChange={(event) => {
                handleUniversalChange(
                  formData,
                  setFormData,
                  event,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  null,
                  input.validate.validateSettings
                );
              }
            }
            rowspan = {1}
              label={`${input.label} ${input.required ? '*' : ''}`}
              messageInfo={input.messageInfo}
              value={formData[input.request]}
            />
          );
        } else {
          // creating input with masks
          const inputProps = createMaskedInputProps(input, index, formData, setFormData, brigadeId );
          return <Input {...inputProps} width={'100%'} rowspan = {1} />;
        }
      })}
      {/* creating submit button according to the mode and checked parameter. */}
      <CustomButton
        width="var(--sadr-font-size-320)"
        height="var(--sadr-font-size-60)"
        margin={'0 0 var(--sadr-font-size-20) 0'}
        text={inputsData.buttonsLabels.confirm}
        onClick={handleSubmit}
        disabled={isButtonDisabled}
        isButton
      />
    </FormContainer>
  );
}
export default BrigadeAddWorkPlace;
