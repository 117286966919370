/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import contentData from '../../content/PagesContent/adminDataPannel/admidDataPannelRu.json';

import FormContainer from '../CustomElements/FormContainer';
import Input from '../CustomElements/Input';
import Selector from '../CustomElements/Selector';
import CustomSelect from '../CustomElements/CustomSelect';
import CustomButton from '../CustomElements/CustomButton';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { createCheckboxProps, createInputProps, createSelectorProps } from '../../utils/createInputProps';
import Checkbox from '../CustomElements/Checkbox';
import ConfirmationPage from '../CustomElements/ConfirmationPage';

import useFormDataChanged from '../../customHook/useFormDataChanged';
import { load, remove, success } from '../../redux/actions/loader.action';
import { addError } from '../../redux/actions/error.action';
import { getData } from '../../redux/actions/adminChoice.action';
import {
  initDepartmenOptionsFromServer,
  initOptionsDirectoratesFromServer,
  getPositionsFromServer,
} from '../../redux/actions/options.action';
import { addNewData, updateData } from '../../utils/apiRequests/apiDataRequest';
import { transformOptionsArray } from '../../utils/transformOptionsArray';

function DataModal({ changingId, setModal }) {
  const [inputData, setInputData] = useState({}); // data for input if component in "editing mode"
  const [dependanceData, setDependanceData] = useState([]); // data for selector if dependence exists
  const [optionalFields, setOptionalFields] = useState([]); // array with not required fields
  const [dataObject, setDataObject] = useState({}); //data formed from json file
  const [apiResult, setApiResult] = useState(false); //Flag for showing succes message after api request
  const [formData, setFormData] = useState({}); // data for form
  //---- Set button state according to validations

  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  //
  const positions = useSelector((state) => state?.options?.positions);
  const [optionsForSelectPositionId, setOptionsForSelectPositionId] = useState([]);

  // Функция для получения значения по ID и имени поля
  const getFieldValueById = (id, fieldName, positionArray) => {
    const item = positionArray.find((data) => data.id === id);
    if (item && fieldName in item) {
      return item[fieldName];
    }
    return false; // Если элемент не найден или поле отсутствует
  };

  const propsForCustomSelect = {
    directorate_id: {
      fetchOptions: initOptionsDirectoratesFromServer,
      resetFieldsArray: ['directorate_id', 'department_id'],
      messageForSelect: 'Для выбора дирекции выберите дорогу',
      optionsName: 'directorates',
      keyField: 'road_id',
    },
    department_id: {
      fetchOptions: initDepartmenOptionsFromServer,
      resetFieldsArray: ['department_id'],
      messageForSelect: 'Для выбора подразделения выберите дирекцию',
      optionsName: 'departments',
      keyField: 'directorate_id',
    },
  };

  const dispatch = useDispatch();
  //---- Get information about chosen parameters from store (what button was pressed)
  const selectedParam = useSelector((state) => state.adminChoiceData.selectedParam);
  //---- Get data from store, according to chosen parameter (server answer data)
  const allData = useSelector((state) => state.adminChoiceData.data);

  //---- Function to send form data to server
  const handleSubmit = async (event) => {
    event.preventDefault();
    //---- if all data is valid
    //---- loading mode
    dispatch(load());
    //---- sending data to server using api without redux
    const result = await (changingId
      ? updateData(formData, selectedParam, changingId)
      : addNewData(formData, selectedParam));
    if (result.status === 200) {
      dispatch(success());
      setApiResult(true);
      //---- Update data and dependence data in store if result is successful
      dispatch(getData(selectedParam));
      if (dataObject.dependance) {
        dispatch(getData(dataObject.dependance));
      }
    } else {
      //---- if api request fails - show error for user
      if (result.data) {
        dispatch(addError(result.data));
      }
    }
  };

  const handlerCloseModal = () => {
    //---- closing modal
    setModal(false);
    //---- clear form data
    setFormData({});
    setApiResult(false);
    //---- remove loading mode and success mode and  information message for user from store
    dispatch(remove());
  };

  //---- hook to check if there are any changes in form (boolean)
  const isFormEdit = useFormDataChanged(inputData, formData, true);

  // Collecting data if component in "editing mode"
  useEffect(() => {
    //check if data exists
    if (changingId && selectedParam && allData) {
      //---- Creating of array with data for editing
      const dataToChange = allData[selectedParam].find((item) => item.id === changingId);
      if (dataToChange) {
        const changeArray = Object.entries(dataToChange).reduce((acc, [key, value]) => {
          if (
            (!key.endsWith('_name') && key !== 'id') ||
            key === 'position_full_name' ||
            key === 'position_id' ||
            key === 'full_name'
          ) {
            //---- replace null with empty string (needed for checking if there is no changes in form)
            if ((!value || value === null) && key !== 'device') {
              acc[key] = '';
              //---- replace null with false dor device checkbox
            } else if (key === 'device' && !value) {
              acc[key] = false;
            } else {
              acc[key] = value;
            }
          }
          return acc;
        }, {});
        setInputData(changeArray);
      }
    }
  }, [changingId]);

  useEffect(() => {
    setDataObject(contentData[selectedParam]);
    if (selectedParam && allData[contentData[selectedParam].dependance]) {
      //---- Creating of array for dependance selector list
      setDependanceData(
        allData[contentData[selectedParam].dependance].map((item) => ({ value: item.id, label: item.name }))
      );
    }
  }, [selectedParam, allData]);
  //---- Creating of array with  form data
  useEffect(() => {
    if (dataObject && dataObject.required) {
      const collectOptionalFields = [];
      dataObject.required.forEach((item, index) => {
        //---- keyValue - parameters from json that are the same as in keys in server answer ("id", "name", "note", "device", "road_id", "road_name")
        const keyValue = dataObject.accessor[index];
        //---- excluding id and name from form (no need)
        if (
          (keyValue !== 'id' && !keyValue.endsWith('_name')) ||
          keyValue === 'position_full_name' ||
          keyValue === 'position_id' ||
          keyValue === 'full_name'
        ) {
          // ---- if it is editind mode and there is data for this parameter
          if (changingId && inputData[keyValue]) {
            setFormData((prev) => ({ ...prev, [keyValue]: inputData[keyValue] }));
            //---- replace empty string with falce for device checkbox
          } else if (!inputData[keyValue] && keyValue === 'device') {
            setFormData((prev) => ({ ...prev, [keyValue]: false }));
          } else {
            //---- set empty data for form if it is "adding" mode
            setFormData((prev) => ({ ...prev, [keyValue]: '' }));
          }
        }
        //---- creating an array with not nescessary fields (item - boolean json parameter)
        if (!item) {
          collectOptionalFields.push(keyValue);
        }
      });
      setOptionalFields(collectOptionalFields);
    }
  }, [dataObject, changingId, inputData]);

  // отслеживаем форм-дату и изменяем массив обязательных полей в зависимости от выбранной должности
  useEffect(() => {
    if (formData?.position_id && selectedParam === 'employees') {
      const requiredFieldsMap = {
        department_id: getFieldValueById(formData.position_id, 'is_department_required', positions),
        directorate_id: getFieldValueById(formData.position_id, 'is_directorate_required', positions),
        position_full_name: getFieldValueById(formData.position_id, 'is_full_name_required', positions),
      };

      let updatedFields = Object.keys(requiredFieldsMap).reduce(
        (updatedFields, field) => {
          if (requiredFieldsMap[field]) {
            // Убираем из optionalFields, если поле обязательное
            return updatedFields.filter((optionalField) => optionalField !== field);
          } else {
            // Добавляем в optionalFields, если поле не обязательное
            return updatedFields.includes(field) ? updatedFields : [...updatedFields, field];
          }
        },
        [...optionalFields]
      );

      // Логика для road_id
      const isDirectorateOrDepartmentRequired = requiredFieldsMap.department_id || requiredFieldsMap.directorate_id;

      if (isDirectorateOrDepartmentRequired) {
        updatedFields = updatedFields.filter((field) => field !== 'road_id'); // Удаляем road_id из optionalFields
      } else {
        if (!updatedFields.includes('road_id')) {
          updatedFields.push('road_id'); // Добавляем road_id в optionalFields, если он там отсутствует
        }
      }

      setOptionalFields(updatedFields);
    } else if (selectedParam === 'employees') {
      // Если positionId сброшен или пустой, возвращаем все соответствующие поля в optionalFields, если их там еще нет
      setOptionalFields((prev) => {
        const fieldsToAdd = ['department_id', 'directorate_id', 'position_full_name'].filter(
          (field) => !prev.includes(field)
        );

        let updatedFields = [...prev, ...fieldsToAdd];

        // Логика для road_id
        if (!updatedFields.includes('road_id')) {
          updatedFields.push('road_id');
        }

        return updatedFields;
      });
    }
  }, [formData, positions]);

  // если зашли на форму добавления сотрудника формирум опции для селекта должностей
  useEffect(() => {
    if (positions && selectedParam === 'employees') {
      let options = transformOptionsArray(positions);
      setOptionsForSelectPositionId(options);
    }
  }, [positions]);

  // если зашли на форму добавления сотрудника отправляем запрос на получение должностей
  useEffect(() => {
    if (selectedParam === 'employees') {
      dispatch(getPositionsFromServer());
    }
  }, []);

  return (
    <section className="data-modal">
      {/* check of component mode and adding title according to the mode */}
      {changingId ? (
        <h3 className="custom-title_text ">
          {/* creating title according to the mode and checked parameter. All text from json */}
          {contentData.editLabels[selectedParam]}
          {selectedParam !== 'employees'
            ? ` "${inputData.name}"`
            : ` ${inputData.surname} ${inputData.name} ${inputData.patronymic}`}
        </h3>
      ) : (
        <h3 className="custom-title_text ">{contentData.addLabels[selectedParam]}</h3>
      )}
      {/* draw form or result of form work */}
      {apiResult ? (
        <ConfirmationPage
          // creating result unswer according to the mode and checked parameter. All text from json
          confirmText={`${
            changingId
              ? selectedParam !== 'employees'
                ? `${dataObject.editResult} ${inputData.name}`
                : `${dataObject.editResult} ${inputData.surname} ${inputData.name} ${inputData.patronymic}`
              : selectedParam !== 'employees'
              ? `${dataObject.addResult} ${formData.name}`
              : `${dataObject.addResult} ${formData.surname} ${formData.name} ${formData.patronymic}`
          }!`}
          onConfirm={handlerCloseModal}
          addNew={dataObject.returnButton}
        />
      ) : (
        <FormContainer>
          {/* draw form according to the mode and checked parameter. */}
          {dataObject && dataObject.accessor && dataObject.accessor.length > 0
            ? dataObject.accessor.map((item, index) => {
                if (
                  (item !== 'id' && !item.endsWith('_name')) ||
                  item === 'position_full_name' ||
                  item === 'position_id' ||
                  item === 'full_name'
                ) {
                  //---- creating props data for checkbox, using function createCheckboxProps
                  if (['directorate_id', 'department_id'].includes(item) && selectedParam === 'employees') {
                    let selectorProps = createSelectorProps(
                      item,
                      index,
                      dataObject,
                      dependanceData,
                      formData,
                      setFormData
                    );
                    //изменяем лейбл в зависимости от массива необязательных полей
                    if (['department_id', 'directorate_id'].includes(item)) {
                      const isRequired = !optionalFields.includes(item);
                      const label = isRequired ? `${selectorProps?.label} *` : selectorProps?.label;
                      selectorProps = { ...selectorProps, label: label };
                    }

                    return (
                      <CustomSelect
                        {...selectorProps}
                        {...propsForCustomSelect?.[item]}
                        formData={formData}
                        setFormData={setFormData}
                      />
                    );
                  }
                  if (item === 'device') {
                    const checkboxProps = createCheckboxProps(
                      item,
                      index,
                      inputData,
                      dataObject,
                      formData,
                      setFormData
                    );
                    return <Checkbox {...checkboxProps} margin={'var(--sadr-font-size-40) 0 0 0'} />;
                  }
                  //---- creating props data for selector, using function createSelectorProps
                  if (item.endsWith('_id') || item === 'position_id') {
                    let selectorProps = createSelectorProps(
                      item,
                      index,
                      dataObject,
                      dependanceData,
                      formData,
                      setFormData,
                      optionsForSelectPositionId
                    );
                    if (item === 'position_id') {
                      const value = formData?.position_id
                        ? optionsForSelectPositionId?.find((option) => option.value === formData?.position_id)
                        : null;
                      selectorProps = { ...selectorProps, options: optionsForSelectPositionId, value: value };
                    }
                    if (item === 'road_id' && selectedParam === 'employees') {
                      const isRequired = !optionalFields.includes(item);
                      const label = isRequired ? `${selectorProps?.label} *` : selectorProps?.label;
                      selectorProps = { ...selectorProps, label: label };
                    }
                    return <Selector {...selectorProps} />;
                  }
                  //---- creating props data for input, using function createInputProps
                  let inputProps = createInputProps(
                    item,
                    index,
                    inputData,
                    dataObject,
                    changingId,
                    formData,
                    setFormData
                  );
                  if (item === 'position_full_name') {
                    const isRequired = !optionalFields.includes(item);
                    const label = isRequired ? `${inputProps?.label} *` : inputProps?.label;
                    inputProps = { ...inputProps, label: label };
                  }
                  return <Input {...inputProps} />;
                } else {
                  return null;
                }
              })
            : null}
          {/* creating submit button according to the mode and checked parameter. */}
          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            margin={'0 0 var(--sadr-font-size-20) 0'}
            text={changingId ? contentData.editLabels[selectedParam] : contentData.addLabels[selectedParam]}
            onClick={handleSubmit}
            disabled={changingId ? isButtonDisabled || !isFormEdit : isButtonDisabled}
            isButton
          />
        </FormContainer>
      )}
    </section>
  );
}
export default DataModal;
