import { INIT_REPORTS, GET_REPORT, FILTER_REPORTS } from '../type';
import { filterReports } from '../../utils/filterReports';

export function documentationReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_REPORTS: {
      return { ...state, reports: payload };
    }

    case GET_REPORT: {
      return { ...state, report: payload };
    }

    case FILTER_REPORTS: {
      return { ...state, reports: filterReports(state?.reports, payload) };
    }

    default:
      return state;
  }
}
