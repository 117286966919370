import {
  ADD_BRIGADE_COMPOSITION,
  ADD_ERROES,
  REMOVE_BRIGADE_INFO,
  INIT_BRIGADE_COMPOSITION,
  INIT_BRIGADE_INFO,
  INIT_BRIGADES,
  INIT_CURRENT_LOCATION_WORK,
  INIT_LOCATION_WORK,
  INIT_MODE,
  INIT_SIGNALS,
  INIT_TRANSFER_OBJECT,
  REMOVE_BRIGADE_COMPOSITION,
  REMOVE_BRIGADE_SIGNALS,
  REMOVE_ERROES,
  INIT_ALL_SIGNALS,
  UPDATE_ALL_SIGNALS,
  RESET_MESH,
  REMOVE_CURRENT_LOCATION_WORK,
  REMOVE_ALL_SIGNALS,
  ADD_BRIGADES_COORDS,
  UPDATE_BRIGADE,
  CLEAR_BRIGADES,
  UPDATE_SINGLE_BRIGADE,
} from '../type';

import { mergeData } from '../../utils/mergeData';
import { updateStateBrigade } from '../../utils/updateStateBrigade';

export function brigadesReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_BRIGADES: {
      //Проверяем, есть ли в payload уже существующие id. Как показала практика, это возможно
      // Создаем Set с существующими id
      const existingIds = new Set(state.brigades?.map((item) => item.id));

      // Фильтруем payload, оставляя только уникальные элементы
      const newPayload = payload.filter((item) => !existingIds.has(item.id));

      // Добавляем уникальные элементы к существующему состоянию
      return {
        ...state,
        brigades: [...(state.brigades || []), ...newPayload],
      };
    }

    case UPDATE_BRIGADE: {
      let updateState = updateStateBrigade(state.brigades, payload);
      return {
        ...state,
        brigades: updateState,
      };
    }

    case INIT_BRIGADE_INFO: {
      return {
        ...state,
        brigadeInfo: payload,
      };
    }

    case INIT_MODE: {
      return {
        ...state,
        mode: payload,
      };
    }

    case INIT_SIGNALS: {
      return {
        ...state,
        brigadeSignals: payload,
      };
    }

    // обновление информации о бригаде и добавление в общий список бригад
    case UPDATE_SINGLE_BRIGADE: {
      const updatedBrigades = state.brigades.map((brigade) => {
        if (brigade.id === Number(payload.id)) {
          return payload;
        }
        return brigade;
      });
      return {
        ...state,
        brigades: updatedBrigades,
      };
    }

    case INIT_TRANSFER_OBJECT: {
      return {
        ...state,
        transfer: payload,
      };
    }

    case INIT_ALL_SIGNALS: {
      return {
        ...state,
        brigadeAllSignals: payload,
      };
    }

    case ADD_BRIGADES_COORDS: {
      const updatedState = mergeData(state?.brigades, payload);
      return {
        ...state,
        brigades: updatedState,
      };
    }

    case UPDATE_ALL_SIGNALS: {
      return {
        ...state,
        brigadeSignals: [...state.brigadeAllSignals, ...payload],
      };
    }

    case INIT_LOCATION_WORK: {
      return {
        ...state,
        locationsWork: payload,
      };
    }

    case INIT_CURRENT_LOCATION_WORK: {
      return {
        ...state,
        currentLocationsWork: payload,
      };
    }

    case INIT_BRIGADE_COMPOSITION: {
      return {
        ...state,
        brigadeComposition: payload,
      };
    }
    case ADD_BRIGADE_COMPOSITION: {
      const newBrigadeComposition = [...state.brigadeComposition, payload];
      return {
        ...state,
        brigadeComposition: newBrigadeComposition,
      };
    }

    case REMOVE_BRIGADE_COMPOSITION: {
      const updatedBrigadeComposition = state.brigadeComposition.filter((item) => item.id !== payload);
      return {
        ...state,
        brigadeComposition: updatedBrigadeComposition,
      };
    }

    case REMOVE_ERROES: {
      return {
        ...state,
        errors: {},
      };
    }

    case ADD_ERROES: {
      return {
        ...state,
        errors: payload,
      };
    }

    case CLEAR_BRIGADES: {
      return { ...state, brigades: payload };
    }

    case RESET_MESH: {
      return {
        ...state,
        resetMesh: payload,
      };
    }

    case REMOVE_CURRENT_LOCATION_WORK: {
      return {
        ...state,
        currentLocationsWork: payload,
      };
    }

    case REMOVE_ALL_SIGNALS: {
      return {
        ...state,
        brigadeAllSignals: payload,
      };
    }

    case REMOVE_BRIGADE_INFO: {
      return {
        ...state,
        brigadeInfo: payload,
      };
    }

    case REMOVE_BRIGADE_SIGNALS: {
      return {
        ...state,
        brigadeSignals: payload,
      };
    }
    default:
      return state;
  }
}
