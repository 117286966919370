import {
  INIT_ARCHIVE,
  INIT_ARCHIVE_SESSION,
  INIT_ARCHIVE_FILTERPARAMS,
  REMOVE_ARCHIVE_FILTERPARAMS,
  FILTER_SIGNALS,
} from '../type';
import axiosInstance from '../../config/axios.config';
import { generateQueryString } from '../../utils/generateQueryString';
import { shouldFilter } from '../../utils/shouldFilter';

export const initArchive = (data) => ({
  type: INIT_ARCHIVE,
  payload: data,
});

export const initArchiveFilterParams = (data) => ({
  type: INIT_ARCHIVE_FILTERPARAMS,
  payload: data,
});

export const removerchiveFilterParams = (data) => ({
  type: REMOVE_ARCHIVE_FILTERPARAMS,
  payload: data,
});

export const filterSignals = (filterCriteria) => ({
  type: FILTER_SIGNALS,
  payload: filterCriteria,
});

// получаем сессии с учетом выбраных фильтров
export const initArchiveFromServer = (filterCriteria) => async (dispatch) => {
  try {
    const queryParams = generateQueryString(filterCriteria);

    if (queryParams) {
      const { data } = await axiosInstance(`/api/sessions?${queryParams}`);
      if (data) {
        dispatch(initArchive(data));
      }
    } else {
      dispatch(initArchive([]));
    }
  } catch (error) {
    console.log(error);
  }
};

// записываем сессию в стейт
export const initArchiveSession = (data) => ({
  type: INIT_ARCHIVE_SESSION,
  payload: data,
});

// получаем сессию по id
export const initArchiveSessionFromServer = (id, filterCriteria) => async (dispatch) => {
  try {
    let filteredCriteria = { ...filterCriteria };

    // Удаляем поля, если они равны false
    if (filteredCriteria.btn_alert === false) {
      delete filteredCriteria.btn_alert;
    }
    if (filteredCriteria.btn_clear === false) {
      delete filteredCriteria.btn_clear;
    }
    if (filteredCriteria.fencingBtnServer === false) {
      delete filteredCriteria.fencingBtnServer;
    }
    const filterFlag = shouldFilter(filteredCriteria);
    const { data } = await axiosInstance(`/api/sessions/${id}`);
    if (data) {
      dispatch(initArchiveSession(data));
    }
    if (data && filterFlag) {
      dispatch(filterSignals(filteredCriteria));
    }
  } catch (error) {
    console.log(error);
  }
};
