// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-title {
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 700;
  font-size: var(--sadr-font-size-20);
  line-height: var(--sadr-font-size-30);
  text-align: center;
  color: var(--sadr-text-color);
  margin-bottom: var(--sadr-font-size-30) ;
  white-space: pre-wrap;
}
.confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  padding: 0 var(--sadr-font-size-20);
}
.confirmation-btn,
.cancel,
.confirm {
  margin: var(--sadr-font-size-15) ;
  width: 100%;
}

.confirmation-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1 1;
  max-width: calc(var(--sadr-font-size-420)*2 + 7%);
}
`, "",{"version":3,"sources":["webpack://./src/styles/ConfirmationPage.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,mCAAmC;EACnC,qCAAqC;EACrC,kBAAkB;EAClB,6BAA6B;EAC7B,wCAAwC;EACxC,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,WAAW;EACX,mCAAmC;AACrC;AACA;;;EAGE,iCAAiC;EACjC,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,SAAO;EACP,iDAAiD;AACnD","sourcesContent":[".confirmation-title {\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 700;\n  font-size: var(--sadr-font-size-20);\n  line-height: var(--sadr-font-size-30);\n  text-align: center;\n  color: var(--sadr-text-color);\n  margin-bottom: var(--sadr-font-size-30) ;\n  white-space: pre-wrap;\n}\n.confirmation-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  width: 100%;\n  padding: 0 var(--sadr-font-size-20);\n}\n.confirmation-btn,\n.cancel,\n.confirm {\n  margin: var(--sadr-font-size-15) ;\n  width: 100%;\n}\n\n.confirmation-btn-container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  flex: 1;\n  max-width: calc(var(--sadr-font-size-420)*2 + 7%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
