import { useMemo } from 'react';
import moment from 'moment-timezone';

import '../../styles/ArchiveBrigadeSignals.css';
import Table from '../Table/Table';
import Selector from '../CustomElements/Selector';

import { defaultCellRender } from '../../utils/formatValueForHTML';
import useTableColumnSelect from '../../customHook/useTableColumnSelect';

function ArchiveBrigadeSignals({ currentSession, index }) {
  // Колонки таблицы
  const columns = useMemo(
    () => [
      {
        Header: '№',
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: 'Время',
        accessor: 'time',
        Cell: ({ value }) => {
          // Проверяем, является ли значение валидной датой
          const date = value !== undefined ? moment(value).tz('Europe/Moscow') : null;
          // Форматируем дату в московском часовом поясе
          return date && date.isValid() ? date.format('DD.MM.YYYY HH:mm:ss') : '-';
        },
      },
      {
        Header: 'Номер сигнала',
        accessor: 'index',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Тип сигнала',
        accessor: 'alertType',
        Cell: ({ value }) => {
          switch (value) {
            case 0:
              return 'Проследование РЦ';
            case 1:
              return 'ОК';
            case 2:
              return 'Оповещение 1';
            case 3:
              return 'Оповещение 2';
            case 4:
              return 'Потеря связи';
            case 5:
              return 'Тревога';
            case 6:
              return 'Ожидание';
            case 'start':
              return 'начало работы';
            case 'exitRails':
              return 'выход на путь';
            case 'finish':
              return 'завершение работы';

            default:
              return '-';
          }
        },
      },
      {
        Header: 'Направление',
        accessor: 'dir',
        Cell: ({ value }) => (value === 1 ? 'Нечетное' : value === 0 ? 'Четное' : '-'),
      },
      {
        Header: 'Расстояние по АПК-ДК',
        accessor: 'distance',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Контролируемая РЦ',
        accessor: 'routeRailName',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Занятые РЦ',
        accessor: 'currentRailName',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'РЦ оповещения',
        accessor: 'warningRails',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'РЦ тревоги',
        accessor: 'alertRails',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Нажатие «Тревога»',
        accessor: 'btn_alert',
        Cell: ({ value }) => (value === 1 ? 'Да' : value === 0 ? 'Нет' : '-'),
      },
      {
        Header: 'Нажатие «Путь свободен»',
        accessor: 'btn_clear',
        Cell: ({ value }) => (value === 1 ? 'Да' : value === 0 ? 'Нет' : '-'),
      },
      {
        Header: 'ID УИППС',
        accessor: 'uippsId',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Сигнал на УИППС',
        accessor: 'uippsAlertType',
        Cell: ({ value }) => {
          switch (Number(value)) {
            case 0:
              return 'ОК';
            case 1:
              return 'Тревога';
            default:
              return '-';
          }
        },
      },
      {
        Header: 'Расстояние по координатам',
        accessor: 'preciseDistance',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'GPS УИППС',
        accessor: 'uippsGPS',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'GSM УИППС',
        accessor: 'uippsGSM',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Ограждение',
        accessor: 'isRestrictOn',
        Cell: ({ value }) => (value === true ? 'Да' : value === false ? 'Нет' : '-'),
      },
      {
        Header: 'Перенос (РЦ для переноса)',
        accessor: 'transferRails',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Сигнал на ИУР',
        accessor: 'iurAlertType',
        Cell: ({ value }) => {
          switch (Number(value)) {
            case 1:
              return 'ОК';
            case 2:
              return 'Оповещение 1';
            case 3:
              return 'Оповещение 2';
            case 4:
              return 'Потеря связи';
            case 5:
              return 'Тревога';
            case 6:
              return 'Ожидание';
            default:
              return '-';
          }
        },
      },
      {
        Header: 'Связь с сервером (ИУР)',
        accessor: 'iurConn',
        Cell: ({ value }) => (value === true ? 'Да' : value === false ? 'Нет' : '-'),
      },
      {
        Header: 'GPS (ИУР)',
        accessor: 'iurGPS',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'GSM (ИУР)',
        accessor: 'iurGSM',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'ИУ в БЗ',
        accessor: 'inSafeZone',
        Cell: ({ value }) => (Number(value) === 1 ? 'Да' : Number(value) === 0 ? 'Нет' : '-'),
      },
      {
        Header: 'Форма БЗ',
        accessor: 'safeZoneType',
        Cell: ({ value }) => (Number(value) === 1 ? 'полигон' : Number(value) === 0 ? 'круг' : '-'),
      },
      {
        Header: 'Ширина БЗ',
        accessor: 'safeZoneWidth',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'ID ведущего КУОБЗ',
        accessor: 'mainKuobzId',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'Сигнал на КУОБЗ',
        accessor: 'kuobzAlertType',
        Cell: ({ value }) => {
          switch (Number(value)) {
            case 1:
              return 'ОК';
            case 2:
              return 'Оповещение 1';
            case 3:
              return 'Оповещение 2';
            case 4:
              return 'Потеря связи';
            case 5:
              return 'Тревога';
            case 6:
              return 'Ожидание';
            default:
              return '-';
          }
        },
      },
      {
        Header: 'Связь с сервером (КУОБЗ)',
        accessor: 'kuobzConn',
        // Если значение `true`, возвращаем "да", иначе — "нет"
        Cell: ({ value }) =>
          // Если значение `true`, возвращаем "да", иначе — "нет"
          value === true ? 'Да' : value === false ? 'Нет' : '-',
      },
      {
        Header: 'GPS (КУОБЗ)',
        accessor: 'kupbzGPS',
        Cell: ({ value }) => defaultCellRender(value),
      },
      {
        Header: 'GSM (КУОБЗ)',
        accessor: 'kuobzGSM',
        Cell: ({ value }) => defaultCellRender(value),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSession?.signals]
  );

  // eslint-disable-next-line no-unused-vars
  const [visibleColumns, hiddenColumns, columnOptions, selectedColumns, handleColumnSelect] =
    useTableColumnSelect(columns);

  return (
    <>
      <div className="archive-brigade-signals_select-container">
        <Selector
          column="left"
          options={columnOptions}
          onChange={(selectedOption) => handleColumnSelect(selectedOption)}
          filtersBlock = {true}
          placeholder="Показать/скрыть поля"
          width="100%"
          height="var(--sadr-font-size-60)"
          value={selectedColumns}
          isMulti={true}
          name="columnName"
          isView
          optionSquare
        />
      </div>

      <Table data={currentSession?.signals || []} columns={visibleColumns} hiddenDiv={true} highlightRowIndex={index} />
    </>
  );
}

export default ArchiveBrigadeSignals;
