import { React, useState, useEffect } from 'react';
import '../../styles/AddDevice.css';
import CustomButton from '../CustomElements/CustomButton';
import CustomContainer from '../CustomElements/CustomContainer';
import CustomTitle from '../CustomElements/CustomTitle';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../CustomElements/Input';
import FormContainer from '../CustomElements/FormContainer';
import Selector from '../CustomElements/Selector';
import DateSelector from '../CustomElements/DateSelector';
import CustomTextarea from '../CustomElements/CustomTextarea';
import PhoneInput from '../CustomElements/PhoneInput';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import { newDeviceFromServer } from '../../redux/actions/devices.action';
import { load, remove } from '../../redux/actions/loader.action';
import { isValidAndroidID } from '../../utils/isValidAndroidID';
import { isValidDeviceID } from '../../utils/isValidDeviceID';
import { addError } from '../../redux/actions//error.action';
import { initIurAndroidIdFromServer } from '../../redux/actions/devices.action';

// компонент формы добавления нового устройства

function AddDevice({ closeModal, t }) {
  // Получаем опции
  const optionsForSelect = useSelector((state) => state.options);

  // Получаем данные о процессе
  const loader = useSelector((state) => state.loader);

  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // состояние для отображения страницы подтверждения
  const [addSuccess, setAddSuccess] = useState(false);

  // состояние для необязательных полей
  const [optionalFields, setOptionalFields] = useState([
    'isBroken',
    'note',
    'storageId',
    'receiverId',
    'sim1',
    'sim2',
    'senderId',
  ]);

  // Состояние для формы
  const [formData, setFormData] = useState({
    id: '',
    receiveDate: '',
    storageId: '',
    receiverId: '',
    sim1: '',
    isBroken: '',
    typeId: '',
    acceptanceCert: null,
    senderId: '',
    manufactureDate: '',
    sim2: '',
    note: '',
    androidId: '',
  });

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData, optionalFields);

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    // Проверяем валидность id
    if (!isValidDeviceID(formData.id, formData.typeId)) {
      return dispatch(addError({ error: 'Указан невалидный ID устройства' }));
    }
    // Проверяем валидность androidId, если он предоставлен
    if (formData?.androidId && !isValidAndroidID(formData.androidId)) {
      return dispatch(addError({ error: 'Указан невалидный ANDROID_ID' }));
    }
    // Если все проверки пройдены успешно, переходим к отправке данных
    // Переводим в состояние загрузки
    dispatch(load());
    // Формируем данные для отправки на сервер
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(newDeviceFromServer(payload, formData));
  };

  // если выбрали тип иур добавляем обязательное поле androidId
  useEffect(() => {
    // Удалить 'androidId', если typeId !== 1
    if (formData.typeId === 1) {
      setOptionalFields((prevFields) => prevFields.filter((field) => field !== 'androidId'));
      // Добавить 'androidId', если typeId === 1
    } else if (formData.typeId !== 1 && formData.typeId !== '') {
      setOptionalFields((prevFields) => {
        // Проверить, существует ли уже 'androidId' в массиве, чтобы избежать дублирования
        if (!prevFields.includes('androidId')) {
          return [...prevFields, 'androidId'];
        }
        return prevFields; // Возвращаем неизмененный массив, если 'androidId' уже есть
      });
    }
  }, [formData]);

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, закрываем модальное окно
    if (!loader?.isLoading && loader?.isSuccess) {
      setAddSuccess(true);
    }
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  // Закрывает модальное окно после успешного добавления нового устройства
  const handlerCloseModal = () => {
    closeModal();
    setAddSuccess(false);
  };

  // прис бросе даты производства  сбрасываем дату приемки
  useEffect(() => {
    setFormData((prev) => ({ ...prev, receiveDate: '' }));
  }, [formData.manufactureDate]);

  // отправляем запрос на получений списка androidId при открытии формы
  useEffect(() => {
    dispatch(initIurAndroidIdFromServer());
  }, [dispatch]);

  return (
    <CustomContainer>
      {/* <div className="add-device"> */}
      <CustomTitle titleText={'ДОБАВЛЕНИЕ НОВОГО УСТРОЙСТВА'} />
      {addSuccess && (
        <ConfirmationPage
          confirmText={'Новое устройство добавлено!'}
          onConfirm={handlerCloseModal}
          addNew={'К списку устройств'}
        />
      )}
      {!addSuccess && (
        <FormContainer>
          <Input
            column="left"
            placeholder="Введите ID устройства"
            onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, null, null, 6)}
            name="id"
            label="ID устройства *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
          />
          <DateSelector
            column="left"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            label="Дата производства *"
            selectedDate={formData.manufactureDate}
            setSelectedDate={(date) =>
              handleUniversalChange(formData, setFormData, null, 'manufactureDate', date, 'date')
            }
            name="manufactureDate"
            placeholder="Выберите дату производства"
            maxDate={new Date()}
          />

          <Selector
            column="left"
            options={optionsForSelect.storages}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'storageId', selectedOption, 'select')
            }
            placeholder="Выберите место хранения"
            label="Место хранения"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="storageId"
          />
          <Selector
            column="left"
            options={optionsForSelect.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'senderId', selectedOption, 'select')
            }
            placeholder="ФИО передавшего"
            label="Выберите ФИО передавшего"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="senderId"
          />
          <PhoneInput
            column="left"
            label="SIM1"
            name="sim1"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData.sim1}
          />
          <Selector
            column="left"
            options={optionsForSelect?.сondition}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'isBroken', selectedOption, 'select')
            }
            placeholder="Не определено"
            label="Состояние"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="isBroken"
          />
          {formData.typeId === 1 && (
            <Input
              column="left"
              placeholder="Введите android_id"
              onChange={(event) => handleUniversalChange(formData, setFormData, event)}
              name="androidId"
              label="ANDROID_ID *"
              width="var(--sadr-font-size-420)"
              height="var(--sadr-font-size-60)"
            />
          )}
          {/* <Selector
            column="left"
            options={optionsForSelect?.iurAndroidId}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'androidId', selectedOption, 'select')
            }
            placeholder="Выберите android_id"
            label="ANDROID_ID *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="androidId"
            optionText="Нет неавторизованных УИППС"
          /> */}
          <Selector
            column="right"
            options={optionsForSelect?.deviceTypes.filter((item) => item.value !== 4 && item.value !== 2)}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'typeId', selectedOption, 'select')
            }
            placeholder="Выберите тип устройства"
            label="Тип устройства *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="typeId"
          />
          <DateSelector
            column="right"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            label="Дата приемки *"
            selectedDate={formData.receiveDate}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'receiveDate', date, 'date')}
            name="receiveDate"
            placeholder="Выберите дату приемки"
            minDate={formData?.manufactureDate}
            maxDate={new Date()}
          />

          <Input
            column="right"
            placeholder="Выберите файл акта"
            onChange={(event) =>
              handleUniversalChange(formData, setFormData, event, null, null, null, 'acceptanceCertName')
            }
            onRemove={() =>
              handleUniversalChange(
                formData,
                setFormData,
                null,
                'isRemove',
                null,
                null,
                'acceptanceCert',
                'acceptanceCertName'
              )
            }
            type="file"
            name="acceptanceCert"
            label="Акт приема-передачи *"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
          />
          <Selector
            column="right"
            options={optionsForSelect.employees}
            onChange={(selectedOption) =>
              handleUniversalChange(formData, setFormData, null, 'receiverId', selectedOption, 'select')
            }
            placeholder="ФИО принявшего"
            label="Выберите ФИО принявшего"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            isMulti={false}
            name="receiverId"
          />
          <PhoneInput
            column="right"
            label="SIM2"
            name="sim2"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData.sim2}
          />
          <CustomTextarea
            column="right"
            placeholder="Оставьте комментарий"
            type="textArea"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="note"
            label="Комментарий"
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-160)"
          />

          <CustomButton
            width="var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            margin={'0 0 var(--sadr-font-size-20) 0'}
            text="Добавить устройство"
            onClick={handlerSubmit}
            disabled={isButtonDisabled}
            isButton
          />
        </FormContainer>
      )}
      {/* </div> */}
    </CustomContainer>
  );
}

export default AddDevice;
