import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import '../../styles/DeviceRepairStatus.css';
import Modal from '../Modal/Modal';
import CustomButton from '../CustomElements/CustomButton';
import DeviceRepair from '../Devices/DeviceRepair';
import DeviceStorageFromRepair from '../Devices/DeviceStorageFromRepair';
import TableMap from '../Table/TableMap';

import { useRole } from '../../customHook/useRole';
import useModalWithStatus from '../../customHook/useModalWithStatus';
import useDeviceStatus from '../../customHook/useDeviceStatus';
import { initStatusesFromServer } from '../../redux/actions/statuses.action';

//компонент для отображения статусов Ремонт (фигма 2.4)

function DeviceRepairStatus({ currentDevice, setActiveButton, activeButton, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  //проверяем роли пользователя
  const isMaster = useRole('master');

  //получакм массив статаусов Хранение из стора
  const { repairStatuses } = useSelector((state) => state?.statuses);
  const [statusId, setStatusId] = useState('');

  const title = 'ИЗМЕНЕНИЕ ДАННЫХ СТАТУСА';

  //поолучаем статус текущего устройства
  const status = currentDevice?.status;

  // Состояние для управления кнопкой
  const [disabledBtn, setDisabledBtn] = useState(!(status === 'Ремонт'));

  // хук для получения текущего статуса по id
  const currentStatus = useDeviceStatus(repairStatuses, statusId);

  // Используем useEffect для обновления disabledBtn при изменении status
  useEffect(() => {
    setDisabledBtn(!(status === 'Ремонт'));
  }, [status]); // Зависимость от status

  // Хук для управления модальным окном статусов
  const { isOpen, activeModal, closeModal, handleButtonClick } = useModalWithStatus(
    repairStatuses,
    currentDevice,
    setStatusId
  );

  // компоненты для отображения
  const contentData = {
    'Направление в ремонт': (
      <DeviceRepair
        currentDevice={currentDevice}
        currentStatus={currentStatus}
        closeModal={closeModal}
        title={title}
        isEdit={true}
        t={t}
      />
    ),
    'Возврат из ремонта': (
      <DeviceStorageFromRepair
        currentDevice={currentDevice}
        currentStatus={currentStatus}
        closeModal={closeModal}
        title={title}
        isEdit={true}
        t={t}
      />
    ),
    'Принять на хранение': <DeviceStorageFromRepair currentDevice={currentDevice} closeModal={closeModal} t={t} />,
  };

  // Инициализации статусов с сервера. принимает id устройства и тип статусов
  useEffect(() => {
    dispatch(initStatusesFromServer(currentDevice.id, 'repair'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Таблица со статусами */}
      {repairStatuses && (
        <TableMap
          data={repairStatuses}
          handleButtonClick={handleButtonClick}
          currentDevice={currentDevice}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          t={t}
        />
      )}

      {/* Блок с кнопками */}
      {!isMaster && (
        <div className="device-repair-status_button-container">
          <CustomButton
            className="device-repair-status_button"
            text="Принять на хранение"
            colorText="#FFFFF"
            onClick={() => handleButtonClick('Принять на хранение')}
            color={disabledBtn ? '#ADADAD' : ''}
            disabled={disabledBtn}
          />
        </div>
      )}

      {/* Модальное окно */}
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal} containerSelector={'.table-for-devices_or_uipps_container'}>
          {activeModal && contentData[activeModal]}
        </Modal>
      )}
    </>
  );
}

export default DeviceRepairStatus;
