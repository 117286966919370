import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../../styles/FilterForFiles.css';
import Input from '../CustomElements/Input';
import DateSelector from '../CustomElements/DateSelector';
import { useFilterControl } from '../../customHook/useFilterControl';
import { handleUniversalChange } from '../../utils/formUtils';
import Accordeon from '../CustomElements/Accordeon';
import { initFilesFromServer } from '../../redux/actions/files.action';

function FilterForFiles({ formData, setFormData, initialFormData }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // хук для сброса филльтров и флага для отображения кнопки сброса
  const { resetFilters, showBtnReset } = useFilterControl(initialFormData, formData, setFormData);

  // запрашиваем документы, передаем параметры для фильтрации
  useEffect(() => {
    dispatch(initFilesFromServer(formData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  return (
    <Accordeon header="ФАЙЛЫ" hideAbove={1650} qParamName={'docs_acc'} formData={formData} resetFilters={resetFilters}>
      <button
        type="button"
        className={`accordeon__reset-filters ${showBtnReset && 'accordeon__reset-filters_visible'}`}
        onClick={() => {resetFilters(false)}}
      >
        <span className="accordeon__reset-filters_text">Сбросить все фильтры</span>
        <img className="accordeon__reset-filters_icon" src="/image/close.svg" alt=""></img>
      </button>

      <form className="accordeon__form" id="" method="POST" action="#" autoComplete="off">
        <div className="accordeon__select-container" id="">
          <Input
            placeholder="Введите название файла"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            value={formData?.name}
            name="name"
            label="Название файла"
          />
          <DateSelector
            label="Дата загрузки"
            selectedDate={formData?.date}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
            name="date"
            maxDate={new Date()}
          />
        </div>
      </form>
    </Accordeon>
  );
}

export default FilterForFiles;
