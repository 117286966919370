/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/DeviceWriteOff.css';
import FormContainer from '../CustomElements/FormContainer';
import CustomButton from '../CustomElements/CustomButton';
import DateSelector from '../CustomElements/DateSelector';
import CustomTextarea from '../CustomElements/CustomTextarea';
import CustomTitle from '../CustomElements/CustomTitle';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import { handleUniversalChange, createFormData } from '../../utils/formUtils';
import { useButtonValidation } from '../../customHook/useButtonValidation';
import { transferDeviceForWriteOff } from '../../redux/actions/devices.action';
import { load } from '../../redux/actions/loader.action';
import { convertToDate } from '../../utils/convertToDate';
import useSuccessListener from '../../customHook/useSuccessListener';
import FileSelector from '../CustomElements/FileSelector';
import useFilesOptions from '../../customHook/useFilesOptions';

function DeviceWriteOff({ currentDevice, closeModal }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();

  // получаем опции из стора
  const optionsForSelect = useSelector((state) => state.options);
  //состояние для отображения подтверждения списания
  const [writeOffSuccess, setWriteOffSuccess] = useState(false);

  // Состояние для формы
  const initialFormData = {
    deviceId: currentDevice.id,
    date: '',
    attachment: '',
    reason: '',
  };
  const [formData, setFormData] = useState(initialFormData);

  const attachmentOptions = useFilesOptions(setFormData, 'attachmentName', 'attachment');

  // Функция для проверки валидности формы
  const isButtonDisabled = useButtonValidation(formData);

  const handlerCloseModal = () => {
    setFormData(initialFormData);
    setWriteOffSuccess(false);
    closeModal();
  };

  // Обработчик отправки формы
  const handlerSubmit = (event) => {
    event.preventDefault();
    //переводим в состояние загрузки
    dispatch(load());
    const payload = createFormData(formData);
    // Отправляем данные на сервер через Redux
    dispatch(transferDeviceForWriteOff(payload, optionsForSelect.deviceStatuses, currentDevice?.type_id));
  };

  //отслеживаем выполнение запроса
  useSuccessListener(setFormData, initialFormData, closeModal);

  return (
    <CustomContainer>
      <CustomTitle titleText="СПИСАНИЕ УСТРОЙСТВА" deviceType={currentDevice.type} deviceId={currentDevice.id} />
      {writeOffSuccess && (
        <ConfirmationPage
          confirmText="Вы уверены, что хотите списать устройство?"
          confirmButtonText="Списать"
          cancelButtonText="Отменить"
          onConfirm={handlerSubmit} // Функция, которая должна быть вызвана при подтверждении
          onCancel={handlerCloseModal} // Функция для закрытия модального окна или отмены действия
        />
      )}
      {!writeOffSuccess && (
        <FormContainer>
          <DateSelector
            column="left"
            width = "100%"
            height="var(--sadr-font-size-60)"
            label="Дата списания *"
            selectedDate={formData.date}
            setSelectedDate={(date) => handleUniversalChange(formData, setFormData, null, 'date', date, 'date')}
            name="date"
            placeholder="Выберите дату"
            minDate={convertToDate(currentDevice?.last_action_date)}
            maxDate={new Date()}
          />
          <FileSelector
            attachmentOptions={attachmentOptions}
            formData={formData}
            setFormData={setFormData}
            column={'right'}
            rowspan={2}
            file={{
              field: 'attachment',
              name: 'attachmentName',
              label: 'Акт списания *'
            }} />
          <CustomTextarea
            placeholder="Укажите причину списания"
            type="textArea"
            onChange={(event) => handleUniversalChange(formData, setFormData, event)}
            name="reason"
            columnspan={2}
            label="Причина списания *"
            width="100%"
            height="var(--sadr-font-size-200)"
            value={formData.reason}
          />
          <CustomButton
            width = "var(--sadr-font-size-420)"
            height="var(--sadr-font-size-60)"
            color={isButtonDisabled ? '#ADADAD' : ''}
            text="Списать устройство"
            disabled={isButtonDisabled}
            onClick={() => setWriteOffSuccess(true)}
            isButton
          />
        </FormContainer>
      )}
    </CustomContainer>
  );
}

export default DeviceWriteOff;
