import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import '../../styles/Preloader.css';
import { useInfocard, useIsEditing, useLoader, useNumberEdit, useSelectEdit, useTextEdit } from './Common';
import { RailChainSelector, StationSelector } from '../CustomElements/RailChainSelector';
import CustomButtonDelete from '../CustomElements/CustomButtonDelete';
import { applyChangesToArray } from '../Table/useTableEdit';
import CustomButton from '../CustomElements/CustomButton';
import Table from '../Table/Table';

import { patchSemaphores, requestSemaphores } from '../../redux/actions/infocards.action';
import { useRole } from '../../customHook/useRole';
/**
 * @typedef {object} IdNameObj
 * @property {number} id
 * @property {string} name
 */
/**
 * @typedef {object} StationBinded
 * @property {number} station
 * @property {string} station_name
 */
/**
 * @typedef {Object} Semaphore
 * @property {number} id
 * @property {'0' | '1'} direction
 * @property {IdNameObj} station
 * @property {IdNameObj & StationBinded} rail_chain_before
 * @property {IdNameObj & StationBinded} rail_chain_after
 */

/**
 * @param className
 * @param rest
 * @return {Element}
 * @constructor
 */
function Loader({ className, ...rest }) {
  return <div className={`preloader__spinner-loader ${className}`} {...rest}></div>;
}

export default function Semaphores() {
  // роли пользователя
  const isAdmin = useRole('admin');
  const { station, road } = useInfocard();
  let { isLoading } = useLoader();
  const [changes, setChanges] = useState(/**@type {TableChanges}*/ {});
  let { isEditing, setEditing } = useIsEditing();
  /** @type {Semaphore[]}*/
  const data = useSelector((s) => s.infocards.semaphores);
  const directionOptions = useSelector((s) => s?.options?.optionsSemaphoreDirection || []);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestSemaphores(station));
  }, [station]);

  const columns = useMemo(() => {
    const rcCell = ({ cell: { value } }) => {
      const names = [];
      if (value?.station && station != value?.station) {
        names.push(value.station_name);
      }
      if (value?.name) {
        names.push(value.name);
      } else if (value?.id) {
        names.push(value.id);
      }
      return names.join('.') || '-';
    };
    const useRcEditCell = ({ cell, row: { cells } }) => {
      const {
        changeState,
        state: { id, station },
      } = cell;
      return (
        <div
          className="d-flex flex-column flex-nowrap"
          style={{ width: 'var(--sadr-font-size-260)', height: 'var(--sadr-font-size-110)' }}
        >
          <StationSelector
            road={road}
            station={station}
            set_station={(station) => changeState({ station, id: null })}
          />
          <RailChainSelector station={station} rc={id} set_rc={(id) => changeState({ station, id })} />
        </div>
      );
    };

    return [
      {
        Header: 'ID светофора',
        // Теперь id - это чисто айдишник в таблице, а semaphore_id - айди светофора из БД, только он важен серверу
        // accessor: 'id',
        accessor: 'semaphore_id',
        width: 'var(--sadr-font-size-150)',
        Cell: ({ cell: { value } }) => <span>{value || ''}</span>,
        EditCell: ({ cell, column, rows, row }) =>
          useNumberEdit({
            ...cell,
            /**
             * Дизейблим редактирование айди для уже существующих светофоров. Ввести id можно только при добавлении,
             * если необходимо изменить id - надо удалить и добавить светофор заново
             * Так надо отчасти для того чтобы не парицца при обновлении, тк нужен айдишник существующей записи, чтобы обновить данные
             */
            isDisabled: data.find((item) => item.semaphore_id == cell.state),
            width: column.width,
            height: 'var(--sadr-font-size-60)',
            placeholder: 'ID',
            validate: (num) => {
              if (!Number.isFinite(num)) return 'Введите ID светофора';

              const actualValues = new Set(
                // все строки, не включая текущую
                rows
                  .filter((x) => x != row)
                  // в каждой строке ищу ячейку с той же колонкой
                  .map((x) => x.cells.find((c) => c.column == column))
                  // Выцепляю её актуальное значение
                  .map((x) => x.state)
              );

              if (actualValues.has(num)) return 'Такой ID уже существует';
            },
          }),
      },
      {
        Header: 'Светофор',
        accessor: 'name',
        width: 'var(--sadr-font-size-260)',
        Cell: ({ cell: { value } }) => <span>{value || ''}</span>,
        EditCell: ({ cell, column }) =>
          useTextEdit({
            ...cell,
            width: column.width,
            height: 'var(--sadr-font-size-60)',
            placeholder: 'Название',
            validate: (txt) => (!txt?.trim().length ? 'Введите название светофора' : null),
          }),
      },
      {
        Header: <span className="text-wrap">Направление движения по светофору</span>,
        accessor: 'direction',
        width: 'var(--sadr-font-size-260)',
        Cell: ({ cell: { value } }) => <span>{directionOptions.find((x) => x.value == value)?.label || value}</span>,
        EditCell: ({ cell, column }) =>
          useSelectEdit({
            ...cell,
            height: 'var(--sadr-font-size-60)',
            options: directionOptions,
            name: 'even',
            placeholder: 'Направление движения',
            validate: (val) => {
              if (!directionOptions.some((x) => x.value == val)) return 'Выберите направление движения';
            },
          }),
      },
      {
        Header: 'РЦ перед светофором',
        accessor: 'rail_chain_before',
        Cell: rcCell,
        EditCell: useRcEditCell,
      },
      {
        Header: 'РЦ за светофором',
        accessor: 'rail_chain_after',
        Cell: rcCell,
        EditCell: useRcEditCell,
      },
      ...(isEditing
        ? [
            {
              Header: 'Действия',
              accessor: '.',
              Cell: ({ row: { deleteRow } }) => {
                return (
                  <div>
                    <CustomButtonDelete onClick={deleteRow} disabled={isLoading} />
                  </div>
                );
              },
            },
          ]
        : []),
    ];
  }, [isEditing, road, directionOptions, isLoading]);

  const getRowId = useCallback((row) => [row?.id, row?._pending].join('.'), []);

  const canApply = useMemo(() => {
    if (changes.errors?.size) return false;

    if ([changes.deleted, changes.added].some((x) => !!x?.length)) return true;

    return changes.updated?.size > 0;
  }, [changes]);

  const onApply = useCallback(() => {
    setEditing(false);
    dispatch(patchSemaphores(station, changes));
  }, [dispatch, changes, station, setEditing]);

  return (
    <div
      className="d-flex flex-column h-100 container-fluid overflow-hidden flex-nowrap"
      style={{ paddingLeft: 'var(--sadr-font-size-12)' }}
    >
      <Table
        columns={columns}
        data={data}
        changes={changes}
        setChanges={setChanges}
        isEditing={isEditing}
        getRowId={getRowId}
        hiddenDiv={true}
      />
      <div className="d-flex flex-row flex-nowrap justify-content-evenly">
        {isEditing && !isLoading && (
          <>
            <CustomButton
              className="confirmation-btn"
              text="+ Добавить светофор"
              isButton
              onClick={() =>
                setChanges((prevState) => {
                  const id = (_.max(applyChangesToArray(changes, data).map((x) => x.id)) || 0) + 1;
                  return {
                    ...prevState,
                    __add: [
                      {
                        id,
                        semaphore_id: null,
                        station: { id: station },
                        rail_chain_after: { station },
                        rail_chain_before: { station },
                      },
                    ],
                  };
                })
              }
            />
            <CustomButton
              className="confirmation-btn"
              text="Сохранить"
              onClick={onApply}
              disabled={!canApply}
              isButton
            />
            <CustomButton
              className="confirmation-btn cancel"
              color="var(--sadr-background-secondary)"
              colorText="var(--sadr-border)"
              text="Отмена"
              isButton
              onClick={() => setEditing(false)}
            />
          </>
        )}

        {!isEditing && !isLoading && isAdmin && (
          <>
            <CustomButton className="confirmation-btn" text="Редактировать" onClick={() => setEditing(true)} isButton />
          </>
        )}

        {isLoading && (
          <>
            <Loader className="mt-5" />
          </>
        )}
      </div>
    </div>
  );
}
