import React, { useState, useEffect } from 'react';
import '../../styles/Feature.css';
import CustomSwitch from '../CustomElements/CustomSwitch';
import axiosInstance from '../../config/axios.config';
import socket from '../../config/socket';
import { logoutUser } from '../../redux/actions/user.action';
import '../../styles/Reports.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSignOut } from 'react-auth-kit';
import { initOptionsMrmData } from '../../redux/actions/options.action';

function Feature() {
  const { mrmIds } = useSelector((state) => state?.options);

  const dispatch = useDispatch();
  const signOut = useSignOut();
  const navigate = useNavigate();

  function logout() {
    dispatch(logoutUser({ logout: true }));
    signOut();
    navigate('/login');
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedAndroidId, setSelectedAndroidId] = useState(null);

  function handleSelectChange(e) {
    setSelectedAndroidId(e.target.value);
  }

  const sendDebugSignal = (event, withSound) => {
    if (!selectedAndroidId) {
      alert('А можно указать устройство?');
      return;
    }
    event.preventDefault();
    const data = {
      androidId: selectedAndroidId,
      text: 'Осмысленное сообщение',
      confirmBtnText: 'Текст кнопки',
      withSound: withSound,
      code: withSound ? 1 : 0,
    };
    socket.emit('send_alert', data);
  };

  // переключение свитчера Источник данных
  // Состояния для флага isMockData
  const [isMockDataEnabled, setIsMockDataEnabled] = useState(false);
  // Функция для получения текущих значений флагов с сервера
  const fetchOrUpdateFlags = async (payload = null) => {
    try {
      const { data } = await axiosInstance.post('/api/administration/buttons', payload || {});
      // Обновляем состояние на основе ответа сервера
      setIsMockDataEnabled(data?.isMockData);
    } catch (error) {
      console.error('Ошибка при запросе флагов:', error);
    }
  };

  // Обработчик переключения свитчера
  const handleToggleSwitch = () => {
    const newMockDataState = !isMockDataEnabled;
    fetchOrUpdateFlags({ isMockData: newMockDataState });
  };

  useEffect(() => {
    fetchOrUpdateFlags();
    // dispatch(initOptionsMrmData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="reports">
      <div className="reports-header">
        <div className="reports-title">Источник данных</div>
        <button type="button" className="btn btn-dark header-for-object_btn-logout" href="#" onClick={logout}>
          Выход
          <img className="header-for-object_icon" src="/image/exit.svg" alt=""></img>
        </button>
      </div>
      {/* <div className="p-3" style={{ textAlign: 'start', width: '40%' }}>
        <div className="p-2" style={{ width: '90vw' }}>
          <label style={{ padding: 0 }}>Устройство</label>
          <select className="form-select w-25 mb-2" onChange={handleSelectChange}>
            <option disabled selected value="">
              Выберите ИУР
            </option>
            {mrmIds?.map((option, index) => (
              <option key={index} value={option.androidId}>
                {option.value} ({option.androidId})
              </option>
            ))}
          </select>
          <div className="d-flex">
            <button className="btn btn-primary m-1" onClick={(event) => sendDebugSignal(event, false)}>
              Alert без звука
            </button>
            <button className="btn btn-primary m-1" onClick={(event) => sendDebugSignal(event, true)}>
              Alert со звуком
            </button>
          </div>
        </div>
      </div> */}
      <div className="feature-switch-container">
        <CustomSwitch
          label="Моковые данные"
          isActive={!isMockDataEnabled}
          onToggle={() => handleToggleSwitch()}
          activeText="выкл"
          inactiveText="вкл"
          disabled={false}
        />
      </div>
    </div>
  );
}

export default Feature;
