export const INIT_USER = 'INIT_USER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export const FILTER_CONFIG = 'FILTER_CONFIG';
export const SET_FILTER_STATE = 'SET_FILTER_STATE';
export const UPDATE_REQUEST_BLOCKER = 'UPDATE_REQUEST_BLOCKER';

export const INIT_MAP_ARRAY = 'INIT_MAP_ARRAY';
export const ADD_MAP_COORDS = 'ADD_MAP_COORDS';
export const EDIT_MAP_ARRAY = 'EDIT_MAP_ARRAY';
export const CLEAR_MAP_IGNORES = 'CLEAR_MAP_IGNORES';
export const ADD_IGNORE_ID = 'ADD_IGNORE_ID';
export const CLEAR_MAP_DATA = 'CLEAR_MAP_DATA';
export const CLEAR_MAP_ARRAY = 'CLEAR_MAP_ARRAY';

export const INIT_OPTIONS = 'INIT_OPTIONS';
export const ADD_DEPARTMENT_OPTIONS = 'ADD_DEPARTMENT_OPTIONS';
export const ADD_EMPLOYEES_OPTIONS = 'ADD_FULL_NAME_OPTIONS';
export const ADD_SECTION_OPTIONS = 'ADD_SECTION_OPTIONS';
export const UPDATE_OPTIONS_LABELS = 'UPDATE_OPTIONS_LABELS';

export const INIT_DEVICES = 'INIT_DEVICES';
export const EDIT_DEVICE = 'EDIT_DEVICE';
export const CLEAR_DEVICES = 'CLEAR_DEVICES';
export const INIT_UPDATE_DEVICES = 'INIT_UPDATE_DEVICES';
export const SERVICE_DEVICE = 'SERVICE_DEVICE';
export const ADD_DEVICES = 'ADD_DEVICES';
export const EDIT_DEVICE_STATUS = 'EDIT_DEVICE_STATUS';
export const UPDATE_SINGLE_DEVICE = 'UPDATE_SINGLE_DEVICE';

export const INIT_UIPPS = 'INIT_UIPS';
export const INIT_UPDATE_UIPPS = 'INIT_UPDATE_UIPS';
export const UPDATE_SINGLE_UIPPS = 'UPDATE_SINGLE_UIPPS';
export const ADD_NEW_UIPPS = 'ADD_NEW_UIPPS';
export const CLEAR_UIPPS = 'CLEAR_UIPPS';
export const EDIT_UIPPS_STATUS = 'EDIT_UIPPS_STATUS';
export const ADD_UIPPS_COORDS = 'ADD_UIPPS_COORDS';

export const INIT_BRIGADES = 'INIT_BRIGADES';
export const INIT_BRIGADE_INFO = 'INIT_BRIGADE_INFO';
export const UPDATE_SINGLE_BRIGADE = 'UPDATE_SINGLE_BRIGADE';
export const INIT_CURRENT_LOCATION_WORK = 'INIT_CURRENT_LOCATION_WORK';
export const INIT_BRIGADE_COMPOSITION = 'INIT_BRIGADE_COMPOSITION';
export const REMOVE_BRIGADE_COMPOSITION = 'REMOVE_BRIGADE_COMPOSITION';
export const ADD_BRIGADE_COMPOSITION = 'ADD_BRIGADE_COMPOSITION';
export const INIT_LOCATION_WORK = 'INIT_LOCATION_WORK';
export const ADD_BRIGADES_COORDS = 'ADD_BRIGADES_COORDS';
export const UPDATE_BRIGADE = 'UPDATE_BRIGADE';
export const REMOVE_BRIGADE_SIGNALS = 'REMOVE_BRIGADE_SIGNALS';
export const CLEAR_BRIGADES = 'CLEAR_BRIGADES';
export const REMOVE_BRIGADE_INFO = 'REMOVE_BRIGADE_INFO';

export const REMOVE_ERROES = 'REMOVE_ERROES';
export const INIT_MODE = 'INIT_MODE';
export const EDIT_STATUS_HISTORY = 'EDIT_STATUS_HISTORY';
export const ADD_ERROES = 'ADD_ERROES';

export const INIT_SIGNALS = 'INIT_SIGNALS';
export const INIT_ALL_SIGNALS = 'INIT_ALL_SIGNALS';
export const UPDATE_ALL_SIGNALS = 'UPDATE_ALL_SIGNALS';
export const REMOVE_ALL_SIGNALS = 'REMOVE_ALL_SIGNALS';
export const FILTER_SIGNALS = 'FILTER_SIGNALS';

export const INIT_INFOCARDS = 'INIT_INFOCARDS';
export const ADD_INFOCARDS = 'ADD_INFOCARDS';
export const DELETE_INFOCARDS = 'DELETE_INFOCARDS';
export const EDIT_INFOCARD = 'EDIT_INFOCARD';

export const INIT_KUOBZES = 'INIT_KUOBZ';
export const CLEAR_KUOBZES = 'CLEAR_KUOBZES';
export const ADD_KUOBZ = 'ADD_KUOBZ';
export const UPDATE_SINGLE_KUOBZ = 'UPDATE_SINGLE_KUOBZ';

export const INIT_STATUSES = 'INIT_STATUSES';
export const ADD_ERROR = 'ADD_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const DELETE_SELECT_OPTIONS = 'DELETE_SELECT_OPTIONS';
export const LOAD = 'LOAD';
export const LOAD_REQUEST = 'LOAD_REQUEST';
export const SUCCESS = 'SUCCESS';
export const REMOVE = 'REMOVE';
export const GET_REPORT = 'GET_REPORT';
export const INIT_REPORTS = 'INIT_REPORTS';
export const RESET_MESH = 'RESET_MESH';
export const CLEAR_STAUSES = 'CLEAR_STAUSES';
export const REMOVE_CURRENT_LOCATION_WORK = 'REMOVE_CURRENT_LOCATION_WORK';
export const FILTER_REPORTS = 'FILTER_REPORTS';
export const INIT_TRANSFER_OBJECT = 'INIT_TRANSFER_OBJECT';
export const INIT_ARCHIVE = 'INIT_ARCHIVE';
export const ADD_ANDROID_ID_OPTIONS = 'ADD_ANDROID_ID_OPTIONS';
export const INIT_ARCHIVE_SESSION = 'INIT_ARCHIVE_SESSION';
export const INIT_ARCHIVE_FILTERPARAMS = 'INIT_ARCHIVE_FILTERPARAMS';
export const REMOVE_ARCHIVE_FILTERPARAMS = 'REMOVE_ARCHIVE_FILTERPARAMS';

export const INIT_RAIL_CHAINS = 'INIT_RAIL_CHAINS';
export const INIT_MRM_IDS = 'INIT_MRM_IDS';
export const ADD_ANDROID_ID_OPTIONS_FOR_IUR = 'ADD_ANDROID_ID_OPTIONS_FOR_IUR';

export const EDIT_RAIL_CHAINS = 'EDIT_RAIL_CHAINS';
export const DELETE_RAIL_CHAINS = 'DELETE_RAIL_CHAINS';
export const ADD_RAIL_CHAINS = 'ADD_RAIL_CHAINS';
export const INIT_ADJACENCY = 'INIT_ADJACENCY';

export const ADD_RAILCHAIN_OPTIONS = 'ADD_RAILCHAIN_OPTIONS';
export const ADD_ADJACENCY = 'ADD_ADJACENCY';
export const SET_ADJACENCY = 'SET_ADJACENCY';
export const DELETE_ADJACENCY = 'DELETE_ADJACENCY';
export const CLEAR_ADJACENCY_STATE = 'CLEAR_ADJACENCY_STATE';
export const CLEAR_RAIL_CHAINS_STATE = 'CLEAR_RAIL_CHAINS_STATE';
export const CLEAR_SEMAPHORES_STATE = 'CLEAR_SEMAPHORES_STATE';

export const INIT_SEMAPHORES = 'INIT_SEMAPHORES';
export const DELETE_SEMAPHORES = 'DELETE_SEMAPHORES';
export const ADD_SEMAPHORES = 'ADD_SEMAPHORES';
export const EDIT_SEMAPHORES = 'EDIT_SEMAPHORES';

export const INIT_FILES = 'INIT_FILES';
export const ADD_FILES = 'ADD_FILES';
export const REMOVE_FILES = 'REMOVE_FILES';
export const FILTER_FILES = 'FILTER_FILES';

export const ADD_DIRECTORATES_UIPPS_OPTIONS = 'ADD_DIRECTORATES_UIPPS_OPTIONS';
export const ADD_DIRECTORATES_OPTIONS = 'ADD_DIRECTORATES_OPTIONS';
export const ADD_BRIGADES_OPTIONS = 'ADD_BRIGADES_OPTIONS';

export const SELECT_PARAMETER = 'SELECT_PARAMETER';
export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';

export const EDIT_LANGUAGE = 'EDIT_LANGUAGE';
export const ADD_POSITION = 'ADD_POSITION';
