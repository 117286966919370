import {
  INIT_DEVICES,
  EDIT_DEVICE,
  SERVICE_DEVICE,
  EDIT_STATUS_HISTORY,
  ADD_DEVICES,
  EDIT_DEVICE_STATUS,
  CLEAR_DEVICES,
  UPDATE_SINGLE_DEVICE,
} from '../type';
import axiosInstance from '../../config/axios.config';
import { addError } from './error.action';
import { success } from './loader.action';
import { addNewUipps, getOnlyOneUippsFromServer } from './uipps.action';
import { initStatusesFromServer } from './statuses.action';
import { initIurAndroidId } from '../actions/options.action';

export const initDevices = (devices) => ({
  type: INIT_DEVICES,
  payload: devices,
});

export const addDevices = (device) => ({
  type: ADD_DEVICES,
  payload: device,
});

export const editDevices = (device) => ({
  type: EDIT_DEVICE,
  payload: device[0],
});

export const editDevicesStatus = (status) => ({
  type: EDIT_DEVICE_STATUS,
  payload: status,
});

export const serviceDevices = (device) => ({
  type: SERVICE_DEVICE,
  payload: device,
});

export const editStatusHistory = (statusHistory) => ({
  type: EDIT_STATUS_HISTORY,
  payload: statusHistory,
});

export const clearDevices = () => ({
  type: CLEAR_DEVICES,
  payload: [],
});

export const updateSingle = (device) => ({
  type: UPDATE_SINGLE_DEVICE,
  payload: device,
});


// получение всех устройств
export const initDevicesFromServer = (query) => async (dispatch, getState) => {
  try {
    const result = await axiosInstance(`/api/devices${query}`);

    if (result.data && result.status === 200) {
      dispatch(initDevices(result.data));
      return result; // Возвращаем данные для использования в .then()
    }
  } catch (error) {
    console.log(error);
  }
};

// добавление нового устройства
export const newDeviceFromServer = (payload, formData) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/api/devices', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response.status === 201) {
      dispatch(success());
      if (formData?.typeId === 4) {
        dispatch(addNewUipps(response.data));
      } else {
        dispatch(addDevices(response.data));
      }
    }
  } catch (error) {
    const message = error?.response?.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// изменение устройства
export const editDeviceFromServer = (payload, formData, typeUipps) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/api/devices`, payload);
    if (response.status === 200) {
      // записываем в стейт сообщение об успешном выполнении

      dispatch(success());
      if (typeUipps) {
        dispatch(getOnlyOneUippsFromServer(formData.deviceId));
      } else {
        dispatch(getOnlyOneDeviceFromServer(formData.deviceId));
      }
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// Получение обновлённых данных устройства после изменения его данных
export const getOnlyOneDeviceFromServer = (id) => async (dispatch) => {
  try {
    const uippsData = await axiosInstance(`/api/devices?id=${id}`);
    if (uippsData.data[0]) {
      dispatch(updateSingle(uippsData.data[0]));
    }
  } catch (error) {
    console.log(error);
  }
};


//проведение ТО
export const deviceServiceFromServer = (payload, formData, typeUipps) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(`/api/devices/maintanance`, payload);
    if (response.status === 201) {
      dispatch(success());
      if (typeUipps) {
        dispatch(getOnlyOneUippsFromServer(formData.deviceId));
      } else {
        dispatch(getOnlyOneDeviceFromServer(formData.deviceId));
      }
      dispatch(initStatusesFromServer(formData.deviceId, 'archive'));
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// передача устройства на хранение
export const transferDeviceForStorage = (payload, statuses, type, modalType) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(`/api/devices/storage`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response.status === 201 && response.data.statusId) {
      dispatch(success());
      if (type !== 4) {
        dispatch(getOnlyOneDeviceFromServer(response.data.deviceId));
      }
      if (type === 4) {
        dispatch(getOnlyOneUippsFromServer(response.data.deviceId));
      }
      await dispatch(initStatusesFromServer(response.data.deviceId, modalType));
    }
  } catch (error) {
    const message = error?.response?.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// выдача устройства в эксплуатацию
export const transferDeviceForOperation = (payload, statuses, uipps) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/api/devices/usage', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response.status === 201 && response.data.statusId && !uipps) {
      dispatch(success());
      dispatch(getOnlyOneDeviceFromServer(payload.get('deviceId')));
    }
    if (response.status === 201 && response.data.statusId && uipps) {
      dispatch(success());
      dispatch(getOnlyOneUippsFromServer(payload.get('deviceId')));
    }
    dispatch(initStatusesFromServer(payload.get('deviceId'), 'usage'));
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// передача устройства в ремонт
export const transferDeviceForRepair = (payload, statuses, type) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/api/devices/repair', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response.status === 201 && response.data.statusId) {
      dispatch(success());
      if (type !== 4) {
        dispatch(getOnlyOneDeviceFromServer(payload.get('deviceId')));
      }
      if (type === 4) {
        dispatch(getOnlyOneUippsFromServer(payload.get('deviceId')));
      }
      dispatch(initStatusesFromServer(payload.get('deviceId'), 'repair'));
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// списание устройства
export const transferDeviceForWriteOff = (payload, statuses, type) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/api/devices/archive', payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (response?.status === 201 && response?.data?.statusId) {
      dispatch(success());
      if (type !== 4) {
        dispatch(getOnlyOneDeviceFromServer(payload.get('deviceId')));
      }
      if (type === 4) {
        dispatch(getOnlyOneUippsFromServer(payload.get('deviceId')));
      }
      dispatch(initStatusesFromServer(payload.get('deviceId'), 'archive'));
    }
  } catch (error) {
    const message = error?.response?.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// запрос на получение массив android_id [string]
export const initIurAndroidIdFromServer = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance('/api/devices/unauthorized');
    if (data) {
      dispatch(initIurAndroidId(data));
    }
  } catch (error) {
    console.log(error);
  }
};
