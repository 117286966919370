// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: var(--sadr-font-size-420);
  min-height: var(--sadr-font-size-60);
  border-radius: var(--sadr-font-size-12);
  background: var(--sadr-success);
  font-family: var(--sadr-font-family);
  font-style: normal;
  font-weight: 600;
  font-size: var(--sadr-font-size);
  line-height: var(--sadr-font-size-30);
  text-align: center;
  color: var(--sadr-background-secondary);
  border: none;
  box-sizing: border-box;
  /* margin-top: auto;
  margin-bottom: var(--sadr-font-size-30)  !important; */
}
.custom-btn:disabled {
  background-color: #adadad;
}

.custom-btn:hover {
  opacity: 0.9;
}

.custom-btn.white-on-hover:hover {
  /* background-color: #f8fff8 !important; */
}
.custom-btn-white:hover {
  transform: translateY(-2px);
  box-shadow: 2px 2px 0 var(--sadr-success);
}
`, "",{"version":3,"sources":["webpack://./src/styles/CustomButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,UAAU;EACV,gCAAgC;EAChC,oCAAoC;EACpC,uCAAuC;EACvC,+BAA+B;EAC/B,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,qCAAqC;EACrC,kBAAkB;EAClB,uCAAuC;EACvC,YAAY;EACZ,sBAAsB;EACtB;wDACsD;AACxD;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,0CAA0C;AAC5C;AACA;EACE,2BAA2B;EAC3B,yCAAyC;AAC3C","sourcesContent":[".custom-btn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: 0;\n  width: var(--sadr-font-size-420);\n  min-height: var(--sadr-font-size-60);\n  border-radius: var(--sadr-font-size-12);\n  background: var(--sadr-success);\n  font-family: var(--sadr-font-family);\n  font-style: normal;\n  font-weight: 600;\n  font-size: var(--sadr-font-size);\n  line-height: var(--sadr-font-size-30);\n  text-align: center;\n  color: var(--sadr-background-secondary);\n  border: none;\n  box-sizing: border-box;\n  /* margin-top: auto;\n  margin-bottom: var(--sadr-font-size-30)  !important; */\n}\n.custom-btn:disabled {\n  background-color: #adadad;\n}\n\n.custom-btn:hover {\n  opacity: 0.9;\n}\n\n.custom-btn.white-on-hover:hover {\n  /* background-color: #f8fff8 !important; */\n}\n.custom-btn-white:hover {\n  transform: translateY(-2px);\n  box-shadow: 2px 2px 0 var(--sadr-success);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
