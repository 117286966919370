// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adjacency{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.adjacency-container .container-select {
  align-items: center;
}
.adjacency-table-body{
  max-width: calc(var(--sadr-font-size-420)*2 + 20%);
  margin: 0 auto;
  border-collapse: collapse;
}
.adjacency-buttons{
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  justify-self: flex-end;
}
.selector-cell{
  max-width: var(--sadr-font-size-420);
  text-align: center;
}
.adjacency-actions{
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.adjacency__del-button{
  padding-bottom: var(--sadr-font-size-20);
}`, "",{"version":3,"sources":["webpack://./src/styles/AdjacencyEdit.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,2BAA2B;EAC3B,mBAAmB;EACnB,qBAAqB;EACrB,gBAAgB;EAChB,6CAA6C;EAC7C,4CAA4C;EAC5C,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,kDAAkD;EAClD,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,sBAAsB;AACxB;AACA;EACE,oCAAoC;EACpC,kBAAkB;AACpB;AACA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,wCAAwC;AAC1C","sourcesContent":[".adjacency{\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  justify-content: flex-start;\n  align-items: center;\n  --bs-gutter-x: 1.5rem;\n  --bs-gutter-y: 0;\n  padding-right: calc(var(--bs-gutter-x) * 0.5);\n  padding-left: calc(var(--bs-gutter-x) * 0.5);\n  margin-right: auto;\n  margin-left: auto;\n}\n\n.adjacency-container .container-select {\n  align-items: center;\n}\n.adjacency-table-body{\n  max-width: calc(var(--sadr-font-size-420)*2 + 20%);\n  margin: 0 auto;\n  border-collapse: collapse;\n}\n.adjacency-buttons{\n  display: flex;\n  width: 100%;\n  flex-wrap: nowrap;\n  justify-self: flex-end;\n}\n.selector-cell{\n  max-width: var(--sadr-font-size-420);\n  text-align: center;\n}\n.adjacency-actions{\n  width: fit-content;\n  margin: 0 auto;\n}\n.adjacency__del-button{\n  padding-bottom: var(--sadr-font-size-20);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
