import { ADD_KUOBZ, CLEAR_KUOBZES, INIT_KUOBZES, UPDATE_SINGLE_KUOBZ } from '../type';
import axiosInstance from '../../config/axios.config';
import { addError } from './error.action';
import { success } from './loader.action';

export const initKuobzes = (kuobzes) => ({
  type: INIT_KUOBZES,
  payload: kuobzes,
});

export const clearKuobzes = () => ({
  type: CLEAR_KUOBZES,
  payload: [],
});

export const updateSingle = (kuobz) => ({
  type: UPDATE_SINGLE_KUOBZ,
  payload: kuobz,
});

export const addKuobz = (kuobz) => ({
  type: ADD_KUOBZ,
  payload: kuobz,
});
// получение всех устройств
export const initKuobzesFromServer = (query) => async (dispatch, getState) => {
  try {
    // получение данных с сервера
    const result = await axiosInstance(`/api/kuobz${query}`);

    if (result.data && result.status === 200) {
      dispatch(initKuobzes(result.data));
      return result; // Возвращаем данные для использования в .then()
    }
  } catch (error) {
    console.log(error);
  }
};

// добавление нового устройства
export const newKuobzFromServer = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/api/kuobz', payload);
    if (response.status === 201) {
      // записываем в стейт сообщение об успешном выполнении
      dispatch(success());

      dispatch(addKuobz(response.data));
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// изменение устройства
export const editKuobzFromServer = (payload, kuobzId) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/api/kuobz/${kuobzId}`, payload);
    if (response.status === 200) {
      // записываем в стейт сообщение об успешном выполнении
      dispatch(success());
      dispatch(getOnlyOneKuobzFromServer(kuobzId));
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// Получение обновлённых данных КУОБЗ после изменения его данных
export const getOnlyOneKuobzFromServer = (id) => async (dispatch) => {
  try {
    const kuobzData = await axiosInstance(`/api/kuobz?id=${id}`);
    if (kuobzData.data[0]) {
      dispatch(updateSingle(kuobzData.data[0]));
    }
  } catch (error) {
    console.log(error);
  }
};
