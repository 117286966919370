import axiosInstance from '../../config/axios.config';
// сокеты передаются в Экшены в сторе
import {
  ADD_BRIGADE_COMPOSITION,
  ADD_ERROES,
  REMOVE_BRIGADE_INFO,
  INIT_BRIGADE_COMPOSITION,
  INIT_BRIGADE_INFO,
  INIT_BRIGADES,
  INIT_CURRENT_LOCATION_WORK,
  INIT_LOCATION_WORK,
  INIT_MODE,
  INIT_SIGNALS,
  INIT_TRANSFER_OBJECT,
  REMOVE_BRIGADE_COMPOSITION,
  REMOVE_BRIGADE_SIGNALS,
  REMOVE_ERROES,
  INIT_ALL_SIGNALS,
  UPDATE_ALL_SIGNALS,
  RESET_MESH,
  REMOVE_CURRENT_LOCATION_WORK,
  REMOVE_ALL_SIGNALS,
  ADD_BRIGADES_COORDS,
  UPDATE_BRIGADE,
  CLEAR_BRIGADES,
  UPDATE_SINGLE_BRIGADE,
} from '../type';

import { addError } from './error.action';
import { emitWithTokens } from '../../utils/emitWithTokens';

import { lookAfterMapArray } from './mapData.action';

/**SOCET */
/** Экшены - генерация событий */
// отправляем событие чтобы сервер начал слать координаты
export const emitGetCoordsStart =
  () =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'coords_listen_start');
  };

// отправляем событие чтобы сервер перестал слать координаты
export const emitGetCoordsStop =
  () =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'coords_listen_stop');
  };

// отправляем событие чтобы сервер начал слать инфо по бригадам
export const emitBrigadeListenStart =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'brigade_listen_start', data);
  };

// отправляем событие чтобы сервер начал слать инфо по сигналам
export const emitPlaceWorkListenStart =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'info_listen_start', data);
  };

// отправляем событие чтобы получить состав бригады
export const emitBrigadeComposition =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'brigade_get', data);
  };

// отправляем событие чтобы получить место работ
export const emitLocationWork =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'location_choose_get', data);
  };

// отправляем событие чтобы получить сигналы(таблица из вкладки сигналы )
export const emitBrigadeAllSignals =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'signals_listen_start', data);
  };

// отправляем событие для начала работ
export const emitStartWork =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'start_session', data);
  };

// отправляем событие выхода на путь
export const emitWayOut =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'exit_rails', data);
  };

// отправляем событие для изменения места  работ
export const emitEditPlaceWork =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'location_choose_set', data);
  };

// включение режима 'тихо' или 'громко' в зависимости от data
export const emitSignalModeChange =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'set_signal_mode', data);
  };

// включение режима 'зона' или 'движение' в зависимости от data
export const emitWorkModeChange =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'set_work_mode', data);
  };

// включение режима Ограждение
export const emitRestrictOn =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'btn_restrict_start', data);
  };

// вЫключение режима Ограждение
export const emitRestrictOff =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'btn_restrict_finish', data);
  };

// получение РЦ для переноса
export const emittGetTansferObjects =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'transfer_objects_get', data);
  };

// отправка рц для переноса
export const emitTransferOn =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'transfer_objects_select', data);
  };

// включение режима Перенос
export const emitTransferStart =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'btn_transfer_start', data);
  };

// вЫключение режима Перенос
export const emitTransferFencingOff =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'btn_transfer_finish', data);
  };

// Работа без КУОБЗ
export const emitSetStandalone =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'set_standalone', data);
  };

// Ввод ординаты
export const emitSetCanEnterOrd =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'set_can_enter_ord', data);
  };

// отправляем событие подтверждения "Завершить работу"
export const emitFinishWork =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'finish_session', data);
  };

// отправляем событие подтверждения добавления устройства
export const emitBrigadeCompositionAddDevice =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'brigade_add', data);
  };

// отправляем событие подтверждения исключения устройства
export const emitBrigadeCompositionRemoveDevice =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'brigade_remove', data);
  };

// отправляем событие чтобы сервер закончил слать инфо
export const emitPlaceWorkListenStop =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'info_listen_stop', data);
  };

// отправляем событие чтобы сервер закончил слать инфо
export const emitBrigadeAllSignalsListenStop =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'signals_listen_stop', data);
  };

// отправляем событие чтобы сервер закончил слать инфо
export const emitBrigadeListenStop =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'brigade_listen_stop', data);
  };

// отправляем событие для сброса Mesh
export const emitResetMesh =
  (data) =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'reset_mesh', data);
  };

/** Экшены - прослушивание событий и обновления стейтов */

// обновление стейта бригад
export const updateBrigade = (data) => ({
  type: UPDATE_BRIGADE,
  payload: data,
});

// слушаем событие
export const listenUpdateBrigade =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('update_brigade', (data) => {
      dispatch(updateBrigade(data));
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('update_brigade');
    };
  };

// обновление стейта
export const initBrigadeInfo = (options) => ({
  type: INIT_BRIGADE_INFO,
  payload: options,
});

// слушаем событие
export const listenBrigadeInfo =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('brigade_info', (data) => {
      dispatch(initBrigadeInfo(data));
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('brigade_info');
    };
  };

// обновление стейта
export const addCoords = (coords) => ({
  type: ADD_BRIGADES_COORDS,
  payload: coords,
});

// слушаем событие получения координат
// слушаем событие получения координат
export const listenBrigadesCoords =
  () =>
  (dispatch, getState, { socket }) => {
    socket.on('brigades_coords', (data) => {
      if (!data) return;
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      dispatch(addCoords(data));
      // Запуск экшена для проверки области видимости, фильтров и обновления стейта с для карты
      dispatch(lookAfterMapArray(data, 'Бригада'));
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('brigades_coords');
    };
  };

// обновление стейта
export const initMode = (options) => ({
  type: INIT_MODE,
  payload: options,
});

// обновление стейта
export const initSignals = (options) => ({
  type: INIT_SIGNALS,
  payload: options,
});

// слушаем событие
export const listenPlaceWorkInfo =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('info', (data) => {
      dispatch(initSignals(data));
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('info');
    };
  };

// обновление стейта
export const initALLSignals = (options) => ({
  type: INIT_ALL_SIGNALS,
  payload: options,
});

export const updateALLSignals = (options) => ({
  type: UPDATE_ALL_SIGNALS,
  payload: options,
});

// слушаем событие
// export const listenBrigadeAllSignalsInfo = () => (dispatch, getState, { socket }) => {
//   socket?.on('get_all_signals_reply', (data) => {
//     dispatch(initSignals(data));
//   });
// //возвращение функции для отписки от события при размонтировании компонента
//   return () => {
//     socket.off('get_all_signals_reply');
//   };
// };

// слушаем событие для получения сигналов
export const listenBrigadeAllSignalsInfo =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('signals', (data) => {
      dispatch(initALLSignals(data));
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('signals');
    };
  };

// обновление стейта
export const initLocationWork = (options) => ({
  type: INIT_LOCATION_WORK,
  payload: options,
});
// слушаем событие
export const listenLocationWorkInfo =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('location_choose_get_reply', (data) => {
      dispatch(initLocationWork(data));
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('location_choose_get_reply');
    };
  };

// обновление стейта информации по выбранному месту работы
export const initCurrentLocationWork = (options) => ({
  type: INIT_CURRENT_LOCATION_WORK,
  payload: options,
});
// слушаем событие  получаем информацию по выбранному месту работы
export const listenCurrentLocationWorkInfo =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('work_place_info', (data) => {
      dispatch(initCurrentLocationWork(data));
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('work_place_info');
    };
  };

// обновление стейта состава бригады
export const initBrigadeCompositionInfo = (options) => ({
  type: INIT_BRIGADE_COMPOSITION,
  payload: options,
});
// слушаем событие, получаем состав бригады
export const listenBrigadeCompositionInfo =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('brigade_get_reply', (data) => {
      dispatch(initBrigadeCompositionInfo(data));
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('brigade_get_reply');
    };
  };

// обновление стейта состава бригады
export const removeErrors = (options) => ({
  type: REMOVE_ERROES,
  payload: options,
});

export const clearBrigades = () => ({
  type: CLEAR_BRIGADES,
  payload: [],
});

export const addErrors = (options) => ({
  type: ADD_ERROES,
  payload: options,
});
export const removeDeviceFromBrigadeComposition = (options) => ({
  type: REMOVE_BRIGADE_COMPOSITION,
  payload: options,
});
// слушаем событие, получаем подтверждение удаления устройства из состава бригады
export const listenBrigadeCompositionRemoveDevice =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('brigade_remove_reply', (data) => {
      if (data.result === 'ok') {
        dispatch(removeDeviceFromBrigadeComposition(data?.id));
      } else {
        dispatch(addError({ error: 'При исключении устройства произошла ошибка' }));
      }
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('brigade_remove_reply');
    };
  };

// обновление стейта состава бригады
export const addDeviceFromBrigadeComposition = (options) => ({
  type: ADD_BRIGADE_COMPOSITION,
  payload: options,
});

// слушаем событие, получаем подтверждение удаления устройства из состава бригады
export const listenBrigadeCompositionAddDevice =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('brigade_add_reply', (data) => {
      if (data.result === 'ok') {
        dispatch(addDeviceFromBrigadeComposition(data));
      }
      if (data.result === 'error') {
        dispatch(addErrors(data));
      }
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('brigade_add_reply');
    };
  };

// обновление стейта РЦ для переноса
export const initTransferObjectFromBrigade = (data) => ({
  type: INIT_TRANSFER_OBJECT,
  payload: data,
});

// слушаем событие, получаем РЦ для переноса
export const listenGetTransferObjectsReply =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('transfer_objects_get_reply', (data) => {
      if (data) {
        dispatch(initTransferObjectFromBrigade(data));
      }
      if (!data) {
        dispatch(addErrors({ error: data }));
      }
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('transfer_objects_get_reply');
    };
  };

// обновление стейта resetMesh при сбросе Mesh
export const resetMesh = (options) => ({
  type: RESET_MESH,
  payload: options,
});

// слушаем событие, получаем подтверждение сброса Mesh
export const listenResetMesh =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('reset_mesh_reply', (data) => {
      if (data.result === 'ok') {
        dispatch(resetMesh(data.result));
      }
      if (data.result === 'error') {
        dispatch(resetMesh(data.result));
      }
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('reset_mesh_reply');
    };
  };

/**HTTP */
/** Экшены - отправки запросов и обновления стейтов */
export const initBrigades = (brigades) => ({
  type: INIT_BRIGADES,
  payload: brigades,
});

export const updateSingle = (device) => ({
  type: UPDATE_SINGLE_BRIGADE,
  payload: device,
});

export const initBrigadesFromServer = (query) => async (dispatch, getState) => {
  try {
    const result = await axiosInstance(`/api/brigades${query}`);

    if (result.data && result.status === 200) {
      await dispatch(initBrigades(result.data));
      return result; // Возвращаем данные для использования в .then()
    }
  } catch (error) {
    console.log(error);
  }
};

// запрос на обновление массива одной бригады в массиве всех бригад
export const initBrigadeAfterUpdate = (updatedBrigadeId) => async (dispatch) => {
  try {
    if (updatedBrigadeId) {
      const result = await axiosInstance(`/api/brigades?id=${updatedBrigadeId}`);
      if (result.data && result.status === 200) {
        dispatch(updateSingle(result.data[0]));
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const getDeviceById = (deviceId) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/brigades?device_id=${encodeURIComponent(deviceId)}`);
    dispatch(clearBrigades());
    dispatch(initBrigades(data));
  } catch (error) {
    console.log(error);
  }
};

// очищаем стейт сместом работ при закрытии вкладки бригада
export const removeStateCurrentLocationsWork = () => ({
  type: REMOVE_CURRENT_LOCATION_WORK,
  payload: {},
});

// очищаем стейт исгналов после закратия вкладки сигналы
export const removeStateAllSignals = () => ({
  type: REMOVE_ALL_SIGNALS,
  payload: [],
});

// очищаем стейт brigadeInfo после закратия вкладки бригады
export const removeStatebrigadeInfo = () => ({
  type: REMOVE_BRIGADE_INFO,
  payload: {},
});

// очищаем стейт brigadeInfo после закратия вкладки бригады
export const removeStateBrigadeSignals = () => ({
  type: REMOVE_BRIGADE_SIGNALS,
  payload: {},
});
