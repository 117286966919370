import { useEffect } from 'react';
import Input from './Input'; // Assuming you have these components
import Selector from './Selector';
import Checkbox from './Checkbox';
import { handleUniversalChange } from '../../utils/formUtils';
import '../../styles/FileSelector.css';

const FileSelector = ({
  t,
  formData,
  setFormData,
  attachmentOptions,
  column, // left/right
  file, // field - field name (acceptanceCert), name - file name field name (acceptanceCertName), label
}) => {
  //при размонтировании чекбокс переключаем в фолс, так как некоторые формы используются для редактирования и создания нового статуса
  useEffect(() => {
    return attachmentOptions?.setIsFile(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="file-selector">
      {!attachmentOptions.isFile ? (
        <Input
          column={column}
          placeholder={t?.placeholderSelectCertFile || 'Выберите файл'}
          onChange={(event) => handleUniversalChange(formData, setFormData, event, null, null, null, file?.name)}
          onRemove={() =>
            handleUniversalChange(formData, setFormData, null, 'isRemove', null, null, file?.field, file?.name)
          }
          type="file"
          name={file?.field}
          label={file?.label}
          width="100%"
          height="var(--sadr-font-size-60)"
          isDisabled={false}
          maxFileSize={20}
          fileName={formData?.[file?.name]}
        />
      ) : (
        <Selector
          column={column}
          options={attachmentOptions.optionsFiles}
          onChange={(selectedOption) => {
            handleUniversalChange(formData, setFormData, null, file?.field, selectedOption, 'select');
            attachmentOptions.handleSelectFile(selectedOption);
          }}
          placeholder={t?.placeholderSelectCertFile || 'Выберите файл'}
          label={file?.label}
          width="100%"
          height="var(--sadr-font-size-60)"
          value={attachmentOptions.optionsFiles?.find((option) => option.value === formData?.[file?.field])}
          isMulti={false}
          name="attachment"
        />
      )}
      <Checkbox
        column={column}
        height={`var(--sadr-font-size-60)`}
        width="100%"
        label={t?.labelSelectExistingFile || 'Выбрать существующий файл'}
        onChange={() => attachmentOptions.handleChangeCheckboxFile()}
        checked={attachmentOptions.isFile}
        selfAlign ='start'
      />
    </div>
  );
};

export default FileSelector;
