import {
  INIT_UIPPS,
  EDIT_UIPPS_STATUS,
  ADD_UIPPS_COORDS,
  CLEAR_UIPPS,
  UPDATE_SINGLE_UIPPS,
  ADD_NEW_UIPPS,
} from '../type';
import axiosInstance from '../../config/axios.config';
import { emitWithTokens } from '../../utils/emitWithTokens';
import { initUippsAndroidId } from '../actions/options.action';
import { lookAfterMapArray } from './mapData.action';

export const initUippss = (uipps) => ({
  type: INIT_UIPPS,
  payload: uipps,
});

export const updateSingle = (device) => ({
  type: UPDATE_SINGLE_UIPPS,
  payload: device,
});
export const clearUippss = () => ({
  type: CLEAR_UIPPS,
  payload: [],
});

export const addNewUipps = (device) => ({
  type: ADD_NEW_UIPPS,
  payload: device,
});

export const editUippsStatus = (device) => ({
  type: EDIT_UIPPS_STATUS,
  payload: device,
});

// запрос на получение списка uipps, с обработкой фильтрации и поиска
export const initUippsFromServer = (query) => async (dispatch, getState) => {
  try {
    // получение данных с сервера
    const result = await axiosInstance(`/api/devices/uipps${query}`);
    if (result.data && result.status === 200) {
      await dispatch(initUippss(result.data));
      return result; // Возвращаем данные для использования в .then()
    }
  } catch (error) {
    console.log(error);
  }
};

// запрос на обновление массива uipps после изменения uipps
export const initUippsAfterUpdate =
  ([updatedUipps]) =>
  async (dispatch) => {
    try {
      if (updatedUipps) {
        await dispatch(updateSingle(updatedUipps));
      }
    } catch (error) {
      console.log(error);
    }
  };

// запрос на получение одного uipps после изменения uipps
export const getOnlyOneUippsFromServer = (id) => async (dispatch) => {
  try {
    const uippsData = await axiosInstance(`/api/devices/uipps?id=${id}`);
    if (uippsData.data[0]) {
      dispatch(updateSingle(uippsData.data[0]));
    }
  } catch (error) {
    console.log(error);
  }
};


// запрос на получение массив android_id [string]
export const initUippsAndroidIdFromServer = () => async (dispatch) => {
  try {
    const { data } = await axiosInstance('/api/devices/uipps/unauthorized');
    if (data) {
      dispatch(initUippsAndroidId(data));
    }
  } catch (error) {
    console.log(error);
  }
};

// отправляем событие чтобы сервер начал слать координаты уипсов
export const emitGetCoordsStart =
  () =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'uipps_listen_start');
  };

// отправляем событие чтобы сервер перестал слать координаты уипсов
export const emitGetCoordsStop =
  () =>
  (dispatch, getState, { socket }) => {
    emitWithTokens(socket, 'uipps_listen_stop');
  };

// обновление стейта
export const addCoords = (coords) => ({
  type: ADD_UIPPS_COORDS,
  payload: coords,
});

// слушаем событие получения координат
export const listenUippsCoords =
  () =>
  (dispatch, getState, { socket }) => {
    socket.on('uipps_coords', (data) => {
      if (!data) return;
      if (typeof data === 'string') {
        data = JSON.parse(data);
      }
      dispatch(addCoords(data));
      // Запуск экшена для проверки области видимости, фильтров и обновления стейта  для карты
      dispatch(lookAfterMapArray(data, 'УИППС'));
    });

    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('uipps_coords');
    };
  };
