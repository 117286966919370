// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-for-files-container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  overflow: auto;
}

.table-for-documentation_btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: var(--sadr-font-size-20);
  padding: var(--sadr-font-size-20);
  padding-top: var(--sadr-font-size-40);
  background-color: var(--sadr-background-secondary);
  border-bottom-left-radius: var(--sadr-font-size-12);
  border-bottom-right-radius: var(--sadr-font-size-12);
}

.margin-none {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/TableForFiles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,4BAA4B;EAC5B,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,uCAAuC;EACvC,iCAAiC;EACjC,qCAAqC;EACrC,kDAAkD;EAClD,mDAAmD;EACnD,oDAAoD;AACtD;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".table-for-files-container {\n  display: flex;\n  flex-direction: column;\n  align-content: space-between;\n  justify-content: space-between;\n  overflow: auto;\n}\n\n.table-for-documentation_btn-container {\n  display: flex;\n  justify-content: center;\n  margin-bottom: var(--sadr-font-size-20);\n  padding: var(--sadr-font-size-20);\n  padding-top: var(--sadr-font-size-40);\n  background-color: var(--sadr-background-secondary);\n  border-bottom-left-radius: var(--sadr-font-size-12);\n  border-bottom-right-radius: var(--sadr-font-size-12);\n}\n\n.margin-none {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
