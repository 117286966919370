import { ADD_ERROR, CLEAR_ERROR } from '../type';

export const addError = (error) => ({
  type: ADD_ERROR,
  payload: error,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
  payload: {},
});

// слушаем событие
export const listenSocketError =
  () =>
  (dispatch, getState, { socket }) => {
    socket?.on('error', (data) => {
      // if (data) {
      //   dispatch(addError(data));
      // }
    });
    //возвращение функции для отписки от события при размонтировании компонента
    return () => {
      socket.off('error');
    };
  };
