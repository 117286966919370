import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../styles/Uipps.css';
import Modal from '../Modal/Modal';
import EditDevice from '../Devices/EditDevice';
import ServiceDevice from '../Devices/ServiceDevice';
import { DeviceHeader } from '../Devices/DeviceHeader';
import DeviceArchiveStatus from '../Statuses/DeviceArchiveStatus';
import DeviceOperationStatus from '../Statuses/DeviceOperationStatus';
import DeviceRepairStatus from '../Statuses/DeviceRepairStatus';
import DeviceStorageStatus from '../Statuses/DeviceStorageStatus';
import { clearStatuses } from '../../redux/actions/statuses.action';
import { remove } from '../../redux/actions/loader.action';

function Uipps({ uippsId, setCurrentUipps, formData, t }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  const uipps = useSelector((state) => state.uipps);
  const currentUipps = uipps?.find((uipps) => uipps?.id === uippsId);
  const [activeButton, setActiveButton] = useState('Эксплуатация');
  const [editUippsModal, setEditUippsModal] = useState(false);
  const [uippsService, setUippsService] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const contentDataStatus = {
    Эксплуатация: (
      <DeviceOperationStatus
        currentDevice={currentUipps}
        setActiveButton={setActiveButton}
        activeButton={activeButton}
        uippsType={true}
        t={t}
      />
    ),
    Хранение: (
      <DeviceStorageStatus
        currentDevice={currentUipps}
        setActiveButton={setActiveButton}
        activeButton={activeButton}
        uippsType={true}
        t={t}
      />
    ),
    Ремонт: (
      <DeviceRepairStatus
        currentDevice={currentUipps}
        setActiveButton={setActiveButton}
        activeButton={activeButton}
        t={t}
      />
    ),
    Архив: (
      <DeviceArchiveStatus
        currentDevice={currentUipps}
        setActiveButton={setActiveButton}
        activeButton={activeButton}
        t={t}
      />
    ),
  };

  /** Закрыть модальное окно редактирования*/
  const closeModalUippsEditAndService = () => {
    setEditUippsModal(false);
    setUippsService(false);
    setIsOpen(false);
    dispatch(remove());
  };

  // Открыть модалку для изменения устройства
  const handleButtonClickEdit = () => {
    setEditUippsModal(true);
    setIsOpen(true);
  };

  /** Открыть модалку для проведения ТО */
  const handleButtonClickService = () => {
    setUippsService(true);
    setIsOpen(true);
  };

  // при закрытии устройства обновляем стейт с устройствами что бы подтянуть измененные данные
  // очищаем стейты статусов
  useEffect(() => {
    return () => {
      dispatch(clearStatuses());
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className="w-100 h-100 div-grid">
      <DeviceHeader device={currentUipps} onEdit={handleButtonClickEdit} onService={handleButtonClickService} t={t}/>

      {/* Отрисовка таблиц Статусов */}
      <>{contentDataStatus[activeButton]}</>

      {/* Модальное окно */}
      <Modal
        isOpen={isOpen}
        onClose={closeModalUippsEditAndService}
        containerSelector={'.table-for-devices_or_uipps_container'}
      >
        {/* Компонент для изменения устройства */}
        {editUippsModal && (
          <EditDevice closeModalDevice={closeModalUippsEditAndService} currentDevice={currentUipps} typeUipps={true} />
        )}
        {/* Компонент для изменения устройства(выполнение ТО) */}
        {uippsService && (
          <ServiceDevice
            closeModalDevice={closeModalUippsEditAndService}
            currentDevice={currentUipps}
            typeUipps={true}
          />
        )}
      </Modal>
    </div>
  );
}

export default Uipps;
