import { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Хук для загрузки опций по выбранному ключевому полю, управления доступностью селектора
 * и сброса указанных полей в formData при отсутствии значения ключевого поля.
 *
 * @param {object} formData - Объект данных формы, содержащий ключевую информацию для загрузки опций.
 * @param {function} setFormData - Функция для обновления formData.
 * @param {Array<string>} resetFields - Массив имён полей в formData, которые будут сброшены при сбросе ключевого поля.
 * @param {string} keyField - Имя поля в formData, используемого для загрузки опций.
 * @param {function} fetchOptions - Функция для загрузки опций с сервера.
 * @param {string} selectMessage - Сообщение для отображения при изменениях селектора.
 * @returns {object} Объект с состояниями: isSelectorDisabled, message и onSelectorChange.
 */
function useOptionsLoader(formData, setFormData, resetFields, keyField, fetchOptions, selectMessage) {
  const dispatch = useDispatch(); // Hook для доступа к dispatch функции Redux.
  const [isSelectorDisabled, setIsSelectorDisabled] = useState(!formData[keyField]); // Состояние доступности селектора.
  const [message, setMessage] = useState(''); // Сообщение, отображаемое пользователю.

  // Используем useRef для хранения актуального selectMessage
  const selectMessageRef = useRef(selectMessage);

  // Обновляем ref при изменении selectMessage, но не обновляем state
  useEffect(() => {
    selectMessageRef.current = selectMessage;

    // Если сообщение уже установлено, обновляем его для актуального языка
    if (message) {
      setMessage(selectMessage);
    }
  }, [selectMessage, message]);

  // Используем useCallback для обновления onSelectorChange с использованием selectMessageRef
  const onSelectorChange = useCallback(() => {
    setMessage(selectMessageRef.current); // Установка актуального сообщения при клике на селектор
  }, []); // Пустой массив зависимостей, чтобы использовать всегда актуальное значение из useRef

  useEffect(() => {
    if (formData[keyField]) {
      // Если ключевое поле установлено, загружаем данные и разрешаем использование селектора.
      setMessage(''); // Очищаем сообщение.
      dispatch(fetchOptions(formData[keyField])); // Диспатчим функцию для загрузки данных.
      setIsSelectorDisabled(false); // Разрешаем использование селектора.
    } else {
      // Если ключевое поле не установлено, сбрасываем зависимые поля и блокируем селектор.
      setFormData(
        (prevFormData) => resetFields.reduce((acc, fieldName) => ({ ...acc, [fieldName]: '' }), prevFormData) // Сброс указанных полей.
      );
      setIsSelectorDisabled(true); // Блокируем селектор.
    }
    // Следим за изменениями в ключевом поле и перезапускаем эффект при их изменении.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData[keyField]]);

  return { isSelectorDisabled, message, onSelectorChange };
}

export default useOptionsLoader;
