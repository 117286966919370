import { ADD_KUOBZ, CLEAR_KUOBZES, INIT_KUOBZES, UPDATE_SINGLE_KUOBZ } from '../type';

export function kuobzReducer(state = [], action) {
  const { type, payload } = action;

  switch (type) {
    case INIT_KUOBZES: {
      //Проверяем, есть ли в payload уже существующие id. Как показала практика, это возможно
      // Создаем Set с существующими id
      const existingIds = new Set(state.map((item) => item.id));

      // Фильтруем payload, оставляя только уникальные элементы
      const newPayload = payload.filter((item) => !existingIds.has(item.id));

      // Добавляем уникальные элементы к существующему состоянию
      return [...state, ...newPayload];
    }
    case ADD_KUOBZ: {
      return [...payload, ...state];
    }

    case UPDATE_SINGLE_KUOBZ: {
      const updatedState = state.map((kuobz) => {
        if (kuobz.id === Number(payload.id)) {
          return { ...kuobz, ...payload };
        }
        return kuobz;
      });
      return updatedState;
    }
    case CLEAR_KUOBZES: {
      return payload;
    }
    default:
      return state;
  }
}
