/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CustomButton from '../CustomElements/CustomButton';
import useTranslations from '../../customHook/useTranslations';
import { logoutUser } from '../../redux/actions/user.action';
import translations from '../../content/MainHeader/mainHeaderTranslations.json';
import { setFilterState } from '../../redux/actions/filterState.action';
import '../../styles/HeaderForObject.css';

/**
 *
 * @param onFilterSubmit
 * @param setSearchInput
 * @param setGlobalFilter
 * @param searchInput
 * @param onFilterChange {(value: string, forced?: boolean)=>void}
 * @returns {Element}
 * @constructor
 */
function HeaderForObject({ onFilterSubmit, searchInput, onFilterChange, show = true }) {
  // Получение функции dispatch для отправки действий в Redux store.
  const dispatch = useDispatch();
  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);
  const [inputToSearch, setInputToSearch] = useState(searchInput);

  const searchState = useSelector((state) => state.filterState);

  useEffect(() => {
    if (!searchState.search && inputToSearch) {
      setInputToSearch('');
    }
  }, [searchState]);
  useEffect(() => {
    setInputToSearch(searchInput);
    if (searchInput) {
      dispatch(
        setFilterState({
          search: true,
          filter: false,
          blockFlag: searchInput.length === 1 ? true : false,
        })
      );
    }
  }, [searchInput]);

  useEffect(() => {
    if (searchState.search && searchState.blockFlag && searchInput.length === 1) {
      dispatch(
        setFilterState({
          search: true,
          filter: false,
          blockFlag: false,
        })
      );
    }
  }, [searchState]);

  const navigate = useNavigate();
  const signOut = useSignOut();
  const user = useSelector((state) => state.user);

  function logout() {
    dispatch(logoutUser({ logout: true }));
    signOut();
    navigate('/login');
  }

  return (
    <div className={`container-fluid d-flex flex-nowrap flex-row gap-3 header-for-object ${show ? '' : 'end'}`}>
      {show && (
        <input
          id="submit_search"
          autoComplete="off"
          className="header-for-object_input"
          placeholder={t?.searchPlaceholder}
          value={inputToSearch}
          onChange={(e) => {
            onFilterChange(e);
          }}
          onKeyDown={(e) => {
            if (e.key == 'Enter') onFilterSubmit?.(e);
            if (e.key == 'Escape') onFilterChange('', true);
          }}
        />
      )}
      {/* <button type="button" className="header-for-object_btn-search me-auto white-on-hover" onClick={(e) => onFilterSubmit?.(e)}>
        Найти
      </button> */}
      {show && (
        <CustomButton
          type="button"
          className="header-for-object_btn-search me-auto white-on-hover "
          color={'var(--sadr-background-secondary)'}
          text={t?.findButton}
          colorText={`var(--sadr-text-color)`}
          width={'10.625rem'}
          onClick={(e) => onFilterSubmit?.(e)}
          // border={`var(--sadr-label-color)`}
        />
      )}
      <div className="d-flex flex-row gap-3" style={{ height: 'var(--sadr-font-size-60)' }}>
        <div className="d-flex flex-column align-items-start align-self-center user-info">
          {/* <span className="header-for-object_text-user">{user?.fullName}</span>
          <span className="header-for-object_text-rople">{user?.position}</span> */}
          <span className="header-for-object_text-user">{user?.fullName}</span>
          <span className="header-for-object_text-rople w-100">-</span>
        </div>
        <button type="button" className="btn btn-dark header-for-object_btn-logout" href="#" onClick={logout}>
          {t?.logoutButton}
          <img className="header-for-object_icon" src="/image/exit.svg" alt=""></img>
        </button>
      </div>
    </div>
  );
}

export default HeaderForObject;
