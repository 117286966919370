/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { simulateOnChange } from '../../utils/htmlUtils';
import { useSelector } from 'react-redux';
import { React, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import MaskedInput from '../CustomElements/MaskedInput';
import Selector from '../CustomElements/Selector';
import { useLocation, useParams } from 'react-router-dom';
import useUrlNavigate from '../../customHook/useUrlNavigate';
import { useWasChanged } from '../../customHook/usePrevious';

const omitCellKeys = 'value getCellProps render'.split(' ');
export const useSelectEdit = ({
  options,
  placeholder,
  name,
  state,
  changeState,
  error,
  width,
  height,
  validate,
  hint,
  message,
  isDisabled,
  customClick,
  bottom,
  ...rest
}) => {
  const value = options?.find?.((x) => x?.value == state);
  const ref = useRef();
  const wasChangedFn = useCallback(
    function (cur, old) {
      return cur == null && !_.isEqual(cur, old) && options?.length;
    },
    [options]
  );
  const shouldResetValue = useWasChanged(value, wasChangedFn);
  useEffect(() => {
    if (shouldResetValue) {
      ref.current?.clearValue?.();
    }
  }, [shouldResetValue]);
  // Подписываюсь на изменение cell.value. Т.к. мы в режиме редактирования, cell.value меняться не будет
  useEffect(
    function initValidationOnTable() {
      if (rest?.hasOwnProperty('value')) {
        let _error = validate?.(state);
        if (_error) {
          changeState(state, _error);
        }
      }
    },
    [rest?.value]
  );
  return renderInput(
    <Selector
      {...rest}
      options={options}
      onChange={(opt) => {
        const _value = opt?.value;
        const err = validate?.(_value);
        changeState(_value, err);
      }}
      placeholder={placeholder}
      width="100%"
      height="100%"
      value={value}
      isMulti={false}
      marginBottom={0}
      inputRef={ref}
      isDisabled={isDisabled}
      message={message}
      customClick={customClick}
      bottom={bottom}
    />,
    {
      hint,
      focused: !!hint,
      error,
      width,
      height,
    }
  );
};

/**
 *
 * @param RControl {JSX.Element}
 * @param width
 * @param height
 * @param focused
 * @param hint
 * @param error
 * @return {Element}
 */
function renderInput(RControl, { width, height, focused, hint, error, key }) {
  const rc_style = {
    height: `calc(0.70 * ${height})`,
    // width: `calc(${width} - var(--sadr-font-size-4))`
    width,
  };
  const hintStyle = {
    height: `calc(0.3 * ${height})`,
    width: rc_style.width,
  };

  return (
    <div className="d-flex flex-column flex-nowrap edit-input-container" style={{ width, height }} key={key}>
      <div className="d-flex mx-auto justify-content-center align-items-center" style={rc_style}>
        {RControl}
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center" style={hintStyle}>
        {error && <span className="error-text">{error}</span>}
        {!error && focused && hint && <span className="hint-text">{hint}</span>}
      </div>
    </div>
  );
}

export function useMaskedInput({
  state,
  changeState,
  error,
  width,
  height,
  mask,
  valueToStr,
  getStateFromChange,
  locateMe,
  coord,
  ...rest
}) {
  const value = valueToStr(state);

  const [focused, setFocused] = useState(false);
  const [hint, setHint] = useState();
  let inputRef = useRef();

  // мягкая проброска значения в компонент
  useEffect(() => {
    if (inputRef.current) simulateOnChange(inputRef.current, value);
  }, [value]);

  function onChange(arr) {
    const { value, err } = getStateFromChange(arr);
    setHint(err);
    const actualError = err && !_.isEmpty(value) ? err : null;
    changeState(value, actualError);
  }

  return renderInput(
    <div
      id={locateMe ? 'cord-locate-container' : 'cord-container'}
      className="d-flex flex-row flex-nowrap w-100 align-items-center justify-content-center bg-transparent"
    >
      <MaskedInput
        {..._.omit(rest || {}, omitCellKeys)}
        name={locateMe ? 'cord-locate' : 'cord'}
        inputClasses="custom-masked-input"
        className=""
        defaultValue={value}
        mask={mask}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        onChange={onChange}
        inputRef={inputRef}
        coord={coord}
      />
    </div>,
    {
      error: error && hint,
      hint: !error && hint,
      width,
      height,
      focused,
    }
  );
}

export function useLoader() {
  // Получаем данные о процессе
  let loader = useSelector((state) => state.loader);

  return {
    isLoading: loader?.isLoading,
    isSuccess: !loader?.isLoading && loader?.isSuccess,
    isFailed: !_.isEmpty(loader) && !loader.isSuccess,
  };
}

/**
 * @typedef {Object} RailChain
 * @property {number} id - ID рельсовой цепи
 * @property {number} road - ID дороги
 * @property {number} staion - ID станции / перегона
 * @property {[number, number]} alertLengths - Длина участка оповещения [ПЧ, ЭЧ]
 * @property {string} object - Имя объекта
 * @property {string} rail_chain_name - Имя рельсовой цепи
 * @property {Coordinate} coordinate - координаты концов РЦ
 * @property {Location[]} track - возможный трэк РЦ (кривая)
 */

export function useIsEditing() {
  let loc = useLocation();
  let navigate = useUrlNavigate();
  const isEditing = useMemo(() => {
    let url = new URL(window.location.href);
    return url.searchParams.get('editing') == 'true';
  }, [loc.search]);
  const setEditing = useCallback(
    (editing = true) => {
      navigate((u) => {
        if (editing) u.searchParams.set('editing', 'true');
        else u.searchParams.delete('editing');
      });
    },
    [navigate]
  );
  // При размонтировании компонента убираем статус редактирования
  useEffect(() => () => setEditing(false), []);
  return { isEditing, setEditing };
}

export const renderCords =
  (getLinkFromLoc) =>
  ({ cell: { value: loc } }) => {
    const locations = Array.isArray(loc) ? loc : [loc];
    const first = locations[0];
    if (_.isEmpty(locations) || (locations.length == 1 && _.isEmpty(locations[0])))
      return <span className="number-cell">-</span>;
    return !first?.lat || !first?.lon ? (
      <span className="number-cell">-</span>
    ) : (
      <a href={getLinkFromLoc(loc).toString()} target="_blank" rel="noreferrer">
        <div className="d-flex flex-column flex-nowrap align-items-start container mx-auto">
          <span className="number-cell">{first.lat}</span>
          <span className="number-cell">
            {first?.lon}
            {locations.length > 1 ? '...' : ''}
          </span>
        </div>
      </a>
    );
  };

/**
 * Возвращает текущую инфокарту
 * @return {Infocard | {}}
 */
export const useInfocard = () => {
  const { station } = useParams();
  const source = useSelector((state) => state.infocards.items);  

  return useMemo(() => {
    if (!station || !Array.isArray(source)) {
      return {};
    }
    return source.find((item) => item.station === Number(station)) || {};
  }, [station, source]);
};


