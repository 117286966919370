import { useState } from 'react';
import '../../styles/AdminDataPanel.css';
import '../../styles/Documentation.css';
import Accordeon from '../CustomElements/Accordeon';
import CustomButton from '../CustomElements/CustomButton';
import HeaderForObject from '../CustomElements/HeaderForObject';
import ContractReport from './ContractReport';
import DownloadReport from './DownloadReport';
import UippsReport from './UippsReport';

function Documentation() {
  const [selectedReportType, setSelectedReportType] = useState('contract');

  function handleReportTypeChange(reportType) {
    setSelectedReportType(reportType);
  }

  return (
    <>
      <Accordeon header="ОТЧЕТНОСТЬ" hideAbove={1200} qParamName="chtoEtoZaHuita">
        <CustomButton
          key={0}
          width={'var(--sadr-font-size-320)'}
          color={selectedReportType === 'contract' ? 'var(--sadr-success)' : 'white'}
          border={selectedReportType === 'contract' ? '' : true}
          colorText={selectedReportType === 'contract' ? 'white' : 'var(--sadr-text-color)'}
          text={'Отчет по договору'}
          margin="0.5rem 0.5rem"
          onClick={() => handleReportTypeChange('contract')}
        />
        <CustomButton
          key={1}
          width={'var(--sadr-font-size-320)'}
          color={selectedReportType === 'uipps' ? 'var(--sadr-success)' : 'white'}
          border={selectedReportType === 'uipps' ? '' : true}
          colorText={selectedReportType === 'uipps' ? 'white' : 'var(--sadr-text-color)'}
          text={'Отчет по УИППС'}
          margin="0.5rem 0.5rem"
          onClick={() => handleReportTypeChange('uipps')}
        />
        <CustomButton
          key={2}
          width={'var(--sadr-font-size-320)'}
          color={selectedReportType === 'brigade' ? 'var(--sadr-success)' : 'white'}
          border={selectedReportType === 'brigade' ? '' : true}
          colorText={selectedReportType === 'brigade' ? 'white' : 'var(--sadr-text-color)'}
          text={'Отчет по работе бригад'}
          margin="0.5rem 0.5rem"
          onClick={() => handleReportTypeChange('brigade')}
        />
      </Accordeon>

      <div className="documentation_content">
        <HeaderForObject show={false} onFilterChange={() => {}} onFilterSubmit={() => {}} />

        {selectedReportType === 'contract' && <ContractReport />}
        {selectedReportType === 'brigade' && <DownloadReport />}
        {selectedReportType === 'uipps' && <UippsReport />}
      </div>
    </>
  );
}
export default Documentation;
