import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/UippsReport.css';

import Preloader from '../CustomElements/Preloader';
import UniversalFormForReports from './UniversalFormForReports';
import { getContractReportFromServer } from '../../redux/actions/documentation.action';
import CustomContainer from '../CustomElements/CustomContainer';
import ConfirmationPage from '../CustomElements/ConfirmationPage';
import Modal from '../Modal/Modal';

import { remove } from '../../redux/actions/loader.action';

function UippsReport({ closeModalForm }) {
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader);
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
    dispatch(remove());
  };
  const initialReportForm = {
    roadId: '',
    departmentId: '',
    directorateId: '',
    startDate: '',
    endDate: '',
  };
  const [uippsSummaryReport, setUippsSummaryReport] = useState({
    ...initialReportForm,
    reportType: 'summary',
    urlPath: 'uipps',
  });

  const [uippsDetailedReport, setUippsDetaileReport] = useState({
    ...initialReportForm,
    reportType: 'detailed',
    urlPath: 'uipps',
  });

  //отслеживаем выполнение запроса
  useEffect(() => {
    // Если нет ошибок, открываем модальное окно отчета
    if (!loader?.isLoading && loader?.isSuccess) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader?.isLoading, loader?.isSuccess]);

  useEffect(() => {
    // при закрытии компонента чистим стейт загрузки
    return () => {
      dispatch(remove());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="uipps-report">
      <div className="reports-form">
        <UniversalFormForReports
          title="Сводный отчет по использованию УИППС"
          action={getContractReportFromServer}
          optionalFields={['departmentId']}
          reportForm={uippsSummaryReport}
          initialReportForm={initialReportForm}
          setReportForm={setUippsSummaryReport}
        />
        <UniversalFormForReports
          title="Детализированный отчет по использованию УИППС"
          action={getContractReportFromServer}
          optionalFields={['departmentId']}
          reportForm={uippsDetailedReport}
          initialReportForm={initialReportForm}
          setReportForm={setUippsDetaileReport}
        />
      </div>
      <Preloader isLoading={loader?.isLoading} />
      <Modal isOpen={isOpen} onClose={closeModal} width="800px" height="500px">
        <CustomContainer>
          <ConfirmationPage
            addNew="К форме отчета"
            confirmText="Отчет сформирован!"
            onConfirm={closeModal} // Функция для закрытия модального окна или отмены действия
          />
        </CustomContainer>
      </Modal>
    </div>
  );
}

export default UippsReport;
