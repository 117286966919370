import { ADD_KUOBZ, CLEAR_KUOBZES, INIT_KUOBZES, UPDATE_SINGLE_KUOBZ } from '../type';
import axiosInstance from '../../config/axios.config';
import { addError } from './error.action';
import { success } from './loader.action';
import { loadRequest } from './requestLoader.action';
import setRequest from '../../utils/createSearchRequest';
import setFilteredData, { updateCanSendRequest, updateRequestBlocker } from './filterConfig.action';

export const initKuobzes = (kuobzes) => ({
  type: INIT_KUOBZES,
  payload: kuobzes,
});

export const clearKuobzes = () => ({
  type: CLEAR_KUOBZES,
  payload: [],
});

export const updateSingle = (kuobz) => ({
  type: UPDATE_SINGLE_KUOBZ,
  payload: kuobz,
});

export const addKuobz = (kuobz) => ({
  type: ADD_KUOBZ,
  payload: kuobz,
});
// получение всех устройств
export const initKuobzesFromServer = (searchString) => async (dispatch, getState) => {
  const state = getState();
  const lastId = state.filterConfig.lastId; // id последнего полученного устройства
  const canSendRequest = state.filterConfig.canSendRequest; // флаг возможна ли отправка запроса (все ли данные получены)
  const searchFlag = state.filterConfig.searchFlag; // флаг если произведён поиск
  const searchData = state.filterConfig.searchData; // текущее значение поискового запроса
  const blockRequest = state.filterConfig.blockRequest; // флаг блокировки запроса на сервер
  try {
    dispatch(loadRequest(true));
    // вызов утилиты по формированию query-строки для запроса
    if (canSendRequest && !blockRequest) {
      const searchReaquest = setRequest('', searchString, lastId, searchFlag, searchData);
      // получение данных с сервера
      const result = await axiosInstance(`/api/kuobz${searchReaquest}${searchReaquest ? '&' : '?'}limit=50`);
      dispatch(updateCanSendRequest(true));
      if (result.data && result.status === 200) {
        dispatch(initKuobzes(result.data));
        // формируется стейт вспомогательных данных для бесконечной подгрузки и перезапросов
        await dispatch(setFilteredData(result.data, searchString, searchReaquest, searchFlag, searchData));
      } else {
        //---- if api request fails - show error for user
        dispatch(addError(result.data));
      }
      dispatch(updateRequestBlocker(false));
    }
  } catch (error) {
    console.log(error);
  }
};

// добавление нового устройства
export const newKuobzFromServer = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.post('/api/kuobz', payload);
    if (response.status === 201) {
      // записываем в стейт сообщение об успешном выполнении
      dispatch(success());

      dispatch(addKuobz(response.data));
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// изменение устройства
export const editKuobzFromServer = (payload, kuobzId) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(`/api/kuobz/${kuobzId}`, payload);
    if (response.status === 200) {
      // записываем в стейт сообщение об успешном выполнении
      dispatch(success());
      dispatch(getOnlyOneKuobzFromServer(kuobzId));
    }
  } catch (error) {
    const message = error.response.data || 'Возникла непредвиденная ошибка, обратитесь к администратору системы.';
    dispatch(addError(message));
    console.log(error);
  }
};

// Получение обновлённых данных КУОБЗ после изменения его данных
export const getOnlyOneKuobzFromServer = (id) => async (dispatch) => {
  try {
    const kuobzData = await axiosInstance(`/api/kuobz?id=${id}`);
    if (kuobzData.data[0]) {
      dispatch(updateSingle(kuobzData.data[0]));
    }
  } catch (error) {
    console.log(error);
  }
};
