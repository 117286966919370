import axiosInstance from '../../config/axios.config';
import { FETCH_DATA_REQUEST, SELECT_PARAMETER } from '../type';

const setSelected = (payload) => ({
  type: SELECT_PARAMETER,
  payload,
});

const fetchDataRequest = (payload) => ({
  type: FETCH_DATA_REQUEST,
  payload,
});

const getData = (parameter) => async (dispatch) => {
  try {
    const { data } = await axiosInstance(`/api/data/${parameter}`);

    // Если параметр departments, преобразуем ключи
    const processedData =
      data && parameter === 'departments'
        ? data.map(({ shift_start: shiftStart, shift_end: shiftEnd, ...rest }) => ({
            ...rest,
            shiftStart,
            shiftEnd,
          }))
        : data;

    if (processedData) {
      dispatch(fetchDataRequest({ data: processedData, parameter }));
    }
  } catch (error) {
    console.error(error);
  }
};

export { getData, setSelected };
