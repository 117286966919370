/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../../styles/Brigades.css';

import FilterForBrigades from '../Filter/FilterForBrigades';
import TableForBrigades from '../Table/TablesForBrigades';
import MultiMarkerMap from '../Maps/MultiMarkerMap';
import HeaderForObject from '../CustomElements/HeaderForObject';

import useTranslations from '../../customHook/useTranslations';
import {
  clearBrigades,
  emitGetCoordsStart,
  emitGetCoordsStop,
  listenBrigadesCoords,
} from '../../redux/actions/brigades.action';
import { initBrigadesFromServer, listenUpdateBrigade } from '../../redux/actions/brigades.action';
import { clearFilterConfig } from '../../redux/actions/filterConfig.action';
import translations from '../../content/PagesContent/BrigadePages/brigadePageTranslations.json';
import { setFilterState } from '../../redux/actions/filterState.action';

function Brigades(props) {
  const dispatch = useDispatch();

  // получение словаря для статических данных в зависимости от выбраного языка
  const t = useTranslations(translations);
  const [isViewMap, setIsViewMap] = useState(false);
  const [isViewList, setIsViewList] = useState(true);

  // состояние для строки поиска
  const [filterInput, setFilterInput] = useState('');

  // Начальное состояние для стейта фильтрации передаем пропсами
  const initialFormData = {
    id: '',
    responsible: '',
    road_id: '',
    department_id: '',
    station_id: '',
    start: '',
    finish: '',
    deviceId: '',
    directorate_id: '',
  };
  const [formData, setFormData] = useState(initialFormData);
  const runDispathes = () => {
    dispatch(clearBrigades());
    dispatch(clearFilterConfig());
    dispatch(initBrigadesFromServer(formData, filterInput));
  };
  // функция автоматической отправки запроса фильтрации при вводе текста в строке поиска
  // useEffect(() => {
  //   if (isViewList) {
  //     runDispathes();
  //   }
  // }, [filterInput]);
  
  // функция для отправки запроса фильтрации
  const onFilterSubmit = (event) => {
    event.preventDefault();
    if (isViewList) {
      runDispathes();
    }
  };

  // Стейт для очистки всех значений при переключении между картой и таблицей
  useEffect(() => {
    // Сбрасываем все поля поиска и фильтрации
    dispatch(
      setFilterState({
        search: false,
        filter: false,
        blockFlag: false,
      }))
      dispatch(clearBrigades());
      dispatch(clearFilterConfig());
      dispatch(initBrigadesFromServer(formData));
  }, [isViewList])

  useEffect(() => {
    // слушаем событие получения координат
    dispatch(listenBrigadesCoords());
    // слушаем событие получения обновленной инфы для бригад
    dispatch(listenUpdateBrigade());
  }, []);

  return (
    <div className="brigades">
      <FilterForBrigades
        setIsViewList={setIsViewList}
        setIsViewMap={setIsViewMap}
        isViewList={isViewList}
        isViewMap={isViewMap}
        initialFormData={initialFormData}
        formData={formData}
        setFormData={setFormData}
        t={t}
      />
      <div className="brigades_content">
        <div className="header-object-container">
          <HeaderForObject
            searchInput={filterInput}
            onFilterChange={(e) => {
              setFilterInput(e?.target?.value);
            }}
            onFilterSubmit={onFilterSubmit}
          />
        </div>

        {isViewList && <TableForBrigades formData={formData} t={t} />}
        {isViewMap && (
          <MultiMarkerMap
            emitStartFunction={emitGetCoordsStart}
            listenStopFunction={emitGetCoordsStop}
            filterInput={filterInput}
            formData={formData}
            // setSearchInput={setFilterInput}
          />
        )}
      </div>
    </div>
  );
}

export default Brigades;
